import { defineMessages } from "react-intl";

const messages = defineMessages({
  onboardingDesc: {
    id: "tripPlanner.onboarding.recommendedRoute",
    defaultMessage: "Suggested travel option, click to see more.",
    description: "Onboarding description for recommended route",
  },

  onboardingDismiss: {
    id: "tripPlanner.onboarding.dismiss",
    defaultMessage: "Got it",
    description: "Onboarding dismiss button",
  },
});

export default messages;
