import { useCallback, useEffect } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import logError from "src/utils/logError";
import { useApiConfig } from "../../api/ApiConfigProvider";
import { useAuthProviders } from "../contexts/AuthContext";
import { useHandleCreateSession } from "../hooks/useHandleCreateSession";
import { UserProfileUpdateValues } from "../utils/user";

const clientId = "com.rome2rio.www";

async function initializeAppleSignin() {
  window.AppleID.auth.init({
    clientId: clientId,
    // eslint-disable-next-line no-restricted-globals
    redirectURI: "https://" + location.hostname,
    scope: "email name",
    usePopup: true,
  });
}

export function useSignInWithAppleButton() {
  const { setAuthProvidersLoading } = useAuthProviders();
  const apiConfig = useApiConfig();
  const handleCreateSession = useHandleCreateSession("apple");

  useEffect(() => {
    // These are failures that aren't errors, so we send them to analytics.
    const loggedFailures = ["popup_closed_by_user", "popup_blocked_by_browser"];

    // Listen for authorization failures.
    function errorCallback(event: CustomEventInit) {
      setAuthProvidersLoading(false);
      if (loggedFailures.includes(event.detail?.error)) {
        sendAnalyticsInteractionEvent(
          "AppleSignIn",
          `Failure:${event.detail?.error}`
        );
      } else {
        logError(event, ["user-accounts", "apple-sign-in"]);
      }
    }
    document.addEventListener("AppleIDSignInOnFailure", errorCallback);

    // Listen for authorization success.
    function successCallback(event: CustomEventInit<AppleCredentialResponse>) {
      setAuthProvidersLoading(false);
      // Handle successful response.
      if (!event.detail?.authorization.id_token) {
        return errorCallback(event);
      }
      const user = getUserInformation(event.detail);
      handleCreateSession(event.detail.authorization.id_token, user);
    }
    document.addEventListener("AppleIDSignInOnSuccess", successCallback);

    return () => {
      document.removeEventListener("AppleIDSignInOnSuccess", successCallback);
      document.removeEventListener("AppleIDSignInOnFailure", errorCallback);
    };
  }, [apiConfig, handleCreateSession, setAuthProvidersLoading]);

  const signInWithApple = useCallback(async () => {
    await signInWithAppleAdapter();
    setAuthProvidersLoading(false);
  }, [setAuthProvidersLoading]);

  return {
    signInWithApple,
  };
}

async function signInWithAppleAdapter() {
  try {
    await window.AppleID.auth.signIn();
  } catch (error) {
    // Errors from this callback, and any other apple provided errors are sent directly to an event listener, where we log the error.
    // Logging the error here will cause it to be logged twice.
  }
}

function getUserInformation(
  detail: AppleCredentialResponse
): UserProfileUpdateValues | undefined {
  const { user } = detail;
  if (!user) return undefined;
  const firstName = user.name.firstName;
  const lastName = user.name.lastName;
  const name = `${firstName || ""} ${lastName || ""}`.trim();
  return {
    name,
  };
}

export function useInitializeAppleSignin() {
  const { setAppleLoading } = useAuthProviders();

  // We want to avoid the race condition where the script is loaded after the
  // component is mounted, so we add the event listener if the component is mounted before the script is loaded
  useEffect(() => {
    if (window.AppleID) {
      initializeAppleSignin();
      setAppleLoading(false);
      return;
    }

    const script = document.getElementById(
      "apple-sign-in-script"
    ) as HTMLScriptElement;

    if (!script) {
      setAppleLoading(false);
      return;
    }

    function onAppleSignInLibraryLoad() {
      initializeAppleSignin();
      setAppleLoading(false);
    }

    script.addEventListener("load", onAppleSignInLibraryLoad);
    return () => {
      script.removeEventListener("load", onAppleSignInLibraryLoad);
    };
  }, [setAppleLoading]);
}
