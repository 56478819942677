import { FormattedMessage, useIntl } from "react-intl";
import { FormattedPrice } from "src/components/FormattedPrice/FormattedPrice";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import color from "src/design-system/tokens/color";
import { HotelDealInfo } from "src/api/HotelListResponse";
import { border_radius } from "src/design-system/tokens/border";
import {
  HeadingMd,
  LabelSm,
} from "src/design-system/components/Typography/Typography";
import messages from "./messages";

type Props = {
  price: { value: number; currency: string };
  deal?: HotelDealInfo;
  nightsStay: number;
};

export function PricingInfo({ price, deal, nightsStay }: Props) {
  return (
    <Container>
      <Prices>
        {deal && (
          <LabelSm decoration="line-through" color={color.text.secondary}>
            <FormattedPrice
              value={deal.originalPrice}
              currency={price.currency}
            />
          </LabelSm>
        )}
        <HeadingMd color={deal && color.text.success}>
          <FormattedPrice {...price} />
        </HeadingMd>
      </Prices>
      <PerNight nightsStay={nightsStay} />
    </Container>
  );
}

export function PerNight({ nightsStay }: { nightsStay: number }) {
  // TODO: No color/Text/SecondaryForeground. Why are there multiple secondary colors?
  return (
    <LabelSm color="#555555">
      <FormattedMessage
        {...(nightsStay === 1
          ? messages.nightsSingle
          : messages.nightsMultiple)}
        values={{ nightsAmount: nightsStay }}
      />
    </LabelSm>
  );
}

type DiscountTagProps = { value: number; vPadding: "xs" | "md" };

export function DiscountTag({ value, vPadding }: DiscountTagProps) {
  const intl = useIntl();
  // intl.formatNumber adds a non breaking space between the number and the percent sign
  // which we want to remove
  const percent = intl
    .formatNumber(value / 100, { style: "percent" })
    .replace(/\s/g, "");

  return (
    <DiscountTagContainer $vPadding={vPadding}>
      <LabelSm weight="bold" color={color.text.onBgFill}>
        <FormattedMessage {...messages.percentOff} values={{ percent }} />
      </LabelSm>
    </DiscountTagContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  row-gap: ${spacing.sm};
`;

const Prices = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  gap: ${spacing.sm};
`;

const DiscountTagContainer = styled.div<{ $vPadding: "xs" | "sm" | "md" }>`
  position: absolute;
  top: ${spacing.lg};
  left: ${spacing.lg};
  display: flex;
  align-items: center;

  border-radius: ${border_radius.rounded_md};
  padding: ${(props) => spacing[props.$vPadding]} ${spacing.lg};
  background-color: ${color.text.success};
`;
