import { PropsWithChildren } from "react";
import { color, fontSize, fontWeight, lineHeight, spacing } from "src/theme";
import styled from "styled-components";

export const placeNumberOffsetPx = 16;

type TripPlannerPlaceTitleProps = {
  index: number;
};

export function TripPlannerPlaceTitle({
  index,
  children,
}: PropsWithChildren<TripPlannerPlaceTitleProps>) {
  return (
    <TripPlannerPlaceTitleWrapper>
      <TripPlannerNumber>{index + 1}</TripPlannerNumber>
      <SubtitleTripPlanner>{children}</SubtitleTripPlanner>
    </TripPlannerPlaceTitleWrapper>
  );
}

const TripPlannerPlaceTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TripPlannerNumber = styled.span`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.snug};
  color: ${color.white};
  border-radius: 50%;
  width: ${placeNumberOffsetPx * 2}px;
  height: ${placeNumberOffsetPx * 2}px;
  background-color: ${color.n300};
  margin: 0 ${spacing.md};
`;

const SubtitleTripPlanner = styled.span`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.h4};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
  margin-top: calc(${placeNumberOffsetPx / 2}px - 0.15em);
`;
