import { defineMessages } from "react-intl";

const messages = defineMessages({
  hideError: {
    id: "tripPlannerMap.hideError",
    description: "Button that hides the error message",
    defaultMessage: "Hide this message",
  },
  error: {
    id: "tripPlannerMap.error",
    description: "Error message when the map fails to load",
    defaultMessage:
      "We can’t get that map right now. Refresh or if it still doesn’t work, you can click below to hide this message.",
  },
  closeMobileMap: {
    id: "tripPlannerMap.closeMobileMap",
    description: "Button that hides the full screen map",
    defaultMessage: "Close Map",
  },
  expandMobileMap: {
    id: "tripPlannerMap.expandMobileMap",
    description: "Button that shows the full screen map",
    defaultMessage: "Expand Map",
  },
});

export default messages;
