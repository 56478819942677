import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import discoverMessages from "src/domain/Discover/Discover.messages";
import { LogoLink, LogoOption, Nav } from "src/domain/TopNav/TopNav";
import { messages } from "src/domain/TopNav/TopNav.messages";
import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useTheme } from "src/utils/hooks/useTheme";
import { getHomepageUrl } from "src/utils/url";
import styled from "styled-components";

import { ErrorBoundary } from "@sentry/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { Button } from "src/design-system/components/Button/Button";
import { Typography } from "src/design-system/components/Typography/Typography";
import color from "src/design-system/tokens/color";
import { DiscoverMap } from "src/domain/Discover/DiscoverMap";
import { DiscoverPaneCards } from "src/domain/Discover/DiscoverPaneCards";
import { DiscoverProvider } from "src/domain/Discover/DiscoverProvider";
import DiscoverSearch from "src/domain/Discover/DiscoverSearch/DiscoverSearch";
import { screenMinWidth, spacing } from "src/theme";
import { useGeocode } from "src/utils/hooks/useGeocode";
import { useSearchParamPlaces } from "src/utils/hooks/useSearchParamPlaces";

export default function Discover() {
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const homepageUrl = getHomepageUrl(languageCode);
  const theme = useTheme();
  const { getPlacesFromSearchParams } = useSearchParamPlaces();
  const searchQueryPlaces = getPlacesFromSearchParams();
  const place = searchQueryPlaces.length ? searchQueryPlaces[0] : undefined;
  const { data: initialQueryData } = useGeocode(place);

  const [origin, setOrigin] = useState<{
    canonicalName: string;
    lat: number;
    lng: number;
  }>();
  const [isGeocoding, setIsGeocoding] = useState(false);

  useEffect(() => {
    if (initialQueryData && initialQueryData.lat) {
      setOrigin(initialQueryData);
    }
  }, [initialQueryData]);

  return (
    <DefaultErrorBoundary>
      <DiscoverProvider>
        <DiscoverDiv>
          <DiscoverNav isLargeNav>
            <LogoOption $small as="div">
              <LogoLink
                href={homepageUrl}
                onClick={() =>
                  sendAnalyticsInteractionEvent("TopNav", "Click:Home")
                }
              >
                <Rome2rioLogo
                  title={intl.formatMessage(messages.homepage)}
                  tint={theme.topNav.iconTint}
                  dotTint="pink"
                />
              </LogoLink>
            </LogoOption>
          </DiscoverNav>
          <DiscoverPane>
            <DiscoverSearch
              {...{ setOrigin, setIsGeocoding }}
              initialQuery={initialQueryData?.shortName ?? place}
            />
            <DiscoverResults>
              <QueryErrorResetBoundary>
                {({ reset }) => (
                  <ErrorBoundary
                    onReset={reset}
                    fallback={({ resetError }) => (
                      <>
                        <Typography variant="body-lg" as="h4">
                          {intl.formatMessage(discoverMessages.requestFailed)}
                        </Typography>
                        <Button onPress={resetError}>
                          {intl.formatMessage(discoverMessages.retry)}
                        </Button>
                      </>
                    )}
                  >
                    <DiscoverPaneCards {...{ isGeocoding, origin }} />
                  </ErrorBoundary>
                )}
              </QueryErrorResetBoundary>
            </DiscoverResults>
          </DiscoverPane>
          <DiscoverMap originRequest={origin} />
        </DiscoverDiv>
      </DiscoverProvider>
    </DefaultErrorBoundary>
  );
}

const DiscoverDiv = styled.div`
  position: fixed;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: min-content auto;
  height: 100%;
  width: 100%;
`;

const DiscoverNav = styled(Nav)`
  grid-column: 1 / 3;
`;

const DiscoverPane = styled.div`
  overflow-y: auto;
  padding-bottom: ${spacing.xxxxl};
  width: 384px;
  ${screenMinWidth.md} {
    width: 496px;
  }
  ${screenMinWidth.xl} {
    width: 576px;
  }
`;

const DiscoverResults = styled.div`
  padding: ${spacing.xl} ${spacing.xxl};
  background-color: ${color.bg.surface.active};
`;
