import { LocationState, TypedLocation } from "../hooks/useTypedLocation";
import { cloneLocation } from "./cloneLocation";

export function stateChange(
  update: Partial<LocationState>,
  current: TypedLocation
): TypedLocation {
  return {
    ...cloneLocation(current),
    state: {
      ...current.state,
      ...update,
    },
  };
}
