import { useState } from "react";
import { useNavigate } from "react-router";
import { SearchFilter } from "src/components/SearchFilter/SearchFilter";
import styled from "styled-components";
import { DebugFeatureInput } from "../../components/DebugWindow/DebugFeatureInput";
import { Icon } from "../../components/Icon/Icon";
import { FeatureName, PossibleFeaturesMap } from "../../feature/FeatureConfig";
import { setSearchParamsFeature } from "../../feature/internal/searchParams/useSearchParamsFeatures";
import { useFeatureConfig } from "../../feature/useFeatureConfig";
import { ChevronDown } from "../../svg/ChevronDown";
import { fontSize, fontWeight, spacing } from "../../theme";
import { largeDesktopLayout, useLayout } from "../../utils/hooks/useLayout";
import { useTheme } from "../../utils/hooks/useTheme";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { searchParamsChange } from "../../utils/location/searchParamsChange";

export function FrontendFeatureSelector() {
  const location = useTypedLocation();
  const featureConfig = useFeatureConfig();
  const navigate = useNavigate();
  const theme = useTheme();
  const layout = useLayout();

  const featureKeys = Object.keys(PossibleFeaturesMap).sort();

  const [featuresKeysFiltered, setFeaturesKeysFiltered] = useState(featureKeys);

  const [isExpanded, setIsExpanded] = useState(false);

  const setFeature = (featureKey: FeatureName, valueIndex: number) => {
    const newSearchParams = setSearchParamsFeature(
      featureKey,
      PossibleFeaturesMap[featureKey][valueIndex],
      location.search
    );

    /** Without this, on select of a feature, the screen will update to search.
     *  We also don't want to force this on desktop, as it updates the pane on
     *  the left.
     *  */
    if (layout === "mobile") {
      navigate(searchParamsChange(newSearchParams, location), {
        replace: true,
        state: {
          highlightedTab: "preferences",
          preferencesScreen: "debug",
        },
      });
    } else {
      navigate(searchParamsChange(newSearchParams, location), {
        replace: true,
      });
    }
  };

  const toggleExpand = () => {
    if (isExpanded) setIsExpanded(false);
    else setIsExpanded(true);
  };

  return (
    <>
      <FeatureItem onClick={toggleExpand} pointer={true}>
        <h3>Frontend Features</h3>
        <StyledChevronIcon pointUp={isExpanded} size="sm">
          <ChevronDown tint={theme.topNav.iconTint} />
        </StyledChevronIcon>
      </FeatureItem>
      {isExpanded && (
        <>
          <SearchFilterContainer>
            <SearchFilter
              data={featureKeys}
              onFilter={(filteredData) => setFeaturesKeysFiltered(filteredData)}
              filteringFunction={(item, filter) =>
                item.toLowerCase().includes(filter.toLowerCase())
              }
            />
          </SearchFilterContainer>
          {featuresKeysFiltered.map((key) => {
            const featureKey = key as FeatureName;
            const featureValue = PossibleFeaturesMap[featureKey].findIndex(
              (v) => v === featureConfig[featureKey]
            );

            return (
              <FeatureItem key={key}>
                {/* The title attribute is used to display a tooltip text when the mouse moves over the element. */}
                {/* So if the text is hidden by overflow, the tooltip will show the full text. */}
                <Text title={key}>{key}</Text>
                <DebugFeatureInput
                  featureName={featureKey}
                  valueIndex={featureValue}
                  possibleFeatureValues={PossibleFeaturesMap[featureKey]}
                  setFeature={setFeature}
                />
              </FeatureItem>
            );
          })}
        </>
      )}
    </>
  );
}

const FeatureItem = styled.div<{ pointer?: boolean }>`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.normal};
  border-bottom: 1px solid #ebebeb;
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
  width: 100%;
  ${largeDesktopLayout} {
    width: 400px;
  }
`;

const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledChevronIcon = styled(Icon)<{
  pointUp?: boolean;
}>`
  margin-left: ${spacing.md};

  ${(props) => {
    if (props.pointUp) {
      return "transform: rotate(180deg);";
    }
  }}
`;

const SearchFilterContainer = styled.div`
  padding-inline: ${spacing.xl};
  padding-block: ${spacing.md};
  display: flex;
  justify-content: flex-end;
`;
