import { PropsWithChildren, SyntheticEvent, useRef } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import styled from "styled-components";
import { Close } from "../../svg/Close";
import { Settings } from "../../svg/Settings";
import {
  color,
  fontSize,
  fontWeight,
  iconSize,
  lineHeight,
  spacing,
} from "../../theme";
import { useTheme } from "../../utils/hooks/useTheme";
import { LocationState } from "../../utils/hooks/useTypedLocation";
import { ButtonBase } from "../Button/ButtonBase";
import { Dialog } from "../Dialog/Dialog";
import { Icon } from "../Icon/Icon";
import { ThemeMap } from "../Theme/Theme";
import { VisuallyHidden } from "../VisuallyHidden/VisuallyHidden";
import { messages } from "./UserPreferencesModal.messages";

type UserPreferencesModalProps = {
  size: keyof typeof iconSize;
  label?: string;
  isModalOpen: boolean;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  highlightScreen: LocationState["preferencesScreen"];
  openLanguageScreen: () => void;
  openCurrencyScreen: () => void;
  openDisplayScreen: () => void;
};

export function UserPreferencesModal(
  props: PropsWithChildren<UserPreferencesModalProps>
) {
  const theme = useTheme();
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const userPreferences = [
    {
      title: intl.formatMessage(messages.language),
      onClick: props.openLanguageScreen,
      highlighted: props.highlightScreen === "language",
    },
    {
      title: intl.formatMessage(messages.currency),
      onClick: props.openCurrencyScreen,
      highlighted: props.highlightScreen === "currency",
    },
    {
      title: intl.formatMessage(messages.display),
      onClick: props.openDisplayScreen,
      highlighted:
        props.highlightScreen === "distance" ||
        props.highlightScreen === "timeFormat",
    },
  ];

  function handleCloseClicked() {
    sendAnalyticsInteractionEvent("Preferences", "Click:Close");
    props.handleCloseModal();
  }

  function handleBackdropClicked(e: SyntheticEvent) {
    e.stopPropagation();
    sendAnalyticsInteractionEvent("Preferences", "Click:Shadow");
    props.handleCloseModal();
  }

  return (
    <DropdownWrapper>
      <DropdownButton
        aria-label={props.label}
        onClick={props.handleOpenModal}
        theme={theme}
      >
        <Icon size={props.size}>
          <Settings
            tint={theme.topNav.iconTint}
            title={intl.formatMessage(messages.preferences)}
          />
        </Icon>
      </DropdownButton>
      <Dialog
        isOpen={props.isModalOpen}
        onBackdropClicked={handleBackdropClicked}
        variant="extra-large"
        // setting the duration to 0 to avoid the animation when the modal is closed
        // so the modal has the same behaviour as the dropdown
        // and avoid showing up the main user preferences screen for a split second
        duration={1}
        focusRef={containerRef}
      >
        <Container
          onClick={(e: SyntheticEvent) => e.stopPropagation()}
          ref={containerRef}
          tabIndex={-1}
        >
          <TopNav>
            <VisuallyHidden>
              <h1>{intl.formatMessage(messages.modalTitle)}</h1>
            </VisuallyHidden>
            <Navigation>
              {userPreferences.map((preference) => (
                <UserPreference
                  title={preference.title}
                  onClick={preference.onClick}
                  highlighted={preference.highlighted}
                  key={preference.title}
                />
              ))}
            </Navigation>
            <IconButton onClick={handleCloseClicked}>
              <Close title="Close" tint="n90" />
            </IconButton>
          </TopNav>
          {props.children}
        </Container>
      </Dialog>
    </DropdownWrapper>
  );
}

function UserPreference({
  title,
  onClick,
  highlighted,
}: {
  title: string;
  onClick: () => void;
  highlighted: boolean;
}) {
  return (
    <UserPreferenceItem>
      <StyledButton onClick={onClick} $highlighted={highlighted}>
        {title}
      </StyledButton>
    </UserPreferenceItem>
  );
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownButton = styled.button<{ theme: ThemeMap }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover path {
    fill: ${(props) => props.theme.topNav.textHover};
  }
`;

const Container = styled.div`
  padding: ${spacing.xxl};
`;

const IconButton = styled(ButtonBase)`
  width: 18px;
  height: 18px;
  svg {
    width: 13.5px;
    height: 13.5px;
  }
`;

const TopNav = styled.div`
  display: flex;
  justify-content: space-between;
  // using box-shadow instead of border-bottom to avoid the border to be cut off
  box-shadow: 0 1px 0 0 ${color.n40};
  border-width: 1px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
`;

const Navigation = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: ${spacing.xxxl};
`;

const UserPreferenceItem = styled.li``;

const StyledButton = styled(ButtonBase)<{
  $highlighted?: boolean;
}>`
  font-weight: ${({ $highlighted }) =>
    $highlighted ? fontWeight.medium : fontWeight.normal};
  font-size: ${fontSize.h4};
  line-height: ${lineHeight.tight};
  color: ${color.black};
  padding-bottom: ${spacing.md};
  position: relative;
  margin-bottom: 1px;
  // using box-shadow instead of border-bottom to avoid the border to be cut off
  box-shadow: 0 2px 0 0
    ${({ $highlighted }) => ($highlighted ? color.pink : "transparent")};
  &:focus-visible {
    outline: 3px solid ${color.pink};
    outline-offset: -2px;
    z-index: 1;
  }
  // Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.
  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;
