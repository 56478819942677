import { defineMessages } from "react-intl";

const messages = defineMessages({
  train: {
    id: "transitMode.train",
    description:
      "Message after the duration with the transport type e.g. 4min by train",
    defaultMessage: "by train",
  },
  subway: {
    id: "transitMode.subway",
    description:
      "Message after the duration with the transport type e.g. 4min by subway",
    defaultMessage: "by subway",
  },
  cartrain: {
    id: "transitMode.cartrain",
    description:
      "Message after the duration with the transport type e.g. 4min by car train",
    defaultMessage: "by car train",
  },
  nighttrain: {
    id: "transitMode.nighttrain",
    description:
      "Message after the duration with the transport type e.g. 4min by night train",
    defaultMessage: "by night train",
  },
  tram: {
    id: "transitMode.tram",
    description:
      "Message after the duration with the transport type e.g. 4min by tram",
    defaultMessage: "by tram",
  },
  cablecar: {
    id: "transitMode.cablecar",
    description:
      "Message after the duration with the transport type e.g. 4min by cable car",
    defaultMessage: "by cable car",
  },
  bus: {
    id: "transitMode.bus",
    description:
      "Message after the duration with the transport type e.g. 4min by bus",
    defaultMessage: "by bus",
  },
  busferry: {
    id: "transitMode.busferry",
    description:
      "Message after the duration with the transport type e.g. 4min by bus ferry",
    defaultMessage: "by bus ferry",
  },
  nightbus: {
    id: "transitMode.nightbus",
    description:
      "Message after the duration with the transport type e.g. 4min by night bus",
    defaultMessage: "by night bus",
  },
  shuttle: {
    id: "transitMode.shuttle",
    description:
      "Message after the duration with the transport type e.g. 4min by shuttle",
    defaultMessage: "by shuttle",
  },
  rideshare: {
    id: "transitMode.rideshare",
    description:
      "Message after the duration with the transport type e.g. 4min with rideshare",
    defaultMessage: "with rideshare",
  },
  flight: {
    id: "transitMode.flight",
    description:
      "Message after the duration with the transport type e.g. 4min by flight",
    defaultMessage: "by flight",
  },
  helicopter: {
    id: "transitMode.helicopter",
    description:
      "Message after the duration with the transport type e.g. 4min by helicopter",
    defaultMessage: "by helicopter",
  },
  ferry: {
    id: "transitMode.ferry",
    description:
      "Message after the duration with the transport type e.g. 4min by ferry",
    defaultMessage: "by ferry",
  },
  carferry: {
    id: "transitMode.carferry",
    description:
      "Message after the duration with the transport type e.g. 4min by car ferry",
    defaultMessage: "by car ferry",
  },
  taxi: {
    id: "transitMode.taxi",
    description:
      "Message after the duration with the transport type e.g. 4min by taxi",
    defaultMessage: "by taxi",
  },
  walk: {
    id: "transitMode.walk",
    description:
      "Message after the duration with the transport type e.g. 4min walk",
    defaultMessage: "walk",
  },
  animal: {
    id: "transitMode.animal",
    description:
      "Message after the duration with the transport type e.g. 4min by animal",
    defaultMessage: "by animal",
  },
  towncar: {
    id: "transitMode.towncar",
    description:
      "Message after the duration with the transport type e.g. 4min by towncar",
    defaultMessage: "by towncar",
  },
  bicycle: {
    id: "transitMode.bicycle",
    description:
      "Message after the duration with the transport type e.g. 4min by bicycle",
    defaultMessage: "by bicycle",
  },
  car: {
    id: "transitMode.car",
    description:
      "Message after the duration with the transport type e.g. 4min by car",
    defaultMessage: "by car",
  },
  chevronIcon: {
    id: "routeDetail.chevronIcon",
    description: "Tells screen readers what the arrow icon means",
    defaultMessage: "Open route",
  },
  fromTo: {
    id: "routeSegmentDetail.fromTo",
    description: "Tells screen readers which route segment they are looking at",
    defaultMessage: `from {origin} to {destination}`,
  },
});

export default messages;
