import { defineMessages } from "react-intl";

const messages = defineMessages({
  close: {
    id: "closeButton.close",
    description: "Close button",
    defaultMessage: "Close",
  },
});

export default messages;
