import { defineMessages } from "react-intl";

const messages = defineMessages({
  header: {
    id: "searchResultsCarHirePromo.header",
    defaultMessage: "Car Hire",
    description: "Header for a car hire promo",
  },
  tagline: {
    id: "searchResultsCarHirePromo.tagline",
    defaultMessage: "Compare best rates",
    description: "Tagline in the car hire promo",
  },
});

export default messages;
