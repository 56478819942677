import { Typography } from "src/design-system/components/Typography/Typography";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { Icon } from "src/design-system/components/Icon/Icon";
import { Plus } from "src/svg/tripplanner/Plus";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { hashChange } from "src/utils/location/hashChange";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { A11yOutline, A11yTargetSize } from "src/utils/accessibility";
import color from "src/design-system/tokens/color";
import { border_radius } from "src/design-system/tokens/border";
import { font_weight } from "src/design-system/tokens/typography";
import { TripList } from "../MyTrips/TripList";
import messages from "./SavedTripsScreen.messages";

export function SavedTripsScreen() {
  const intl = useIntl();
  const location = useTypedLocation();
  const [numVisibleTrips, setNumVisibleTrips] = useState(5);

  return (
    <>
      <HeadingWrapper>
        <Typography variant="heading-xl">
          {intl.formatMessage(messages.savedTrips)}
        </Typography>
        <StyledLink to={hashChange("#trips/create/", location)}>
          <StyledIcon size="small">
            <Plus tint="cod" />
          </StyledIcon>{" "}
          <Typography variant="label-md">
            {intl.formatMessage(messages.newButton)}
          </Typography>
        </StyledLink>
      </HeadingWrapper>
      <TripList
        numVisibleTrips={numVisibleTrips}
        setNumVisibleTrips={setNumVisibleTrips}
        isFullScreen
      />
    </>
  );
}

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.xl};
`;

const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
  ${A11yTargetSize};
  ${A11yOutline};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: ${color.bg.fill.fill};
  text-decoration: none;
  color: ${color.text.primary};
  border: 1px solid ${color.border.border};
  border-radius: ${border_radius.rounded_md};
  padding: ${spacing.md} ${spacing.lg};
  font-weight: ${font_weight.medium};

  &:hover,
  &:focus {
    background-color: ${color.bg.fill.hover};
  }
`;
