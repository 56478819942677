export function ChangeTransferIcon(props: { tint: string; title?: string }) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {props.title && <title>{props.title}</title>}
      <path
        d="M11.3093 0.948241V1.67414H10.6474C9.43 1.67414 8.28749 2.26209 7.57986 3.25277L4.3435 7.78367C3.96247 8.31712 3.34728 8.63371 2.69173 8.63371H0.869946C0.389488 8.63371 0 9.02319 0 9.50365C0 9.98411 0.389488 10.3736 0.869946 10.3736H2.69173C3.90918 10.3736 5.05168 9.78564 5.75931 8.79496L8.99567 4.26406C9.3767 3.73062 9.9919 3.41403 10.6474 3.41403H11.3093V4.13993C11.3093 4.43171 11.6468 4.59393 11.8747 4.41165L13.8695 2.81581C14.0436 2.67651 14.0436 2.41166 13.8695 2.27236L11.8747 0.676516C11.6468 0.494241 11.3093 0.656459 11.3093 0.948241Z"
        fill={props.tint}
      />
      <path
        d="M5.43702 2.86036C4.72984 2.1088 3.73848 1.67416 2.69173 1.67416H0.869946C0.389488 1.67416 0 2.06364 0 2.5441C0 3.02456 0.389488 3.41405 0.869946 3.41405H2.69173C3.34728 3.41405 3.96247 3.73064 4.3435 4.26408L4.38893 4.32768L5.43702 2.86036Z"
        fill={props.tint}
      />
      <path
        d="M7.90226 9.18753C8.60944 9.93902 9.60074 10.3736 10.6474 10.3736H11.3093V11.0995C11.3093 11.3913 11.6468 11.5535 11.8747 11.3712L13.8695 9.7754C14.0436 9.63609 14.0436 9.37125 13.8695 9.23195L11.8747 7.6361C11.6468 7.45383 11.3093 7.61605 11.3093 7.90783V8.63372H10.6474C9.9919 8.63372 9.3767 8.31713 8.99567 7.78369L8.95034 7.72023L7.90226 9.18753Z"
        fill={props.tint}
      />
    </svg>
  );
}
