import { useEffect } from "react";
import { configureAnalytics } from "../../analytics/configureAnalytics";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { UserProfile } from "../utils/user";

export function useGaConfigUser(user: UserProfile | undefined) {
  const location = useTypedLocation();

  useEffect(() => {
    if (user?.id) {
      configureAnalytics({
        pageConfig: {
          pagePath: location.pathname + location.search + location.hash,
          pageLocation: window.location.href,
        },
        userId: user?.id,
      });
    }
    // We want to run this effect only once, when the user is first set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
}
