import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { iconSize } from "src/theme";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { UserPreferencesScreen } from "../../domain/UserPreferencesScreen/UserPreferencesScreen";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import { UserPreferencesModal } from "../UserPreferencesModal/UserPreferencesModal";
import { messages } from "./UserPreferencesButton.messages";

type Props = {
  size?: keyof typeof iconSize;
};

export function UserPreferencesButton({ size = "xxl" }: Props) {
  const intl = useIntl();
  const location = useTypedLocation();
  const navigate = useNavigate();

  function handleOpenModal() {
    sendAnalyticsInteractionEvent("TopNav", "Click:UserPreferencesButton");
    navigateToNewState(navigate, { preferencesScreen: "language" }, location);
  }

  function openLanguageScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:EditLanguage");
    navigateToNewState(navigate, { preferencesScreen: "language" }, location);
  }

  function openDisplayScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:EditDisplay");
    navigateToNewState(navigate, { preferencesScreen: "distance" }, location);
  }

  function openCurrencyScreen() {
    sendAnalyticsInteractionEvent("Preferences", "Click:EditCurrency");
    navigateToNewState(navigate, { preferencesScreen: "currency" }, location);
  }

  function handleCloseModal() {
    navigateToNewState(navigate, { preferencesScreen: undefined }, location);
  }

  const isModalOpen = Boolean(
    location.state?.preferencesScreen &&
      location.state?.preferencesScreen !== "main"
  );

  return (
    <UserPreferencesModal
      size={size}
      isModalOpen={isModalOpen}
      handleOpenModal={handleOpenModal}
      handleCloseModal={handleCloseModal}
      label={intl.formatMessage(messages.preferences)}
      highlightScreen={location.state?.preferencesScreen}
      openLanguageScreen={openLanguageScreen}
      openCurrencyScreen={openCurrencyScreen}
      openDisplayScreen={openDisplayScreen}
    >
      <UserPreferencesScreen />
    </UserPreferencesModal>
  );
}
