import { defineMessages } from "react-intl";

export const messages = defineMessages({
  SignIn: {
    id: "topNav.SignIn",
    defaultMessage: "Sign in",
    description:
      "The text that appears on the sign in button when the user is not signed in",
  },
  SignInToSave: {
    id: "topNav.SignInToSave",
    defaultMessage: "Sign in to save",
    description:
      "The text that appears on the sign in button when the user is not signed in and in the trips context",
  },
});
