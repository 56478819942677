import { defineMessages } from "react-intl";

const messages = defineMessages({
  findHotelsIn: {
    id: "timeline.accomPromoFindHotelsIn",
    description:
      "A message on a link that takes a user to a hotel booking site",
    defaultMessage: "Staying in {location}?",
  },
  findHotelsNear: {
    id: "timeline.accomPromoFindHotelsNear",
    description:
      "A message on a link that takes a user to a hotel booking site",
    defaultMessage: "Staying near {location}?",
  },
  findHotelsCta: {
    id: "timeline.findHotelsCta",
    description:
      "A call to action message that takes the user to the internal hotels experience when clicked",
    defaultMessage: "Find hotels",
  },
  accommodationPromoSecondaryCta: {
    id: "timeline.accommodationPromoSecondaryCta",
    description:
      "A message on a link that takes a user to a particular hotel booking site, where the text has information about the price",
    defaultMessage: "Rooms from {price} on {hotelProvider}",
  },
  accommodationPromoSecondaryCtaNoPrice: {
    id: "timeline.accommodationPromoSecondaryCtaNoPrice",
    description:
      "A message on a link that takes a user to a particular hotel booking site, where the text does not have information about price",
    defaultMessage: "Find rooms on {hotelProvider}",
  },
});

export default messages;
