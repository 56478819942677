import { useTripRouteIndex } from "src/domain/TripPlanner/hooks/useTripRoute";
import { useTripRouteSegmentIndex } from "src/domain/TripPlanner/hooks/useTripSegment";
import { useIsTripScreen } from "./useIsTripScreen";
import { useRouteIndex } from "./useRoute";
import { useRouteSegmentIndex } from "./useSegment";

// This hook returns the index of the route, and segment of the trip plan, if active.
// If the trip planner is not active, it will use the route, and segment index of the main query.
export function useTripOrTransportIndexes() {
  const isTripsScreen = useIsTripScreen();
  const tripRouteIndex = useTripRouteIndex();
  const tripRouteSegmentIndex = useTripRouteSegmentIndex();

  const transportRouteIndex = useRouteIndex();
  const transportRouteSegmentIndex = useRouteSegmentIndex();

  if (isTripsScreen) {
    return {
      routeIndex: tripRouteIndex,
      routeSegmentIndex: tripRouteSegmentIndex,
    };
  }

  return {
    routeIndex: transportRouteIndex,
    routeSegmentIndex: transportRouteSegmentIndex,
  };
}
