import styled from "styled-components";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { border_radius } from "src/design-system/tokens/border";
import { Button } from "src/design-system/components/Button/Button";
import { FormattedMessage } from "react-intl";
import {
  PricingInfoLoading,
  RatingWithScoreLoading,
  TagsLoading,
  TitleLoading,
} from "./LoadingComponents";
import messages from "./messages";
import { MIN_HEIGHT_MOBILE_LIST_CARD } from "./constants";

type Props = {
  nightsStay: number;
};

export function ListCardMobileLoading({ nightsStay }: Props) {
  return (
    <Card role="button" aria-disabled tabIndex={0}>
      <Skeleton width={125} height="" borderRadius="md" />

      <Description>
        <TitleLoading />
        <RatingWithScoreLoading />
        <TagsLoading />
        <Pricing>
          <PricingInfoLoading nightsStay={nightsStay} />
          <Button variant="primary" isDisabled>
            <FormattedMessage {...messages.callToAction} />
          </Button>
        </Pricing>
      </Description>
    </Card>
  );
}

const Card = styled.div`
  position: relative;
  display: flex;

  border-radius: ${border_radius.rounded_lg};
  padding: ${spacing.sm};
  gap: ${spacing.sm};
  min-height: ${MIN_HEIGHT_MOBILE_LIST_CARD}px;

  background-color: ${color.bg.fill.fill};
  border: 1px solid ${color.border.border};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
  padding: ${spacing.lg};
  justify-content: space-between;
  flex-basis: 0;
  flex-grow: 1;
`;

const Pricing = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;
  gap: ${spacing.sm} ${spacing.xl};
`;
