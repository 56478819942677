import { createContext, useContext } from "react";
import { SearchResponse } from "src/api/SearchResponse";
import { Geocoded } from "src/PrefetchData";
import { SearchPlace } from "./useSearch";

type SearchOverrideContextType = {
  searchResponse: SearchResponse;
  origin?: Geocoded | SearchPlace;
  destination?: Geocoded | SearchPlace;
};

const SearchOverrideContext = createContext<
  SearchOverrideContextType | undefined
>(undefined);

export const SearchOverrideProvider = SearchOverrideContext.Provider;

export function useSearchOverride() {
  return useContext(SearchOverrideContext);
}
