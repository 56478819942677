import { darken } from "polished";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { Icon } from "../../../components/Icon/Icon";
import { ChevronRight } from "../../../svg/ChevronRight";
import { color, fontSize, fontWeight, spacing } from "../../../theme";
import {
  getHelpLanguageCode,
  localeToLanguageCode,
} from "../../../utils/conversions/languageCode";
import { preferenceStyle } from "../PreferenceScreen";
import messages from "./ExtraDetails.messages";

export function ExtraDetails() {
  const intl = useIntl();
  const language = localeToLanguageCode(intl.locale);

  const languageHelpCode = getHelpLanguageCode(language);

  return (
    <>
      <ExtraDetail
        link={`https://help.rome2rio.com/${languageHelpCode}/support/tickets/new`}
        title={intl.formatMessage(messages.contact)}
        gaActionValue="Contact"
      />
      <ExtraDetail
        link="https://www.rome2rio.com/about"
        title={intl.formatMessage(messages.about)}
        gaActionValue="About"
      />
      <ExtraDetail
        link={`https://help.rome2rio.com/${getHelpLanguageCode(
          language
        )}/support/home`}
        title={intl.formatMessage(messages.faq)}
        gaActionValue="FAQ"
      />
      <ExtraDetail
        link="https://www.rome2rio.com/travel-guides"
        title={intl.formatMessage(messages.travelGuides)}
        gaActionValue="TravelGuides"
      />
      <ExtraDetail
        link="https://www.rome2rio.com/blog"
        title={intl.formatMessage(messages.blog)}
        gaActionValue="Blog"
      />
      <ExtraDetail
        link="https://www.rome2rio.com/press"
        title={intl.formatMessage(messages.press)}
        gaActionValue="Press"
      />
      <ExtraDetail
        link="https://www.rome2rio.com/careers"
        title={intl.formatMessage(messages.careers)}
        gaActionValue="Careers"
      />
      <ExtraDetail
        link="https://www.facebook.com/rome2rio"
        title={intl.formatMessage(messages.facebook)}
        gaActionValue="Facebook"
      />
      <ExtraDetail
        link="https://www.twitter.com/rome2rio"
        title={intl.formatMessage(messages.twitter)}
        gaActionValue="Twitter"
      />
    </>
  );
}

type ExtraDetailsProps = {
  link: string;
  title: string;
  gaActionValue: string;
};

function ExtraDetail(props: ExtraDetailsProps) {
  const handleClick = (gaActionValue: string) => {
    sendAnalyticsInteractionEvent("MoreExtraDetail", `Click:${gaActionValue}`);
  };

  return (
    <ExtraDetailWrapper>
      <Link
        href={props.link}
        target="_blank"
        rel="noopener"
        onClick={() => handleClick(props.gaActionValue)}
      >
        {props.title}
      </Link>
      <StyledIcon size="md">
        <ChevronRight tint="iconOnLight" />
      </StyledIcon>
    </ExtraDetailWrapper>
  );
}

const ExtraDetailWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid ${color.grey2};
  background-color: ${color.white};
  ${preferenceStyle};

  padding: 0;
  &:active {
    background-color: ${darken(0.05, color.white)};
    -webkit-tap-highlight-color: transparent;
  }
`;

const Link = styled.a`
  padding: ${spacing.lg};
  display: block;
  color: ${color.cod};
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.normal};
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: ${spacing.lg};
  right: 0;
`;
