import { dateInUtcOffset } from "./dateTime";

export function useDefaultDateTime(originUtcOffset: number) {
  const offsetDateTimeNow = dateInUtcOffset(new Date(), originUtcOffset);

  // Get time of origin location at the following day at 6am from now.
  const offsetDateTimeTomorrowMorning = new Date(offsetDateTimeNow);
  offsetDateTimeTomorrowMorning.setDate(
    offsetDateTimeTomorrowMorning.getDate() + 1
  );
  offsetDateTimeTomorrowMorning.setHours(6, 0, 0, 0);

  const initialDateTime =
    offsetDateTimeNow.getHours() >= 16
      ? offsetDateTimeTomorrowMorning
      : offsetDateTimeNow;

  return initialDateTime;
}
