import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import styled, { ThemeProvider } from "styled-components";
import { PaneNavSkeleton } from "src/domain/PaneNav/PaneNavSkeleton";
import messages from "../RouteScreen.messages";
import { Navbar } from "../../../components/Navbar/Navbar";
import { spacing, color } from "../../../theme";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { hashChange } from "../../../utils/location/hashChange";
import { useLayout } from "../../../utils/hooks/useLayout";
import { useTheme } from "../../../utils/hooks/useTheme";
import { FontLoader } from "../../../components/FontLoader/FontLoader";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";

export function RouteScreenLoading() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const headerMargin = layout === "mobile" ? "0" : "16px 0 8px";

  // The RouteHeader has a light background when using the light theme, we need
  // to overwrite the PaneNav breadrumbs to be the same as the route header.
  const theme = useTheme();
  theme.breadcrumbs = theme.routeHeader;
  // We know which colour variant of the skeleton to use based on the iconTint.
  const lightOnDark = theme.breadcrumbs.iconTint === "white";

  return (
    <LoadingWrapper>
      {layout === "mobile" && (
        <Navbar
          headingText={intl.formatMessage(messages.heading)}
          onBackClick={() => navigate(hashChange(undefined, location))}
        />
      )}
      <Header>
        {layout !== "mobile" && (
          <ThemeProvider theme={theme}>
            <PaneNavSkeleton />
          </ThemeProvider>
        )}
        <Skeleton
          width="60%"
          height={24}
          margin={headerMargin}
          lightOnDark={lightOnDark}
        />
        <HeaderInner>
          <Skeleton
            width={50}
            height={16}
            padding="2px 0"
            lightOnDark={lightOnDark}
          />
          <Skeleton
            width={120}
            height={16}
            padding="2px 0"
            delay={0.15}
            lightOnDark={lightOnDark}
          />
        </HeaderInner>
      </Header>
      <LoadingTimelineItem />
      <LoadingTimelineItem />
      <Skeleton height={18} width="50%" margin="12px" />
      <Skeleton height={48} padding="0 12px" margin="12px 0" />
      <FontLoader />
    </LoadingWrapper>
  );
}

function LoadingTimelineItem() {
  return (
    <>
      <TimelineItem>
        <Row>
          <Skeleton width={24} height={24} margin="4px 0" />
          <Skeleton width={140} height={24} margin="4px 8px" padding={2} />
        </Row>
        <Skeleton
          height={18}
          width="70%"
          margin="32px 0 0"
          padding="0 0 0 40px"
        />
        <Skeleton height={22} margin="4px 0 8px" padding="0 0 0 40px" />
        <Skeleton
          height={18}
          width="30%"
          margin="0 0 24px"
          padding="0 0 0 40px"
        />
        <Row>
          <Skeleton width={24} height={24} margin="4px 0" />
          <Skeleton width={140} height={24} margin="4px 8px 16px" padding={2} />
        </Row>
      </TimelineItem>
    </>
  );
}

/** This is so this sits below the search results screen */
const LoadingWrapper = styled.div`
  background-color: ${color.white};
  z-index: 5;
  position: absolute;
  width: 100%;
  /* Make long enough that ads don't render within the visibility range */
  min-height: 200vh;
  overflow: hidden;
`;

const Header = styled.div`
  padding: ${spacing.xl};
  background-color: ${(props) => props.theme.routeHeader.background};
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.md};
`;

const TimelineItem = styled.div`
  padding: ${spacing.xl};
`;

const Row = styled.div`
  display: flex;
`;
