import { defineMessages } from "react-intl";

const messages = defineMessages({
  search: {
    defaultMessage: "Search",
    id: "hotel.search",
    description: "The text on a button that searches for hotels",
  },
  done: {
    defaultMessage: "Done",
    id: "hotel.done",
    description:
      "The text on a button that searches for hotels when the user is done filling out the form",
  },
  travellersLabel: {
    defaultMessage: "Travellers",
    id: "hotel.search.travellersLabel",
    description:
      "Label in the selection box indicating the box below is for number of travellers",
  },
  guestsLabel: {
    defaultMessage: "Guests",
    id: "hotel.search.guestsLabel",
    description:
      "Label in the selection box indicating the box below is for number of guests",
  },
  datesLabel: {
    defaultMessage: "Dates",
    id: "hotel.search.datesLabel",
    description:
      "Label above the selection box indicating the box below is for selecting dates",
  },
  location: {
    defaultMessage: "Hotel location",
    id: "hotel.search.location",
    description:
      "Label on button for screen readers that says button is for hotel location",
  },
  dates: {
    defaultMessage: "Hotel stay dates",
    id: "hotel.search.dates",
    description:
      "Label on button for screen readers that says button is for hotel stay dates",
  },
  traveller: {
    defaultMessage: "Traveller",
    id: "hotel.search.traveller",
    description: '"Traveller" will follow a number, for example "1 traveller"',
  },
  travellers: {
    defaultMessage: "Travellers",
    id: "hotel.search.travellers",
    description:
      'A plural of "traveller" that will follow a number, for example "2 Travellers"',
  },
  guest: {
    defaultMessage: "Guest",
    id: "hotel.search.guest",
    description: '"Guest" will follow a number, for example "1 guest"',
  },
  guests: {
    defaultMessage: "Guests",
    id: "hotel.search.guests",
    description:
      'A plural of "guest" that will follow a number, for example "2 Guests"',
  },
  room: {
    defaultMessage: "Room",
    id: "hotel.search.room",
    description: "The text for a single hotel room",
  },
  rooms: {
    defaultMessage: "Rooms",
    id: "hotel.search.rooms",
    description: "The text for multiple hotel rooms",
  },
  selectDates: {
    defaultMessage: "Select dates",
    id: "hotel.calendar.selectDates",
    description:
      "The text on a button for users to confirm their selected dates.",
  },
  datesAriaLabel: {
    defaultMessage: "Stay dates combobox",
    id: "hotel.search.datesAriaLabel",
    description: "Aria label to describe the stay dates dropdown combobox",
  },
  roomDetailsAriaLabel: {
    defaultMessage: "Room details combobox",
    id: "hotel.search.roomDetailsAriaLabel",
    description: "Aria label to describe the room details dropdown combobox",
  },
  checkIn: {
    defaultMessage: "Check-in",
    id: "datePicker.checkIn",
    description: "Label for field showing selected check-in date",
  },
  checkOut: {
    defaultMessage: "Check-out",
    id: "datePicker.checkOut",
    description: "Label for field showing selected check-out date",
  },
  selectCheckOutDate: {
    defaultMessage: "Select check-out date",
    id: "datePicker.selectedCheckOut",
    description:
      "The text in a button below calendar prompting users to select their check-out date before progressing further",
  },
  next: {
    defaultMessage: "Next",
    id: "datePicker.next",
    description:
      "The text in a button below calendar informing user they can proceed to the next form",
  },
  checkInCheckOutLabel: {
    defaultMessage: "Check-in — Check-out",
    id: "hotel.search.checkInCheckOutLabel",
    description:
      "Label in the selection box indicating the box is for selecting check-in and check-out dates",
  },
});

export default messages;
