import { NavigateFunction } from "react-router";
import { LocationState, TypedLocation } from "../hooks/useTypedLocation";
import { stateChange } from "./stateChange";

export function navigateToNewState(
  navigate: NavigateFunction,
  newState: Partial<LocationState>,
  current: TypedLocation,
  replace?: boolean
) {
  const newLocation = stateChange(newState, current);
  navigate(newLocation, { state: newLocation.state, replace: !!replace });
}
