import { defineMessages } from "react-intl";

export const messages = defineMessages({
  code: {
    defaultMessage: "Code",
    description: "The label shown above the code input on the login form.",
    id: "UserAccess.code",
  },
  email: {
    defaultMessage: "Email",
    description: "The label shown above the email input on the login form.",
    id: "UserAccess.email",
  },
  invalidCode: {
    defaultMessage: "Enter a valid code",
    description:
      "The error message shown when the user enters an invalid code.",
    id: "UserAccess.invalidCode",
  },
  invalidEmail: {
    defaultMessage: "Please enter a valid email address",
    description:
      "The error message shown when the user enters an invalid email address.",
    id: "UserAccess.invalidEmail",
  },
  sendCode: {
    defaultMessage: "Send code",
    description:
      "The text on the button that submits the email so we can send their 6-digit code.",
    id: "UserAccess.sendCode",
  },
  login: {
    defaultMessage: "Sign in",
    description: "The text on the button that submits the login form.",
    id: "UserAccess.login",
  },
  loginCodeText: {
    defaultMessage: "We’ve sent a 6-digit code to your inbox at {email}",
    description:
      "The title shown above the login form, after email entered, that asks the users to enter their 6-digit code",
    id: "UserAccess.loginCodeText",
  },
  loginCodeTitle: {
    defaultMessage: "Enter 6-digit code",
    description:
      "The title shown above the login form, after email entered, that asks the users to enter their 6-digit code",
    id: "UserAccess.loginCodeTitle",
  },
  loginText: {
    defaultMessage: "We’ll send you a code to your inbox",
    description:
      "The description shown above the login title, that describes the login process",
    id: "UserAccess.loginText",
  },
  loginTitle: {
    defaultMessage: "Enter your email",
    description:
      "The title shown above the login form, that asks the users to enter their email",
    id: "UserAccess.loginTitle",
  },
  preSignInTitle: {
    defaultMessage:
      "Sign in or create an account to keep planning on any device",
    description: "The title shown above the first step in the signin process.",
    id: "UserAccess.preSignIn.title",
  },
  resendCode: {
    defaultMessage: "Try again",
    description:
      "The text of the link that tries again to send a code to the user email on the login form.",
    id: "UserAccess.resendCode",
  },
  useEmail: {
    defaultMessage: "Continue with email",
    description: "The text on the button that send the user to the login form.",
    id: "UserAccess.useEmail",
  },
  problem: {
    defaultMessage: "A problem occured, please try again later.",
    description: "A message shown to the user after a network problem",
    id: "UserAccess.problem",
  },
  codeDidNotMatch: {
    defaultMessage: "Code is not correct, please try again.",
    description: "A Login code entered by the user was not valid",
    id: "UserAccess.codeDidNotMatch",
  },
  maxOtpAttempts: {
    defaultMessage: "Maximum number of attempts reached. Please try again.",
    description:
      "A message shown to the user indicating that they entered an incorrect login code too many times.",
    id: "UserAccess.maxOtpAttempts",
  },
});
