import { Reducer } from "react";

/**
 * @property "past" Historical values, grows as actions that aren't undo and redo are called.
 * @property "present" This is the current value
 * @property "future" Values that were undone, grows as "undo" is called.
 */
export type History<S> = {
  past: S[];
  present: S;
  future: S[];
};

export type HistoryAction<A> = { type: "UNDO" | "REDO" } | A;

export type HistoryReducer<S, A> = (
  state: History<S>,
  action: HistoryAction<A>
) => History<S>;

/**
 * Creates a reducer that keeps track of the history of the state.
 * @param parentReducer The original reducer that is used to update the state.
 * @param persistedKeys The keys + entries that should be persisted when undoing and redoing.
 * @param maxStorage The maximum number of states to keep in the past.
 */
export function createDefaultHistoryReducer<T, A extends { type: string }>(
  parentReducer: Reducer<T[], A>,
  persistedKeys?: (keyof T)[],
  maxStorage: number = 5
) {
  function defaultHistoryReducer(state: History<T>, action: HistoryAction<A>) {
    let newHistory: History<T>;
    if (action.type === "UNDO") {
      newHistory = reduceUndoAction(state, persistedKeys);
    } else if (action.type === "REDO") {
      newHistory = reduceRedoAction(state, persistedKeys);
    } else {
      newHistory = reduceAddAction(state, action, parentReducer, maxStorage);
    }
    return newHistory;
  }

  return defaultHistoryReducer;
}

export function reduceAddAction<S, A>(
  state: History<S>,
  action: HistoryAction<A>,
  parentReducer: Reducer<S[], A>,
  maxStorage: number = 5
) {
  if (!state.present) return state;

  const isMax = state.past.length >= maxStorage;
  const sliceFromIndex = isMax ? state.past.length - (maxStorage - 1) : 0;
  const trimmedPast = state.past.slice(sliceFromIndex);

  const newPast = state.present
    ? [...trimmedPast, state.present]
    : [...trimmedPast];
  const newPresent = parentReducer([state.present], action as A);

  return {
    past: newPast,
    present: newPresent[0],
    future: [],
  };
}

export function reduceUndoAction<S>(
  state: History<S>,
  persistedKeys?: (keyof S)[]
) {
  let { past, present, future } = state;

  if (past.length === 0) return state;

  const newFuture = [present, ...future];
  const newPresent = persistKeysFromPresent({
    oldPresent: present,
    newPresent: past.slice(-1, past.length)[0],
    persistedKeys,
  });
  const newPast = past.slice(0, past.length - 1);

  return {
    past: newPast,
    present: newPresent,
    future: newFuture,
  };
}

export function reduceRedoAction<S>(
  state: History<S>,
  persistedKeys?: (keyof S)[]
) {
  let { past, present, future } = state;

  if (future.length === 0) return state;

  const newPresent = persistKeysFromPresent({
    oldPresent: present,
    newPresent: future[0],
    persistedKeys,
  });
  const newPast = [...past, present];
  const newFuture = future.slice(1);

  return {
    past: newPast,
    present: newPresent,
    future: newFuture,
  };
}

export function clearAction<S>(newPresent: S) {
  return {
    past: [],
    present: newPresent,
    future: [],
  };
}

type PersistData<T> = {
  oldPresent: T;
  newPresent: T;
  persistedKeys?: (keyof T)[];
};

function persistKeysFromPresent<S>({
  oldPresent,
  newPresent,
  persistedKeys,
}: PersistData<S>) {
  if (!persistedKeys) {
    return newPresent;
  }

  const persistedDataFromPresent: Partial<S> = {};
  for (let key of persistedKeys) {
    persistedDataFromPresent[key] = oldPresent[key];
  }

  return { ...newPresent, ...persistedDataFromPresent };
}
