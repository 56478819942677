import { useNavigate, useLocation as useTypedLocation } from "react-router-dom";
import { Place } from "src/api/SearchResponse";
import { searchParamsChange } from "src/utils/location/searchParamsChange";

export function useSearchParamPlaces() {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  function getPlacesFromSearchParams() {
    const result = searchParams.get("search");
    return result ? result.split(",") : [];
  }

  function navigateSearchParamsFromPlaces(places: Place[], pathname?: string) {
    setSearchParamFromPlaces(searchParams, places);
    navigate(
      searchParamsChange(searchParams, {
        ...location,
        pathname: pathname ?? location.pathname,
      })
    );
  }

  return {
    getPlacesFromSearchParams,
    navigateSearchParamsFromPlaces,
  };
}

export function setSearchParamFromPlaces(
  searchParams: URLSearchParams,
  places: Place[]
) {
  searchParams.set("search", places.map((place) => place.canonicalName).join());
}
