import { useIntl } from "react-intl";
import { SearchResponse } from "src/api/SearchResponse";
import styled from "styled-components";
import { lowestPriceRangeFromSearch } from "src/utils/adapters/priceRange";
import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import {
  TripPlannerDetails,
  TripPlannerURL,
} from "../../../domain/TripPlanner/TripPlannerProvider";
import { ChevronRight } from "../../../svg/ChevronRight";
import TransferIcon from "../../../svg/TransferIcon";
import { color, lineHeight, spacing } from "../../../theme";
import { Mode } from "../../../utils/types/mode";
import { Button } from "../../Button/Button";
import {
  TripCardTypeProps,
  TripOriginDestination,
  getTripCardAnalyticsLabel,
} from "../TripCard/TripCard";
import { placeNumberOffsetPx } from "../Headings/PlaceTitle";
import messages from "./SearchCard.messages";

type SearchCardProps = TripOriginDestination & {
  url: TripPlannerURL;
  searchResponse?: SearchResponse | PartialSearchResponse;
  clickCallback?: () => void;
  transitMode?: Mode;
  gaLabel?: string;
};

export function SearchCard({ destination, clickCallback }: SearchCardProps) {
  const intl = useIntl();

  return (
    <StyledButton
      leftIcon={<TransferIcon tint="pink" />}
      leftIconSpacing="lg"
      rightIcon={
        <ChevronWrapper>
          <ChevronRight tint="pink" />
        </ChevronWrapper>
      }
      rightIconSize="sm-1"
      rightIconSpacing="auto"
      onClick={clickCallback}
      backgroundColor="white"
      textColor="primaryOnLight"
      justify="flex-start"
      size="smaller"
      target="_blank"
      rel="noopener"
    >
      <StyledText>
        {intl.formatMessage(messages.searchTravel, {
          destination: destination.shortName,
        })}
      </StyledText>
    </StyledButton>
  );
}

const StyledText = styled.span`
  text-align: left;
  line-height: ${lineHeight.tight};
`;

const StyledButton = styled(Button)`
  border: 1px solid ${color.n30};
  border-radius: ${spacing.md};
  align-items: flex-start;
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.md};
  padding-left: ${placeNumberOffsetPx - 2}px;
`;

// Center the chevron within the first
// line of text
const ChevronWrapper = styled.div`
  margin-top: 0.15em;
`;

export function getSearchPromptPropsFromSearchResponse(
  searchResponse: SearchResponse | PartialSearchResponse,
  url: TripPlannerURL
): TripCardTypeProps {
  return {
    origin: searchResponse.places[0],
    destination: searchResponse.places[1],
    searchResponse,
    priceRange: lowestPriceRangeFromSearch(searchResponse),
    transitMode: "multi",
    url,
    type: "searchPrompt",
    gaLabel: getTripCardAnalyticsLabel(false, searchResponse),
  };
}

export function getSearchPropsFromOriginDestination(
  origin: TripPlannerDetails["places"][number],
  destination: TripPlannerDetails["places"][number]
): TripCardTypeProps {
  return {
    origin,
    destination,
    transitMode: "multi",
    url: {
      pathname: `/map/${origin.canonicalName}/${destination.canonicalName}`,
      hash: undefined,
    },
    type: "searchPrompt",
    searchResponse: undefined,
    gaLabel: `tc0:${origin.canonicalName}:${destination.canonicalName}`,
  };
}
