import { SvgProps, useColor } from "./common";

export const Trash = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33331 4.00004C1.33331 3.63185 1.63179 3.33337 1.99998 3.33337H14C14.3682 3.33337 14.6666 3.63185 14.6666 4.00004C14.6666 4.36823 14.3682 4.66671 14 4.66671H1.99998C1.63179 4.66671 1.33331 4.36823 1.33331 4.00004Z"
        fill={tintColor}
        stroke={tintColor}
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66663 2.00002C6.48981 2.00002 6.32025 2.07026 6.19522 2.19528C6.0702 2.32031 5.99996 2.48988 5.99996 2.66669V4.00002C5.99996 4.36821 5.70148 4.66669 5.33329 4.66669C4.9651 4.66669 4.66663 4.36821 4.66663 4.00002V2.66669C4.66663 2.13625 4.87734 1.62755 5.25241 1.25247C5.62749 0.877401 6.13619 0.666687 6.66663 0.666687H9.33329C9.86373 0.666687 10.3724 0.877401 10.7475 1.25247C11.1226 1.62755 11.3333 2.13625 11.3333 2.66669V4.00002C11.3333 4.36821 11.0348 4.66669 10.6666 4.66669C10.2984 4.66669 9.99996 4.36821 9.99996 4.00002V2.66669C9.99996 2.48988 9.92972 2.32031 9.8047 2.19528C9.67967 2.07026 9.5101 2.00002 9.33329 2.00002H6.66663ZM3.33329 3.33335C3.70148 3.33335 3.99996 3.63183 3.99996 4.00002V13.3334C3.99996 13.5102 4.0702 13.6797 4.19522 13.8048C4.32025 13.9298 4.48982 14 4.66663 14H11.3333C11.5101 14 11.6797 13.9298 11.8047 13.8048C11.9297 13.6797 12 13.5102 12 13.3334V4.00002C12 3.63183 12.2984 3.33335 12.6666 3.33335C13.0348 3.33335 13.3333 3.63183 13.3333 4.00002V13.3334C13.3333 13.8638 13.1226 14.3725 12.7475 14.7476C12.3724 15.1226 11.8637 15.3334 11.3333 15.3334H4.66663C4.13619 15.3334 3.62748 15.1226 3.25241 14.7476C2.87734 14.3725 2.66663 13.8638 2.66663 13.3334V4.00002C2.66663 3.63183 2.9651 3.33335 3.33329 3.33335Z"
        fill={tintColor}
        stroke={tintColor}
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 6.66669C7.03486 6.66669 7.33333 6.96516 7.33333 7.33335V11.3334C7.33333 11.7015 7.03486 12 6.66667 12C6.29848 12 6 11.7015 6 11.3334V7.33335C6 6.96516 6.29848 6.66669 6.66667 6.66669Z"
        fill={tintColor}
        stroke={tintColor}
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33329 6.66669C9.70148 6.66669 9.99996 6.96516 9.99996 7.33335V11.3334C9.99996 11.7015 9.70148 12 9.33329 12C8.9651 12 8.66663 11.7015 8.66663 11.3334V7.33335C8.66663 6.96516 8.9651 6.66669 9.33329 6.66669Z"
        fill={tintColor}
        stroke={tintColor}
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
