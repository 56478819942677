// There is no removeEventListener for gtag's slotRenderEnded. We can use
// this pub/sub manager to attach only one listener to the pubads object
// and have the ability to unsubscribe once we're done with the component.

type SubscriberCallback = (
  event: googletag.events.SlotRenderEndedEvent
) => void;

// Make sure that googletag.cmd exists as it may not have loaded yet.
window.googletag = window.googletag || {};
// When the GPT JavaScript is loaded, it looks through the cmd array and executes all the functions in order.
window.googletag.cmd = window.googletag.cmd || [];

export const subscribers: SubscriberCallback[] = [];

export function subscribeToSlotRenderEndedEvents(
  callback: SubscriberCallback
): () => void {
  subscribers.push(callback);
  return () => {
    const index = subscribers.indexOf(callback);
    subscribers.splice(index, 1);
  };
}

function sendEventToSubscribers(event: googletag.events.SlotRenderEndedEvent) {
  subscribers.forEach((subscriber) => subscriber(event));
}

window.googletag.cmd.push(function () {
  window.googletag
    .pubads()
    .addEventListener("slotRenderEnded", sendEventToSubscribers);
});
