import { Icon } from "src/components/Icon/Icon";
import { CheckCircle } from "src/svg/CheckCircle";
import { InfoInverse } from "src/svg/Info";
import { borderRadius, fontSize, spacing } from "src/theme";
import styled from "styled-components";

type BannerStatus = "info" | "success";

type BannerProps = {
  status: BannerStatus;
  text: string;
  heading?: string;
};

export function Banner(props: BannerProps) {
  return (
    <BannerWrapper role="status" status={props.status}>
      <Icon size="xl">
        {props.status === "success" ? (
          <CheckCircle tint="emerald" />
        ) : (
          <InfoInverse tint="blue" />
        )}
      </Icon>
      <TextWrapper>
        <BannerHeading>{props.heading}</BannerHeading>
        <BannerText>{props.text}</BannerText>
      </TextWrapper>
    </BannerWrapper>
  );
}

const BannerWrapper = styled.div<{ status: BannerStatus }>`
  padding: ${spacing.xl};
  margin-bottom: ${spacing.lg};
  border-radius: ${borderRadius.md};
  display: flex;

  // all new colours in new ds
  ${(props) => {
    if (props.status === "success") {
      return `
        background-color: #DDF7E5;
        border: 1px solid #9EE5B5;

        color: #004517;
      `;
    } else if (props.status === "info") {
      return `
        background-color: #E8EFFF;
        border: 1px solid #BBD1FF;

        color: #1A376B;
      `;
    }
  }}
`;

const TextWrapper = styled.div`
  margin-left: ${spacing.md};
`;

const BannerHeading = styled.h4`
  font-size: ${fontSize.md};
`;

const BannerText = styled.p`
  font-size: ${fontSize.md};
`;
