import styled from "styled-components";
import { Skeleton } from "../../components/Skeleton/Skeleton";
import { spacing } from "../../theme";
import { useTripPlannerContext } from "./hooks/useTripPlannerContext";

const skeleDefaults = {
  margin: `${spacing.lg} 0 0 0`,
};

export function TripPlannerSkeleton() {
  const { isMultiTrip } = useTripPlannerContext();

  return (
    <Wrapper>
      <Skeleton height="27px" width="100%" />
      {isMultiTrip && <SkeletonInput />}
      <Skeleton margin={`${spacing.lg} 0 0 0`} height="52px" width="100%" />
      <CellSkele />
      <CellSkele />
      <CellSkele />
      <CellSkele />
      <Skeleton {...skeleDefaults} width="100%" height="59px" />
      {isMultiTrip && <SkeletonInput />}
      <Skeleton {...skeleDefaults} width="100%" height="45px" />
    </Wrapper>
  );
}

function CellSkele() {
  return <Skeleton {...skeleDefaults} height="112px" width="100%" />;
}

function SkeletonInput() {
  return <Skeleton {...skeleDefaults} height="40px" width="33%" />;
}

const Wrapper = styled.div`
  padding-top: ${spacing.lg};
`;
