import styled from "styled-components";
import { useIntl, IntlShape } from "react-intl";
import { MergeElementProps } from "../../utils/MergeElementProps";
import { parseDurationFormat } from "../../utils/conversions/duration";
import {
  TimeUnits,
  isZeroEstimate,
  makeTimeUnits,
} from "../../utils/types/TimeUnits";
import messages from "./FormattedDuration.messages";

type Props = MergeElementProps<
  "time",
  {
    totalMinutes: number;
  }
>;

export function FormattedDuration({ totalMinutes, ...other }: Props) {
  const intl = useIntl();

  return (
    <Duration
      dateTime={getFormattedDurationDatetimeAttibute(totalMinutes, intl)}
      {...other}
    >
      {getFormattedDurationText(totalMinutes, intl)}
    </Duration>
  );
}

/**
 * Returns a formatted duration as text, in precise terms, based on
 * the given number of minutes.
 * @param totalMinutes - total minutes to format into days, hours, mins
 * @param intl - i18n support
 * @returns formatted, localized text string.
 */
export function getFormattedDurationText(
  totalMinutes: number,
  intl: IntlShape
): string {
  const travelDuration = makeTimeUnits(parseDurationFormat(totalMinutes));
  const formatted = makeBestDurationText(intl, travelDuration);

  return formatted;
}

/**
 * Uses the appropriate message format based on the layout indicated in the
 * adjusted TimeUnits instance.
 * @param intl - internationalization object
 * @param travelDuration - raw duration given by API
 * @returns - formatted string
 */
const makeBestDurationText = (
  intl: IntlShape,
  travelDuration: TimeUnits
): string => {
  let durationMessage: string;
  const adjustedDuration = fixDurationForFeature(travelDuration);

  switch (adjustedDuration.layout) {
    case "exactDays":
    case "exactDaysHours":
      durationMessage = intl.formatMessage(messages.daysAndHours, {
        days: adjustedDuration.days,
        hours: adjustedDuration.hours,
      });
      break;
    case "exactHours":
      durationMessage = intl.formatMessage(messages.hours, {
        hours: adjustedDuration.hours,
      });
      break;
    case "exactHoursMinutes":
      durationMessage = intl.formatMessage(messages.hoursAndMinutes, {
        hours: adjustedDuration.hours,
        minutes: adjustedDuration.minutes,
      });
      break;
    case "exactMinutes":
      durationMessage = intl.formatMessage(messages.minutes, {
        minutes: adjustedDuration.minutes,
      });
      break;

    default:
      // --- SHOULD NOT HAPPEN ---
      console.error(`Unhandled layout: ${adjustedDuration.layout}`);
      durationMessage = intl.formatMessage(messages.daysAndHours, {
        days: adjustedDuration.days,
        hours: adjustedDuration.hours,
      });
      break;
  }

  return durationMessage;
};

/**
 * Applies rounding or ranging information by calling the appropriate TimeUnits method based on needed feature.
 * @param estimatedDuration from the parsed number of minutes
 * @returns TimeUnits adjusted for the zero estimate case.
 */
function fixDurationForFeature(estimatedDuration: TimeUnits): TimeUnits {
  const correctedDuration = isZeroEstimate(estimatedDuration)
    ? { ...estimatedDuration, minutes: 1 }
    : estimatedDuration;

  return correctedDuration;
}

/**
 * Returns a machine-readable datetime attribute, based on the given number of minutes.
 * @param totalMinutes - total minutes to format into days, hours, mins
 * @param intl - i18n support
 * @returns formatted, machine-readable datetime attribute string.
 */
const getFormattedDurationDatetimeAttibute = (
  totalMinutes: number,
  intl: IntlShape
): string => {
  // PT means it is a duration/period time
  const prefix = "PT";
  const durationMessage = getFormattedDurationText(totalMinutes, intl)
    .toUpperCase()
    .replace(/\s/g, "")
    .replace(/MIN$/g, "M");

  return `${prefix}${durationMessage}`;
};

const Duration = styled.time`
  white-space: nowrap;
`;
