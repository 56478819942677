import styled from "styled-components";
import { color, spacing } from "../../../theme";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { FontLoader } from "../../../components/FontLoader/FontLoader";

export function SearchResultsLoading() {
  return (
    <Wrapper>
      <SearchResultLoading width="40%" tag />
      <SearchResultLoading width="60%" />
      <SearchResultLoading width="80%" />
      <SearchResultLoading width="60%" />
      <FontLoader />
    </Wrapper>
  );
}

function SearchResultLoading(props: {
  width?: string | number;
  tag?: boolean;
}) {
  return (
    <LoadingWrapper>
      {props?.tag && (
        <Tag>
          <Skeleton />
        </Tag>
      )}
      <Skeleton width="55%" height={16} />
      <Row>
        <Skeleton width={28} height={28} />
        <Skeleton
          width={props.width}
          height={28}
          padding="12px 4px"
          delay={0.1}
        />
      </Row>
      <LastRow>
        <Skeleton width={120} height={20} />
        <Skeleton width={124} height={20} delay={0.25} />
      </LastRow>
    </LoadingWrapper>
  );
}

const Wrapper = styled.div`
  // Give enough space that footer ads don't start close to the view
  min-height: 150vh;
`;

const Tag = styled.div`
  width: 120px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 12px;
`;

const LoadingWrapper = styled.div`
  border-bottom: 1px solid ${color.grey2};
  padding: ${spacing.xl};
  background-color: ${color.white};
`;

const Row = styled.div`
  display: flex;
  margin-top: 12px;
`;

const LastRow = styled(Row)`
  justify-content: space-between;
`;
