import { getAqidCookie, getGclidCookie } from "../../utils/uid";
import { formatExitDateUTC } from "../../utils/dateTime";

// Label used to track details of a user exit event.
//
// WARNING:
//   - This serializer needs to be kept in sync with c# backend and BigQuery deserialisation.
//  For more information, SEE:
//  https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
export function generateExitLabel(
  exitPoint: string,
  requestId?: string,
  bookingProvider?: string,
  experiments?: string[]
) {
  const schema = "r2r-mw01";
  const aqid = getAqidCookie();
  const adwordsGclid = getGclidCookie();
  const timestamp = formatExitDateUTC(new Date());

  const labelItems = [
    schema,
    exitPoint,
    requestId ?? "",
    bookingProvider ?? "",
    aqid ?? "",
    experiments?.join("|") ?? "",
    adwordsGclid ?? "",
    timestamp,
    "", // Empty string needed for trailing comma in return value.
  ];

  return labelItems.join(",");
}
