import { IntlShape, useIntl } from "react-intl";
import { css } from "styled-components";
import { useHotelList } from "src/utils/hooks/useHotelList";
import useSearch from "src/utils/hooks/useSearch";
import { color } from "../../theme";
import { default as errorMessages } from "../SearchResultsScreen/SearchResultError/SearchResultError.messages";
import hardcodedData from "./assets/hardcoded-data-refine-search.json";
import { default as searchMessages } from "./HotelSearch/HotelSearch.messages";
import { DateCombos, useHotelsContext } from "./HotelsContext";
import messages from "./HotelsScreen.messages";

// This is for design consistency across the combobox, sorting and filters.
export const HotelScreenHover = css`
  &:hover {
    @media (hover: hover) {
      background-color: ${color.n10};
    }
  }

  &::placeholder {
    color: ${color.n60};
  }
`;

export function useGuestRoomCount() {
  const { roomDetailsUI } = useHotelsContext();
  const guestCount = roomDetailsUI.reduce(
    (acc, obj) => acc + obj.adults + obj.children.length,
    0
  );
  const roomCount = roomDetailsUI.length;
  return { guestCount, roomCount };
}

export function useHotelStayAndRoomDetailsText() {
  const { stayDatesUI } = useHotelsContext();

  const intl = useIntl();
  const { guestCount, roomCount } = useGuestRoomCount();

  const { checkIn, checkOut } = getFormattedStayDates(stayDatesUI, intl);
  const datesText = `${checkIn} — ${checkOut}`;

  let roomDetailsText = `${guestCount} ${
    guestCount === 1
      ? intl.formatMessage(searchMessages.guest)
      : intl.formatMessage(searchMessages.guests)
  }`;

  if (roomCount > 1) {
    roomDetailsText += `, ${roomCount} ${intl.formatMessage(
      searchMessages.rooms
    )}`;
  }
  return { datesText, roomDetailsText };
}

export function getFormattedStayDates(
  stayDates: DateCombos,
  intl: IntlShape
): { checkIn: string; checkOut: string } {
  let checkIn = intl.formatMessage(messages.checkIn);
  let checkOut = intl.formatMessage(messages.checkOut);

  if (stayDates === undefined) {
    return { checkIn, checkOut };
  }

  const isDifferentYear =
    stayDates[0].getFullYear() !== stayDates[1].getFullYear();
  const isFutureYear = stayDates[0].getFullYear() !== new Date().getFullYear();
  const isDifferentMonth = stayDates[0].getMonth() !== stayDates[1].getMonth();

  if (isDifferentYear) {
    checkIn = intl.formatDate(stayDates[0], {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    checkOut = intl.formatDate(stayDates[1], {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  } else if (isDifferentMonth) {
    checkIn = intl.formatDate(stayDates[0], {
      day: "numeric",
      month: "short",
    });
    checkOut = intl.formatDate(stayDates[1], {
      day: "numeric",
      month: "short",
      year: isFutureYear ? "numeric" : undefined,
    });
  } else {
    checkIn = intl.formatDate(stayDates[0], {
      day: "numeric",
    });
    checkOut = intl.formatDate(stayDates[1], {
      day: "numeric",
      month: "short",
      year: isFutureYear ? "numeric" : undefined,
    });
  }
  return { checkIn, checkOut };
}

export function useGetHotelsFoundMessage() {
  const intl = useIntl();
  const { isLoading, hotelListResponse, numDefaultFilteredHotels } =
    useHotelList();

  if (
    isLoading ||
    hotelListResponse === undefined ||
    numDefaultFilteredHotels === undefined
  ) {
    return intl.formatMessage(messages.searchingForHotels);
  }

  const numberOfHotels =
    numDefaultFilteredHotels + (hotelListResponse.selectedHotel ? 1 : 0);

  if (numberOfHotels === 0) {
    return intl.formatMessage(messages.noHotelsFound);
  }

  return `${
    numberOfHotels > 1
      ? intl.formatMessage(messages.hotelsFound, {
          NUMBER_OF_HOTELS: numberOfHotels,
        })
      : intl.formatMessage(messages.hotelFound)
  }`;
}

export function useMobileHExHeaderMessage() {
  const intl = useIntl();
  const hotelsFoundMessage = useGetHotelsFoundMessage();
  const { destination: contextDestination } = useHotelsContext();
  const { destination: searchDestination } = useSearch();

  if (searchDestination === undefined) {
    return intl.formatMessage(errorMessages.noDestination);
  }

  if (contextDestination === undefined) {
    return intl.formatMessage(messages.searchingForHotels);
  }
  return `${contextDestination.shortName}: ${hotelsFoundMessage}`;
}

export type RefineSearchOption = {
  shortName: string;
  refineKind: string;
  destinationKind: string;
  canonicalName: string;
  imgSrc: string;
  center: {
    lat: number;
    lng: number;
  };
  refineSearchParent: string; // canonical of the parent location.
  mapZoom?: number;
};

const refineSearchParentPlaces = ["London", "Paris", "Rome"] as const;
export type HExRefineSearchPlaces = (typeof refineSearchParentPlaces)[number];

export function isHExRefineSearchPlace(value: string) {
  return refineSearchParentPlaces.includes(value as HExRefineSearchPlaces);
}

export function isRefineSearchChildPlace(canonical: string) {
  return Object.values(hardcodedData)
    .flat()
    .map((child) => child.canonicalName)
    .includes(canonical);
}
export function getHardcodedRefineSearchData(
  kind: "suburb" | "attraction",
  place: HExRefineSearchPlaces
) {
  // Retrieve data. 20240904 data is hardcoded pending experiment outcome and building of a BE service.
  return hardcodedData[place].filter((item) => item.refineKind === kind);
}
