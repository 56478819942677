import { defineMessages } from "react-intl";

const messages = defineMessages({
  origin: {
    id: "label.from",
    description: "The label for an origin's input (Eg: From: Melbourne)",
    defaultMessage: "From",
  },
  originPlaceholder: {
    id: "label.originPlaceholder",
    description:
      "The placeholder for an origin input, point means something like City, station or place",
    defaultMessage: "Enter a starting point",
  },
  destination: {
    id: "label.to",
    description: "The label for a destination's input (Eg: To: Melbourne)",
    defaultMessage: "To",
  },
  destinationPlaceholder: {
    id: "label.destinationPlaceholder",
    description:
      "The placeholder for a destination input, place means something like City, station or place",
    defaultMessage: "Enter a city or place to visit",
  },
});

export default messages;
