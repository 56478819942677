import { TypedLocation } from "../hooks/useTypedLocation";

import { cloneLocation } from "./cloneLocation";

export function hashChange(
  update: string | undefined,
  current: TypedLocation
): TypedLocation {
  return {
    ...cloneLocation(current),
    hash: update ?? "",
  };
}
