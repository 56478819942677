export function encodeSearchParamsForUrl(
  searchParams: URLSearchParams
): string | undefined {
  // When we're encoding urlSearchPrams to push to state, we do not want to encode the 'features' query param.
  // Browsers can handle un-encoded search params so we should be safe, and this will only apply to debug URLs.
  // We will encode the value as normal, but deode the '!', ',', and ':' characters because we use them in the query param.

  let parts: string[] = [];

  for (const param of Array.from(searchParams.entries())) {
    const key = encodeURIComponent(param[0]);
    let value = encodeURIComponent(param[1]);
    if (
      key === "features" ||
      key === "backendFeatures" ||
      key === "backendFlags" ||
      key === "search"
    ) {
      value = value
        .replace(new RegExp("%21", "g"), "!")
        .replace(new RegExp("%2C", "g"), ",")
        .replace(new RegExp("%3A", "g"), ":")
        .replace(new RegExp("%7E", "g"), "~");
    }

    parts.push(`${key}=${value}`);
  }

  return parts.length > 0 ? `?${parts.join("&")}` : undefined;
}
