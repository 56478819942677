import { defineMessages } from "react-intl";

const messages = defineMessages({
  chevronIcon: {
    defaultMessage: "Open promo information",
    description:
      "Tells screen readers what the arrow icon indicates (opens promo information)",
    id: "searchResultPromo.chevronIcon",
  },
});

export default messages;
