import { useEffect } from "react";

export function useHeadMeta(description: string) {
  useEffect(() => {
    const metaDesc = document.querySelector('meta[name="description"]');
    const metaOgDesc = document.querySelector(
      'meta[property="og:description"]'
    );
    const metaTwitterDesc = document.querySelector(
      'meta[name="twitter:description"]'
    );

    if (metaDesc) {
      metaDesc.setAttribute("content", description);
    }
    if (metaOgDesc) {
      metaOgDesc.setAttribute("content", description);
    }
    if (metaTwitterDesc) {
      metaTwitterDesc.setAttribute("content", description);
    }
  }, [description]);
}
