import { SearchResponse } from "../../api/SearchResponse";
import { transitModeFromVehicleKind } from "../../utils/adapters/transitMode";
import { durationInMinutesFromRoute } from "../../utils/adapters/duration";
import {
  priceRangeFromRoute,
  PriceRange,
} from "../../utils/adapters/priceRange";
import { vehicleFromSegment } from "../../utils/adapters/vehicle";
import { Mode } from "../../utils/types/mode";
import { Kind } from "../../api/Kind";

import { generateRouteLabel } from "../../analytics/generateRouteLabel/generateRouteLabel";
import { distanceFromRoute } from "../../utils/adapters/distance";

export type Segment = {
  isMajor: boolean;
  transitMode: Mode;
  transitKind: Kind;
  duration: number;
};

type Tag = "best" | "cheapest" | "fastest";

export type SearchResultViewModel = {
  routeIndex: number;
  title: string;
  durationInMinutes: number;
  isBookable: boolean;
  segments: Segment[];
  priceRange?: PriceRange;
  tag?: Tag;
  canonicalName: string;
  routeLabel: string;
  totalDistance?: number;
  flightOriginCode?: string;
  flightDestCode?: string;
  destAirportName?: string;
};

export function createSearchResultViewModel(
  response: SearchResponse
): SearchResultViewModel[] {
  const result = response.routes.map((route, index): SearchResultViewModel => {
    return searchResultPropsFromRoute(response, index);
  });

  const annotatedResults = annotateTags(result);
  return annotatedResults;
}

function annotateTags(
  results: SearchResultViewModel[]
): SearchResultViewModel[] {
  const searchResults = [...results];

  // The first result from the backend is always the best.
  const best = 0;

  // Find the cheapest result that has a price range.
  const cheapest = results
    .filter((result) => result.priceRange?.low)
    .sort((a, b) => a.priceRange!.low - b.priceRange!.low)[0]?.routeIndex;

  // Find the fastest result that is not a car mode.
  const fastest = results
    .filter((result) =>
      result.segments.every((segment) => segment.transitMode !== "car")
    )
    .sort((a, b) => a.durationInMinutes - b.durationInMinutes)[0]?.routeIndex;

  // We only want one tag per route eg. if the route is the best and the fastest,
  // just tag best. The order of priority is Best → Cheapest → Fastest.
  searchResults.map((result) => {
    switch (result.routeIndex) {
      case best:
        result.tag = "best";
        break;
      case cheapest:
        result.tag = "cheapest";
        break;
      case fastest:
        result.tag = "fastest";
        break;
    }
    return result;
  });

  return searchResults;
}

function searchResultPropsFromRoute(
  response: SearchResponse,
  routeIndex: number
): SearchResultViewModel {
  const route = response.routes[routeIndex];

  return {
    routeIndex: routeIndex,
    title: route.name,
    durationInMinutes: durationInMinutesFromRoute(response, route),
    priceRange: priceRangeFromRoute(route),
    isBookable: !!route.bookingInfo,
    segments: searchResultViewSegments(response, routeIndex),
    canonicalName: route.canonicalName,
    routeLabel: generateRouteLabel(response, routeIndex),
    totalDistance: distanceFromRoute(response, routeIndex),
  };
}

export function searchResultViewSegments(
  response: SearchResponse,
  routeIndex: number
): Segment[] {
  const route = response.routes[routeIndex];
  const result: Segment[] = [];

  for (const segmentIndex of route.segments) {
    const segment = response.segments[segmentIndex];
    const vehicle = vehicleFromSegment(response, segment);
    const mode = transitModeFromVehicleKind(vehicle.kind);

    result.push({
      transitKind: vehicle.kind,
      transitMode: mode,
      isMajor: segment.isMajor,
      duration: segment.duration,
    });
  }

  return result;
}
