import { SvgProps, useColor } from "./common";

export const Info = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 32 32" fill="none">
      <title>{props.title}</title>
      <g fill="none" d="M0 0h32v32H0z">
        <path
          fillRule="evenodd"
          d="M16 3C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zM0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16zm17.5 8a1.5 1.5 0 01-3 0v-9a1.5 1.5 0 013 0v9zM16 6.5a2 2 0 100 4 2 2 0 000-4z"
          fill={tintColor}
        />
      </g>
    </svg>
  );
};

export const InfoInverse = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.0004C18 14.4189 14.4183 18.0008 10 18.0008C5.58172 18.0008 2 14.4189 2 10.0004C2 5.5819 5.58172 2 10 2C14.4183 2 18 5.5819 18 10.0004ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14L9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10V14ZM10 5.00001C9.44772 5.00001 9 5.44772 9 6.00001C9 6.55229 9.44771 7.00001 10 7.00001C10.5523 7.00001 11 6.55229 11 6.00001C11 5.44772 10.5523 5.00001 10 5.00001Z"
        fill={tintColor}
      />
    </svg>
  );
};
