import styled from "styled-components";
import { textColorBasedOnBackgroundColor } from "../../utils/color";
import { Mode } from "../../utils/types/mode";
import {
  fontWeight,
  textColor,
  transitColor,
  fontSize,
  lineHeight,
} from "../../theme";

export type LineNameProps = {
  name: string;
  transitMode: Mode;
  textColor?: string;
  backgroundColor?: string;
  className?: string;
  labelledby?: string;
  spacing?: string;
  title?: string;
};

export function LineName(props: LineNameProps) {
  const backgroundColor = props.backgroundColor
    ? `#${props.backgroundColor}`
    : transitColor[props.transitMode];

  let calculatedTextColor;
  if (props.backgroundColor && props.textColor) {
    calculatedTextColor = `#${props.textColor}`;
  } else if (props.backgroundColor) {
    calculatedTextColor =
      textColorBasedOnBackgroundColor(backgroundColor) === "light"
        ? textColor.primaryOnDark
        : textColor.primaryOnLight;
  } else {
    // If we're falling back to our default transit colors, make the text white
    calculatedTextColor = textColor.primaryOnDark;
  }

  return (
    <Wrapper
      aria-labelledby={props.labelledby}
      textColor={calculatedTextColor}
      backgroundColor={backgroundColor}
      className={props.className}
      title={props.title ?? props.name}
      spacing={props.spacing}
    >
      {props.name}
    </Wrapper>
  );
}

type WrapperProps = {
  textColor: string;
  backgroundColor: string;
  spacing?: string;
};

const Wrapper = styled.span<WrapperProps>`
  display: inline-block;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  margin: 0 ${(props) => props.spacing} 0 0;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.tight};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 140px;
`;
