import { PropsWithChildren } from "react";
import { defaultFeatureConfig, FeatureConfig } from "./FeatureConfig";
import { useConfigureFeatures } from "./internal/useConfigureFeatures";
import { FeatureContext } from "./useFeatureConfig";

export function FeatureProvider(props: PropsWithChildren<{}>) {
  const featureConfig = useConfigureFeatures();

  return (
    <FeatureContext.Provider value={featureConfig}>
      {props.children}
    </FeatureContext.Provider>
  );
}

export function StaticFeatureProvider(
  props: PropsWithChildren<{ featureConfig?: Partial<FeatureConfig> }>
) {
  return (
    <FeatureContext.Provider
      value={{ ...defaultFeatureConfig, ...props.featureConfig }}
    >
      {props.children}
    </FeatureContext.Provider>
  );
}
