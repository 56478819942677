import { Suspense, lazy } from "react";
import type { SegmentScreenProps } from "./SegmentScreen";
import { SegmentScreenLoading } from "./SegmentScreenLoading/SegmentScreenLoading";

const Component = lazy(() => import("./SegmentScreen"));
export default function LazySegmentScreen(props: SegmentScreenProps) {
  return (
    <Suspense fallback={<SegmentScreenLoading />}>
      <Component {...props} />
    </Suspense>
  );
}
