import { useIntl } from "react-intl";
import { UserAccounts } from "src/auth/components/UserAccounts/UserAccounts";
import { Dialog } from "src/components/Dialog/Dialog";
import { Rome2rioLogoMonogram } from "src/svg/Rome2rioLogoMonogram";
import { Icon } from "src/components/Icon/Icon";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { spacing } from "src/theme";
import styled from "styled-components";
import messages from "./UserAccountTakeover.messages";

type UserAccountTakeoverProps = {
  isDropdownOpen: boolean;
  handleClose: () => void;
  handleClickOffDropdown: () => void;
};

export function UserAccountTakeover(props: UserAccountTakeoverProps) {
  const intl = useIntl();

  return (
    <StyledDialog
      isOpen={props.isDropdownOpen}
      onBackdropClicked={props.handleClickOffDropdown}
    >
      <CloseButtonWrapper>
        <CloseButton onClick={props.handleClose} />
      </CloseButtonWrapper>
      <IconWrapper>
        <Icon size="xxxxxl">
          <Rome2rioLogoMonogram tint="pink" />
        </Icon>
      </IconWrapper>
      <UserAccounts title={intl.formatMessage(messages.singleTripsHeading)} />
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  width: 360px;
  padding-top: ${spacing.xxxl};
  padding-bottom: ${spacing.xl};
  padding-left: ${spacing.xxxl};
  padding-right: ${spacing.xxxl};
  overflow: hidden;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing.xl};
  right: ${spacing.xl};
`;

const IconWrapper = styled.div`
  text-align: center;
  padding-bottom: ${spacing.lg};
`;
