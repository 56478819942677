import { defineMessages } from "react-intl";

export const messages = defineMessages({
  help: {
    id: "topNav.help",
    defaultMessage: "Help",
    description:
      "Text on button that expands dropdown with help links in the navigation header",
  },
  more: {
    id: "topNav.more",
    defaultMessage: "More",
    description:
      "Text on button that expands dropdown with more links in the navigation header",
  },
  travelGuides: {
    id: "topNav.moreDropdown.travelGuides",
    defaultMessage: "Travel guides",
    description: "Label on link to Rome2Rio's travel guides",
  },
  blog: {
    id: "topNav.moreDropdown.blog",
    defaultMessage: "Blog",
    description: "Label on link to the Rome2Rio blog",
  },
  about: {
    id: "topNav.moreDropdown.about",
    defaultMessage: "About us",
    description: 'Label on link to the Rome2Rio "About us" page',
  },
  doNotShareCcpaLink: {
    id: "topNav.moreDropdown.doNotShareCcpaLink",
    defaultMessage: "Do not sell or share my personal information",
    description:
      "Label on link to not sell personal information. Note this has specific legal implications and any changes to translations should be cleared with the leadership team or their delegate.",
  },
  press: {
    id: "topNav.moreDropdown.press",
    defaultMessage: "Press",
    description: 'Label on link to the Rome2Rio "Press" page',
  },
  labs: {
    id: "topNav.moreDropdown.labs",
    defaultMessage: "Labs",
    description: 'Label on link to the Rome2Rio "Labs" page',
  },
  careers: {
    id: "topNav.moreDropdown.careers",
    defaultMessage: "Careers",
    description: 'Label on link to the Rome2Rio "Career" page',
  },
  api: {
    id: "topNav.moreDropdown.api",
    defaultMessage: "API",
    description: 'Label on link to the Rome2Rio "API" page',
  },
  faq: {
    id: "topNav.moreDropdown.faq",
    defaultMessage: "FAQs/Help",
    description:
      'Label on link to the Rome2Rio "Frequently Asked Questions" and "Help" page',
  },
  contact: {
    id: "topNav.moreDropdown.contact",
    defaultMessage: "Contact us",
    description: 'Label on link to the Rome2Rio "Contact us" page',
  },
  facebook: {
    id: "topNav.moreDropdown.facebook",
    defaultMessage: "Facebook",
    description: "Label on link to the Rome2Rio Facebook page",
  },
  twitter: {
    id: "topNav.moreDropdown.twitter",
    defaultMessage: "Twitter",
    description: "Label on link to the Rome2Rio Twitter page",
  },
  manageCookies: {
    id: "topNav.moreDropdown.manageCookies",
    defaultMessage: "Manage cookies",
    description:
      'Label on link to the Rome2Rio "Manage cookies" modal or banner',
  },
  manageConsent: {
    id: "topNav.moreDropdown.manageConsent",
    defaultMessage: "Privacy settings",
    description: "Button to open the GDPR consent popup",
  },
});
