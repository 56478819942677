import { SuccessfulDestinationInfoResponse } from "src/api/DestinationInfoResponse";

export default function createAttractionViewModel(
  attractionInfo: SuccessfulDestinationInfoResponse["attractions"][0]
) {
  return {
    title: attractionInfo.title,
    imageUrl: attractionInfo.imageUrl,
    url: attractionInfo.link,
    description: attractionInfo.description,
  };
}
