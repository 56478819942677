import {
  Hotel,
  HotelDealInfo,
  HotelDealTag,
  HotelListResponse,
} from "../../api/HotelListResponse";
import { SupportedCurrencyCode } from "../../utils/currency";
import { HotelProviderKind } from "../../utils/types/accommodationProviders";
import { HotelSortOption } from "./HotelsContext";

export function hotelsScreenAdapter(
  hotelListResponse: HotelListResponse,
  sortOption: HotelSortOption,
  numOfHotels = 12
): HotelsScreenViewModel {
  const { hotelList, selectedHotel } = hotelListResponse;

  const defaultFilteredHotels =
    hotelList?.filter((hotel) => {
      if (hotel.hotelId === selectedHotel?.hotelId) {
        return false;
      }
      return hotel.minRate > 0;
    }) ?? [];

  if (selectedHotel) {
    defaultFilteredHotels.unshift(selectedHotel);
  }

  const sortedHotels = sortHotels(defaultFilteredHotels, sortOption);

  const hotels = sortedHotels.slice(0, numOfHotels).map((hotel: Hotel) => {
    return {
      price: {
        value: hotel.minRate,
        currency: hotelListResponse.currencyCode,
      },
      title: hotel.hotelName,
      starRating: hotel.rating > 0 ? hotel.rating : undefined, // Expedia hotels have -1 rating. TODO: Remove rating on backend & make this optional.
      hotelId: hotel.hotelId,
      provider: hotelListResponse.provider,
      lat: hotel.lat,
      lng: hotel.lng,
      deal: hotel.deal,
      freeCancellation: hotel.freeCancellation,
    };
  });

  return { hotels, numDefaultFilteredHotels: defaultFilteredHotels.length };
}

function sortHotels(hotels: Hotel[], sortOption: HotelSortOption) {
  if (sortOption === "priceLow") {
    return hotels.slice().sort((a, b) => a.minRate - b.minRate);
  } else if (sortOption === "priceHigh") {
    return hotels.slice().sort((a, b) => b.minRate - a.minRate);
  } else if (sortOption === "reviewHigh") {
    return hotels.slice().sort((a, b) => b.reviewScore - a.reviewScore);
  } else {
    return hotels.slice();
  }
}

export type HotelViewModel = {
  price: { value: number; currency: SupportedCurrencyCode };
  title: string;
  starRating?: number;
  hotelId: string;
  provider: HotelProviderKind;
  lat: number;
  lng: number;
  deal?: HotelDealInfo;
  freeCancellation?: boolean;
};

export type HotelPromotion = {
  tag: HotelDealTag;
  maxDiscount: number;
};

type HotelsScreenViewModel = {
  hotels: HotelViewModel[];
  numDefaultFilteredHotels: number;
};
