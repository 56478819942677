import styled from "styled-components";
import { useIntl } from "react-intl";
import { color, fontSize, textColor } from "../../../theme";
import useSearch from "../../../utils/hooks/useSearch";
import messages from "./SearchResultError.messages";

export type Errors = "no-results" | "error" | "no-destination" | "no-origin";

type Props = {
  errorType: Errors;
};

export function SearchResultError(props: Props) {
  const { origin, destination } = useSearch();
  const intl = useIntl();

  switch (props.errorType) {
    case "error":
      return (
        <ErrorContainer>
          <WarningIconContainer>
            <WarningIcon />
          </WarningIconContainer>
          <ErrorMessage>{intl.formatMessage(messages.error)}</ErrorMessage>
        </ErrorContainer>
      );
    case "no-results":
      return (
        <ErrorMessage>
          {intl.formatMessage(messages.noResults, {
            origin: origin?.canonicalName,
            destination: destination?.canonicalName,
          })}
        </ErrorMessage>
      );
    case "no-origin":
      return (
        <ErrorContainer>
          <WarningIconContainer>
            <WarningIcon />
          </WarningIconContainer>
          <ErrorMessage>{intl.formatMessage(messages.noOrigin)}</ErrorMessage>
        </ErrorContainer>
      );
    case "no-destination":
      return (
        <ErrorContainer>
          <WarningIconContainer>
            <WarningIcon />
          </WarningIconContainer>
          <ErrorMessage>
            {intl.formatMessage(messages.noDestination)}
          </ErrorMessage>
        </ErrorContainer>
      );
  }
}

const ErrorMessage = styled.span`
  font-size: ${fontSize.body};
  color: ${textColor.primaryOnLight};
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WarningIconContainer = styled.div`
  display: flex;
  background: #ebe7eb;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  svg {
    height: 40px;
    width: 40px;
  }
`;

function WarningIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <path
        d="M16.018 1c-3.183 0-8.473 9.667-10.065 12.444S-1.313 25.664.278 28.442c1.591 2.778 12.556 2.554 15.739 2.554s14.112.224 15.704-2.554c1.591-2.778-4.083-12.22-5.675-14.998S19.2 1 16.017 1zm-2.106 6.546h4.069v4.64l-.964 10.035h-2.141l-.964-10.035v-4.64zm.179 16.005h3.747v3.776h-3.747v-3.776z"
        fill={color.white}
      />
    </svg>
  );
}
