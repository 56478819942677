import { useCallback, useEffect, useState } from "react";
import { TripPlannerDetails } from "../TripPlannerProvider";
import { ApiState } from "../util/api";
import {
  markIntroductionComplete,
  useIsIntroductionIncomplete,
} from "./useIsIntroductionComplete";
import { useTripPlannerContext } from "./useTripPlannerContext";

export type TripPlanOnboardStep =
  | undefined
  | "introduction"
  | "training"
  | "complete";

export function useTripPlanOnboarding(
  apiState: ApiState,
  skipIntroAndTraining?: boolean
) {
  const { tripPlannerDetails } = useTripPlannerContext();
  const isIntroductionIncomplete = useIsIntroductionIncomplete();

  const [onboardingStep, setOnboardingStep] =
    useState<TripPlanOnboardStep>(undefined);

  const completeCallback = useCallback(() => {
    if (
      !skipIntroAndTraining &&
      tripPlannerDetails.places.length === 0 &&
      apiState.fetchState !== "fetching"
    ) {
      setOnboardingStep("training");
    }
  }, [tripPlannerDetails, apiState, skipIntroAndTraining]);

  useEffect(() => {
    if (onboardingStep === undefined && apiState.fetchState !== "fetching") {
      setOnboardingStep(
        getCurrentOnboardingStep(
          isIntroductionIncomplete,
          tripPlannerDetails,
          skipIntroAndTraining
        )
      );
    }
  }, [
    onboardingStep,
    isIntroductionIncomplete,
    apiState,
    tripPlannerDetails,
    skipIntroAndTraining,
  ]);

  useEffect(() => {
    if (
      onboardingStep !== "complete" &&
      (skipIntroAndTraining ||
        (tripPlannerDetails.places.length > 2 &&
          apiState.fetchState === "fetched"))
    ) {
      setOnboardingStep("complete");
    }
    if (onboardingStep === "complete") {
      markIntroductionComplete();
      completeCallback();
    }
    if (onboardingStep === "training") {
      markIntroductionComplete();
    }
  }, [
    onboardingStep,
    completeCallback,
    apiState,
    tripPlannerDetails,
    skipIntroAndTraining,
  ]);

  return {
    setOnboardingStep,
    onboardingStep,
  };
}

export function getCurrentOnboardingStep(
  isIntroductionIncomplete: boolean,
  tripPlannerDetails: TripPlannerDetails,
  skipIntroAndTraining?: boolean
): TripPlanOnboardStep {
  if (skipIntroAndTraining) {
    return "complete";
  }
  if (isIntroductionIncomplete && tripPlannerDetails.places.length === 0) {
    return "introduction";
  }
  if (tripPlannerDetails.places.length === 0) {
    return "training";
  }
  return "complete";
}
