import { Mode } from "../../utils/types/mode";
import { compressMode } from "./compressMode";

// Label used to track where users exit from when they go to hotels, car hire, etc
//
// WARNING:
//   - This serializer needs to be kept in sync with c# backend and BigQuery deserialisation.
//   - Note that the "View" enum type in this module corresponds to "Screen" in the other codebases.
//  For more information, SEE:
//  https://rome2rio.atlassian.net/wiki/spaces/ENG/pages/2354610204/ExitLabel+and+ExitPoint+encoding
export function generateExitPoint(
  view: View,
  trigger: Trigger,
  transitMode?: Mode
) {
  const points = ["fe1"];

  points.push(compressView(view));
  points.push(compressTrigger(trigger));

  // PopOut method
  points.push("po");

  // Transit mode
  points.push(compressMode(transitMode));

  return points.join(":");
}

// This needs to be kept in sync with decoders in the c# and bigquery codebases.
// See the comments at the top of the file for more information.
export type View = keyof typeof views;

const views = {
  searchResults: "qp",
  route: "rp",
  segment: "sp",
  schedule: "sdp",
  hotels: "htp",
  bottomNav: "bn",
  genericMap: "m",
  tripSummary: "ts",
  genericTripPlanner: "tpp",
  tripPlannerHotels: "tphtp",
  tripPlannerSearchResults: "tpqp",
  tripPlannerRoute: "tprp",
  tripPlannerSegment: "tpse",
  tripPlannerSchedule: "tpsc",
  tripPlannerTripSummary: "tpts",
} as const;

export function compressView(view: View) {
  return views[view];
}

// This needs to be kept in sync with decoders in the c# and bigquery codebases.
// See the comments at the top of the file for more information.
export type Trigger = keyof typeof triggers;

const triggers = {
  ticketCta: "tcta",
  hotelCta: "hcta",
  carCta: "ccta",
  airportTransferCta: "atcta",
  hotelSearch: "hs",
  schedulesNotification: "sn",
  headerHotel: "hhcta",
  headerCarHire: "rhcta",
  transitUrl: "tu",
  transitUrlHome: "tuh",
  transitUrlSchedules: "tus",
  transitUrlBook: "tub",
  hotelCard: "hc",
  hotelCtaTimeline: "htcta",
  affiliatePromo: "apcta",
} as const;

export type PartnerPageExitCategory =
  | "NotSpecified"
  | "Search"
  | "DateResults"
  | "SpecificResult"
  | "HotelAffiliatePromo";

export function compressTrigger(trigger: Trigger) {
  return triggers[trigger];
}
