// create messages file
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  closeButtonTitle: {
    id: "SignInButton.closeButtonTitle",
    defaultMessage: "Close",
    description: "Close button icon title shown to screen readers",
  },
  profile: {
    defaultMessage: "Profile",
    description: "Text that describe the profile image button",
    id: "SignInButton.profile",
  },
});
