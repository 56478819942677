import { useIntl } from "react-intl";
import { color, fontSize, fontWeight, lineHeight, spacing } from "src/theme";
import styled from "styled-components";
import { Dot } from "./LocationNights";
import { tripDateMessages } from "./TripDatePicker.messages";
import { dateFormat, getDateFormatText, getNightOrNightsText } from "./utils";

type SelectionNightsInfoProps = {
  departureDate: Date | undefined;
  nightsCount: number | undefined;
};

export function SelectionNightsInfo({
  departureDate: startDate,
  nightsCount,
}: SelectionNightsInfoProps) {
  const intl = useIntl();

  if (!startDate) {
    return (
      <DateMessage>
        {intl.formatMessage(tripDateMessages.instructions)}
      </DateMessage>
    );
  }

  if (nightsCount === undefined) {
    return (
      <DateMessage>
        {intl.formatMessage(tripDateMessages.tripStarts)}
        <Emphasis $filled>
          {intl.formatDate(startDate, getDateFormat(startDate))}
        </Emphasis>
      </DateMessage>
    );
  }

  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + nightsCount);

  const formattedDateText = getDateFormatText(intl, startDate, endDate, true);

  return (
    <DateMessage>
      <Emphasis $filled>{formattedDateText}</Emphasis>
      <Dot />
      <Emphasis $filled>
        {nightsCount} {getNightOrNightsText(intl, nightsCount)}
      </Emphasis>
    </DateMessage>
  );
}

function getDateFormat(date: Date) {
  if (date.getFullYear() !== new Date().getFullYear()) {
    return dateFormat.xl;
  }
  return dateFormat.lg;
}

const Emphasis = styled.p<{ $filled?: boolean }>`
  font-size: ${fontSize.md};
  color: ${color.n300};
  font-weight: ${fontWeight.medium};
`;
const DateMessage = styled.span`
  display: flex;
  align-items: center;
  gap: ${spacing.md};
  font-size: ${fontSize.md};
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.tight};
  color: ${color.n70};
`;
