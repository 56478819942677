import { defineMessages } from "react-intl";

const messages = defineMessages({
  close: {
    id: "tripPlanner.userPrompt.close",
    description: "Close button",
    defaultMessage: "Close",
  },
  title: {
    id: "tripPlanner.userPrompt.title",
    description: "Title for saving your trip notification",
    defaultMessage: "Save your progress",
  },
  description: {
    id: "tripPlanner.userPrompt.description",
    description: "Description for saving your trip notification",
    defaultMessage:
      "Don’t lose your trip, keep planning on your computer, smartphone or tablet",
  },
  save: {
    id: "tripPlanner.userPrompt.save",
    description: "Save button for saving your trip notification",
    defaultMessage: "Save trip",
  },
  signInTitle: {
    id: "tripPlanner.userPrompt.signInTitle",
    description: "Title for sign in notification",
    defaultMessage: "Keep planning on any device, anywhere ",
  },
});

export default messages;
