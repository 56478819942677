import { ReactElement } from "react";
import { useIntl } from "react-intl";
import { HotelProviderKind } from "src/utils/types/accommodationProviders";
import styled from "styled-components";
import { Icon } from "../../../components/Icon/Icon";
import { ChevronRight } from "../../../svg/ChevronRight";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import messages from "./SearchResultsPromo.messages";

type Props = {
  icon: ReactElement;
  iconBackgroundColor: keyof typeof color;
  header: string;
  tagline: string;
  onClick: () => void;
  className?: string;
  providerLogo?: HotelProviderKind;
};

export function SearchResultsPromo(props: Props) {
  const intl = useIntl();

  return (
    <PromoWrapper onClick={props.onClick} className={props.className}>
      <IconWrapper backgroundColor={props.iconBackgroundColor}>
        <Icon size="md">{props.icon}</Icon>
      </IconWrapper>
      <div>
        <Header>{props.header}</Header>
        {props.tagline ? <Tagline>{props.tagline}</Tagline> : undefined}
      </div>
      <StyledChevronIcon size="lg">
        <ChevronRight
          title={intl.formatMessage(messages.chevronIcon)}
          tint="grey4"
        />
      </StyledChevronIcon>
    </PromoWrapper>
  );
}

const PromoWrapper = styled.div`
  padding: ${spacing.xl};
  display: flex;
  align-items: center;
  background-color: ${color.white};
  cursor: pointer;

  &:active {
    background-color: ${color.grey1};
    -webkit-tap-highlight-color: ${color.grey1};
  }

  &:hover {
    background-color: ${color.grey2};
    -webkit-tap-highlight-color: transparent;

    // Reset hover state on touch devices so that they don't get a
    // lingering hover effect after a click event.
    &:not(:active) {
      @media (hover: none) {
        background-color: ${color.white};
        -webkit-tap-highlight-color: transparent;
      }
    }
  }
`;

const IconWrapper = styled.span<{
  backgroundColor: keyof typeof color;
}>`
  background-color: ${(props) => color[props.backgroundColor]};
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacing.xl};
  flex-shrink: 0;
`;

const Header = styled.h2`
  font-size: ${fontSize.h5};
  font-weight: ${fontWeight.medium};
`;

const Tagline = styled.div`
  font-size: ${fontSize.h6};
  margin-top: ${spacing.sm};
  line-height: ${lineHeight.normal};
`;

const StyledChevronIcon = styled(Icon)`
  margin-left: auto;
`;
