import Cookies from "js-cookie";
import { timeFormatMessages } from "../domain/TimeFormatScreen/TimeFormatScreen.messages";

export const TIME_FORMAT_COOKIE_NAME = "timeFormat12HR" as const;

/**
 * This shouldn't be used to access user preferences outside of index.
 * The hook "useUser" or utilising the UserPreferences context is the way to do it
 */
export const getInitialTimeFormatCookieValue = (): boolean | undefined => {
  const timeFormat12HR = Cookies.get(TIME_FORMAT_COOKIE_NAME);
  if (timeFormat12HR === "true") {
    return true;
  } else if (timeFormat12HR === "false") {
    return false;
  } else {
    return undefined;
  }
};

export const setTimeFormatCookie = (timeFormat12HR: boolean) => {
  const cookieValue = `${timeFormat12HR}`;
  // set to expire in 1000 days because that's how it works in old MapView code
  Cookies.set(TIME_FORMAT_COOKIE_NAME, cookieValue, {
    expires: 1000,
  });
};

export const getSupportedTimeFormat = (prefers12Hour: boolean) => {
  const TIME_FORMAT_12HR = {
    text: timeFormatMessages.timeFormat12H,
    abbr: "12 HR",
    message: "12h",
  } as const;

  const TIME_FORMAT_24HR = {
    text: timeFormatMessages.timeFormat24H,
    abbr: "24 HR",
    message: "24h",
  } as const;

  return prefers12Hour ? TIME_FORMAT_12HR : TIME_FORMAT_24HR;
};
