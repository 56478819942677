import { Suspense, lazy } from "react";
import { Skeleton } from "src/components/Skeleton/Skeleton";

// TODO: Ideally combine this bundle with the HotelScreen bundle, as they will both activate at the same time.
const HotelLargeSearchBar = lazy(() => import("./HotelLargeSearchBar"));

export default function LazyHotelLargeSearchBar() {
  return (
    <Suspense fallback={<Skeleton height={56} />}>
      <HotelLargeSearchBar />
    </Suspense>
  );
}
