import { SvgProps, useColor } from "./common";

export const KeyFilled = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        d="M16.1373 12.9442L10.7571 7.564C11.6971 4.38381 9.55467 1.02485 6.06984 0.690766C3.94815 0.487234 1.85257 1.62608 0.869727 3.51745C-0.744473 6.62333 0.908558 10.2481 4.08875 11.1887C5.08231 11.4827 6.1388 11.4827 7.13236 11.1887L8.49549 12.5519C8.62136 12.6777 8.79142 12.748 8.96884 12.748L10.3079 12.748C10.6774 12.748 10.9774 13.048 10.9774 13.4176L10.9774 14.7566C10.9774 14.934 11.0477 15.1047 11.1735 15.2299L12.5126 16.569C12.6384 16.6948 12.8085 16.7651 12.9859 16.7651L15.664 16.7651C16.0336 16.7651 16.3335 16.4652 16.3335 16.0956L16.3335 13.4176C16.3335 13.2401 16.2632 13.0694 16.1373 12.9442ZM3.61273 6.0529C3.61273 4.94352 4.51188 4.04436 5.62127 4.04436C6.73065 4.04436 7.62981 4.94352 7.62981 6.0529C7.62981 7.16229 6.73065 8.06145 5.62127 8.06145C4.51188 8.06145 3.61273 7.16229 3.61273 6.0529Z"
        fill={tintColor}
      />
    </svg>
  );
};
