import { defineMessages } from "react-intl";

const messages = defineMessages({
  addDestination: {
    id: "placeInputList.addDestination",
    description: "Label for button to add another destination to the list",
    defaultMessage: "Add Destination",
  },
  startingFrom: {
    id: "placeInputList.startingFrom",
    description: "Label for an empty input to set the first location",
    defaultMessage: "Starting from...",
  },
  goingTo: {
    id: "placeInputList.goingTo",
    description: "Label for an empty input to set the second location",
    defaultMessage: "Going to...",
  },
});

export default messages;
