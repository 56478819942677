import { useNavigate } from "react-router";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";

export function useClosePane() {
  const location = useTypedLocation();
  const navigate = useNavigate();
  function closePane({ replace } = { replace: false }) {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
        tripScheduleIndex: undefined,
        returnsFlowLocation: "departing",
      },
      `#trips`,
      {
        ...location,
      },
      replace
    );
  }
  return { closePane };
}
