import { SyntheticEvent, useState } from "react";
import { useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import styled from "styled-components";
import { Button } from "../../../../components/Button/Button";
import { Dialog } from "../../../../components/Dialog/Dialog";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../../theme";
import { UserProfile } from "../../../utils/user";
import { CloseButton } from "../../CloseButton/CloseButton";
import { ManageProfile } from "../ManageProfile";
import { messages } from "../ManageProfile.messages";

type Props = {
  user: UserProfile;
};
export function ManageProfileDialog({ user }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  function handleManageProfileClick() {
    sendAnalyticsInteractionEvent("UserAccount", "Click:ManageProfile");
    setIsOpen(true);
  }

  function handleManageProfileClose() {
    sendAnalyticsInteractionEvent("UserProfile", "Click:Close");
    setIsOpen(false);
  }

  function handleBackdropClicked(e: SyntheticEvent) {
    e.stopPropagation();
    sendAnalyticsInteractionEvent("UserProfile", "Click:Shadow");
    setIsOpen(false);
  }

  return (
    <>
      <StyledButton
        backgroundColor="transparent"
        textColor="pinkOnLight"
        size="large"
        inline
        onClick={handleManageProfileClick}
      >
        {intl.formatMessage(messages.manageProfile)}
      </StyledButton>
      <Dialog
        isOpen={isOpen}
        onBackdropClicked={handleBackdropClicked}
        variant="default"
      >
        <Container onClick={(e: SyntheticEvent) => e.stopPropagation()}>
          <Heading>
            <CloseButton onClick={handleManageProfileClose} />
            <Title>{intl.formatMessage(messages.updateYourDetails)}</Title>
          </Heading>

          <ManageProfile user={user} />
        </Container>
      </Dialog>
    </>
  );
}

const Container = styled.div`
  padding: ${spacing.xxl};
  max-width: 432px;
  height: 512px;
  gap: ${spacing.xxl};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;
const Title = styled.h4`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h2};
  line-height: ${lineHeight.snug};
  color: ${color.black};
  width: 100%;
`;

const StyledButton = styled(Button)`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  min-height: 22px;
  padding: 0;
`;
