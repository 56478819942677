import { useIntl } from "react-intl";
import styled, { css } from "styled-components";
import { Plus } from "../../../../svg/tripplanner/Plus";
import { Search } from "../../../../svg/Search";
import { Bookmark } from "../../../../svg/tripplanner/Bookmark";
import { SimplePlan } from "../../../../svg/tripplanner/SimplePlan";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../../theme";
import messages from "../TripPlannerOnboarding.messages";
import { Confetti } from "../../../../svg/tripplanner/Confetti";
import { Button } from "../../../../components/Button/Button";
import { sendAnalyticsInteractionEvent } from "../../../../analytics/sendAnalyticsInteractionEvent";
import {
  largeDesktopLayout,
  useLayout,
} from "../../../../utils/hooks/useLayout";
import { TripPlannerOnboardingExitButton } from "../TripPlannerOnboardingExitButton";

type TripPlannerIntroProps = {
  hideIntroductionCallback: () => void;
};

export function TripPlannerIntroduction({
  hideIntroductionCallback,
}: TripPlannerIntroProps) {
  const intl = useIntl();
  const startLabel = intl.formatMessage(messages.start);
  const layout = useLayout();

  function startPlanningCallback() {
    sendAnalyticsInteractionEvent("TripPlanner", "Click:StartPlanning");
    hideIntroductionCallback();
  }

  return (
    <>
      <ConfettiWrapper>
        <Confetti size={layout === "desktop" ? "large" : "small"} />
      </ConfettiWrapper>
      <Container>
        <Title>{intl.formatMessage(messages.title)}</Title>
        {layout !== "desktop" && (
          <Figure>
            <SimplePlan />
          </Figure>
        )}
        <Caption>{intl.formatMessage(messages.caption)}</Caption>
        {layout !== "desktop" && (
          <ListTitle>{intl.formatMessage(messages.listtitle)}</ListTitle>
        )}
        <ActionList>
          <Action>
            <IconWrapper>
              <Search tint="pink" />
            </IconWrapper>
            {intl.formatMessage(messages.search)}
          </Action>
          <Action>
            <IconWrapper>
              <Bookmark tint="pink" />
            </IconWrapper>
            {intl.formatMessage(messages.save)}
          </Action>
          <Action>
            <IconWrapper>
              <Plus tint="pink" />
            </IconWrapper>
            {intl.formatMessage(messages.add)}
          </Action>
        </ActionList>
      </Container>
      <ButtonRow>
        <TripPlannerOnboardingExitButton />
        <StartPlanButton
          data-testid="start-planning"
          onClick={startPlanningCallback}
          backgroundColor="pink"
          textColor="primaryOnDark"
          justify="center"
          title={startLabel}
          fontSize="body"
        >
          {startLabel}
        </StartPlanButton>
      </ButtonRow>
    </>
  );
}

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StartPlanButton = styled(Button)`
  width: 328px;
  max-width: 100%;
  margin: ${spacing.xl} auto 0;
  ${largeDesktopLayout} {
    margin: 0 0 0 auto;
    width: 150px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 246px;
  max-width: 100%;
  margin: 0 auto;
  color: ${color.cod};
  position: relative;

  ${largeDesktopLayout} {
    width: 400px;
  }
`;

const Figure = styled.div`
  margin-bottom: ${spacing.xl};
  svg {
    max-width: 100%;
    height: auto;
  }
`;

const Title = styled.h1`
  font-size: ${fontSize.h2};
  text-align: center;
  margin-bottom: ${spacing.xl};
  box-shadow: 0 0 10px 5px ${color.n10};
  border-radius: 1rem;
  background-color: ${color.n10};

  ${largeDesktopLayout} {
    box-shadow: none;
    background-color: transparent;
  }
`;
const Caption = styled.p`
  text-align: center;
  line-height: ${lineHeight.tight};
  margin-bottom: ${spacing.xl};
  ${largeDesktopLayout} {
    font-size: ${fontSize.h4};
    margin-bottom: ${spacing.xxl};
  }
`;

const ListTitle = styled.h4`
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.medium};
  width: 100%;
  margin-bottom: ${spacing.sm};
`;

const ActionList = styled.ul`
  list-style: none;
  width: 100%;
  ${largeDesktopLayout} {
    width: 90%;
  }
`;

const Action = styled.li`
  display: flex;
  align-items: center;
  font-size: ${fontSize.h6};
  margin-bottom: ${spacing.md};
  ${largeDesktopLayout} {
    margin-bottom: ${spacing.xl};
    font-size: ${fontSize.h4};
  }
`;
const IconWrapper = styled.div`
  width: 12px;
  margin-right: ${spacing.xl};
  ${largeDesktopLayout} {
    width: 18px;
  }

  svg {
    display: block;
    margin: 0 auto;
    height: 12px;
    width: auto;
    ${largeDesktopLayout} {
      height: 18px;
    }
  }
`;

function delayPaths() {
  let styles = "";
  for (let i = 0; i < 20; i++) {
    styles += `
      &:nth-child(${i}) {
        animation-delay: ${i / 2}s;
      }
    `;
  }
  return css`
    ${styles}
  `;
}

const floatAnimation = css`
  @keyframes swirl {
    0% {
      transform: rotate(0deg) translateY(0) rotate(0deg);
    }
    50% {
      transform: rotate(90deg) translateY(10px) rotate(-90deg);
    }
    100% {
      transform: rotate(0deg) translateY(0) rotate(0deg);
    }
  }
  path {
    animation: swirl 20s linear infinite;
    transform-origin: center center;
    ${delayPaths()}
  }
`;

const ConfettiWrapper = styled.div`
  position: absolute;
  top: -4%;
  left: 0;
  width: 100%;
  height: auto;
  svg {
    width: 100%;
    height: auto;
    overflow: visible;
    ${floatAnimation}
  }
`;
