import { SearchResponse, Segment, Option } from "../../api/SearchResponse";

export const frequencyFromSegment = (
  response: SearchResponse,
  segment: Segment
): number | undefined => {
  let result;
  for (const optionIndex of segment.options) {
    const option = response.options[optionIndex];
    const currentFrequency = frequencyFromOption(response, option);

    if (result === undefined) {
      result = currentFrequency;
    } else if (currentFrequency !== undefined) {
      result += currentFrequency;
    }
  }

  return result;
};

export const frequencyFromOption = (
  response: SearchResponse,
  option: Option
): number | undefined => {
  // No need to loop because Options are currently only ever of a singe type and only surface segments have
  // frequency information.
  const hopIndex = option.hops[0];
  const hop = response.hops[hopIndex];
  return hop.frequency;
};
