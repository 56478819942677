import { defineMessages } from "react-intl";

const messages = defineMessages({
  header: {
    id: "searchResultsHotelPromo.header",
    defaultMessage: "{location} Accommodation",
    description: "Header for a hotel promo. Ex: {Sydney Accommodation}",
  },
  tagline: {
    id: "searchResultsHotelPromo.tagline",
    defaultMessage: "Best price guarantee",
    description: "Tagline in the hotel promo",
  },
});

export default messages;
