import { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { isLoggedIn } from "src/auth/utils/session";
import { UserProfile } from "src/auth/utils/user";
import { UserAccountTakeover } from "src/domain/TripPlanner/UserAccountTakeover/UserAccountTakeover";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import { FreelancerMarkerLogin } from "src/domain/FreelancerMarkerLogin/FreelancerMarkerLogin";
import { useFeature } from "src/feature/useFeature";
import { SignInButton } from "../../../auth/components/SignInButton/SignInButton";
import { UserAccounts } from "../../../auth/components/UserAccounts/UserAccounts";
import useUser from "../../../utils/hooks/useUser";
import { messages } from "./UserDropdown.messages";

type UserDropdownProps = {
  fullWidth?: boolean;
};

export function UserDropdown(props: UserDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const intl = useIntl();
  const { user, loadingSession } = useUser();
  const { isSmScreen } = useScreenMinWidth();
  const freelancerQaFeature = useFeature("FreelancerQA");

  const text = getTextValue(intl, user);

  function handleClickButton() {
    sendAnalyticsInteractionEvent(
      "TopNav",
      `Click:${user ? "UserButton" : "SignInButton"}`
    );
    setIsDropdownOpen(true);
  }
  function handleClickOffDropdown() {
    sendAnalyticsInteractionEvent("UserDropdown", "Click:Shadow");
    setIsDropdownOpen(false);
  }
  function handleClose() {
    sendAnalyticsInteractionEvent("UserDropdown", "Click:Close");
    setIsDropdownOpen(false);
  }

  // Close the dropdown once a user has logged in
  useEffect(() => {
    if (!loadingSession) {
      setIsDropdownOpen(false);
    }
  }, [loadingSession]);

  function getVariant() {
    if (isSmScreen) {
      return "horizontalSearch";
    } else {
      return "default";
    }
  }

  return (
    <>
      {isSmScreen ? (
        <>
          <SignInButton
            handleClickButton={handleClickButton}
            text={text}
            variant={getVariant()}
            fullWidth
          />
          <UserAccountTakeover
            isDropdownOpen={isDropdownOpen}
            handleClose={handleClose}
            handleClickOffDropdown={handleClickOffDropdown}
          />
        </>
      ) : (
        <SignInButton
          isDropdownOpen={isDropdownOpen}
          handleClickButton={handleClickButton}
          handleClickOffDropdown={handleClickOffDropdown}
          handleClose={handleClose}
          text={text}
        >
          <UserAccounts />
        </SignInButton>
      )}
      {freelancerQaFeature === "login" && (
        <FreelancerMarkerLogin showLoginForm={handleClickButton} />
      )}
    </>
  );
}

function getTextValue(intl: IntlShape, user?: UserProfile) {
  if (user) {
    return user.name;
  }
  // If there is no user, but the user is logged in, we don't want to show the sign in button, so we return undefined
  // for now until the user state is updated
  if (isLoggedIn()) {
    return undefined;
  }
  return intl.formatMessage(messages.SignIn);
}
