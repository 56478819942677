import { defineMessages } from "react-intl";

const messages = defineMessages({
  header: {
    defaultMessage: "Rome2Rio Tip",
    id: "routeTicketsPromo.header",
    description:
      "Header of a section that displays a helpful tip/hint for Rome2Rio",
  },
  selectDatesCta: {
    defaultMessage: "Select Dates",
    id: "routeTicketsPromo.mobileCta",
    description: "Text on a button that opens the date picker on mobile",
  },
});

export default messages;
