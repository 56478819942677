import { SvgProps, useColor } from "./common";

export function Calendar(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.113 3.75a.75.75 0 00-1.5 0V5H6a3 3 0 00-3 3v10a3 3 0 003 3h12a3 3 0 003-3V8a3 3 0 00-3-3h-1.615V3.75a.75.75 0 00-1.5 0V5H9.113V3.75zM19 10.296H5V18a1 1 0 001 1h12a1 1 0 001-1v-7.704zM6.682 12.239a.5.5 0 00-.5.5v.456a.5.5 0 00.5.5h.455a.5.5 0 00.5-.5v-.456a.5.5 0 00-.5-.5h-.455zm4.591.5a.5.5 0 01.5-.5h.457a.5.5 0 01.5.5v.456a.5.5 0 01-.5.5h-.457a.5.5 0 01-.5-.5v-.456zm5.59-.5a.5.5 0 00-.5.5v.456a.5.5 0 00.5.5h.456a.5.5 0 00.5-.5v-.456a.5.5 0 00-.5-.5h-.455zM6.183 16.614a.5.5 0 01.5-.5h.455a.5.5 0 01.5.5v.455a.5.5 0 01-.5.5h-.455a.5.5 0 01-.5-.5v-.455zm5.591-.5a.5.5 0 00-.5.5v.455a.5.5 0 00.5.5h.457a.5.5 0 00.5-.5v-.455a.5.5 0 00-.5-.5h-.457zm4.59.5a.5.5 0 01.5-.5h.456a.5.5 0 01.5.5v.455a.5.5 0 01-.5.5h-.455a.5.5 0 01-.5-.5v-.455z"
        fill={tintColor}
      />
    </svg>
  );
}
