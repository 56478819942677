import { SearchResponse, Option } from "../../api/SearchResponse";

export const distanceFromRoute = (
  response: SearchResponse,
  routeIndex: number
): number | undefined => {
  const route = response.routes[routeIndex];

  const distances = route.segments.map((_, segmentIndexInRoute) =>
    distanceFromSegment(response, routeIndex, segmentIndexInRoute)
  );

  return distances.reduce<number>((acc, d) => (d ? acc + d : acc), 0);
};

export const distanceFromSegment = (
  response: SearchResponse,
  routeIndex: number,
  segmentIndexInRoute: number
): number | undefined => {
  const searchResponseSegmentIndex =
    response.routes[routeIndex].segments[segmentIndexInRoute];
  const segment = response.segments[searchResponseSegmentIndex];

  const optionIndex = segment.options[0];
  const option = response.options[optionIndex];
  return distanceFromOption(response, option);
};

const distanceFromOption = (
  response: SearchResponse,
  option: Option
): number | undefined => {
  let result = 0;
  for (const hopIndex of option.hops) {
    const hop = response.hops[hopIndex];
    const lineIndex = hop.line;
    const line = response.lines[lineIndex];
    if (line.distance) {
      result += line.distance;
    }
  }
  if (result === 0) {
    return undefined;
  }
  return result;
};
