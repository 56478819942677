import { useEffect, useRef } from "react";
import { useNavigateToHotelsPage } from "./useNavigateToHotelsPage";
import useSearch from "./useSearch";
import { useTypedLocation } from "./useTypedLocation";

export function useSwitchToHotelsNear() {
  const { origin } = useSearch();
  const location = useTypedLocation();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const isHotelsNear = origin?.canonicalName === "Hotels-Near";
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {
    if (isHotelsNear) {
      navigateToHotels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHotelsNear]);
}
