import { cva, type VariantProps } from "class-variance-authority";
import {
  Button as AriaButton,
  composeRenderProps,
  type ButtonProps as AriaButtonProps,
} from "react-aria-components";
import fontStyles from "../Typography/Typography.module.css";
import styles from "./Button.module.css";

export const buttonVariants = cva(styles["r2r-button"], {
  variants: {
    variant: {
      primary: [styles.primary, fontStyles.medium],
      secondary: [styles.secondary, fontStyles.medium],
      subtle: [styles.subtle, fontStyles.medium, fontStyles.underline],
    },
    size: {
      small: [styles.small, fontStyles["label-md"]],
      medium: [styles.medium, fontStyles["label-md"]],
      large: [styles.large, fontStyles["label-lg"]],
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "medium",
  },
});

export type ButtonProps = AriaButtonProps & VariantProps<typeof buttonVariants>;

export function Button({ className, variant, size, ...props }: ButtonProps) {
  return (
    <AriaButton
      className={composeRenderProps(className, (className) =>
        buttonVariants({
          variant,
          size,
          className,
        })
      )}
      {...props}
    />
  );
}
