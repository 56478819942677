import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { AutocompleteKind } from "src/domain/AutocompleteScreen/AutocompleteScreen";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import { FocusContext } from "src/FocusContext";
import { GeocodedPlace } from "src/PrefetchData";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { useFeature } from "src/feature/useFeature";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { getLastTripPlannerPlace } from "src/domain/TripPlanner/util/getTripPlannerPlace";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import useAutocomplete, {
  useKeyboardAutocompleteNavigation,
} from "../../../../utils/hooks/useAutocomplete";
import { AutocompleteInput } from "../../../AutocompleteInput/AutocompleteInput";
import { AutocompleteList } from "../../../AutocompleteList/AutocompleteList";

export function AddDestinationInput(props: {
  onSelectOption: (place: AutocompletePlace) => void;
  id: string;
  type: AutocompleteKind;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  floatingList?: boolean;
  onFocus?: (e: SyntheticEvent) => void;
  onChange?: (query: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  AutocompleteListWrapper?: FC<React.PropsWithChildren<unknown>>;
  initialValue?: string;
  solidBackground?: boolean;
  hideList?: boolean;
  placeholder?: string;
  hideIcon?: boolean;
  transparentBackground?: boolean;
  addDestinationRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  notRelative?: boolean;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  curvedBorder?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  isFocused?: boolean;
  showClearIcon?: boolean;
  handleClear?: () => void;
  handleClickAway?: () => void;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  horizontalSearch?: boolean;
  index?: number;
  filteredPlaces?: GeocodedPlace[];
}) {
  const autocompleteListScrollRef = useRef<HTMLDivElement>(null);
  const { AutocompleteListWrapper = DefaultAutocompleteWrapper } = props;
  const { tripPlannerDetails, isMultiTrip } = useTripPlannerContext();
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);
  const isDestinationRecommendation =
    useFeature("PreDestinationPages") !== "baseline";
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const destinationPlace = getLastTripPlannerPlace(tripPlannerDetails);
  // TODO: once active, this will turn on autocomplete rec
  const isDestinationPageActive = false;
  const isRecommendationsEnabled =
    isDestinationRecommendation &&
    isTripsAsCoreHoldback &&
    !screenKey &&
    destinationPlace?.countryCode === "IT" &&
    isDestinationPageActive;
  const isHSBDestinationInput = ![
    "add-destination-inline-0",
    "add-destination-inline",
    "add-destination-modal",
  ].includes(props.id);
  const isDestinationInput = [
    "add-destination-inline",
    "add-destination",
  ].includes(props.id);
  const {
    results: rawResults,
    query,
    changeQuery,
  } = useAutocomplete(props.initialValue, undefined, false);

  let results: AutocompletePlace[] = query !== "" ? rawResults : [];

  const { filteredPlaces } = props;
  if (filteredPlaces?.length) {
    results = results.filter(
      (result) =>
        !filteredPlaces.some(
          (place) =>
            place.canonicalName === result.canonicalName ||
            place.longName === result.longName // To catch places with multiple canonical names (e.g Berlin, Berlin-Germany)
        )
    );
  }

  const italyRecommendation: AutocompletePlace = {
    kind: "country",
    shortName: "Italy",
    canonicalName: "Italy",
    longName: "Explore recommendations for Italy",
    link: "/discover/italy",
  };
  if (isRecommendationsEnabled && isHSBDestinationInput) {
    results.push(italyRecommendation);
  }

  function onSelectOption(place: AutocompletePlace) {
    const inputValue = !isDestinationInput ? place.longName : "";
    resetInput(inputValue);
    props.onSelectOption(place);
  }

  function onPressEscape() {
    changeQuery("");
  }
  const { onKeyDown, focusRef, resetFocus, onFocusChanged, focusedIndex } =
    useKeyboardAutocompleteNavigation({
      id: props.id,
      results,
      onPressEnter: onSelectOption,
      onPressEscape,
      onPressTab: () => resetInput(),
      scrollContainerRef: autocompleteListScrollRef,
    });

  function onChangeCallback(query: string) {
    changeQuery(query);
    if (props.onChange) {
      props.onChange(query);
    }
  }

  function resetInput(newValue?: string) {
    changeQuery(newValue || props.initialValue || "");
    resetFocus();
    props.handleClickAway?.();
    setIsFocused(false);
  }

  function handleClickAway(e: MouseEvent) {
    if (e.target !== props.addDestinationRef?.current) {
      resetInput();
    }
  }

  // Prefill input on load
  useEffect(() => {
    if (props.initialValue) {
      changeQuery(props.initialValue);
    }
    // Ignore exhaustive deps because we only want to run this if an input is
    // empty and another destination is added.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripPlannerDetails.places]);

  const [isFocused, setIsFocused] = useState(false);

  function handleFocus(e: SyntheticEvent) {
    props.onFocus?.(e);
    setIsFocused(true);
  }

  return (
    <FocusContext.Provider
      value={{
        focusedElement: { id: props.id, index: focusedIndex },
        onFocusChanged,
      }}
    >
      <InputWrapper notRelative={props.notRelative} fullWidth={props.fullWidth}>
        <AutocompleteInput
          type={props.type}
          value={!props.disabled ? query : ""}
          onChange={onChangeCallback}
          id={props.id}
          disabled={props.disabled}
          onBlur={props.onBlur}
          onFocus={handleFocus}
          solidBackground={props.solidBackground}
          icon={props.icon}
          onKeyDown={onKeyDown}
          // We can disable this autoFocus rule because we only ever have one instance
          // in the DOM at any one time.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          transparentBackground={props.transparentBackground}
          noShadow={props.noShadow}
          noBorder={props.noBorder}
          className={props.className}
          addDestinationRef={props.addDestinationRef}
          fullWidth={props.fullWidth}
          curvedBorder={props.curvedBorder}
          isFocused={props.isFocused}
          handleClear={props.handleClear}
          showClearIcon={props.showClearIcon}
          horizontalSearch={props.horizontalSearch}
          index={props.index}
          isDraggable={props.horizontalSearch && isMultiTrip}
        />
        <ClickAwayListener onClickAway={handleClickAway}>
          {props.initialValue === query ||
            (!props.disabled && (
              <AutocompleteListWrapper ref={autocompleteListScrollRef}>
                <AutocompleteList
                  size="sm"
                  iconSize="xxl"
                  results={results}
                  onSelectPlace={onSelectOption}
                  focusRef={focusRef}
                  isInputFocused={isFocused}
                />
              </AutocompleteListWrapper>
            ))}
        </ClickAwayListener>
      </InputWrapper>
    </FocusContext.Provider>
  );
}

const DefaultAutocompleteWrapper = styled.div``;

const InputWrapper = styled.div<{ notRelative?: boolean; fullWidth?: boolean }>`
  position: ${(props) => !props.notRelative && "relative"};
  display: block;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
