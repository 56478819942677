// Description of encoded path:
// http://www.rome2rio.com/api/1.5/docs?key=618d3da82701fdb9cf5fe5729399e2ed#operation/search
export function decodePath(encodedPath: string) {
  let index = 0;
  let lat = 0;
  let lng = 0;
  let latDelta;
  let lngDelta;
  const latLngs: google.maps.LatLngLiteral[] = [];

  if (encodedPath == null) return latLngs;

  while (index < encodedPath.length) {
    var bits = null;
    var shifter = 0;
    var num = 0;

    // decode lat
    do {
      bits = encodedPath.charCodeAt(index++) - 63;
      num |= (bits & 0x1f) << shifter;
      shifter += 5;
    } while (bits >= 0x20);
    latDelta = num & 1 ? ~(num >> 1) : num >> 1;

    shifter = 0;
    num = 0;

    // decode lng
    do {
      bits = encodedPath.charCodeAt(index++) - 63;
      num |= (bits & 0x1f) << shifter;
      shifter += 5;
    } while (bits >= 0x20);
    lngDelta = num & 1 ? ~(num >> 1) : num >> 1;

    lat += latDelta;
    lng += lngDelta;

    latLngs.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }

  return latLngs;
}
