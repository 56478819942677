import { useContext } from "react";
import { UserContext } from "../../UserProvider";

export default function useUser() {
  const result = useContext(UserContext);
  if (result === undefined) {
    throw Error("useUser must be used within a UserContext.");
  }
  return result;
}
