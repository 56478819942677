import { SvgProps, useColor } from "./common";

export const Rome2rioLogoMonogram = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <title>{props.title}</title>
      <g clip-path="url(#z)">
        <path fill={tintColor} d="M48 0H0v48h48V0Z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M15.994 34.772a11.324 11.324 0 0 1-2.288-12.731 11.343 11.343 0 0 1 2.288-12.732c4.425-4.425 11.597-4.425 16.022 0 3.44 3.441 4.209 8.55 2.287 12.732a11.343 11.343 0 0 1-2.287 12.73l-6.685 6.685a1.887 1.887 0 0 1-2.653-.01l-6.684-6.674Zm13.715-16.21a2.841 2.841 0 0 1-3.225 1.07 7.554 7.554 0 0 0-7.818 1.79c-2.954 2.953-2.954 7.734 0 10.678l5.343 5.344 5.344-5.344a7.535 7.535 0 0 0 2.156-6.253l-6.168 6.169a1.887 1.887 0 0 1-2.654-.01l-4.771-4.772a.65.65 0 0 1 0-.918l.206-.207a2.83 2.83 0 0 1 4.003 0l1.894 1.894 5.344-5.344c2.953-2.953 2.953-7.734 0-10.678-2.954-2.953-7.735-2.953-10.679 0a7.563 7.563 0 0 0-2.156 6.281c3.647-3.225 8.888-3.693 12.994-1.406.122.075.394.216.497.31a.668.668 0 0 1 .094.853l-.404.544Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="z">
          <rect width="48" height="48" fill="#fff" rx="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
