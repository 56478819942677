import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "attractionList.heading",
    defaultMessage: "Top attractions",
    description: "Heading for the list of attractions",
  },
  scrollRight: {
    id: "attractionList.scrollRight",
    defaultMessage: "Scroll right",
    description: "Button to scroll right in the list of attractions",
  },
  scrollLeft: {
    id: "attractionList.scrollLeft",
    defaultMessage: "Scroll left",
    description: "Button to scroll left in the list of attractions",
  },
});

export default messages;
