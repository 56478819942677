import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useLayout } from "src/utils/hooks/useLayout";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { RadioGroupList } from "../../components/RadioGroupList/RadioGroupList";
import {
  distanceSystemMessages,
  isDistanceSystem,
  supportedDistanceSystems,
} from "../../utils/distanceSystem";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import useUser from "../../utils/hooks/useUser";
import { navigateToNewState } from "../../utils/location/navigateToNewState";
import messages from "./DistanceScreen.messages";

export function useDistancePreferenceScreen() {
  const intl = useIntl();

  return {
    heading: intl.formatMessage(messages.heading),
    description: intl.formatMessage(messages.description),
  };
}

export function DistanceScreenContent() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const isDesktop = layout === "desktop";
  const { distanceSystem, saveDistanceSystem } = useUser();

  function handleChange(newDistanceSystem: string) {
    if (!isDistanceSystem(newDistanceSystem)) {
      throw Error(
        `received ${newDistanceSystem} but expected one of: ${supportedDistanceSystems.join(
          ", "
        )}`
      );
    }

    sendAnalyticsInteractionEvent(
      "Preferences",
      "Click:SelectDistance",
      `${distanceSystem}->${newDistanceSystem}`
    );

    saveDistanceSystem(newDistanceSystem);

    // go back to main user preferences screen
    if (!isDesktop) {
      navigateToNewState(navigate, { preferencesScreen: "main" }, location);
    }
  }

  return (
    <RadioGroupList
      name="distance"
      selectedValue={distanceSystem}
      onChange={handleChange}
      options={supportedDistanceSystems.map((distanceSystem) => ({
        value: distanceSystem,
        label: intl.formatMessage(distanceSystemMessages[distanceSystem]),
        subtitle: `5 ${distanceSystem === "imperial" ? "mi" : "km"}`,
      }))}
    />
  );
}
