import { IntlShape, useIntl } from "react-intl";
import { formatPrice } from "../FormattedPrice/FormattedPrice";
import { isLocaleCurrencyAfter } from "../../utils/currency";

export type FormattedPriceRangeProps = {
  low: number;
  high: number;
  currencyCode: string;
  showDecimals?: boolean;
  className?: string;
};

export function FormattedPriceRange(props: FormattedPriceRangeProps) {
  const intl = useIntl();
  const formattedPriceRange = formatPriceRange(
    intl,
    props.low,
    props.high,
    props.currencyCode,
    props.showDecimals
  );

  return <span className={props.className}>{formattedPriceRange}</span>;
}

export function formatPriceRange(
  intl: IntlShape,
  low: number,
  high: number,
  currencyCode: string,
  showDecimals?: boolean
): string {
  // Determines if we should show a currency symbol at the end of the range
  const trailingCurrency = isLocaleCurrencyAfter(intl);

  let showOnePrice: boolean;
  if (showDecimals) {
    showOnePrice = low === high;
  } else {
    showOnePrice = Math.round(low) === Math.round(high);
  }

  if (showOnePrice) {
    return formatPrice(
      { value: low, currency: currencyCode, showDecimals },
      intl
    );
  } else {
    const lowFormatted = formatPrice(
      {
        value: low,
        currency: currencyCode,
        showDecimals,
        hideCurrency: trailingCurrency,
      },
      intl
    );
    const highFormatted = formatPrice(
      {
        value: high,
        currency: currencyCode,
        showDecimals,
        hideCurrency: !trailingCurrency,
      },
      intl
    );
    return `${lowFormatted}–${highFormatted}`; // We must use an 'en' dash. Unicode: U+02013
  }
}
