import { IntlShape } from "react-intl";
import { tripDateMessages } from "./TripDatePicker.messages";
import { datesInfoMessages } from "./DatesInfoModal/DatesInfoModal.messages";

export const dateFormat = {
  sm: {
    day: "numeric",
  },
  md: {
    day: "numeric",
    month: "short",
  },
  lg: {
    weekday: "short",
    day: "numeric",
    month: "short",
  },
  xl: {
    day: "numeric",
    month: "short",
    year: "numeric",
  },
} as const;

export const spacedEnDash = ` – `;
export const closedEnDash = `–`;

export function getNightOrNightsText(intl: IntlShape, nightsCount: number) {
  return intl.formatMessage(
    nightsCount === 1 ? tripDateMessages.night : tripDateMessages.nights
  );
}

export function getDateFormatText(
  intl: IntlShape,
  startDate: Date,
  endDate: Date,
  simplified?: boolean
) {
  const isCurrentYear = startDate.getFullYear() === new Date().getFullYear();
  const isWithinSameYear = startDate.getFullYear() === endDate.getFullYear();
  if (!isCurrentYear || !isWithinSameYear) {
    return (
      intl.formatDate(startDate, dateFormat.xl) +
      spacedEnDash +
      intl.formatDate(endDate, dateFormat.xl)
    );
  }
  if (simplified) {
    return (
      intl.formatDate(startDate, dateFormat.lg) +
      spacedEnDash +
      intl.formatDate(endDate, dateFormat.lg)
    );
  }
  const isWithinSameMonth = startDate.getMonth() === endDate.getMonth();
  if (!isWithinSameMonth) {
    return (
      intl.formatDate(startDate, dateFormat.md) +
      spacedEnDash +
      intl.formatDate(endDate, dateFormat.md)
    );
  }
  return (
    intl.formatDate(startDate, dateFormat.sm) +
    closedEnDash +
    intl.formatDate(endDate, dateFormat.md)
  );
}

type ModalMessage = {
  modalTitle: string;
  modalMessage: string;
};

export function getModalMessage(
  intl: IntlShape,
  tripDates: string,
  tripNights: number,
  plannedNights: number
): ModalMessage {
  const nightsToPlan = tripNights - plannedNights;
  const extendPlannedLocations = tripNights > plannedNights;

  // If the user hasn't added dates to any of the destinations of their trip plan
  if (plannedNights === 0) {
    const addPlanTitle = intl.formatMessage(datesInfoMessages.noDatesTitle, {
      nightsToPlan: nightsToPlan,
    });

    const addPlanInfo = intl.formatMessage(datesInfoMessages.noDatesInfo, {
      tripDates: tripDates,
      tripNights: tripNights,
    });

    return {
      modalTitle: addPlanTitle,
      modalMessage: addPlanInfo,
    };

    // If the total trip nights are greater than the allocated nights for each location
  } else if (extendPlannedLocations) {
    const extendTripPlanTitle = intl.formatMessage(
      datesInfoMessages.datesExceedPlan,
      {
        nightsToPlan: nightsToPlan,
      }
    );

    const extendTripPlanInfo = intl.formatMessage(
      datesInfoMessages.extendTripDates,
      {
        tripDates: tripDates,
        tripNights: tripNights,
        plannedNights: plannedNights,
      }
    );

    return {
      modalTitle: extendTripPlanTitle,
      modalMessage: extendTripPlanInfo,
    };
  }

  const defaultTitle = intl.formatMessage(datesInfoMessages.planExceedsDates);

  const extendTripDatesInfo = intl.formatMessage(
    datesInfoMessages.extendTripDates,
    {
      tripDates: tripDates,
      tripNights: tripNights,
      plannedNights: plannedNights,
    }
  );

  // If the total trip nights are less than the allocated nights for each location
  // Default message for any other possible instances as well
  return { modalTitle: defaultTitle, modalMessage: extendTripDatesInfo };
}
