import { SvgProps, useColor } from "../common";

type Props = {
  active?: boolean;
} & SvgProps;

export const DragHandle = (props: Props) => {
  const tintColor = useColor(props.tint);
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{props.title}</title>
      <ellipse
        cx="8.5"
        cy="6.32912"
        rx="1.32912"
        ry="1.5"
        transform="rotate(90 8.5 6.32912)"
        fill={tintColor}
      />
      <ellipse
        cx="8.5"
        cy="12"
        rx="1.32912"
        ry="1.5"
        transform="rotate(90 8.5 12)"
        fill={tintColor}
      />
      <ellipse
        cx="8.5"
        cy="17.6709"
        rx="1.32912"
        ry="1.5"
        transform="rotate(90 8.5 17.6709)"
        fill={tintColor}
      />
      <ellipse
        cx="15.5"
        cy="6.32912"
        rx="1.32912"
        ry="1.5"
        transform="rotate(90 15.5 6.32912)"
        fill={tintColor}
      />
      <ellipse
        cx="15.5"
        cy="12"
        rx="1.32912"
        ry="1.5"
        transform="rotate(90 15.5 12)"
        fill={tintColor}
      />
      <ellipse
        cx="15.5"
        cy="17.6709"
        rx="1.32912"
        ry="1.5"
        transform="rotate(90 15.5 17.6709)"
        fill={tintColor}
      />
    </svg>
  );
};
