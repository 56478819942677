import { useIntl } from "react-intl";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { useLayout } from "src/utils/hooks/useLayout";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useScroll } from "src/ScrollContext";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import useSearch from "src/utils/hooks/useSearch";
import { GeoKind, isPointKind } from "src/utils/geoKind";
import { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "src/utils/hooks/useIsInViewport";
import { HotelProviderKind } from "src/utils/types/accommodationProviders";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { AccomPromoLoggingCategory } from "src/analytics/analyticsEventTypes";
import { SegmentPromoDriveVariant } from "../SegmentPromoDriveVariant/SegmentPromoDriveVariant";
import messages from "./SegmentAccommodationPromoDriveVariant.messages";

type Props = {
  location: string;
  kind: GeoKind;
  loggingFrom: AccomPromoLoggingCategory;
  hotelProvider: HotelProviderKind;
  id: string;
};

export function SegmentAccommodationPromoDriveVariant({
  location,
  kind,
  loggingFrom,
  hotelProvider,
  id,
}: Props) {
  const intl = useIntl();
  const { setScrollTop } = useScroll();
  const { navigateToHotels } = useNavigateToHotelsPage();
  const { origin, destination } = useSearch();
  const layout = useLayout();
  const isMobile = layout === "mobile";
  const isTripsScreenAsCoreFullExperience = useIsTripsAsCoreFullExperience();
  const copyPreposition = isPointKind(kind) ? "Near" : "Around";
  const calloutMessage = messages[`findHotels${copyPreposition}`];

  // We want to see when an accommodation promo gets into view but only fire the log once.
  const accommodationPromoRef = useRef<HTMLButtonElement>(null);
  const accommodationPromoInViewport = useIsInViewport(accommodationPromoRef);
  const [hasAccommodationPromoBeenViewed, setHasAccommodationPromoBeenViewed] =
    useState(false);

  function handleClick() {
    const gaLoggingCategory =
      loggingFrom === "Schedule" ? "ScheduleDetails" : loggingFrom;

    sendAnalyticsInteractionEvent(gaLoggingCategory, "Click:HotelPromo");
    setScrollTop(0);

    if (isTripsScreenAsCoreFullExperience) {
      sendAnalyticsInteractionEvent("TripPlanner", "Click:HotelPromo");
      navigateToHotels({
        originCanonical: origin?.canonicalName,
        destinationCanonical: destination?.canonicalName,
      });
    } else {
      navigateToHotels();
    }
  }

  useEffect(() => {
    if (accommodationPromoInViewport && !hasAccommodationPromoBeenViewed) {
      sendAnalyticsNonInteractionEvent(
        "Screen",
        "AccomPromoVisible",
        `ap0:${hotelProvider}`
      );
      setHasAccommodationPromoBeenViewed(true);
    }
  }, [
    accommodationPromoInViewport,
    id,
    hotelProvider,
    hasAccommodationPromoBeenViewed,
  ]);

  return (
    <SegmentPromoDriveVariant
      ref={accommodationPromoRef}
      heading={intl.formatMessage(calloutMessage, {
        location,
      })}
      tagline={intl.formatMessage(messages.tagline)}
      icon={<HotelAlternative tint="cod" />}
      iconAlign={isMobile ? "left" : "right"}
      outline={isMobile}
      fullWidth={isMobile}
      onClick={handleClick}
    />
  );
}
