import { Portal } from "src/components/Portal/Portal";
import { spacing } from "src/design-system/tokens/spacing";
import styled, { css, keyframes } from "styled-components";
import { border_radius } from "src/design-system/tokens/border";
import { elevation, zIndex } from "src/theme";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import color from "src/design-system/tokens/color";
import { useIsInViewport } from "src/utils/hooks/useIsInViewport";

const TAIL_HEIGHT = 5;

type Props = PropsWithChildren<{
  DialogContent: () => JSX.Element;
  trackPosition?: boolean;
  className?: string;
}>;

/**
 * This component creates a spotlight effect around a parent element. With a dialog that appears next to it.
 * @param DialogContent The content to display in the dialog.
 * @param trackPosition If true, the dialog will track the parent's position. Only use this if you have to.
 */
export default function Spotlight({
  DialogContent,
  trackPosition,
  children,
  className,
}: Props) {
  const [parent, setParent] = useState<HTMLElement | null>(null);
  const [boundingRect, setBoundingRect] = useState<DOMRect>(
    () => parent?.getBoundingClientRect() ?? new DOMRect()
  );
  const isInView = useIsInViewport({
    current: parent,
  });
  const [isAwaiting, setIsAwaiting] = useState(true);
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (isInView && isAwaiting) {
      timeout = setTimeout(() => {
        setIsAwaiting(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInView, isAwaiting]);

  const updateBoundingRect = useCallback(
    (parent: HTMLElement) => {
      const newRect = parent.getBoundingClientRect();
      if (
        newRect.top !== boundingRect.top ||
        newRect.left !== boundingRect.left
      ) {
        setBoundingRect(newRect);
      }
    },
    [boundingRect]
  );

  const dialogPosition = {
    $x: boundingRect.left + boundingRect.width,
    $y: boundingRect.top + boundingRect.height / 2,
  };

  useEffect(() => {
    if (trackPosition && parent) {
      // This element needs to be positioned with the parent's bounding rect.
      // Polling is necessary because the parent's position can change.
      // A resize observer will not work because the parent's x/y attributes won't be tracked.
      // A mutation observer will not work because the parent's attributes may not change when repositioned.
      // An intersection observer will not work because the parent's intersection with the viewport may not change status.
      const interval = setInterval(() => {
        updateBoundingRect(parent);
      }, 10);
      return () => clearInterval(interval);
    }
  }, [parent, updateBoundingRect, trackPosition]);

  return (
    <>
      <ParentContainer
        $isDismissed={isDismissed}
        onClick={() => setIsDismissed(true)}
        ref={setParent}
      >
        {children}
      </ParentContainer>
      {isInView && !isDismissed && parent && !isAwaiting && (
        <>
          <Shadow className={className} onClick={() => setIsDismissed(true)} />
          <Portal>
            <FixedDialog
              {...dialogPosition}
              role="dialog"
              aria-modal="true"
              aria-live="polite"
              tabIndex={-1}
            >
              <Tail />
              <DialogContent />
            </FixedDialog>
          </Portal>
        </>
      )}
    </>
  );
}

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(.2) translateY(-50%);
  }
  to {
    transform: scale(1) translateY(-50%);
    opacity: 1;
  }
`;

const FixedDialog = styled.div<{
  $x: number;
  $y: number;
}>`
  position: fixed;
  left: ${({ $x }) => $x}px;
  top: ${({ $y }) => $y}px;
  transform: translateY(-50%);
  margin: 0 0 0 calc(${spacing.lg} + ${TAIL_HEIGHT}px);
  border-radius: ${border_radius.rounded_lg};
  filter: drop-shadow(${elevation.mid});
  z-index: ${zIndex.dropdown + 1};
  transform-origin: center center;

  @media screen and (prefers-reduced-motion: no-preference) {
    animation: forwards ${scaleIn} 0.3s ease;
  }
`;

const Tail = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform-origin: center center;
  transform: translate(-70%, -50%) rotate(90deg);
  width: 20px;
  height: ${TAIL_HEIGHT}px;
  background-color: white;
  border: 10px solid white;
  pointer-events: none;
  clip-path: path("M 0 0 L 7 12 Q 10 16 13 12 L 20 0 Z");
`;

const showSpotlight = keyframes`
  from {
   opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Shadow = styled.div`
  position: fixed;
  cursor: initial;
  left: 0;
  top: 0;
  width: 200vw;
  height: 200vh;
  z-index: ${zIndex.dropdown};
  background-color: ${color.bg.transparent.active};
  animation: forwards ${showSpotlight} 0.7s ease;
`;

const ParentContainer = styled.div<{ $isDismissed: boolean }>`
  ${({ $isDismissed }) =>
    !$isDismissed &&
    css`
      z-index: ${zIndex.dropdown + 1};
      position: relative;
    `}
`;
