import { lazy, Suspense } from "react";
import HotelsScreenLoading from "./HotelsScreenLoading";

const HotelsScreen = lazy(() => import("src/domain/HotelsScreen/HotelsScreen"));
export default function LazyHotelsScreen() {
  return (
    <Suspense fallback={<HotelsScreenLoading />}>
      <HotelsScreen />
    </Suspense>
  );
}
