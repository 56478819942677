import { useParams } from "react-router";

export type RouteParams = {
  originCanonical?: string;
  destinationCanonical?: string;
  languageCode?: string;
  "*"?: string;
};

export function useRouteParams(): RouteParams {
  const params = useParams();
  if (params && Object.keys(params).length) return params;

  return {};
}
