import { SvgProps, useColor } from "./common";

export const ArrowBackward = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>{props.title}</title>
      <path
        stroke={tintColor}
        d="M14.475 4L2.25 15.995m0 0l12.22 12.003M2.25 15.996l27.5.007"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
