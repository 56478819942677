import { defineMessages } from "react-intl";

export const operatorDetailsRedesignMessages = defineMessages({
  on_demand: {
    id: "frequency.on_demand",
    description:
      "Frequency of a bus that is an on demand service e.g. you need to book ahead",
    defaultMessage: "On demand",
  },
  hourly: {
    id: "frequency.hourly",
    description: "Frequency of a mode that runs hourly e.g. bus runs hourly",
    defaultMessage: "Hourly",
  },
  hours: {
    id: "frequency.hours",
    description:
      "Frequency of a mode that runs every few hours e.g. bus runs every 3 hours",
    defaultMessage: "Every {hours} hours",
  },
  minutes: {
    id: "frequency.minutes",
    description:
      "Frequency of a mode that runs multiple times an hour e.g. bus runs every 10 minutes",
    defaultMessage: "Every {minutes} minutes",
  },
  dailyOnce: {
    id: "frequency.daily.once",
    description:
      "Frequency of a mode that runs once a day e.g. bus runs once daily",
    defaultMessage: "Once daily",
  },
  dailyTwice: {
    id: "frequency.daily.twice",
    description:
      "Frequency of a mode that runs twice a day e.g. bus runs twice daily",
    defaultMessage: "Twice daily",
  },
  dailyMultiple: {
    id: "frequency.daily.multiple",
    description:
      "Frequency of a mode that runs multiple times a day e.g. bus runs 3 times a day",
    defaultMessage: "{frequencyPerDay} times a day",
  },
  weekOnce: {
    id: "frequency.week.once",
    description:
      "Frequency of a mode that runs once a week e.g. bus runs once a week",
    defaultMessage: "Once a week",
  },
  weekTwice: {
    id: "frequency.week.twice",
    description:
      "Frequency of a mode that runs twice a week e.g. bus runs twice a week",
    defaultMessage: "Twice a week",
  },
  weekMultiple: {
    id: "frequency.week.multiple",
    description:
      "Frequency of a mode that runs multiple times a week e.g. bus runs 4 times a week",
    defaultMessage: "{frequencyPerWeek} times a week",
  },
});
