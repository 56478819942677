import { useFeature } from "../../feature/useFeature";
import { isRouteSkippable } from "../isRouteSkippable";
import useSearch from "./useSearch";

export function useIsRouteSkipped(routeIndex?: number) {
  const { searchResponse } = useSearch();
  const shouldSkipNonBookableRoutes = useFeature("SkipNonBookableRoutes");

  return (
    useFeature("SkipRoutes") &&
    !!searchResponse &&
    routeIndex !== undefined &&
    isRouteSkippable(searchResponse, routeIndex, shouldSkipNonBookableRoutes)
  );
}
