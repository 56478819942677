import { SearchResponse } from "../../api/SearchResponse";
import { routeIsFlight } from "../../components/CsaAd/CsaAd";
import { ScreenComponent } from "./ScreenComponent";

export function searchResultScreenComponentList(
  response: SearchResponse | undefined,
  jayridePromosEnabled: boolean
): ScreenComponent[] {
  const result: ScreenComponent[] = [];

  const originCountryCode = getOriginCountryCode(response);
  const destinationCountryCode = getDestinationCountryCode(response);

  if (originCountryCode === "UA" || destinationCountryCode === "UA") {
    // Show a search notification for Ukraine.
    result.push("SearchNotification");
  }

  result.push("RouteList");

  const hasSearchResults = !!response?.routes?.length;
  if (hasSearchResults) {
    result.push("HotelCta");
    result.push("RentalCarCta");

    if (
      jayridePromosEnabled &&
      (response?.carriers.some((carrier) =>
        carrier.name.toLowerCase().includes("jayride")
      ) ||
        response?.routes.some((route) => routeIsFlight(route.canonicalName)))
    ) {
      result.push("AirportTransferCta");
    }
  }

  result.push("TieredDisplayAd");
  result.push("CsaAd");

  return result;
}

function getOriginCountryCode(searchResponse: SearchResponse | undefined) {
  return searchResponse
    ? searchResponse.places[searchResponse.result.originPlace].countryCode
    : undefined;
}

function getDestinationCountryCode(searchResponse: SearchResponse | undefined) {
  return searchResponse
    ? searchResponse?.places[searchResponse.result.destinationPlace].countryCode
    : undefined;
}
