import styled from "styled-components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { isSaveableSearchResponse } from "src/domain/TripPlanner/util/isSaveableSearchResponse";
import { isRouteSaved } from "src/domain/TripPlanner/util/isRouteSaved";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import { TripNavbar } from "src/domain/TripPlanner/TripNavbar/TripNavbar";
import { RouteHeader } from "../RouteHeader/RouteHeader";
import { Navbar } from "../../../components/Navbar/Navbar";
import messages from "../RouteScreen.messages";
import { RouteTicketsPromo } from "../RouteTicketsPromo/RouteTicketsPromo";
import { useAnalyticsPageView } from "../../../utils/hooks/useAnalyticsPageView";
import { createRouteScreenViewModel } from "../createRouteScreenViewModel";
import { SearchResponse } from "../../../api/SearchResponse";
import { color } from "../../../theme";
import { hashChange } from "../../../utils/location/hashChange";
import { useLayout } from "../../../utils/hooks/useLayout";
import { useAnalyticsCoreExperience } from "../../../analytics/useAnalyticsCoreExperience/useAnalyticsCoreExperience";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { routeScreenComponentList } from "../../../analytics/generateScreenComponentsRendered/routeScreenComponentList";
import { TroniconRouteTimeline } from "../../../components/Timeline/UnscheduledTimeline/RouteTimeline/TroniconRouteTimeline";
import { getTransportModes } from "../../../analytics/getTransportModes/getTransportModes";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";

import { navigateToNewStateHash } from "../../../utils/location/navigateToNewStateHash";
import { RouteScreenAds } from "../RouteScreenAds/RouteScreenAds";

type Props = {
  context: "transport" | "tripPlanner";
  searchResponse: SearchResponse;
  routeIndex: number;
};

export function RouteScreenLoaded(props: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const { tripPlannerDetails, isMultiTrip } = useTripPlannerContext();
  const isSaved = isRouteSaved(
    tripPlannerDetails,
    props.searchResponse,
    props.routeIndex
  );

  const components = routeScreenComponentList(
    props.searchResponse,
    props.routeIndex
  );

  const routeCanonical =
    props.searchResponse.routes[props.routeIndex].canonicalName;

  const viewModel = createRouteScreenViewModel(
    props.searchResponse,
    props.routeIndex
  );

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    props.searchResponse,
    "Route",
    getTransportModes(props.searchResponse, props.routeIndex),
    components,
    undefined,
    viewModel.carriers
  );

  useAnalyticsCoreExperience({
    experienceHash: `${location.pathname}:r${routeCanonical}`,
    analyticsLabel: "Route Screen",
  });

  function handleBackClick() {
    navigate(hashChange(undefined, location));
  }

  function handleDetailClick() {
    sendAnalyticsInteractionEvent("RouteTimeline", "Click:Segment");
  }

  function handleTripBackClick() {
    if (!isMultiTrip) {
      return navigateToNewStateHash(navigate, {}, "#trips", {
        ...location,
      });
    }
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      tripHashFromSearchResponse(props.searchResponse),
      {
        ...location,
      }
    );
  }

  const fromDestination = location.state?.fromHash?.includes("destination");
  const breadcrumbProps = !fromDestination
    ? {
        showRouteBreadcrumb: true,
        showSearchBreadcrumb: isMultiTrip,
      }
    : {
        showRouteBreadcrumb: true,
        showDestinationBreadcrumb: true,
      };

  return (
    <RouteScreenWrapper>
      {props.context === "transport" && layout === "mobile" && (
        <Navbar
          headingText={intl.formatMessage(messages.heading)}
          onBackClick={handleBackClick}
          isSaveableScreen={isSaveableSearchResponse(props.searchResponse)}
        />
      )}
      <Content>
        {props.context === "tripPlanner" && (
          <TripNavbar
            isSaved={isSaved}
            onBackClick={handleTripBackClick}
            isSaveableScreen={isSaveableSearchResponse(props.searchResponse)}
            headingText={intl.formatMessage(messages.heading)}
            variant={layout === "desktop" ? "fullScreen" : "default"}
            {...breadcrumbProps}
          />
        )}
        {components.includes("RouteSummary") && (
          <RouteHeader
            title={viewModel.title}
            priceRange={viewModel.priceRange}
            durationInMinutes={viewModel.durationInMinutes}
            routeCanonical={routeCanonical}
            onBackClick={
              props.context === "tripPlanner"
                ? handleTripBackClick
                : handleBackClick
            }
            hidePaneNav={props.context === "tripPlanner"}
          />
        )}
        {components.includes("Timeline") && (
          <TroniconRouteTimeline
            context={props.context}
            routeIndex={props.routeIndex}
            onDetailClick={handleDetailClick}
          />
        )}
        {components.includes("TicketCta") && (
          <StyledRouteTicketsPromo routeIndex={props.routeIndex} />
        )}
        {layout === "desktop" && props.context === "tripPlanner" && (
          <RouteScreenAds />
        )}
      </Content>
    </RouteScreenWrapper>
  );
}

const StyledRouteTicketsPromo = styled(RouteTicketsPromo)`
  border-top: 1px solid ${color.grey2};
  border-bottom: 1px solid ${color.grey2};
`;

const Content = styled.div`
  width: 100%;
  background-color: ${color.white};
`;

const RouteScreenWrapper = styled.div`
  display: inline;
`;
