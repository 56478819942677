import { lighten } from "polished";
import { ChangeEvent, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import styled from "styled-components";
import {
  FocusContext,
  FocusedElement,
  defaultFocusedElement,
} from "../../FocusContext";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { AutocompletePlace } from "../../api/AutocompleteResponse";
import { MAX_RESULTS } from "../../components/AutocompleteDropdown/AutocompleteDropdown";
import { Button } from "../../components/Button/Button";
import { ButtonBase } from "../../components/Button/ButtonBase";
import { ClickAwayListener } from "../../components/ClickAwayListener/ClickAwayListener";
import { Icon } from "../../components/Icon/Icon";
import { Switch } from "../../svg/Switch";
import { color } from "../../theme";
import { localeToLanguageCode } from "../../utils/conversions/languageCode";
import { encodeSearchParamsForUrl } from "../../utils/encodeSearchParamsForUrl";
import { getAutocompleteHint } from "../../utils/getAutocompleteHint";
import { useLayout } from "../../utils/hooks/useLayout";
import { useIsHotelsUrlDeeplink } from "../../utils/hooks/useNavigateToHotelsPage";
import useSearch from "../../utils/hooks/useSearch";
import { useTheme } from "../../utils/hooks/useTheme";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { modulo } from "../../utils/modulo";
import { getPath } from "../../utils/url";
import {
  SearchBar,
  defaultSearchState,
} from "../SearchResultsScreen/SearchBar/SearchBar";
import messages from "./LargeSearchBar.messages.ts";
import { SearchAutocomplete } from "./SearchAutocomplete/SearchAutocomplete";
import { useSearchAutocomplete } from "./useSearchAutocomplete";
import { useUpdateDisplayValueOnGeocode } from "./useUpdateDisplayValueOnGeocode";

const emptyArray: AutocompletePlace[] = [];

export function LargeSearchBar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const theme = useTheme();
  const isHotelScreen = useIsHotelsUrlDeeplink();

  const { origin: searchOrigin, destination: searchDestination } = useSearch();

  const originAutocomplete = useSearchAutocomplete(
    searchOrigin?.longName ?? ""
  );
  const destinationAutocomplete = useSearchAutocomplete(
    searchDestination?.longName ?? ""
  );

  const [originCanonical, setOriginCanonical] = useState(
    searchOrigin?.canonicalName
  );
  const [destinationCanonical, setDestinationCanonical] = useState(
    searchDestination?.canonicalName
  );

  const [isOriginClosed, setIsOriginClosed] = useState(true);
  const [isDestinationClosed, setIsDestinationClosed] = useState(true);

  const originInputRef = useRef<HTMLInputElement>(null);
  const destinationInputRef = useRef<HTMLInputElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const [focusedElement, onFocusElementChanged] = useState<FocusedElement>(
    defaultFocusedElement
  );

  useUpdateDisplayValueOnGeocode({
    currentSearchLongName: searchOrigin?.longName ?? "",
    updateDisplayValue: originAutocomplete.changeDisplayValueWithoutResults,
  });

  useUpdateDisplayValueOnGeocode({
    currentSearchLongName: searchDestination?.longName ?? "",
    updateDisplayValue:
      destinationAutocomplete.changeDisplayValueWithoutResults,
  });

  function handleSwitch() {
    sendAnalyticsInteractionEvent("SearchBox", "Click:Switch");

    if (originCanonical === destinationCanonical) return;

    destinationAutocomplete.changeDisplayValueWithoutResults(
      originAutocomplete.displayValue
    );
    setDestinationCanonical(originCanonical);

    originAutocomplete.changeDisplayValueWithoutResults(
      destinationAutocomplete.displayValue
    );
    setOriginCanonical(destinationCanonical);

    setIsOriginClosed(true);
    setIsDestinationClosed(true);
  }

  function handleOriginQueryChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;

    originAutocomplete.changeDisplayValueWithResults(value);
    // When a user changes the input, also update the canonical because then if a user searches
    // without selecting an autocomplete option the search will be performed using that canonical
    // (which will be geocded before a search is done).
    setOriginCanonical(value);

    setIsOriginClosed(false);
    // Reset the focused autocomplete dropdown element if the user continues to type into the
    // search input while cycling through the autocomplete dropdown list.
    onFocusChanged({ id: "origin", index: 0 });
  }

  function handleDestinationQueryChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;

    destinationAutocomplete.changeDisplayValueWithResults(value);
    // When a user changes the input, also update the canonical because then if a user searches
    // without selecting an autocomplete option the search will be performed using that canonical
    // (which will be geocded before a search is done).
    setDestinationCanonical(value);

    setIsDestinationClosed(false);
    // Reset the focused autocomplete dropdown element if the user continues to type into the
    // search input while cycling through the autocomplete dropdown list.
    onFocusChanged({ id: "destination", index: 0 });
  }

  function handleOriginAutocompleteSelect(place: AutocompletePlace) {
    sendAnalyticsInteractionEvent("Autocomplete", "Click:AutocompleteOption");
    originAutocomplete.changeDisplayValueWithoutResults(
      place.longName ?? place.shortName
    );
    setOriginCanonical(place.canonicalName);

    setIsOriginClosed(true);
  }

  function handleDestinationAutocompleteSelect(place: AutocompletePlace) {
    sendAnalyticsInteractionEvent("Autocomplete", "Click:AutocompleteOption");
    destinationAutocomplete.changeDisplayValueWithoutResults(
      place.longName ?? place.shortName
    );
    setDestinationCanonical(place.canonicalName);

    setIsDestinationClosed(true);
  }

  function handleSearch() {
    sendAnalyticsInteractionEvent("SearchBar", "Click:Search");

    const params = new URLSearchParams(location.search);

    navigate(
      {
        pathname: getPath(
          originCanonical,
          destinationCanonical,
          localeToLanguageCode(intl.locale)
        ),
        hash: isHotelScreen ? location.hash : undefined,
        search: encodeSearchParamsForUrl(params),
      },
      {
        replace: true,
        state: defaultSearchState,
      }
    );
  }

  // We need to do capture this so we know to stop trying to show a hint each time
  // a character is removed until the user starts typing again.
  const [justPressedBackspace, setJustPressedBackspace] = useState(false);

  const originResults = isOriginClosed
    ? emptyArray
    : originAutocomplete.results;
  // We want the input value to match the currently focused autocomplete dropdown element.
  // First we check the focused element kind and make sure the index is more than zero,
  // index 1 being the first autocomplete dropdown element.
  const originDropdownFocused =
    focusedElement.id === "origin" && focusedElement.index > 0;
  const originDisplayValue = originDropdownFocused
    ? originResults[focusedElement.index - 1]?.longName
    : originAutocomplete.displayValue;

  const originLongName = originResults[0]?.longName;

  const originHint =
    justPressedBackspace || originDropdownFocused
      ? ""
      : getAutocompleteHint(originAutocomplete.displayValue, originLongName);

  const destinationResults = isDestinationClosed
    ? emptyArray
    : destinationAutocomplete.results;
  // We want the input value to match the currently focused autocomplete dropdown element.
  // First we check the focused element id and make sure the index is more than zero,
  // index 1 being the first autocomplete dropdown element.
  const destinationDropdownFocused =
    focusedElement.id === "destination" && focusedElement.index > 0;
  const destinationDisplayValue = destinationDropdownFocused
    ? destinationResults[focusedElement.index - 1]?.longName
    : destinationAutocomplete.displayValue;

  const destinationLongName = destinationResults[0]?.longName;
  const destinationHint =
    justPressedBackspace || destinationDropdownFocused
      ? ""
      : getAutocompleteHint(
          destinationAutocomplete.displayValue,
          destinationLongName
        );

  function handleKeydownEvents(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.key) {
      case "Backspace":
      case "Delete":
        setJustPressedBackspace(true);
        if (originHint || destinationHint) {
          // The first time we press backspace and there is a hint we only want
          // to clear the backspace.
          event.preventDefault();
        }
        break;

      case "Enter":
        // Hitting enter should move focus, we don't need to manually update
        // the selected places because blurring will automatically do that for
        // us.
        if (focusedElement.id === "origin") {
          destinationInputRef.current?.focus();
        } else if (focusedElement.id === "destination") {
          searchButtonRef.current?.focus();
        }
        setJustPressedBackspace(false);

        break;

      // Pressing right arrow when hinting is showing will select the hint (which)
      // is the first autocomplete result.
      case "ArrowRight":
        if (focusedElement.id === "origin" && originResults.length > 0) {
          handleOriginAutocompleteSelect(
            originResults[
              focusedElement.index === 0 ? 0 : focusedElement.index - 1
            ]
          );
        } else if (
          focusedElement.id === "destination" &&
          destinationResults.length > 0
        ) {
          handleDestinationAutocompleteSelect(
            destinationResults[
              focusedElement.index === 0 ? 0 : focusedElement.index - 1
            ]
          );
        }
        break;

      default:
        setJustPressedBackspace(false);
    }
  }

  // If the current input values match the url (and thus search response)
  // then the search button should be disabled since a search
  // wouldn't actually do anything. We also want to disable the button if
  // either input has no value.
  const searchMatchesInputs =
    searchOrigin?.canonicalName?.toLowerCase() ===
      originCanonical?.toLowerCase() &&
    searchDestination?.canonicalName?.toLowerCase() ===
      destinationCanonical?.toLowerCase();
  const isSearchButtonDisabled =
    searchMatchesInputs ||
    originCanonical === "" ||
    destinationCanonical === "";

  function onFocusChanged(newFocusedElement: FocusedElement) {
    let index = newFocusedElement.index;

    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (newFocusedElement.id) {
      case "origin":
        index = modulo(index, originResults.slice(0, MAX_RESULTS).length + 1);
        break;
      case "destination":
        index = modulo(
          index,
          destinationResults.slice(0, MAX_RESULTS).length + 1
        );
        break;
    }
    index = Number.isNaN(index) ? 0 : index;
    onFocusElementChanged({ id: newFocusedElement.id, index });
  }

  const layout = useLayout();

  return (
    <FocusContext.Provider value={{ focusedElement, onFocusChanged }}>
      <Container>
        {layout === "tablet" ? (
          <StyledSmallSearchBar />
        ) : (
          <>
            <InputClickAwayListener onClickAway={() => setIsOriginClosed(true)}>
              <SearchAutocomplete
                kind="origin"
                value={originDisplayValue}
                onChange={handleOriginQueryChange}
                results={originResults}
                onAutocompleteSelect={handleOriginAutocompleteSelect}
                hint={originHint}
                ref={originInputRef}
                onKeyDown={handleKeydownEvents}
              />
            </InputClickAwayListener>

            <SwitchButton
              onClick={handleSwitch}
              aria-label={intl.formatMessage(messages.switchLabel)}
            >
              <Icon size="lg">
                <Switch
                  title="switch"
                  tint={theme.searchBar.switchButton.iconTint}
                />
              </Icon>
            </SwitchButton>

            <InputClickAwayListener
              onClickAway={() => setIsDestinationClosed(true)}
            >
              <SearchAutocomplete
                kind="destination"
                value={destinationDisplayValue}
                onChange={handleDestinationQueryChange}
                results={destinationResults}
                onAutocompleteSelect={handleDestinationAutocompleteSelect}
                hint={destinationHint}
                ref={destinationInputRef}
                onKeyDown={handleKeydownEvents}
              />
            </InputClickAwayListener>

            <SearchButton
              textColor="primaryOnDark"
              backgroundColor="pink"
              size="large"
              onClick={handleSearch}
              inline
              disabled={isSearchButtonDisabled}
              ref={searchButtonRef}
            >
              {intl.formatMessage(messages.searchLabel)}
            </SearchButton>
          </>
        )}
      </Container>
    </FocusContext.Provider>
  );
}

const InputClickAwayListener = styled(ClickAwayListener)`
  flex: 1 1;
  max-width: 325px;
`;

const SwitchButton = styled(ButtonBase)`
  border-radius: 4px;
  width: 48px;
  min-width: 48px;
  // We want the inner icon to be 18px in size, so we apply 15px padding because
  // 48px - 30px = 18px.
  padding: 15px;
  height: 48px;
  background-color: ${(props) => props.theme.searchBar.switchButton.background};
  border: 1px solid ${(props) => props.theme.searchBar.switchButton.border};

  &:hover {
    background-color: ${(props) =>
      props.theme.searchBar.switchButton.backgroundHover};

    // Reset the background-color on touch devices so that they don't get a
    // lingering hover effect after a click event.
    @media (hover: none) {
      background-color: ${(props) =>
        props.theme.searchBar.switchButton.background};
    }
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.searchBar.background};
  min-height: 72px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 12px;
  padding-bottom: 12px;

  & > * {
    margin-right: 8px;
  }
`;

const SearchButton = styled(Button)`
  margin-left: 4px;
  padding: 0 32px;

  &:disabled {
    background-color: ${lighten(0.485, color.pink)};

    span {
      color: ${color.primaryOnDark};
    }
  }

  &:focus-visible {
    outline: 2px solid ${color.pink};
    outline-offset: 2px;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
  }
`;

const StyledSmallSearchBar = styled(SearchBar)`
  width: 100%;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
`;
