import styled, { css, keyframes } from "styled-components";
import { borderRadius, color, fontSize, lineHeight } from "../../theme";

type Props = {
  variant?: keyof typeof fontSize;
  repeat?: number;
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  margin?: string | number;
  delay?: number;
  inline?: boolean;
  lightOnDark?: boolean;
  borderRadius?: keyof typeof borderRadius;
  solidColor?: boolean;
  border?: boolean;
};

export function Skeleton({
  variant,
  repeat = 1,
  width = "100%",
  height = 50,
  padding = 0,
  margin = 0,
  delay = 0,
  inline = false,
  lightOnDark = false,
  borderRadius = "sm",
  solidColor = false,
  border = false,
}: Props) {
  const styles = {
    width,
    height,
    padding,
    margin,
  };

  if (variant) {
    // Calculate the total height of the text element
    const fontHeight = getFontHeight(variant);
    const totalHeight = Math.floor(fontHeight * lineHeight.normal);
    // Calculate the white space between each line of text
    const verticalPadding = (totalHeight - fontHeight) / 2;

    styles.height = totalHeight;
    styles.padding = `${verticalPadding}px 0`;
  }

  // Create an empty array with the amount of times we want to repeat the skeleton
  const items = [...Array(repeat)];

  return (
    <>
      {items.map((_, index) => (
        <Shimmer
          key={index}
          style={styles}
          delay={delay}
          inline={inline}
          lightOnDark={lightOnDark}
          borderRadius={borderRadius}
          solidColor={solidColor}
          border={border}
          aria-label="loading"
        />
      ))}
    </>
  );
}

function getFontHeight(key: keyof typeof fontSize): number {
  return parseInt(fontSize[key], 10);
}

const shimmerKeyframes = keyframes`
  0% {
    background-position: -750px 0
  }
  100% {
    background-position: 750px 0
  }
`;

const darkOnLightShimmer = css`
  opacity: 0.82;
  background-color: rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.009) 20%,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.05) 100%
  );
`;
const lightOnDarkShimmer = css`
  opacity: 0.82;
  background-color: rgba(255, 255, 255, 0.2);
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.3) 20%,
    rgba(255, 255, 255, 0.2) 40%,
    rgba(255, 255, 255, 0.2) 100%
  );
`;
const solidColorShimmer = css`
  opacity: 0.82;
  background-color: ${color.n20};
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.009) 20%,
    rgba(0, 0, 0, 0.05) 40%,
    rgba(0, 0, 0, 0.05) 100%
  );
`;

const Shimmer = styled.div<{
  delay: number;
  borderRadius: keyof typeof borderRadius;
  inline?: boolean;
  lightOnDark?: boolean;
  solidColor?: boolean;
  border?: boolean;
}>`
  display: ${(props) => (props.inline ? "inline-flex" : "flex")};

  &:after {
    content: "";
    display: block;
    flex: 1;
    background-size: 750px 70px;
    ${(props) => (props.lightOnDark ? lightOnDarkShimmer : darkOnLightShimmer)}
    animation-duration: 1.5s;
    animation-delay: ${(props) => props.delay}s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmerKeyframes};
    animation-timing-function: linear;
    border-radius: ${(props) => borderRadius[props.borderRadius]};
    border: ${(props) => props.border && `1px solid ${color.n40}`};
    ${(props) => {
      if (props.lightOnDark) {
        return lightOnDarkShimmer;
      } else if (!props.lightOnDark && props.solidColor) {
        return solidColorShimmer;
      } else {
        return darkOnLightShimmer;
      }
    }};
  }
`;
