export function getSegmentIndexFromHash(hash: string) {
  const parts = hash.slice(1).split("/");
  const sIndex = parts.indexOf("s");
  if (sIndex === -1) return undefined;

  const segmentStr = parts[sIndex + 1];
  if (!segmentStr) return undefined;

  const segmentIndex = parseInt(segmentStr);
  return !Number.isNaN(segmentIndex) ? segmentIndex : undefined;
}
