import { defineMessages } from "react-intl";

const messages = defineMessages({
  closeAdsLabel: {
    id: "label.closeAds",
    description: "Button that hides the right rail ad",
    defaultMessage: "Hide Ads",
  },
});

export default messages;
