import { useEffect, useState } from "react";

// useHorizontalMouseScroll enables horizontal scrolling in a carousel-like component using mouse drag events.
// This can be used to provide a more interactive and intuitive navigation experience for users.
// For example, in a photo gallery or product slider, users can click and drag to scroll through the items.
// The hook ensures the correct scrolling behavior when the mouse is pressed, dragged, and released.

export function useHorizontalMouseScroll(
  carouselRef: React.RefObject<HTMLElement>
) {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragClientX, setDragClientX] = useState(0);

  function handleMouseDown(event: React.MouseEvent) {
    if (carouselRef.current) {
      setIsMouseDown(true);
      setDragClientX(event.clientX + carouselRef.current.scrollLeft);
    }
  }

  useEffect(() => {
    function handleMouseMove(event: MouseEvent) {
      if (!carouselRef.current) return;
      const offset = dragClientX - event.clientX;
      carouselRef.current.scrollTo({ left: offset });
      if (!!offset) {
        setIsDragging(true);
      }
    }
    function handleMouseUp() {
      setIsDragging(false);
      setIsMouseDown(false);
    }
    if (isMouseDown) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [carouselRef, isMouseDown, dragClientX]);

  return { handleMouseDown, isDragging };
}
