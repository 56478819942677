import { defineMessages } from "react-intl";

const messages = defineMessages({
  addDestination: {
    id: "AddDestinationButton.addDestination",
    description:
      "A message on a button that aims to add a destination/place in a saved trip on a trip planner",
    defaultMessage: "Add destination",
  },
  destinationMarkTitle: {
    id: "AddDestinationButton.destinationMarkTitle",
    description:
      "A message on an destination icon on button that aims to add a destination/place in a saved trip on a trip planner, this message works to improve the button accessibility",
    defaultMessage: "Destination mark icon",
  },
  destinationPrompt: {
    id: "AddDestination.destinationPrompt",
    description: "Prompts the user to enter a destination",
    defaultMessage: "Add destination",
  },
  editPlacePrompt: {
    id: "AddDestination.editPlacePrompt",
    description: "Prompts the user to edit a destination.",
    defaultMessage: "Edit place",
  },
  destinationInputLabel: {
    id: "AddDestination.destinationInputLabel",
    description: "Label for the input where the user enters a destination",
    defaultMessage: "Enter a location",
  },
});

export default messages;
