import { useEffect, useRef } from "react";

type Arguments = {
  currentSearchLongName: string;
  updateDisplayValue: (displayValue: string) => void;
};

export function useUpdateDisplayValueOnGeocode({
  currentSearchLongName,
  updateDisplayValue,
}: Arguments) {
  const updateDisplayValueRef = useRef(updateDisplayValue);

  useEffect(() => {
    updateDisplayValueRef.current = updateDisplayValue;
  }, [updateDisplayValue]);

  // If the current search's long name changes then a geocode or search request
  // has happened and we should update the inputs to reflect the new long name.
  useEffect(() => {
    updateDisplayValueRef.current(currentSearchLongName);
  }, [currentSearchLongName]);
}
