import { useQuery } from "@tanstack/react-query";
import { Mode } from "src/utils/types/mode";
import { PassengerDetails } from "src/PassengerDetailsProvider";
import { SchedulesResponse } from "src/api/SchedulesResponse";
import useUser from "src/utils/hooks/useUser";
import useSearch from "src/utils/hooks/useSearch";
import { formatIsoDate, numNightsBetweenTwoDates } from "src/utils/dateTime";
import { useTravelSearchSettings } from "src/domain/SegmentScreen/TravelSearchSettingsProvider";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { RoomDetail } from "src/domain/HotelsScreen/HotelsContext";
import { HotelEvent, sendAdaraEvent, TransportSearch } from "./sendAdaraEvent";
import { getAdaraTransportData } from "./getAdaraTransportData";

type Transport = {
  searchType: "transport";
  currencyType?: string;
  schedulesResponse?: SchedulesResponse;
  passengers?: PassengerDetails;
  transitMode?: Mode;
  routeIndex?: number;
  destinationCountryCode?: never;
  hotelDestinationName?: never;
  hotelArrivalDate?: never;
  hotelDepartureDate?: never;
  hotelName?: never;
  hotelStarRating?: never;
  avgDailyRate?: never;
  roomDetails?: never;
};

type Hotel = {
  searchType: "hotel";
  currencyType?: string;
  destinationCountryCode?: string;
  hotelDestinationName?: string;
  hotelArrivalDate?: Date;
  hotelDepartureDate?: Date;
  hotelName?: string;
  hotelStarRating?: number;
  avgDailyRate?: number;
  roomDetails?: RoomDetail[];
  schedulesResponse?: never;
  passengers?: never;
  transitMode?: never;
  routeIndex?: never;
};

export function useAdara({
  searchType,
  schedulesResponse,
  passengers,
  transitMode,
  routeIndex,
  currencyType,
  destinationCountryCode,
  hotelDestinationName,
  hotelArrivalDate,
  hotelDepartureDate,
  hotelName,
  hotelStarRating,
  avgDailyRate,
  roomDetails,
}: Transport | Hotel) {
  const { language } = useUser();
  const { returnDate } = useTravelSearchSettings().dateTimeObject;
  const numTripLegs = useTripPlannerContext().tripRoutes.placePairs.length;
  const { origin, destination, searchResponse } = useSearch();

  const interestData = searchResponse?.analytics?.interest_data;
  const isAdaraEnabled = interestData?.["Adara"]?.enabled;

  useQuery({
    queryKey: [
      "useAdara",
      isAdaraEnabled,
      searchResponse?.request,
      schedulesResponse,
      hotelArrivalDate,
      hotelDepartureDate,
      hotelDestinationName,
      roomDetails,
    ],
    queryFn: () => {
      let adaraEvent: TransportSearch | HotelEvent | undefined;

      if (
        isAdaraEnabled &&
        searchType === "transport" &&
        origin &&
        destination &&
        (!transitMode || schedulesResponse !== undefined)
      ) {
        const data = getAdaraTransportData<TransportSearch>({
          searchResponse,
          routeIndex,
          schedulesResponse,
          transitMode,
          numTripLegs,
          returnDate,
        });

        adaraEvent = {
          event: "transportSearch",
          travelProductType: transitMode,
          departureDate: data.departureDate,
          departureCity: origin.shortName,
          departureState:
            "regionName" in origin ? origin.regionName : undefined,
          departureCountry: data.departureCountry,
          departureAirportCode: data.departureAirportCode,
          arrivalDate: data.arrivalDate,
          arrivalCity: destination.shortName,
          arrivalState:
            "regionName" in destination ? destination.regionName : undefined,
          arrivalCountry: data.arrivalCountry,
          arrivalAirportCode: data.arrivalAirportCode,
          price: data.price,
          currencyType: currencyType ?? data.currencyType,
          ticketClass: data.ticketClass,
          numPassengers: passengers?.length,
          tripType: data.tripType,
          siteLanguage: language,
        };
      }

      if (isAdaraEnabled && searchType === "hotel" && hotelDestinationName) {
        const formattedCheckIn =
          hotelArrivalDate && formatIsoDate(hotelArrivalDate);
        const formattedCheckOut =
          hotelDepartureDate && formatIsoDate(hotelDepartureDate);
        const numNights =
          hotelArrivalDate &&
          hotelDepartureDate &&
          numNightsBetweenTwoDates(hotelArrivalDate, hotelDepartureDate);

        const { numAdults, numChildren, numRooms } =
          roomDetails?.reduce(
            (acc, room) => {
              return {
                ...acc,
                numAdults: acc.numAdults + room.adults,
                numChildren: acc.numChildren + room.children.length,
              };
            },
            { numAdults: 0, numChildren: 0, numRooms: roomDetails.length }
          ) ?? {};

        adaraEvent = {
          event: "hotelSearch",
          arrivalCity: hotelDestinationName,
          arrivalState:
            destination && "regionName" in destination
              ? destination.regionName
              : undefined,
          arrivalCountry: destinationCountryCode,
          siteLanguage: language,
          hotelName,
          hotelCheckInDate: formattedCheckIn,
          hotelCheckOutDate: formattedCheckOut,
          numNights,
          hotelStarRating,
          avgDailyRate,
          currencyType,
          numAdults,
          numChildren,
          numRooms,
        };
      }

      if (adaraEvent) {
        sendAdaraEvent(adaraEvent);
      }

      // Queries must return a value.
      return "";
    },
    refetchOnWindowFocus: false,
  });
}
