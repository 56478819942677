import { useCallback } from "react";
import { useNavigate } from "react-router";
import { SupportedLanguageCode } from "../language";
import { getPath } from "../url";
import { useRouteParams } from "../useRouteParams";
import { useLayout } from "./useLayout";
import { useTypedLocation } from "./useTypedLocation";

export function useNavigateToNewLanguage() {
  const navigate = useNavigate();
  const typedLocation = useTypedLocation();
  const layout = useLayout();
  const isDesktop = layout === "desktop";
  const { originCanonical, destinationCanonical } = useRouteParams();

  const navigateToNewLanguage = useCallback(
    (language: SupportedLanguageCode) => {
      const preferencesScreen = isDesktop
        ? { preferencesScreen: undefined }
        : { preferencesScreen: "main" };
      navigate(
        {
          pathname: getPath(originCanonical, destinationCanonical, language),
          hash: typedLocation.hash,
          search: typedLocation.search,
        },
        {
          state: {
            ...typedLocation.state,
            ...preferencesScreen,
          },
        }
      );
      if (isDesktop) {
        // eslint-disable-next-line no-restricted-globals
        location.reload(); // This reloads the page completely to re-render the localized map
      }
    },
    [
      isDesktop,
      navigate,
      originCanonical,
      destinationCanonical,
      typedLocation.hash,
      typedLocation.search,
      typedLocation.state,
    ]
  );

  return { navigateToNewLanguage };
}
