import { defineMessages } from "react-intl";

const messages = defineMessages({
  removePlace: {
    id: "tripPlanner.tripcard.removePlace",
    description: "Button title for remove a place from the trip",
    defaultMessage: "Remove",
  },
  dragPlace: {
    id: "tripPlanner.tripcard.dragPlace",
    description: "Button title for reordering a place",
    defaultMessage: "Drag",
  },
  edit: {
    id: "tripPlanner.tripcard.edit",
    description: "Button title for editing a place",
    defaultMessage: "Edit",
  },
});

export default messages;
