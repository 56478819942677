import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/theme";
import styled from "styled-components";

export function TripSearchBarSkeleton() {
  return (
    <SkeletonsDiv>
      <Skeleton height={44} width={240} />
      <Skeleton height={44} width={50} />
      <Skeleton height={44} width={240} />
      <Skeleton height={44} width={240} />
    </SkeletonsDiv>
  );
}

const SkeletonsDiv = styled.div`
  display: flex;
  gap: ${spacing.md};
  padding: 0 ${spacing.md} ${spacing.md} ${spacing.xxl};
  background-color: white;
`;
