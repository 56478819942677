import { useCallback } from "react";
import useAutocomplete from "../../utils/hooks/useAutocomplete";
import { AutocompletePlace } from "../../api/AutocompleteResponse";

type SearchAutocompleteState = {
  displayValue: string;
  results: AutocompletePlace[];
  changeDisplayValueWithoutResults: (query: string) => void;
  changeDisplayValueWithResults: (query: string) => void;
};

export function useSearchAutocomplete(
  initialDisplayValue: string
): SearchAutocompleteState {
  const { results, query, changeQuery } = useAutocomplete(initialDisplayValue);

  const changeDisplayValueWithoutResults = useCallback(
    (query: string) => {
      changeQuery(query, false);
    },
    [changeQuery]
  );

  const changeDisplayValueWithResults = useCallback(
    (query: string) => {
      changeQuery(query, true);
    },
    [changeQuery]
  );

  return {
    displayValue: query,
    results,
    changeDisplayValueWithResults,
    changeDisplayValueWithoutResults,
  };
}
