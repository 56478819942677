import { defineMessages } from "react-intl";

const messages = defineMessages({
  skipToMainContent: {
    id: "skipLink.label",
    defaultMessage: "Skip to content",
    description:
      "The message in a link to skip the nav bar and send the user to the main content.",
  },
});

export default messages;
