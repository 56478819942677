import { TripSearchResultSkeleton } from "src/domain/TripPlanner/Drawer/TripSearchResults/TripSearchResultSkeleton";
import { Fragment } from "react";
import { TripSearchResultsContainer } from "./SearchResultListCard";

export function SearchResultListCardSkeleton() {
  const resultSkeles = Array.from({ length: 4 });

  return (
    <>
      <TripSearchResultsContainer>
        {resultSkeles.map((value, index) => (
          <Fragment key={index}>
            <TripSearchResultSkeleton />
          </Fragment>
        ))}
      </TripSearchResultsContainer>
    </>
  );
}
