import styled from "styled-components";
import { spacing, color } from "src/theme";
import { DisplayAd } from "../DisplayAd/DisplayAd";

type MobileHeaderAdProps = {
  hide: boolean;
};

export function MobileHeaderAd({ hide }: MobileHeaderAdProps) {
  return (
    <Wrapper hideAd={hide} test-id="mobile-header-ad">
      <DisplayAd slotConfigId="mobileHeader" />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ hideAd: boolean }>`
  display: ${(props) => (props.hideAd ? "none" : "flex")};
  top: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xl};
  background-color: ${color.n10};
`;
