import styled from "styled-components";
import { color, fontSize, fontWeight, lineHeight } from "../../../theme";

export const TitleTripPlanner = styled.h2<{ isTripsAsCoreHoldback?: boolean }>`
  position: relative;
  font-weight: ${fontWeight.semibold};
  font-size: ${(props) =>
    props.isTripsAsCoreHoldback ? `${fontSize.h3}` : `${fontSize.h2}`};
  line-height: ${lineHeight.snug};
  color: ${color.cod};
`;
