import { useSearchParams } from "../../../utils/hooks/useSearchParams";
import {
  PossibleFeaturesMap,
  FeatureConfig,
  FeatureName,
  FeatureValue,
} from "../../FeatureConfig";

export function getSearchParamsFeatures(
  featureParams: string
): Partial<FeatureConfig> {
  // Allows parsing of the 'feature' query param in the following format:
  // '?features=LogAnalyticsEventsToConsole,!SkipRoutes,FillAds:default'
  // Will override all other methods of setting features (flags, experiments, etc.)

  let features: Partial<FeatureConfig> = {};

  // Split different features.
  const searchParamsFeatures = featureParams.split(",");

  for (const searchParamFeature of searchParamsFeatures) {
    let splitFeature = searchParamFeature.split(":");

    let [maybeFeatureName, maybeFeatureValue] = [
      splitFeature[0],
      // If value has a ':' in it, this will reassemble the proper value string
      splitFeature.splice(1).join(":"),
    ];

    // Boolean features are set using `!FeatureName` or `FeatureName`.
    const negator = maybeFeatureName.startsWith("!");
    if (negator) maybeFeatureName = maybeFeatureName.substring(1);

    // Check valid feature name
    const featureName = checkFeatureName(maybeFeatureName);
    if (featureName !== undefined) {
      const possibleValues = PossibleFeaturesMap[featureName];

      // Check if the feature is a string array feature.
      if (isBooleanFeature(possibleValues)) {
        // We have to cast this, but we know setting a boolean is valid.
        (features as any)[featureName] = !negator;
      } else {
        const possibleFeatureValues = PossibleFeaturesMap[featureName]!;

        // Empty strings are 'undefined' for now
        const featureValue =
          maybeFeatureValue === "" ? undefined : maybeFeatureValue;

        if (possibleFeatureValues.findIndex((v) => v === featureValue) !== -1) {
          // We have to cast this as typescript can't narrow the type based on the stringArrayFeatures object.
          // We know it's valid because we've checked the value is in the array.
          (features as any)[featureName] = featureValue;
        }
        // Silently fail if the feature value is non existent
      }
    }
  }

  return features;
}

function checkFeatureName(maybeFeatureName: string): FeatureName | undefined {
  // Want to check for a matching feature in a non-case-sensitive way
  let lowercaseSearchFeature = maybeFeatureName.toLowerCase();
  for (const [featureName] of Object.entries(PossibleFeaturesMap)) {
    if (featureName.toLowerCase() === lowercaseSearchFeature) {
      return featureName as FeatureName;
    }
  }

  return undefined;
}

export function useSearchParamsFeatures() {
  const searchParams = useSearchParams();
  const searchFeatureConfig = searchParams.get("features");
  if (searchFeatureConfig === null) return {};

  return getSearchParamsFeatures(searchFeatureConfig);
}

export function setSearchParamsFeature(
  featureName: FeatureName,
  value: FeatureConfig[FeatureName],
  search: string
): URLSearchParams {
  const searchParams = new URLSearchParams(search);
  const currentFeatureParam = searchParams.get("features");
  const features: Partial<FeatureConfig> =
    currentFeatureParam !== null
      ? getSearchParamsFeatures(currentFeatureParam)
      : {};

  // We're trusting our caller to only send us valid values for the feature.
  // Worst case scenario, any invalid values are ignored when parsing the query param.
  (features as any)[featureName] = value;

  const featureParam = makeFeatureSearchParam(features);
  searchParams.set("features", featureParam);
  return searchParams;
}

export function makeFeatureSearchParam(
  features: Partial<FeatureConfig>
): string {
  // Produces a query param value from a feature config object.
  // eg: 'LogAnalyticsEventsToConsole,!SkipRoutes,FillAds:default'

  let featureStrings: string[] = [];
  for (const featureName of Object.keys(features)) {
    const feature = features[featureName as FeatureName];
    if (typeof feature === "boolean") {
      featureStrings.push(`${feature ? "" : "!"}${featureName}`);
    } else if (typeof feature === "string") {
      featureStrings.push(`${featureName}:${feature}`);
    } else if (typeof feature === "undefined") {
      featureStrings.push(`${featureName}:`);
    } else {
      // This is probably the best place to throw on an invalid feature value, as the "user" is interacting with the debug pane.
      throw new Error(
        `Not sure how to set feature: ${featureName} with value: ${feature}`
      );
    }
  }

  return featureStrings.join(",");
}

function isBooleanFeature(
  possibleValues: readonly FeatureValue<FeatureName>[]
) {
  return (
    possibleValues.includes(true) &&
    possibleValues.includes(false) &&
    possibleValues.length === 2
  );
}
