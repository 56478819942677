import { ReturnFlowStateType } from "src/domain/SegmentScreen/ReturnFlowStateProvider";
import { Itinerary, SchedulesResponse } from "../api/SchedulesResponse";
import { ReturnStage } from "./hooks/useTypedLocation";

export function getInboundOrOutboundItineraries(
  schedulesResponse: SchedulesResponse,
  returnStage: ReturnStage,
  returnFlowState: ReturnFlowStateType
): Itinerary[] {
  const legPairsPresent = schedulesResponse.fares?.some(
    (fare) => !!fare.legPairs
  );
  if (
    returnStage === "return" &&
    legPairsPresent &&
    schedulesResponse.inboundItineraries &&
    returnFlowState.selectedDepartureSchedule
  ) {
    const selectedOutboundLegPairFares = schedulesResponse.fares?.filter(
      (fare) =>
        fare?.legPairs &&
        fare?.legPairs[0].outboundLeg ===
          returnFlowState.selectedDepartureSchedule?.itinerary?.legs[0]
    );
    if (selectedOutboundLegPairFares) {
      return schedulesResponse.inboundItineraries.filter((inboundItinerary) =>
        selectedOutboundLegPairFares.some(
          (fare) =>
            fare.legPairs &&
            fare.legPairs[0].inboundLeg === inboundItinerary.legs[0]
        )
      );
    } else {
      console.error(
        "getInboundOrOutboundItineraries: No legPairs matched when legPairs were present!"
      );
      return [];
    }
  } else if (returnStage === "return" && schedulesResponse.inboundItineraries) {
    return schedulesResponse.inboundItineraries;
  } else {
    return schedulesResponse.outboundItineraries;
  }
}
