import { defineMessages } from "react-intl";

const messages = defineMessages({
  moreWays: {
    id: "searchresult.moreWays",
    defaultMessage: "More ways to get there",
    description: "A header indicating more possible routes to the destination",
  },
  recommended: {
    id: "searchresult.recommended",
    defaultMessage: "Recommended",
    description: "Indicates that the search result is our recommended option",
  },
  best: {
    id: "searchresult.best",
    defaultMessage: "Best",
    description:
      "The label tagged on a search result indicating it is the best result.",
  },
  cheapest: {
    id: "searchresult.cheapest",
    defaultMessage: "Cheapest",
    description: "Indicates that the search result is our cheapest option",
  },
  secondCheapest: {
    id: "searchresult.secondCheapest",
    defaultMessage: "Second Cheapest",
    description:
      "Indicates that the search result is our second cheapest option",
  },
  fastest: {
    id: "searchresult.fastest",
    defaultMessage: "Fastest",
    description: "Indicates that the search result is our fastest option",
  },
  secondFastest: {
    id: "searchresult.secondFastest",
    defaultMessage: "Second Fastest",
    description:
      "Indicates that the search result is our second fastest option",
  },
  headingSingular: {
    id: "searchresult.headingSingular",
    defaultMessage: "Here’s {numberOfRoutes} way to get to {destination}",
    description:
      "Heading for the search results page when there is one result eg. Here’s 1 way to get to Milan",
  },
  headingPlural: {
    id: "searchresult.headingPlural",
    defaultMessage: "Here’s {numberOfRoutes} ways to get to {destination}",
    description:
      "Heading for the search results page when there is many results eg. Here’s 6 ways to get to Milan",
  },
  subHeading: {
    id: "searchresult.subHeading",
    defaultMessage: "More options",
    description: "Subheading for the search results page",
  },
  headingSingleRoute: {
    id: "searchresult.headingSingle",
    defaultMessage:
      "{numberOfRoutes} way to travel from {origin} to {destination}",
    description:
      "Heading for the search results page when there is a single route eg. 1 way to travel from Amsterdam to Milan",
  },
  headingMultipleRoutes: {
    id: "searchresult.headingMultiple",
    defaultMessage:
      "{numberOfRoutes} ways to travel from {origin} to {destination}",
    description:
      "Heading for the search results page when there is multiple routes eg. 6 ways to travel from Amsterdam to Milan",
  },
});

export default messages;
