import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/theme";
import { useLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";

export function PinnedPillsBarSkeleton() {
  const layout = useLayout();
  const isDesktop = layout === "desktop";

  if (isDesktop || layout === "tablet") {
    return (
      <Wrapper>
        <Skeleton
          height="35px"
          width={isDesktop ? "150px" : "90px"}
          borderRadius="xxxl"
        />
        <Skeleton
          height="35px"
          width={isDesktop ? "92px" : "95px"}
          borderRadius="xxxl"
        />
        <Skeleton
          height="35px"
          width={isDesktop ? "115px" : "114px"}
          borderRadius="xxxl"
        />
        <Skeleton
          height="35px"
          width={isDesktop ? "58px" : "60px"}
          borderRadius="xxxl"
        />

        <div>
          <Skeleton height="35px" width="110px" borderRadius="xxxl" />
        </div>
      </Wrapper>
    );
  }

  // mobile
  return (
    <MobileWrapper>
      <Skeleton height="34px" borderRadius="xxxl" />
      <Skeleton height="34px" borderRadius="xxxl" />
      <Skeleton height="34px" borderRadius="xxxl" />
      <Skeleton height="34px" borderRadius="xxxl" />
    </MobileWrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: ${spacing.md};

  & div:last-child {
    margin-left: auto;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  gap: ${spacing.sm};
  justify-content: flex-start;
  padding: 0 ${spacing.xl};
`;
