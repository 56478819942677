export type UrlConfig = {
  apiHost: string;
  exitHost: string;
  homepageHost: string;
};

// environments sets up the environments we deploy the explore product to.
// The below table outlines the environments we serve to and their intended
// default backend.
// ------------------------------------------------------------------------------
// Explore Origin                | Backend                 | Environment
// ------------------------------------------------------------------------------
// www.rome2rio.com              | www.rome2rio.com        | Production
// *.rome2rio.com                | *.rome2rio.com          | ProductionLike
// mwebdev.rome2rio.com          | mwebdev.rome2rio.com    | ProductionLike
// mweb.rome2rio.com             | mweb.rome2rio.com       | ProductionLike
// *rome2rio-explore.pages.dev   | release-explore.rome2rio.com    | Development
// localhost:3000                | release-explore.rome2rio.com    | Development
// localhost:15555               | localhost:15555         | LocalBackend
// ------------------------------------------------------------------------------
const environments: Partial<{ [key: string]: UrlConfig }> = {
  LocalBackend: {
    // The apiHost is empty as we use the proxy config in vite.config.js to configure
    // a proxy that will forward all non /map paths through to the local
    // backend/monolith. That allows landing pages, API, other content
    // to be combined with map view in similar manner to production.
    apiHost: "",
    exitHost: "http://localhost:15555",
    homepageHost: "http://localhost:15555",
  },
  Development: {
    apiHost: "https://release-explore.rome2rio.com",
    exitHost: "https://release-explore.rome2rio.com",
    homepageHost: "https://www.rome2rio.com",
  },
  ProductionLike: {
    apiHost: window.location.origin,
    exitHost: window.location.origin,
    homepageHost: window.location.origin,
  },
  Production: {
    apiHost: "https://www.rome2rio.com",
    exitHost: "https://www.rome2rio.com",
    homepageHost: "https://www.rome2rio.com",
  },
};

const selectedAppEnvironment = import.meta.env.VITE_ENVIRONMENT
  ? environments[import.meta.env.VITE_ENVIRONMENT]
  : undefined;
export const urlConfig: UrlConfig =
  selectedAppEnvironment ?? environments["Production"]!;
