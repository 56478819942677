import { defineMessages } from "react-intl";

const messages = defineMessages({
  am: {
    id: "time.am",
    description: "Time in the am. A time before noon (12:00).",
    defaultMessage: "{time} am",
  },
  pm: {
    id: "time.pm",
    description: "Time in the pm. A time after noon (12:00).",
    defaultMessage: "{time} pm",
  },
});

export default messages;
