import { useIntl } from "react-intl";
import {
  getHelpLanguageCode,
  localeToLanguageCode,
} from "../../../utils/conversions/languageCode";
import { SearchNotification } from "./SearchNotification";
import messages from "./SearchNotification.messages";

export function UkraineSearchNotification() {
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const helpLanguageCode = getHelpLanguageCode(languageCode);
  const link = `https://help.rome2rio.com/${helpLanguageCode}/support/solutions/articles/22000271866-information-on-international-travel-from-ukraine`;

  const notification = {
    level: 1,
    message: intl.formatMessage(messages.ukraineReduced),
    generic: true,
    links: [{ url: link, text: intl.formatMessage(messages.readMore) }],
  };

  return <SearchNotification {...notification} />;
}
