import { GeocodedPlace } from "src/PrefetchData";
import { TripPlannerTransportKey } from "../TripPlannerProvider";

export function getPlaceIndexFromTransportKey(
  places: GeocodedPlace[],
  pair: TripPlannerTransportKey
) {
  const [origin, destination] = pair.split("_");

  const placeIndex = places.findIndex(
    (place, index, places) =>
      index < places.length - 1 &&
      place.canonicalName === origin &&
      places[index + 1].canonicalName === destination
  );

  return placeIndex === -1 ? undefined : placeIndex;
}
