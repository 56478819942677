import { defineMessages } from "react-intl";

const messages = defineMessages({
  error: {
    id: "searchResult.error",
    defaultMessage: "An error occurred. Please try again shortly.",
    description:
      "Default error message when our search functionality doesn't work.",
  },
  noResults: {
    id: "searchResult.noResults",
    defaultMessage: "We can’t find how to get to {destination} from {origin}.",
    description: "Default message when a search returns no results.",
  },
  noOrigin: {
    id: "searchResult.noOrigin",
    defaultMessage: "Please enter an origin",
    description: "Default message when an origin has not been entered yet.",
  },
  noDestination: {
    id: "searchResult.noDestination",
    defaultMessage: "Please enter a destination",
    description: "Default message when a destination has not been entered yet.",
  },
});

export default messages;
