import styled from "styled-components";
import { SyntheticEvent } from "react";
import useAutocomplete, {
  useKeyboardAutocompleteNavigation,
} from "src/utils/hooks/useAutocomplete";
import { AutocompleteInput } from "src/components/AutocompleteInput/AutocompleteInput";
import { TripCardDestinationMarker } from "src/svg/DestinationMarker";
import { Icon } from "src/components/Icon/Icon";
import { AutocompleteList } from "src/components/AutocompleteList/AutocompleteList";
import { borderRadius, color, fontSize, lineHeight } from "src/theme";
import { largeDesktopLayout } from "src/utils/hooks/useLayout";
import { FocusContext } from "src/FocusContext";
import { AutocompletePlace } from "../../../../api/AutocompleteResponse";

type TripTrainingInputProps = {
  label: string;
  onSelectOption: (place: AutocompletePlace) => void;
  id: string;
  type: "origin" | "destination";
  onChange?: (query: string) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onFocus?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  floatingList?: boolean;
  initialValue?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
};

export function TripTrainingInput(props: TripTrainingInputProps) {
  const { results, query, changeQuery } = useAutocomplete(
    props.initialValue,
    undefined,
    false
  );
  function onPressEscape() {
    changeQuery("");
  }
  const { onKeyDown, onFocusChanged, focusedIndex, focusRef } =
    useKeyboardAutocompleteNavigation({
      id: props.type,
      results,
      onPressEnter: props.onSelectOption,
      onPressEscape,
    });

  function onChangeCallback(query: string) {
    changeQuery(query);
    if (props.onChange) {
      props.onChange(query);
    }
  }

  return (
    <FocusContext.Provider
      value={{
        focusedElement: { id: props.type, index: focusedIndex },
        onFocusChanged,
      }}
    >
      <InputWrapper>
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <AutocompleteInput
          darkHighlight
          type={props.type}
          value={!props.disabled ? query : ""}
          onChange={onChangeCallback}
          id={props.id}
          disabled={props.disabled}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={onKeyDown}
          solidBackground={true}
          icon={
            <Icon size="md">
              <TripCardDestinationMarker tint="n40" />
            </Icon>
          }
        />

        {!props.disabled && Boolean(results.length) && (
          <AutocompleteList
            isInputFocused={true}
            results={results}
            onSelectPlace={props.onSelectOption}
            focusRef={focusRef}
          />
        )}
      </InputWrapper>
    </FocusContext.Provider>
  );
}

const InputLabel = styled.label`
  font-size: ${fontSize.h6};
  line-height: ${lineHeight.loose};
  color: ${color.cod};
`;

const InputWrapper = styled.div`
  position: relative;
  ${largeDesktopLayout} {
    ol {
      position: absolute;
      background-color: ${color.white};
      border-radius: ${borderRadius.sm};
      box-shadow: 0px 1px 0px 1px ${color.n30};

      width: 100%;
      z-index: 1;
      max-height: 200px;
      overflow-y: scroll;
    }
  }
`;
