import styled from "styled-components";
import useSearch from "src/utils/hooks/useSearch";
import { ScreenComponent } from "../../../analytics/generateScreenComponentsRendered/ScreenComponent";
import { mobileLayout } from "../../../utils/hooks/useLayout";
import { TripPlanOnboardStep } from "../hooks/useTripPlanOnboarding";
import { TripPlannerSkeleton } from "../TripPlannerSkeleton";
import { TripPlannerIntroduction } from "./Introduction/TripPlannerIntroduction";
import { TripTraining } from "./Training/TripTraining";

type TripPlannerOnboardingType = {
  onboardingStep: TripPlanOnboardStep;
  startTrainingCallback: () => void;
  hideTrainingCallback: () => void;
  components: ScreenComponent[];
};

export function TripPlannerOnboarding({
  startTrainingCallback,
  hideTrainingCallback,
  components,
}: TripPlannerOnboardingType) {
  const { isLoading } = useSearch();
  return (
    <>
      {isLoading && <TripPlannerSkeleton />}
      {!isLoading && (
        <>
          {components.includes("TripPlannerIntroduction") && (
            <IntroductionWrapper data-testid="onboarding-introduction-wrapper">
              <TripPlannerIntroduction
                hideIntroductionCallback={startTrainingCallback}
              />
            </IntroductionWrapper>
          )}

          {components.includes("TripPlannerTraining") && (
            <TripTraining hideTrainingCallback={hideTrainingCallback} />
          )}
        </>
      )}
    </>
  );
}

const IntroductionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  ${mobileLayout} {
    justify-content: space-evenly;
    min-height: calc(100vh - 250px);
  }
`;
