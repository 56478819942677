import { duration } from "src/theme";
import { SvgProps, useColor } from "./common";

export function HotelAlternative(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        d="M2.48896 4.94891H4.59394V3.75554C4.59394 2.98482 5.02488 2.56216 5.81217 2.56216H8.90334C9.68235 2.56216 10.1133 2.98482 10.1133 3.75554V4.94891H11.9945V3.75554C11.9945 2.98482 12.4255 2.56216 13.2542 2.56216H16.1547C16.9835 2.56216 17.4144 2.98482 17.4144 3.75554V4.94891H19.5278V2.57045C19.5278 1.0373 18.699 0.25 17.2073 0.25H4.80112C3.3094 0.25 2.48896 1.0373 2.48896 2.57045V4.94891ZM0.5 13.5263C0.5 13.9572 0.773482 14.2306 1.20442 14.2306H1.70166C2.12431 14.2306 2.40608 13.9572 2.40608 13.5263V12.2998C2.49724 12.3246 2.77073 12.3412 2.96962 12.3412H19.0305C19.2294 12.3412 19.5028 12.3246 19.594 12.2998V13.5263C19.594 13.9572 19.8757 14.2306 20.2984 14.2306H20.7956C21.2266 14.2306 21.5 13.9572 21.5 13.5263V8.58704C21.5 7.00416 20.6217 6.134 19.0387 6.134H2.96133C1.37846 6.134 0.5 7.00416 0.5 8.58704V13.5263Z"
        style={{ fill: tintColor, transition: `fill ${duration.lg}ms` }}
      />
    </svg>
  );
}
