import { SearchResponse } from "src/api/SearchResponse";
import {
  MapSegment,
  mapSegmentsFromRoute,
} from "src/components/Map/mapSegments";
import { Mode } from "src/utils/types/mode";

type Props = {
  searchResponse?: SearchResponse;
  count?: number;
};
export type TripStopRoute = {
  mapSegments: MapSegment[];
  duration: number;
  transitModeLabels: Mode[];
};
export function getRoutesBetween({
  searchResponse,
  count,
}: Props): TripStopRoute[] | undefined {
  if (!searchResponse) {
    return;
  }
  const topRouteInfo = getTopRouteInfo(searchResponse, count);

  const routes = topRouteInfo.map((routeInfo) => {
    const transitModeInfo = getTransitModeInfoFromMapSegments(
      routeInfo.mapSegments
    );
    return {
      ...routeInfo,
      ...transitModeInfo,
    };
  });

  return routes;
}

function getTransitModeInfoFromMapSegments(mapSegments: MapSegment[]): {
  transitModeLabels: Mode[];
} {
  return {
    transitModeLabels: mapSegments
      .filter((segment) => segment.isMajor)
      .map((segment) => segment.transitMode),
  };
}

type TopRouteInfo = {
  mapSegments: MapSegment[];
  duration: number;
}[];
function getTopRouteInfo(
  searchResponse: SearchResponse,
  count: number = 3
): TopRouteInfo {
  const totalCount = searchResponse.routes.length;
  const countLimit = Math.min(count, totalCount);

  let topRouteInfo: TopRouteInfo = [];
  for (let i = 0; i < countLimit; i++) {
    topRouteInfo.push({
      mapSegments: mapSegmentsFromRoute(searchResponse, i),
      duration: searchResponse.routes[i].duration,
    });
  }

  return topRouteInfo;
}
