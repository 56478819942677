// create messages file
import { defineMessages } from "react-intl";

export const messages = defineMessages({
  closeButtonTitle: {
    id: "SignInButton.closeButtonTitle",
    defaultMessage: "Close",
    description: "Close button icon title shown to screen readers",
  },
  preferences: {
    defaultMessage: "User preferences",
    description: "Text that describe the preferences settings image button",
    id: ".preferences",
  },
  language: {
    id: "userPreferencesModal.language",
    description: "Label for the user's selected language",
    defaultMessage: "Language",
  },
  currency: {
    id: "userPreferencesModal.currency",
    description: "Label for the user's selected currency",
    defaultMessage: "Currency",
  },
  display: {
    id: "userPreferencesModal.display",
    description:
      "Label for the user's selected distance unit or time format preferences",
    defaultMessage: "Display",
  },
  timeFormat: {
    id: "userPreferencesModal.timeFormat",
    description: "Label for the user's selected time format",
    defaultMessage: "Time format",
  },
  modalTitle: {
    defaultMessage: "Modal User Preferences",
    description: "Title of the modal for accessibility purposes",
    id: "userPreferencesModal.modalTitle",
  },
});
