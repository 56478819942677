import * as Sentry from "@sentry/react";
// safeLocalStorage attempts to hide some of the surprises of localStorage, to
// do that it falls back on in-memory storage if localStorage is unsupported.
// There are some limitations, however:
// * Use-cases involving communication across tabs may not work if localStorage
//   is unsupported.
// * Functions may still throw, for example if the device runs out of disk space
//   because of operations performed over time.
// See: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
export const safeLocalStorage = storageFactory(() => window.localStorage);

export const safeSessionStorage = storageFactory(() => window.sessionStorage);

// This factory function will provide error handling for exceptions thrown by
// the backing storage getter function, this is necessary because in Safari,
// referencing localStorage my causes an immediate exception "SecurityError:
// The operation is insecure".
//
// This strategy is lifted from the following blog post:
// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
function storageFactory(getStorage: () => Storage): Storage {
  let inMemoryStorage: { [key: string]: string } = {};
  let isStorageSupported: boolean | undefined;

  function isSupported() {
    if (isStorageSupported === undefined) {
      try {
        const testKey = "__storage_test_key__";
        getStorage().setItem(testKey, testKey);
        getStorage().removeItem(testKey);
        isStorageSupported = true;
      } catch (error) {
        isStorageSupported = false;
        let isStorageFull =
          isQuotaExceededError(error) && getStorage().length > 0;
        Sentry.addBreadcrumb({
          level: "error",
          category: "safeLocalStorage",
          message: `Failed to set test key in storage, QuotaExceededError: ${isStorageFull}. Error: ${error}`,
        });
      }
    }

    return isStorageSupported;
  }

  function clear(): void {
    if (isStorageSupported) {
      getStorage().clear();
    }

    inMemoryStorage = {};
    isStorageSupported = undefined;
  }

  function getItem(name: string): string | null {
    if (isSupported()) {
      return getStorage().getItem(name);
    }
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index: number): string | null {
    if (isSupported()) {
      return getStorage().key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  function removeItem(name: string): void {
    if (isSupported()) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name: string, value: string): void {
    if (isSupported()) {
      getStorage().setItem(name, value);
    } else {
      inMemoryStorage[name] = value;
    }
  }

  function length(): number {
    if (isSupported()) {
      return getStorage().length;
    } else {
      return Object.keys(inMemoryStorage).length;
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
}

/**
 * Determines whether an error is a QuotaExceededError.
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
 * More information if interested: https://mmazzarolo.com/blog/2022-06-25-local-storage-status/
 */
export function isQuotaExceededError(error: unknown): boolean {
  return (
    error instanceof DOMException &&
    // everything except Firefox
    (error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === "QuotaExceededError" ||
      // Firefox
      error.name === "NS_ERROR_DOM_QUOTA_REACHED")
  );
}
