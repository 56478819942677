import { css } from "styled-components";

export const scrollbarHiddenStyling = css`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  -webkit-overflow-scrolling: touch; /* Momentum-based scrolling */
  ::-webkit-scrollbar {
    display: none;
  }
`;
