import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { SearchResponse } from "../../api/SearchResponse";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { hashChange } from "../../utils/location/hashChange";
import { useIsRouteSkipped } from "../../utils/hooks/useIsRouteSkipped";
import { useRouteIndex } from "../../utils/hooks/useRoute";
import { useRouteSegmentIndex } from "../../utils/hooks/useSegment";
import useSearch from "../../utils/hooks/useSearch";

export function useEnsureValidSegment(
  searchResponse: SearchResponse | undefined,
  routeIndex: number | undefined,
  routeSegmentIndex: number | undefined
) {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const [hasRedirected, setHasRedirected] = useState(false);

  const isRouteSkipped = useIsRouteSkipped(routeIndex);

  useEffect(() => {
    // Do nothing because we're already redirecting
    if (hasRedirected) {
      return;
    }

    if (isRouteSkipped) {
      // We're skipping the route, there won't be a valid segment!
      return;
    }

    if (searchResponse) {
      // If the segment isn't vaild or route index is missing,
      // we'll redirect back to the search screen
      if (
        !isValidSegment(
          searchResponse,
          routeIndex,
          routeSegmentIndex,
          isRouteSkipped
        )
      ) {
        setHasRedirected(true);
        navigate(hashChange(undefined, location), { replace: true });
      }
    }
  }, [
    searchResponse,
    navigate,
    location,
    routeIndex,
    routeSegmentIndex,
    hasRedirected,
    setHasRedirected,
    isRouteSkipped,
  ]);

  return isValidSegment(
    searchResponse,
    routeIndex,
    routeSegmentIndex,
    isRouteSkipped
  );
}

export function useIsValidSegment() {
  const { searchResponse } = useSearch();
  const routeIndex = useRouteIndex();
  const segmentIndex = useRouteSegmentIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  return isValidSegment(
    searchResponse,
    routeIndex,
    segmentIndex,
    isRouteSkipped
  );
}

export function isValidSegment(
  searchResponse: SearchResponse | undefined,
  routeIndex: number | undefined,
  routeSegmentIndex: number | undefined,
  isRouteSkipped: boolean
) {
  // We need to wait for a search response to determine validity.
  if (!searchResponse) {
    return false;
  }

  if (isRouteSkipped) {
    // We're skipping the route, there won't be a valid segment.
    return true;
  }

  if (routeIndex === undefined || routeSegmentIndex === undefined) {
    // If the segment index is missing from the URL
    return false;
  }

  const route = searchResponse.routes[routeIndex];
  if (!route) {
    // Route index was out of bounds. Can be caused by a cached routeIndex being fed in.
    return false;
  }

  const segmentsLength = route.segments.length;
  return routeSegmentIndex >= 0 && routeSegmentIndex < segmentsLength;
}

/**
 * Checks that the route and segment indices are valid.
 *
 * @param searchResponse The search response to check against
 * @param routeIndex The route index. If undefined, the function will return false
 * @param routeSegmentIndex optional, will also be checked for validity if defined
 */
export function isValidRouteOrSegment(
  searchResponse: SearchResponse | undefined,
  routeIndex: number | undefined,
  routeSegmentIndex?: number | undefined
): routeIndex is number {
  if (!searchResponse || routeIndex === undefined) {
    return false;
  }

  const route = searchResponse.routes[routeIndex];
  if (!route) {
    return false;
  }

  if (routeSegmentIndex === undefined) {
    return true;
  }

  const segmentsLength = route.segments.length;
  return routeSegmentIndex >= 0 && routeSegmentIndex < segmentsLength;
}
