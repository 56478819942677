import { defineMessages } from "react-intl";

export const messages = defineMessages({
  startDrawerTitle: {
    id: "tripPlanner.introduction.startDrawer",
    defaultMessage: "Start building your trip",
    description: "Text prompting user to start planning a trip",
  },
  startInputLabel: {
    id: "tripPlanner.introduction.startInputLabel",
    defaultMessage: "Start from",
    description: "Label for the start location input",
  },
  destinationInputLabel: {
    id: "tripPlanner.introduction.destinationInputLabel",
    defaultMessage: "Go to",
    description: "Label for the destination location input",
  },
  add: {
    id: "tripPlanner.introduction.addPlaces",
    defaultMessage: "Create trip",
    description: "CTA text prompting user to add the first 2 places to a trip",
  },
});
