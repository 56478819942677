import { PropsWithChildren, useEffect, useRef, useState } from "react";

type Props = PropsWithChildren<{
  // How many viewport heights out we should start rendering the content
  loadMargin: number;
  className?: string;
}>;

/**
 * Delays rendering of children until they are within a certain distance of the viewport.
 *
 * @remarks Currently only works when scrolling down - it assumes the content starts below the viewport
 */
export function LazyLoader({ loadMargin, className, children }: Props) {
  const [render, setRender] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      const { top } = ref.current.getBoundingClientRect();
      if (top <= window.innerHeight * (1 + loadMargin)) {
        setRender(true);
        // No more need to listen for scroll events
        document.removeEventListener("scroll", handleScroll, true);
      }
    };

    handleScroll(); // Do an initial call, just to check if we're already in view
    document.addEventListener("scroll", handleScroll, true);
    // Note: No effect if already removed.
    return () => document.removeEventListener("scroll", handleScroll, true);
  }, [loadMargin]);

  return (
    <div ref={ref} className={className}>
      {render && children}
    </div>
  );
}
