import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";

export function logApiInfo(status: "Success" | "Error", message?: string) {
  sendAnalyticsNonInteractionEvent(
    "UserAccounts",
    `SessionRequest:${status}`,
    message
  );
}

export function logFetchError(error: unknown) {
  let message = error instanceof Error ? error.message : undefined;
  logApiInfo("Error", message);
}
