import { defineMessages } from "react-intl";
import { Kind } from "../../api/Kind";

export function getSegmentHeadingMessage(vehicleKind: Kind) {
  switch (vehicleKind) {
    case "animal":
      return messages.animalHeading;
    case "bus":
      return messages.busHeading;
    case "busferry":
      return messages.busFerryHeading;
    case "cablecar":
      return messages.cableCarHeading;
    case "car":
      return messages.carHeading;
    case "carferry":
      return messages.carFerryHeading;
    case "cartrain":
      return messages.carTrainHeading;
    case "ferry":
      return messages.ferryHeading;
    case "helicopter":
      return messages.helicopterHeading;
    case "nightbus":
      return messages.nightBusHeading;
    case "nighttrain":
      return messages.nightTrainHeading;
    case "rideshare":
      return messages.rideshareHeading;
    case "shuttle":
      return messages.shuttleHeading;
    case "subway":
      return messages.subwayHeading;
    case "taxi":
      return messages.taxiHeading;
    case "towncar":
      return messages.towncarHeading;
    case "train":
      return messages.trainHeading;
    case "tram":
      return messages.tramHeading;

    case "plane":
      return messages.flyHeading;

    case "transfer":
    case "walk":
      return messages.walkHeading;

    case "cycle":
      return messages.bikeHeading;

    case "unknown":
    case "none":
      return messages.unknownHeading;

    default:
      throw new Error(`Unrecognised vehicleKind ${vehicleKind}`);
  }
}

const messages = defineMessages({
  animalHeading: {
    defaultMessage: "Animal",
    id: "segmentScreen.animalHeading",
    description: "Heading for a Animal page",
  },
  bikeHeading: {
    defaultMessage: "Bike",
    id: "segmentScreen.bikeHeading",
    description: "Heading for a Bike page",
  },
  busHeading: {
    defaultMessage: "Bus",
    id: "segmentScreen.busHeading",
    description: "Heading for a Bus page",
  },
  busFerryHeading: {
    defaultMessage: "Bus Ferry",
    id: "segmentScreen.busFerryHeading",
    description: "Heading for a Bus Ferry page",
  },
  cableCarHeading: {
    defaultMessage: "Cable Car",
    id: "segmentScreen.cableCarHeading",
    description: "Heading for a Cable Car page",
  },
  carHeading: {
    defaultMessage: "Car",
    id: "segmentScreen.carHeading",
    description: "Heading for a Car page",
  },
  carFerryHeading: {
    defaultMessage: "Car Ferry",
    id: "segmentScreen.carFerryHeading",
    description: "Heading for a Car Ferry page",
  },
  carTrainHeading: {
    defaultMessage: "Car Train",
    id: "segmentScreen.carTrainHeading",
    description: "Heading for a Car Train page",
  },
  ferryHeading: {
    defaultMessage: "Ferry",
    id: "segmentScreen.ferryHeading",
    description: "Heading for a Ferry page",
  },
  flyHeading: {
    defaultMessage: "Fly",
    id: "segmentScreen.flyHeading",
    description: "Heading for a Fly page",
  },
  helicopterHeading: {
    defaultMessage: "Helicopter",
    id: "segmentScreen.helicopterHeading",
    description: "Heading for a Helicopter page",
  },
  nightBusHeading: {
    defaultMessage: "Night Bus",
    id: "segmentScreen.nightBusHeading",
    description: "Heading for a Night Bus page",
  },
  nightTrainHeading: {
    defaultMessage: "Night Train",
    id: "segmentScreen.nightTrainHeading",
    description: "Heading for a Night Train page",
  },
  rideshareHeading: {
    defaultMessage: "Rideshare",
    id: "segmentScreen.rideshareHeading",
    description: "Heading for a Rideshare page",
  },
  taxiHeading: {
    defaultMessage: "Taxi",
    id: "segmentScreen.taxiHeading",
    description: "Heading for a Taxi page",
  },
  towncarHeading: {
    defaultMessage: "Towncar",
    id: "segmentScreen.towncarHeading",
    description: "Heading for a Towncar page",
  },
  trainHeading: {
    defaultMessage: "Train",
    id: "segmentScreen.trainHeading",
    description: "Heading for a Train page",
  },
  tramHeading: {
    defaultMessage: "Tram",
    id: "segmentScreen.tramHeading",
    description: "Heading for a Tram page",
  },
  shuttleHeading: {
    defaultMessage: "Shuttle",
    id: "segmentScreen.shuttleHeading",
    description: "Heading for a Shuttle page",
  },
  subwayHeading: {
    defaultMessage: "Subway",
    id: "segmentScreen.subwayHeading",
    description: "Heading for a Subway page",
  },
  walkHeading: {
    defaultMessage: "Walk",
    id: "segmentScreen.walkHeading",
    description: "Heading for a Walk page",
  },
  unknownHeading: {
    defaultMessage: "Unknown",
    id: "segmentScreen.unknownHeading",
    description: "Heading for a Walk page",
  },
});
