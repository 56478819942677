import { SvgProps, useColor } from "../common";

type EditProps = {
  active?: boolean;
} & SvgProps;

export const Edit = (props: EditProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0565 0.862512C16.0581 -0.114143 14.8826 -0.140705 13.9152 0.192306C13.0041 0.505979 12.2355 1.14128 11.7904 1.63165L1.83008 11.3034L2.9025 12.2751L2.90775 12.27L5.5782 14.6973L5.57705 14.6985L6.33183 15.3824L6.64649 15.6684L6.64695 15.6679L6.64929 15.67L16.6056 5.9308C17.0594 5.50086 17.6342 4.75211 17.8803 3.86008C18.1397 2.92027 18.0277 1.81249 17.0565 0.862512ZM6.67819 13.6213L4.01194 11.1978L12.8328 2.63254C12.8446 2.62103 12.8561 2.60912 12.8671 2.59683C13.2028 2.22273 13.7819 1.75478 14.3978 1.54278C14.9693 1.34601 15.5157 1.3757 16.0274 1.87622C16.5663 2.40342 16.62 2.9535 16.4731 3.4859C16.3139 4.06289 15.9135 4.59855 15.59 4.90398L15.5808 4.91283L6.67819 13.6213ZM0.0129139 17.371C-0.0747597 17.7594 0.298781 18.0953 0.685286 17.9756L4.36727 16.8356C4.75378 16.716 4.86337 16.2305 4.56454 15.9617L1.71776 13.4018C1.41893 13.133 0.935798 13.2826 0.848124 13.671L0.0129139 17.371Z"
        fill={tintColor}
      />
    </svg>
  );
};
