import { useCallback } from "react";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { getUserEndpoint } from "src/api/endpoints";
import useUser from "src/utils/hooks/useUser";
import { logFetchError } from "./log";
import { logout } from "./session";

export function useDeleteUserProfile() {
  const apiConfig = useApiConfig();
  const userEndpoint = getUserEndpoint(apiConfig, "/me");
  const { resetUser } = useUser();

  const deleteUserProfile = useCallback(async (): Promise<any> => {
    try {
      const response = await fetch(`${userEndpoint}`, {
        method: "DELETE",
        credentials: "include",
      });
      if (!response.ok) throw new Error("Failed to delete user profile");
      logout(
        {
          apiConfig,
        },
        resetUser
      );
      return;
    } catch (error) {
      logFetchError(error);
      return;
    }
  }, [apiConfig, resetUser, userEndpoint]);

  return deleteUserProfile;
}
