import { Suspense, lazy } from "react";
import { TripSearchBarSkeleton } from "./TripSearchBarSkeleton";

const ImportBar = lazy(() => import("./TripSearchBar"));

export const LazyTripSearchBar = () => {
  return (
    <Suspense fallback={<TripSearchBarSkeleton />}>
      <ImportBar />
    </Suspense>
  );
};
