import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "segmentCarHireDriveVariant.heading",
    defaultMessage: "Need a car from {location}?",
    description:
      "Heading for the car hire promo in the drive experiment variant",
  },
  tagline: {
    id: "segmentCarHireDriveVariant.tagline",
    defaultMessage: "See rentals",
    description:
      "Tagline for the car hire promo in the drive experiment variant",
  },
});

export default messages;
