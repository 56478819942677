import { useState } from "react";
import styled from "styled-components";
import { DisplayAd } from "src/components/DisplayAd/DisplayAd";
import { borderRadius, color, spacing } from "src/theme";
import { mobileLayout } from "src/utils/hooks/useLayout";

export function TripInlineSerpAd() {
  const [hasAd, setHasAd] = useState(false);

  return (
    <InlineAdWrapper test-id="desktop-inline-serp-ad" hasAd={hasAd}>
      <StyledDisplayAd
        onFilled={() => setHasAd(true)}
        slotConfigId="desktopInlineSerpAd"
      />
    </InlineAdWrapper>
  );
}

const InlineAdWrapper = styled.div<{ hasAd: boolean }>`
  display: ${({ hasAd }) => (hasAd ? "flex" : "none")};
  background-color: ${color.white};
  padding: 0 6px 6px 15px;
  margin-top: ${spacing.md};
  border: 1px solid ${color.n30};
  border-left: 1px solid ${color.n30};
  border-radius: ${borderRadius.md};

  cursor: pointer;
  &:hover {
    // Note: hover effect cannot be applied on GAM ads, only the container
  }
  ${mobileLayout} {
    margin-bottom: ${spacing.md};
  }
`;

const StyledDisplayAd = styled(DisplayAd)`
  width: 100%;
`;
