import {
  APILoadingStatus,
  useApiLoadingStatus,
  useMap,
} from "@vis.gl/react-google-maps";
import { useIntl } from "react-intl";
import messages from "src/App.messages";
import { duration } from "src/design-system/tokens/animation";
import styled from "styled-components";
import richmondMapImage from "./Richmond.webp";

export function MapLoadingPlaceholder() {
  const isMapLoaded = !!useMap();
  const intl = useIntl();
  const status = useApiLoadingStatus();

  const isErrored =
    status === APILoadingStatus.FAILED ||
    status === APILoadingStatus.AUTH_FAILURE;

  return (
    <MapLoadingImg
      // This placeholder is 400*400px.
      // Any image in the viewport exceeding 160,000px will overtake this as the Largest Contentful Element.
      src={richmondMapImage}
      alt={intl.formatMessage(messages.mapLoading)}
      aria-hidden={isMapLoaded || isErrored}
      $hide={isMapLoaded || isErrored}
    />
  );
}

const MapLoadingImg = styled.img<{ $hide: Boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: ${(props) => (props.$hide ? 0 : 1)};
  filter: blur(12px);
  transform: scale(1.1);
  transform-origin: center;
  transition: opacity ${duration.lg}ms ease-in-out;
  pointer-events: none;
`;
