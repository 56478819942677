import { SvgProps, useColor } from "./common";

export function DottedTimeline(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="8"
      height="36"
      viewBox="0 0 8 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="2" r="2" fill={tintColor} />
      <circle cx="4" cy="10" r="2" fill={tintColor} />
      <circle cx="4" cy="18" r="2" fill={tintColor} />
      <circle cx="4" cy="26" r="2" fill={tintColor} />
      <circle cx="4" cy="34" r="2" fill={tintColor} />
    </svg>
  );
}
