import { useEffect, useState } from "react";
import { getConsentImplementation } from "@rome2rio/tcf-api";
import { useFeature } from "src/feature/useFeature";

/**
 * A hook that updates with which privacy regulations are applicable for the given user
 *
 * @returns A list of applicable privacy regulations to the user, or `undefined` if not yet known
 */
export function useApplicablePrivacyRegs() {
  const enableUsPrivacyReg = useFeature("USStateConsentManagement");
  // Try setting the value early - to placate tests that HATE state being updated 1 microsecond after rendering in a promise.
  const [applicableRegulations, setApplicableRegulations] = useState<
    string[] | undefined
  >(import.meta.env.MODE === "test" ? [] : undefined);

  // TODO: If we end up causing too many calls to addEventListener, we could make this into a context
  useEffect(() => {
    getConsentImplementation().then(
      (consentImplementation) => {
        let filteredConsents = consentImplementation;
        if (!enableUsPrivacyReg) {
          filteredConsents = consentImplementation.filter(
            (imp) => imp !== "usprivacy"
          );
        }
        setApplicableRegulations(filteredConsents);
      },
      () => {
        setApplicableRegulations(undefined);
      }
    );
  }, [enableUsPrivacyReg]);

  return applicableRegulations;
}
