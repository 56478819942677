import { sendAnalyticsNonInteractionEvent } from "../../analytics/sendAnalyticsNonInteractionEvent";

export function trackError(error: ErrorEvent["error"]) {
  sendAnalyticsNonInteractionEvent(
    "GenericExplore",
    "PageError",
    error ?? "(not set)"
  );
}

export function trackErrors() {
  const loadErrorEvents = (window.__e && window.__e.q) ?? [];

  // Replay any stored load error events.
  for (let event of loadErrorEvents) {
    trackError(event.error);
  }

  // Add a new listener to track event immediately.
  window.addEventListener(
    "error",
    (event) => {
      trackError(event.error);
    },
    true
  );

  window.addEventListener(
    "unhandledrejection",
    (event) => {
      // This callback catches all promise rejections, even those rejections that
      // are subsequently handled in the 'handledrejection' callback. We've
      // deliberately ignored the 'handledrejection' callback because it's not
      // clear whether it's worth the additional complexity.
      // See: https://googlechrome.github.io/samples/promise-rejection-events/
      trackError(event.reason);
    },
    true
  );
}
