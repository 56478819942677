import { SvgProps, useColor } from "../common";

type Props = {
  className?: string;
  strokeWidth?: string;
} & SvgProps;

/** @deprecated Use the ChevronRight svg instead */
export const ChevronRight = (props: Props) => {
  const { tint, strokeWidth = 2 } = props;
  const tintColor = useColor(tint);

  return (
    <svg
      className={props.className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <title>{props.title}</title>
      <path
        d="M11 28L21 15.9913L11 4"
        stroke={tintColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
