import { SvgProps, useColor } from "./common";

export const Suitcase = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
      <title>{props.title}</title>
      <rect
        width="20"
        height="14"
        x="1"
        y="5"
        stroke={tintColor}
        strokeWidth="2"
        rx="2"
      />
      <path
        stroke={tintColor}
        strokeWidth="2"
        d="M9 1h4a1 1 0 0 1 1 1v3H8V2a1 1 0 0 1 1-1ZM6 4v15M16 4v14.882"
      />
    </svg>
  );
};
