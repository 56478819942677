import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";

type DiscoverInfo = {
  hoveredCanonical: string | undefined;
  setHoveredCanonical: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedCanonical: string | undefined;
  setSelectedCanonical: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
};

const DiscoverContext = createContext<DiscoverInfo | undefined>(undefined);

export function DiscoverProvider(props: PropsWithChildren<{}>) {
  const [hoveredCanonical, setHoveredCanonical] = useState<
    string | undefined
  >();
  const [selectedCanonical, setSelectedCanonicalRaw] = useState<
    string | undefined
  >();

  function setSelectedCanonical(
    canonicalName: React.SetStateAction<string | undefined>
  ) {
    setSelectedCanonicalRaw(canonicalName);
    setHoveredCanonical(undefined);
  }

  return (
    <DiscoverContext.Provider
      value={{
        hoveredCanonical,
        setHoveredCanonical,
        selectedCanonical,
        setSelectedCanonical,
      }}
      {...props}
    />
  );
}

export function useDiscoverContext() {
  const context = useContext(DiscoverContext);
  if (!context) {
    throw new Error("useDiscoverContext must be used within DiscoverProvider");
  }
  return context;
}
