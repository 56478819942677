import styled from "styled-components";
import { spacing } from "src/theme";
import { Mode } from "../../../utils/types/mode";
import { TransitIcon } from "../../../components/Icon/TransitIcon";
import { desktopLayout } from "../../../utils/hooks/useLayout";

export function TransitIcons({ modes }: { modes: Mode[] }) {
  return (
    <Container>
      {modes.map((it, i) => {
        const isFirst = i === 0;
        const shouldRotate = it === "plane";

        return (
          <Segment key={i}>
            {!isFirst && <Spacer />}
            <Icon shouldRotate={shouldRotate}>
              <TransitIcon transitMode={it} tint={it} />
            </Icon>
          </Segment>
        );
      })}
    </Container>
  );
}

const Spacer = styled.span`
  width: ${spacing.md};
`;

const Container = styled.ol`
  display: flex;
  list-style: none;
  overflow: hidden;
  white-space: nowrap;
`;

const Segment = styled.li`
  display: flex;
  align-items: center;
`;

const Icon = styled.div<{ shouldRotate: boolean }>`
  display: flex;
  align-items: center;

  svg {
    display: inline-block;
    height: 20px;
    width: auto;
    position: relative;
    transform: ${(props) => (props.shouldRotate ? `rotate(45deg)` : null)};
    top: ${(props) => (props.shouldRotate ? `-2px` : 0)};

    ${desktopLayout} {
      height: 24px;
    }
  }
`;
