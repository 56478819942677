import {
  ExperimentName,
  ExperimentVariant,
} from "../../../experiment/ExperimentDefinition";
import { ExperimentConfig } from "../../../experiment/useExperimentConfig";
import { FeatureConfig } from "../../FeatureConfig";

type FeatureExperimentConfig = {
  [E in ExperimentName]?: {
    [V in ExperimentVariant<E>]: Partial<FeatureConfig>;
  };
};

// experimentFeatureMap describes which features should be controlled by which
// experiments.
//
// The way it's structured means that multiple experiments can control the same
// feature. However, if you do define multiple experiments for the same feature
// the last one will overwrite any previous ones.
//
// Note that omitted an Experiment Variant will use the default variant from
// FeatureConfig.
const experimentFeatureMap: FeatureExperimentConfig = {
  ShortNav: {
    None: { ShortNavFeature: false },
    Baseline: { ShortNavFeature: false },
    Short: { ShortNavFeature: true },
  },
  USStatesConsentExperiment: {
    None: { USStateConsentManagement: false },
    Baseline: { USStateConsentManagement: false },
    USStatesConsent: { USStateConsentManagement: true },
  },
  DesktopInlineSerpAd90: {
    None: { DesktopInlineSerpAd90: false },
    Baseline: { DesktopInlineSerpAd90: false },
    InlineAd: { DesktopInlineSerpAd90: true },
  },
  HotelsScrollNudge: {
    None: { HExScrollNudge: false },
    Baseline: { HExScrollNudge: false },
    ScrollNudge: { HExScrollNudge: true },
  },
  ClearerMessageV2: {
    None: { ClearerMessaging: "baseline" },
    Baseline: { ClearerMessaging: "baseline" },
    WithOperatorHeading: { ClearerMessaging: "withHeading" },
    WithoutOperatorHeading: {
      ClearerMessaging: "withoutHeading",
    },
  },
  PreDestination: {
    None: { PreDestinationPages: "baseline" },
    Baseline: { PreDestinationPages: "baseline" },
    TransitInfo: { PreDestinationPages: "transitInfo" },
  },
  TimeOfDayFilter: {
    None: { TimeOfDayFilter: false },
    Baseline: { TimeOfDayFilter: false },
    TimeOfDayFilter: { TimeOfDayFilter: true },
  },
  HotelsRefineSearch: {
    None: {
      HExRefineSearchKind: "baseline",
      HExRefineSearchPosition: "baseline",
    },
    Baseline: {
      HExRefineSearchKind: "baseline",
      HExRefineSearchPosition: "baseline",
    },
    SuburbTop: {
      HExRefineSearchKind: "suburb",
      HExRefineSearchPosition: "top",
    },
    SuburbMid: {
      HExRefineSearchKind: "suburb",
      HExRefineSearchPosition: "mid",
    },
    AttractionTop: {
      HExRefineSearchKind: "attraction",
      HExRefineSearchPosition: "top",
    },
    AttractionMid: {
      HExRefineSearchKind: "attraction",
      HExRefineSearchPosition: "mid",
    },
  },
  TransportCardUxV2: {
    None: { TransportCardUx: false },
    Baseline: { TransportCardUx: false },
    TransportCardUx: { TransportCardUx: true },
  },
};

export function getFeatureConfigForExperimentConfig(
  experimentConfig?: ExperimentConfig
): Partial<FeatureConfig> {
  if (!experimentConfig) {
    return {};
  }
  let result: Partial<FeatureConfig> = {};
  const experimentNameList = Object.keys(
    experimentFeatureMap
  ) as ExperimentName[];
  for (let experimentName of experimentNameList) {
    const experimentPolicy = experimentFeatureMap[experimentName]!;
    // Get the experiment variant that has been set by the backend.
    const experimentVariant = experimentConfig[experimentName];

    // TypeScript is trying to protect us from indexing a value that doesn't exist
    // but it doesn't matter if we do because if we do it'll return undefined
    // anyway.
    const featureConfig = (experimentPolicy as any)[experimentVariant]!;

    if (featureConfig !== undefined) {
      result = { ...result, ...featureConfig };
    }
  }

  return result;
}
