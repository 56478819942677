import { useRoutesBetween } from "src/utils/hooks/useRoutesBetween";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { centerZoomMapForSegments } from "../../centerZoomMapForSegments";
import { Polyline } from "./Polyline";

type Props = {
  from: google.maps.LatLngLiteral;
  to: google.maps.LatLngLiteral;
  count?: number;
  highlightedRoute?: number;
  googleMap: google.maps.Map;
  isFitBounds?: boolean;
};
/**
 * This function relies on two coordinates to fetch the transport polylines between them
 * @param from Origin coordinates
 * @param to Destination coordinates
 * @param count Specifies how many top routes to display
 * @param highlightedRoute Index of the route result to highlight, the rest are blurred/greyed out
 * @googleMap
 * @isFitBounds Turns the fitBounds on/off
 * @returns transport polylines as JSX
 */
export function RoutePolylines({
  from,
  to,
  count,
  highlightedRoute,
  googleMap,
  isFitBounds = true,
}: Props) {
  const routes = useRoutesBetween({
    from,
    to,
    count,
  });
  const { LatLngBounds } = useMapsLibrary("core") || {};

  if (!routes || !LatLngBounds) {
    return null;
  }

  const boundsSegments = routes.flatMap((route) => route.mapSegments);
  if (isFitBounds) {
    centerZoomMapForSegments({
      map: googleMap,
      boundsSegments,
      bounds: new LatLngBounds(),
    });
  }

  return (
    <>
      {routes?.map(({ mapSegments }, index) => {
        return mapSegments.map((segment, segmentIndex) => {
          return segment.paths.map((path, pathIndex) => {
            const focused = highlightedRoute === index;
            const key = `segment-${segmentIndex}-path-${pathIndex}`;
            return (
              <Polyline
                googleMap={googleMap}
                path={path}
                transitMode={segment.transitMode}
                blurred={!focused}
                key={key}
              />
            );
          });
        });
      })}
    </>
  );
}
