import { SvgProps, useColor } from "../../../svg/common";

export function DestinationIcon(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <title>{props.title}</title>
      <path
        d="M9 4.878c.796 0 1.44.652 1.44 1.455S9.796 7.79 9 7.79s-1.44-.652-1.44-1.455S8.205 4.878 9 4.878z"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        d="M9 0C5.135 0 2 3.135 2 7c0 2.235 1.19 4.57 2.357 6.358 1.193 1.825 2.486 3.258 2.908 3.717l.057.064c.24.272.762.862 1.68.862s1.44-.6 1.678-.86l.058-.064c.36-.4 1.652-1.822 2.86-3.663 1.18-1.8 2.406-4.173 2.406-6.413 0-3.866-3.135-7-7-7zM4 7c0-2.76 2.24-5 5-5s5 2.24 5 5c0 1.615-.93 3.566-2.078 5.316-1.12 1.708-2.33 3.048-2.658 3.405l-.073.08-.114.122-.078.074c-.016-.013-.04-.035-.078-.073l-.116-.125-.07-.076c-.396-.43-1.603-1.77-2.707-3.46C4.902 10.537 4 8.62 4 7z"
        fill={tintColor}
      />
    </svg>
  );
}
