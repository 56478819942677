import { useLocation } from "react-router";
import { useGeocode } from "src/utils/hooks/useGeocode";
import { useGetRoutes } from "src/utils/hooks/useGetRoutes";
import { getOriginDestinationFromHash } from "../util/getOriginDestinationFromHash";

export function useTripSearchResponse() {
  const location = useLocation();
  const { origin, destination } =
    getOriginDestinationFromHash(location.hash) ?? {};
  const geocodedOrigin = useGeocode(origin);
  const geocodedDestination = useGeocode(destination);
  const { data: searchResponse, isLoading } = useGetRoutes(
    geocodedOrigin.data,
    geocodedDestination.data
  );

  return {
    tripOrigin: geocodedOrigin.data,
    tripDestination: geocodedDestination.data,
    tripSearchResponse: searchResponse,
    isTripSearchResponseLoading: isLoading,
  };
}
