import { defineMessages } from "react-intl";

const messages = defineMessages({
  language: {
    id: "userPreference.language",
    description: "Label for the user's selected language",
    defaultMessage: "Language",
  },
  currency: {
    id: "userPreference.currency",
    description: "Label for the user's selected currency",
    defaultMessage: "Currency",
  },
  distance: {
    id: "userPreference.distance",
    description: "Label for the user's selected distance unit",
    defaultMessage: "Distance",
  },
  timeFormat: {
    id: "userPreference.timeFormat",
    description: "Label for the user's selected time format",
    defaultMessage: "Time Format",
  },
  change: {
    id: "userPreference.change",
    description:
      "Label for screenreaders to indicate that the button lets users change their preference",
    defaultMessage: "Change preference",
  },
  debugTitle: {
    id: "userPreference.debug.title",
    description: "Label for debug button",
    defaultMessage: "Debug",
  },
  debugDescription: {
    id: "userPreference.debug.description",
    description: "Description for debug button",
    defaultMessage: "Change enabled features to test",
  },
  consent: {
    id: "userPreference.consent",
    description:
      "A button that allows users to change their GDPR consent preferences",
    defaultMessage: "Privacy Settings",
  },
  doNotShare: {
    id: "userPreference.doNotShare",
    description:
      "A button that allows users to change their US privacy consent preferences.",
    defaultMessage: "Do Not Share or Sell My Personal Information",
  },
});

export default messages;
