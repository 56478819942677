import { getRouteCanonicalFromHash } from "../location/routeCanonicalFromHash";
import { getRouteIndexFromHash } from "../location/routeIndexFromHash";
import useSearch from "./useSearch";
import { useTypedLocation } from "./useTypedLocation";

/**
 * @deprecated use getRouteCanonicalFromHash instead
 */
export function useRouteCanonical(): string | undefined {
  const location = useTypedLocation();
  return getRouteCanonicalFromHash(location.hash);
}

export function useRouteIndex(): number | undefined {
  const { searchResponse } = useSearch();
  const location = useTypedLocation();
  const index = getRouteIndexFromHash(location.hash, searchResponse);
  return index !== undefined && index >= 0 ? index : undefined;
}
