import { SvgProps, useColor } from "../common";

type BookmarkProps = {
  active?: boolean;
} & SvgProps;

export const Bookmark = (props: BookmarkProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
      <title>{props.title}</title>
      <path
        d="M12.6102 2.50026V16.6436C12.6102 16.839 12.396 16.9442 12.2483 16.824L7.47644 12.8109C7.19575 12.578 6.79686 12.578 6.51617 12.8109L1.74434 16.824C1.5966 16.9442 1.375 16.839 1.375 16.6436V2.50026C1.375 1.74124 1.98071 1.125 2.72677 1.125H11.2806C12.0267 1.125 12.625 1.74124 12.625 2.50026H12.6102Z"
        stroke={tintColor}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        fill={props.active ? tintColor : "none"}
      />
    </svg>
  );
};
