import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

type HoveredIndex = {
  hoveredRouteIndex: number | undefined;
  setHoveredRouteIndex: Dispatch<SetStateAction<number | undefined>>;
  hoveredSegmentIndex: number | undefined;
  setHoveredSegmentIndex: Dispatch<SetStateAction<number | undefined>>;
  hoveredScheduleIndex: number | undefined;
  setHoveredScheduleIndex: Dispatch<SetStateAction<number | undefined>>;
};

const HoveredIndexContext = createContext<HoveredIndex | undefined>(undefined);

export function HoveredIndexProvider(props: PropsWithChildren<{}>) {
  const [hoveredRouteIndex, setHoveredRouteIndex] = useState<
    number | undefined
  >();
  const [hoveredSegmentIndex, setHoveredSegmentIndex] = useState<
    number | undefined
  >();
  const [hoveredScheduleIndex, setHoveredScheduleIndex] = useState<
    number | undefined
  >();

  return (
    <HoveredIndexContext.Provider
      value={{
        hoveredRouteIndex,
        setHoveredRouteIndex,
        hoveredSegmentIndex,
        setHoveredSegmentIndex,
        hoveredScheduleIndex,
        setHoveredScheduleIndex,
      }}
      {...props}
    />
  );
}

export function useHoveredIndex() {
  const context = useContext(HoveredIndexContext);
  if (!context) {
    throw new Error(`useHoveredIndex must be used within HoveredIndexProvider`);
  }
  return context;
}
