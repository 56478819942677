import styled from "styled-components";
import { ChevronUp } from "./ChevronUp";

const rotateDirection = {
  up: "0deg",
  right: "90deg",
  down: "180deg",
  left: "270deg",
} as const;

export const RotatingChevron = styled(ChevronUp)<{
  direction: keyof typeof rotateDirection;
}>`
  transition: transform 0.3s ease;
  transform: rotate(${(props) => rotateDirection[props.direction]});
`;
