import { SvgProps, useColor } from "./common";

export function CheckCircle(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0ZM16.6187 8.61872C16.9604 8.27701 16.9604 7.72299 16.6187 7.38128C16.277 7.03957 15.723 7.03957 15.3813 7.38128L10 12.7626L7.61872 10.3813C7.27701 10.0396 6.72299 10.0396 6.38128 10.3813C6.03957 10.723 6.03957 11.277 6.38128 11.6187L9.38128 14.6187C9.72299 14.9604 10.277 14.9604 10.6187 14.6187L16.6187 8.61872Z"
        fill={tintColor}
      />
    </svg>
  );
}
