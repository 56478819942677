import { defineMessages } from "react-intl";

const messages = defineMessages({
  searchLabel: {
    id: "search.label",
    defaultMessage: "Search",
    description: "Label search button",
  },
  switchLabel: {
    id: "search.switch.label",
    defaultMessage: "switch",
    description:
      "The label for a button that switches the origin and destination",
  },
});

export default messages;
