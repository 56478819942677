import { SetStateAction, SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useDeleteUserProfile } from "src/auth/utils/useDeleteUserProfile";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";
import useUser from "../../../utils/hooks/useUser";
import { navigateToNewState } from "../../../utils/location/navigateToNewState";
import { UserProfile, getUserEmail } from "../../utils/user";

export function useManageProfile(user: UserProfile) {
  const { updateUserProfile } = useUser();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const [name, setName] = useState(user.name ?? "");
  const [email, setEmail] = useState(getUserEmail(user) ?? "");
  const [toggle, setToggle] = useState(user.optedInMarketingEmails);
  const disabled =
    (name === user.name && toggle === user.optedInMarketingEmails) || !name;
  const deleteProfile = useDeleteUserProfile();

  function handleNameChange(value: string) {
    setName(value);
  }

  function handleEmailChange(value: string) {
    setEmail(value);
  }

  function handleOnChangeToggle(value: SetStateAction<boolean>) {
    setToggle(value);
  }

  function handleUpdate(event: SyntheticEvent) {
    event.preventDefault();
    sendAnalyticsInteractionEvent(
      "UserProfile",
      "Click:Update",
      `$marketing=${toggle}`
    );
    updateUserProfile({
      name,
      optedInMarketingEmails: toggle,
    });
    navigateToNewState(navigate, { preferencesScreen: "main" }, location);
  }

  async function handleDeleteProfile() {
    sendAnalyticsInteractionEvent("UserProfile", "Click:Delete");
    await deleteProfile();
    navigateToNewState(navigate, { preferencesScreen: "main" }, location);
  }

  return {
    name,
    email,
    toggle,
    handleNameChange,
    handleEmailChange,
    handleOnChangeToggle,
    handleUpdate,
    disabled,
    handleDeleteProfile,
  };
}
