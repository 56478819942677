import { defineMessages } from "react-intl";

const messages = defineMessages({
  findHotelsAround: {
    id: "segmentAccommodationDriveVariant.findHotelsAround",
    defaultMessage: "Staying around {location}?",
    description:
      "A message on a link that takes a user to a hotel booking site in the requested location",
  },
  findHotelsNear: {
    id: "segmentAccommodationDriveVariant.findHotelsNear",
    defaultMessage: "Staying near {location}?",
    description:
      "A message on a link that takes a user to a hotel booking site near the requested location",
  },
  tagline: {
    id: "segmentAccommodationDriveVariant.tagline",
    defaultMessage: "Find hotels",
    description:
      "Tagline for the accommodation promo in the drive experiment variant",
  },
});

export default messages;
