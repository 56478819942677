import Cookies from "js-cookie";
import { defineMessages, IntlShape } from "react-intl";

export const getCurrencyCodeFromCookie = ():
  | SupportedCurrencyCode
  | undefined => {
  const cookieCurrencyCode = Cookies.get("currency")?.toUpperCase();

  return supportedCurrencyCodes.find(
    (supportedCurrencyCode) => cookieCurrencyCode === supportedCurrencyCode
  );
};

export const setCurrencyCookie = (currencyCode: SupportedCurrencyCode) => {
  // set to expire in 1000 days because that's how it works in old MapView code
  Cookies.set("currency", currencyCode, { expires: 1000 });
};

// Keep in sync with currencies in Currency.cs
export const currencyMessages = defineMessages({
  ARS: {
    id: "currency.ARS",
    defaultMessage: "Argentine Peso",
    description: "The name of the 'Argentine Peso' currency",
  },
  AUD: {
    id: "currency.AUD",
    defaultMessage: "Australian Dollar",
    description: "The name of the 'Australian Dollar' currency",
  },
  BRL: {
    id: "currency.BRL",
    defaultMessage: "Brazillian Real",
    description: "The name of the 'Brazillian Real' currency",
  },
  CAD: {
    id: "currency.CAD",
    defaultMessage: "Canadian Dollar",
    description: "The name of the 'Canadian Dollar' currency",
  },
  CLP: {
    id: "currency.CLP",
    defaultMessage: "Chilean Peso",
    description: "The name of the 'Chilean Peso' currency",
  },
  CNY: {
    id: "currency.CNY",
    defaultMessage: "Chinese Yuan",
    description: "The name of the 'Chinese Yuan' currency",
  },
  COP: {
    id: "currency.COP",
    defaultMessage: "Colombian Peso",
    description: "The name of the 'Colombian Peso' currency",
  },
  CZK: {
    id: "currency.CZK",
    defaultMessage: "Czech Koruna",
    description: "The name of the 'Czech Koruna' currency",
  },
  DKK: {
    id: "currency.DKK",
    defaultMessage: "Danish Krone",
    description: "The name of the 'Danish Krone' currency",
  },
  EUR: {
    id: "currency.EUR",
    defaultMessage: "Euro",
    description: "The name of the 'Euro' currency",
  },
  HKD: {
    id: "currency.HKD",
    defaultMessage: "Hong Kong Dollar",
    description: "The name of the 'Hong Kong Dollar' currency",
  },
  HUF: {
    id: "currency.HUF",
    defaultMessage: "Hungarian Forint",
    description: "The name of the 'Hungarian Forint' currency",
  },
  INR: {
    id: "currency.INR",
    defaultMessage: "Indian Rupee",
    description: "The name of the 'Indian Rupee' currency",
  },
  IDR: {
    id: "currency.IDR",
    defaultMessage: "Indonesian Rupiah",
    description: "The name of the 'Indonesian Rupiah' currency",
  },
  ILS: {
    id: "currency.ILS",
    defaultMessage: "Israeli New Shekel",
    description: "The name of the 'Israeli New Shekel' currency",
  },
  JPY: {
    id: "currency.JPY",
    defaultMessage: "Japanese Yen",
    description: "The name of the 'Japanese Yen' currency",
  },
  MYR: {
    id: "currency.MYR",
    defaultMessage: "Malaysian Ringgit",
    description: "The name of the 'Malaysian Ringgit' currency",
  },
  MXN: {
    id: "currency.MXN",
    defaultMessage: "Mexican Peso",
    description: "The name of the 'Mexican Peso' currency",
  },
  MAD: {
    id: "currency.MAD",
    defaultMessage: "Moroccan Dirham",
    description: "The name of the 'Moroccan Dirham' currency",
  },
  NPR: {
    id: "currency.NPR",
    defaultMessage: "Nepalese Rupee",
    description: "The name of the 'Nepalese Rupee' currency",
  },
  NZD: {
    id: "currency.NZD",
    defaultMessage: "New Zealand Dollar",
    description: "The name of the 'New Zealand Dollar' currency",
  },
  TWD: {
    id: "currency.TWD",
    defaultMessage: "New Taiwan Dollar",
    description: "The name of the 'New Taiwan Dollar' currency",
  },
  NOK: {
    id: "currency.NOK",
    defaultMessage: "Norwegian Krone",
    description: "The name of the 'Norwegian Krone' currency",
  },
  PKR: {
    id: "currency.PKR",
    defaultMessage: "Pakistani Rupee",
    description: "The name of the 'Pakistani Rupee' currency",
  },
  PHP: {
    id: "currency.PHP",
    defaultMessage: "Philippines Peso",
    description: "The name of the 'Philippines Peso' currency",
  },
  PLN: {
    id: "currency.PLN",
    defaultMessage: "Polish Złoty",
    description: "The name of the 'Polish Złoty' currency",
  },
  GBP: {
    id: "currency.GBP",
    defaultMessage: "Pound Sterling",
    description: "The name of the 'Pound Sterling' currency",
  },
  RON: {
    id: "currency.RON",
    defaultMessage: "Romanian leu",
    description: "The name of the 'Romanian leu' currency",
  },
  RUB: {
    id: "currency.RUB",
    defaultMessage: "Russian Ruble",
    description: "The name of the 'Russian Ruble' currency",
  },
  SAR: {
    id: "currency.SAR",
    defaultMessage: "Saudi Arabian Riyal",
    description: "The name of the 'Saudi Arabian Riyal' currency",
  },
  RSD: {
    id: "currency.RSD",
    defaultMessage: "Serbian Dinar",
    description: "The name of the 'Serbian Dinar' currency",
  },
  SGD: {
    id: "currency.SGD",
    defaultMessage: "Singapore Dollar",
    description: "The name of the 'Singapore Dollar' currency",
  },
  ZAR: {
    id: "currency.ZAR",
    defaultMessage: "South African Rand",
    description: "The name of the 'South African Rand' currency",
  },
  KRW: {
    id: "currency.KRW",
    defaultMessage: "South Korean Won",
    description: "The name of the 'South Korean Won' currency",
  },
  LKR: {
    id: "currency.LKR",
    defaultMessage: "Sri Lankan Rupee",
    description: "The name of the 'Sri Lankan Rupee' currency",
  },
  SEK: {
    id: "currency.SEK",
    defaultMessage: "Swedish Krona",
    description: "The name of the 'Swedish Krona' currency",
  },
  CHF: {
    id: "currency.CHF",
    defaultMessage: "Swiss Franc",
    description: "The name of the 'Swiss Franc' currency",
  },
  THB: {
    id: "currency.THB",
    defaultMessage: "Thai Baht",
    description: "The name of the 'Thai Baht' currency",
  },
  TRY: {
    id: "currency.TRY",
    defaultMessage: "Turkish Lira",
    description: "The name of the 'Turkish Lira' currency",
  },
  AED: {
    id: "currency.AED",
    defaultMessage: "UAE Dirham",
    description: "The name of the 'UAE Dirham' currency",
  },
  UAH: {
    id: "currency.UAH",
    defaultMessage: "Ukranian Hryvnia",
    description: "The name of the 'Ukranian Hryvnia' currency",
  },
  USD: {
    id: "currency.USD",
    defaultMessage: "US Dollar",
    description: "The name of the 'US Dollar' currency",
  },
});

export type SupportedCurrencyCode = keyof typeof currencyMessages;

export const supportedCurrencyCodes = Object.keys(
  currencyMessages
) as SupportedCurrencyCode[];

export function isSupportedCurrencyCode(
  value: string
): value is SupportedCurrencyCode {
  // This conversion is safe because value is a string and SupportedCurrencyCode
  // is a union of strings.
  return supportedCurrencyCodes.includes(value as SupportedCurrencyCode);
}

/**
 * For the given locale, this function will determine if the currency symbol
 * should be listed after the price
 */
export function isLocaleCurrencyAfter(intl: IntlShape): boolean {
  // German, Spanish, French, Russian and Italian
  // all of these languages list their currency after the price
  return ["de", "es", "fr", "ru", "it"].includes(intl.locale);
}
