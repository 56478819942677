import { defineMessages } from "react-intl";

const messages = defineMessages({
  transport: {
    defaultMessage: "Transportation {origin} to {destination}",
    id: "csa.transport",
    description: "Title of an ad",
  },
  travel: {
    defaultMessage: "Travel {origin} to {destination}",
    id: "csa.travel",
    description: "Title of an ad",
  },
  flight: {
    defaultMessage: "Flights {origin} to {destination}",
    id: "csa.flight",
    description: "Title of an ad",
  },
  mode: {
    defaultMessage: "{mode} {origin} to {destination}",
    id: "csa.mode",
    description: "Title of an ad",
  },
});

export default messages;
