import { SearchResponse } from "../../../../api/SearchResponse";
import { transitModeFromVehicleKind } from "../../../../utils/adapters/transitMode";
import {
  getAlternativeNameForRouteSegment,
  TripPointAlternativeNames,
} from "../../../../utils/hooks/useTimelineTripPointAlternativeNames";
import { Mode } from "../../../../utils/types/mode";

export function originCellFromSegment(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  isStandalone: boolean | undefined,
  tripPointAlternatives: TripPointAlternativeNames | undefined
): OriginCell {
  return originCellFromOption(
    response,
    routeIndex,
    segmentIndex,
    0,
    isStandalone,
    tripPointAlternatives
  );
}

function originCellFromOption(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  optionIndex: number,
  isStandalone: boolean = false,
  tripPointAlternatives?: TripPointAlternativeNames
): OriginCell {
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[segmentIndex];
  const segment = response.segments[segmentLookupIndex];

  const option = response.options[segment.options[optionIndex]];

  const firstHopIndex = option.hops[0];
  const firstHop = response.hops[firstHopIndex];

  const vehicle = response.vehicles[firstHop.vehicle];

  const departureLine = response.lines[firstHop.line];
  const departurePlace = response.places[departureLine.places[0]];
  const transitMode = transitModeFromVehicleKind(vehicle.kind);

  const isFirstSegment = segmentIndex === 0;
  const tripOriginName = getAlternativeNameForRouteSegment(
    segmentIndex,
    route,
    transitMode,
    tripPointAlternatives
  );

  return {
    type: "origin",
    transitMode,
    placeName: departurePlace.shortName,
    id: `origin-${routeIndex}-${segmentIndex}-${optionIndex}`,
    tripOriginName,
    isFirstSegment,
    isStandalone: isStandalone,
  };
}

export type OriginCell = {
  type: "origin";
  id: string;
  placeName: string;
  transitMode: Mode;
  tripOriginName?: string;
  isFirstSegment: boolean;
  isStandalone: boolean;
};
