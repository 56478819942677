import { useEffect } from "react";
import { useIntl } from "react-intl";
import messages from "../../App.messages";

export function useHeadTitle(origin?: string, destination?: string) {
  const intl = useIntl();

  useEffect(() => {
    if (origin && destination) {
      const pageTitle = intl.formatMessage(messages.pageTitle, {
        origin,
        destination,
      });
      document.title = pageTitle;
    } else {
      document.title = "Rome2Rio";
    }
  }, [origin, destination, intl]);
}
