import { createContext, MutableRefObject, useContext } from "react";

type ScrollState = {
  getScrollTop: () => number;
  setScrollTop: (top: number) => void;
  scrollRef: MutableRefObject<HTMLElement> | null;
};

export const ScrollContext = createContext<ScrollState>({
  getScrollTop: () => 0,
  setScrollTop: () => {},
  scrollRef: null,
});

export function useScroll() {
  return useContext(ScrollContext);
}
