export const DEFAULT_NIGHTS_STAY = 1; // Assumed length of hotel stay when user first enters Hotel Experience.
export const MAX_FUTURE_BOOKABLE_DAYS = 365 + 90; // The limit of days into the future that user can make a hotel booking. ~ 15 months
export const DEFAULT_ROOM = { adults: 2, children: [] };
export const MAX_ROOMS = 8; // Expedia 8, BookingC 30.
export const MAX_GUESTS_IN_ROOM = 14; // Expedia.
export const MAX_CHILDREN_IN_ROOM = 6; // Expedia.
export const MAX_ADULTS = 30; // BookingC.
export const MAX_CHILDREN = 10; // BookingC.
export const MAX_GUESTS = 40; // Backup for when we onboard more providers.
export const DEFAULT_CHILD_AGE = 6; // BookingC.
