import { useCallback, useEffect } from "react";
import { useApiConfig } from "src/api/ApiConfigProvider";
import logError from "src/utils/logError";
import { useFeature } from "src/feature/useFeature";
import { useAuthProviders } from "../contexts/AuthContext";
import { useHandleCreateSession } from "../hooks/useHandleCreateSession";
import {
  gapiParams,
  initializeGoogleSignin,
  googleGapiLogin,
  type LoginCallback,
} from "./GoogleAuthSdk";

export function useInitializeGoogleSignin() {
  const googleCustomButton = useGoogleCustomButton();
  const handleCreateSession = useHandleCreateSession("google");
  const { setGoogleLoading } = useAuthProviders();
  const apiConfig = useApiConfig();

  // Initialize google accounts. We need to call it once per page as that is what google recommends.
  // See: https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize
  useEffect(() => {
    if (apiConfig.endpoint16Base && !window.onGoogleLibraryLoad) {
      window.onGoogleLibraryLoad = function () {
        setGoogleLoading(false);
        if (googleCustomButton) {
          window.gapi.load("auth2", async function () {
            await window.gapi.auth2.init(gapiParams);
          });
        } else {
          initializeGoogleSignin(handleCreateSession);
        }
      };
    }
  }, [apiConfig, setGoogleLoading, handleCreateSession, googleCustomButton]);
}

export function useGoogleLogin(
  onSessionInit: LoginCallback
): () => Promise<void> {
  const loginImplicitFlow = useCallback(async () => {
    try {
      const { id_token } = await googleGapiLogin();
      await onSessionInit(id_token);
    } catch (error) {
      // The user closed the popup before completing the sign in flow.
      // This is not an error, so we don't want to show the error message.
      if ((error as any).error !== "popup_closed_by_user") {
        logError(error, ["user-accounts"]);
      }
    }
  }, [onSessionInit]);

  return loginImplicitFlow;
}

export function useGoogleCustomButton() {
  const googleCustomButton = useFeature("GoogleCustomButton");
  if (import.meta.env.DEV || window.gapi?.load !== undefined) {
    return googleCustomButton;
  }
  return false;
}
