import { Dispatch } from "react";
import { ApiConfig } from "src/api/ApiConfig";
import { buildUrl } from "src/utils/url";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { Action, TripPlannerDetails } from "../TripPlannerProvider";
import { tripResponseToTripPlannerDetails } from "./tripResponseToTripPlannerDetails";
import { tripResponseToTripAttributes } from "./tripResponseToTripAttributes";
import getTripRequestContents from "./getTripRequestContents";

type FetchState = "fetching" | "fetched" | "failed";

export const TRIPS_API_ERROR: {
  [key: Response["status"]]: string;
} = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  500: "Internal Server Error",
} as const;

export type ApiState = {
  fetchState: FetchState;
};

export type TripResponse = {
  tripId: string;
  slug: string;
  data: TripPlannerDetails;
  updatedOn?: string;
};

export type TripError = {
  message: (typeof TRIPS_API_ERROR)[number];
};

export type TripPlanWithID = TripPlannerDetails & { id: string };

export type TripAttributes = {
  id: string;
  slug?: string;
  updatedOn?: string;
};

export type onSingleCompleteCallback = (
  data?: TripPlanWithID,
  error?: TripError
) => void;
export type onCompleteCallback = (
  data?: TripAttributes,
  error?: TripError
) => void;
export type onListCompleteCallback = (
  data?: TripAttributes[],
  error?: TripError
) => void;
export type onDeleteCallback = (success?: boolean, error?: TripError) => void;

export async function getAccountTripPlan(
  onCompleteCallback: onSingleCompleteCallback,
  apiConfig: ApiConfig,
  hasAuth?: boolean
) {
  const url = buildUrl(
    apiConfig.endpoint16Base + "/Trips",
    { key: apiConfig.key, uid: apiConfig.uid, aqid: apiConfig.aqid },
    {
      useApi16: true,
    }
  );
  try {
    const tripListResult = await fetch(url, {
      method: "GET",
      ...getTripRequestContents(undefined, hasAuth),
    });
    const tripData = await handleListResponse(tripListResult);
    const tripResult = await getTripPlan(
      onCompleteCallback,
      apiConfig,
      tripData[0].id
    );
    return tripResult;
  } catch (err) {
    logFetchError(err);
    throw err;
  }
}

export async function getAllAccountTripPlans(
  onCompleteCallback: onListCompleteCallback,
  apiConfig: ApiConfig
) {
  const url = buildUrl(
    apiConfig.endpoint16Base + "/Trips",
    { key: apiConfig.key, uid: apiConfig.uid, aqid: apiConfig.aqid },
    {
      useApi16: true,
    }
  );
  try {
    return await fetch(url, {
      method: "GET",
      ...getTripRequestContents(undefined, true),
    }).then((response) => handleListResponse(response, onCompleteCallback));
  } catch (err) {
    logFetchError(err);
    throw err;
  }
}

export async function getTripPlan(
  onCompleteCallback: onSingleCompleteCallback,
  apiConfig: ApiConfig,
  id?: string
) {
  if (!id) {
    return undefined;
  }

  const url = buildUrl(
    apiConfig.endpoint16Base + "/Trip/" + id,
    { key: apiConfig.key, uid: apiConfig.uid, aqid: apiConfig.aqid },
    {
      useApi16: true,
    }
  );
  try {
    return await fetch(url, {
      method: "GET",
      ...getTripRequestContents(),
    }).then((response) => handleSingleResponse(response, onCompleteCallback));
  } catch (err) {
    logFetchError(err);
    throw err;
  }
}

export async function saveTripPlan(
  apiConfig: ApiConfig,
  body: TripPlannerDetails,
  hasAuth?: boolean
) {
  const url = buildUrl(
    apiConfig.endpoint16Base + "/Trip",
    { key: apiConfig.key, uid: apiConfig.uid, aqid: apiConfig.aqid },
    {
      useApi16: true,
    }
  );
  try {
    return await fetch(url, {
      method: "POST",
      ...getTripRequestContents(body, hasAuth),
    }).then((response) => response.json());
  } catch (err) {
    logFetchError(err);
    throw err;
  }
}

export async function updateTripPlan(
  apiConfig: ApiConfig,
  body: TripPlanWithID,
  hasAuth?: boolean
) {
  const url = buildUrl(
    apiConfig.endpoint16Base + "/Trip/" + body.id,
    { key: apiConfig.key, uid: apiConfig.uid, aqid: apiConfig.aqid },
    {
      useApi16: true,
    }
  );
  try {
    if (!body.id) throw new Error("No trip id provided");
    return await fetch(url, {
      method: "PUT",
      ...getTripRequestContents(body, hasAuth),
    }).then(handleSingleResponse);
  } catch (err) {
    logFetchError(err);
    throw err;
  }
}

export async function deleteTripPlan(
  apiConfig: ApiConfig,
  id: string,
  onDeleteCallback?: onDeleteCallback,
  hasAuth?: boolean
) {
  const url = buildUrl(
    apiConfig.endpoint16Base + "/Trip/" + id,
    { key: apiConfig.key, uid: apiConfig.uid, aqid: apiConfig.aqid },
    {
      useApi16: true,
    }
  );
  try {
    if (!id) throw new Error("No trip id provided");
    return await fetch(url, {
      method: "DELETE",
      ...getTripRequestContents(undefined, hasAuth),
    }).then((response) => handleDeleteResponse(response, onDeleteCallback));
  } catch (err) {
    logFetchError(err);
    throw err;
  }
}

async function handleListResponse(
  response: Response,
  onCompleteCallback?: onListCompleteCallback
) {
  let error;
  let result;

  if (!response.ok || response.type === "error") {
    error = new Error(TRIPS_API_ERROR[response.status]);
  } else {
    const data = await response.json();
    result = data.length ? data.map(tripResponseToTripAttributes) : undefined;
  }

  if (onCompleteCallback) {
    onCompleteCallback(result, error);
  }

  if (error) {
    throw error;
  } else {
    logApiInfo("Success");
  }
  return result;
}

async function handleSingleResponse(
  response: Response,
  onCompleteCallback?: onSingleCompleteCallback
) {
  let error;
  let result;

  if (!response.ok || response.type === "error") {
    error = new Error(TRIPS_API_ERROR[response.status]);
  } else {
    const data = await response.json();
    result = data ? tripResponseToTripPlannerDetails(data) : undefined;
  }

  if (onCompleteCallback) {
    onCompleteCallback(result, error);
  }

  if (error && response.status !== 403) {
    throw error;
  } else if (response.status !== 403) {
    logApiInfo("Success");
  }

  if (response.status === 403) {
    // TODO: Handle 403 https://rome2rio.atlassian.net/browse/DAP-1485
    sendAnalyticsNonInteractionEvent("TripPlanner", "Open:Forbidden");
  }

  return result;
}

async function handleDeleteResponse(
  response: Response,
  onDeleteCallback?: onDeleteCallback
) {
  let error;
  let success;

  if (!response.ok || response.type === "error") {
    error = new Error(TRIPS_API_ERROR[response.status]);
  }

  if (response.status === 204) {
    success = true;
  }

  if (onDeleteCallback) {
    onDeleteCallback(success, error);
  }

  if (error) {
    throw error;
  } else {
    logApiInfo("Success");
  }

  return success;
}

/**
 * If a user tries to fetch a:
 * - trip plan that doesn't exist
 * - trip plan that they don't have access to
 *
 * We remove the ID from local storage.
 * This is to ensure the user's changes will be saved to a new trip plan they have access to.
 */
export function handleTripPlanError({
  dispatcher,
  errorMessage,
  onAuthError,
  on404Error,
}: {
  dispatcher: Dispatch<Action>;
  errorMessage?: string;
  onAuthError?: () => void;
  on404Error?: () => void;
}) {
  if (!errorMessage) return;

  let action: Action["type"] | null = null;
  const errorCode = getErrorCodeFromMessage(errorMessage);

  switch (errorCode) {
    case "403":
      action = "REMOVE_ID";
      onAuthError?.();
      break;
    case "404":
      action = "REMOVE_ID";
      on404Error?.();
      break;
    default:
      break;
  }

  if (action) {
    dispatcher({
      type: action,
    });
  }
}

export function getErrorCodeFromMessage(message: string) {
  const codeIndex = Object.values(TRIPS_API_ERROR).indexOf(message);
  return Object.keys(TRIPS_API_ERROR)[codeIndex];
}

function logApiInfo(status: "Success" | "Error", message?: string) {
  sendAnalyticsNonInteractionEvent(
    "TripPlanner",
    `TripRequest:${status}`,
    message
  );
}

function logFetchError(error: unknown) {
  let message = error instanceof Error ? error.message : undefined;
  logApiInfo("Error", message);
}

export function getTripCacheKey(
  tripPlannerDetails: TripPlannerDetails,
  hasAuth: boolean
) {
  const transportKey = Object.keys(tripPlannerDetails.transport);
  const placeKey = tripPlannerDetails.places
    .map((place) => place.canonicalName)
    .join("-");
  return [placeKey, transportKey, hasAuth];
}
