import { defineMessages } from "react-intl";

const messages = defineMessages({
  undo: {
    id: "tripPlanner.undo",
    defaultMessage: "Undo",
    description: "Undo button text for the trip planner",
  },
  redo: {
    id: "tripPlanner.redo",
    defaultMessage: "Redo",
    description: "Redo button text for the trip planner",
  },
});

export default messages;
