import { darken } from "polished";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { CcpaStatus } from "src/privacy-regulations/hooks/useCcpaManagement";
import { useApplicablePrivacyRegs } from "src/privacy-regulations/hooks/useApplicablePrivacyRegs";
import { useFeature } from "../../../feature/useFeature";
import { Icon } from "../../../components/Icon/Icon";
import { ChevronRight } from "../../../svg/ChevronRight";
import {
  color,
  fontSize,
  fontWeight,
  shadowColor,
  spacing,
} from "../../../theme";
import { localeToLanguageCode } from "../../../utils/conversions/languageCode";
import { distanceSystemMessages } from "../../../utils/distanceSystem";
import useUser from "../../../utils/hooks/useUser";
import { supportedLanguageNames } from "../../../utils/language";
import { timeFormatMessages } from "../../TimeFormatScreen/TimeFormatScreen.messages";
import { ExtraDetails } from "../ExtraInfo/ExtraDetails";
import { preferenceStyle } from "../PreferenceScreen";
import messages from "./UserPreferences.messages";

type Props = {
  onLanguageClick: () => void;
  onCurrencyClick: () => void;
  onDistanceClick: () => void;
  onTimeFormatClick: () => void;
  onDebugClick: () => void;
  onGDPRConsentClick: () => void;
  onUsPrivacyConsentClick: () => void;
  ccpaStatus: CcpaStatus;
};

export function UserPreferences(props: Props) {
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const { currencyCode, distanceSystem, prefers12Hour } = useUser();
  const isDebug = useFeature("Debug");

  const supportedTimeFormat = prefers12Hour
    ? intl.formatMessage(timeFormatMessages.timeFormat12H)
    : intl.formatMessage(timeFormatMessages.timeFormat24H);

  const privacyRegulations = useApplicablePrivacyRegs();
  const gdprApplies = privacyRegulations?.includes("gdpr");
  const usConsentApplies = privacyRegulations?.includes("usprivacy");

  return (
    <UserPreferencesWrapper>
      <UserPreference
        title={intl.formatMessage(messages.language)}
        value={supportedLanguageNames[languageCode]}
        onClick={props.onLanguageClick}
        labelId="language-preference-title"
      />
      <UserPreference
        title={intl.formatMessage(messages.currency)}
        value={currencyCode}
        onClick={props.onCurrencyClick}
        labelId="currency-preference-title"
      />
      <UserPreference
        title={intl.formatMessage(messages.distance)}
        value={intl.formatMessage(distanceSystemMessages[distanceSystem])}
        onClick={props.onDistanceClick}
        labelId="distance-preference-title"
      />
      <UserPreference
        title={intl.formatMessage(messages.timeFormat)}
        value={supportedTimeFormat}
        onClick={props.onTimeFormatClick}
        labelId="time-format-preference-title"
      />
      {isDebug && (
        <UserPreference
          title={intl.formatMessage(messages.debugTitle)}
          value={intl.formatMessage(messages.debugDescription)}
          onClick={props.onDebugClick}
          labelId={messages.debugTitle.id}
        />
      )}
      {gdprApplies && (
        <UserPreferenceWrapper
          role="button"
          tabIndex={0}
          onClick={props.onGDPRConsentClick}
        >
          <Category>{intl.formatMessage(messages.consent)}</Category>
          <Icon size="md">
            <ChevronRight
              title={intl.formatMessage(messages.change)}
              tint="iconOnLight"
            />
          </Icon>
        </UserPreferenceWrapper>
      )}
      {usConsentApplies && props.ccpaStatus === "NOT_OPTED_OUT" && (
        <UserPreferenceWrapper
          role="button"
          tabIndex={0}
          onClick={props.onUsPrivacyConsentClick}
        >
          <Category>{intl.formatMessage(messages.doNotShare)}</Category>
          <Icon size="md">
            <ChevronRight
              title={intl.formatMessage(messages.change)}
              tint="iconOnLight"
            />
          </Icon>
        </UserPreferenceWrapper>
      )}
      <ExtraDetails />
    </UserPreferencesWrapper>
  );
}

type UserPreferenceProps = {
  onClick: () => void;
  title: string;
  value: string;
  labelId: string;
};

const UserPreference = (props: UserPreferenceProps) => {
  const intl = useIntl();

  return (
    <UserPreferenceWrapper
      role="button"
      tabIndex={0}
      onClick={props.onClick}
      aria-label={`${props.title}: ${props.value}`}
      aria-describedby={props.labelId}
    >
      <Category>{props.title}</Category>
      <Value id={props.labelId}>{props.value}</Value>
      <Icon size="md">
        <ChevronRight
          title={intl.formatMessage(messages.change)}
          tint="iconOnLight"
        />
      </Icon>
    </UserPreferenceWrapper>
  );
};

const UserPreferencesWrapper = styled.div`
  box-shadow: 0px 1px 6px 0px ${shadowColor.cell};
  box-shadow: none;
  padding: ${spacing.xl};
`;

const UserPreferenceWrapper = styled.div`
  background-color: ${color.white};
  padding: ${spacing.xl};
  position: relative;
  border-bottom: 1px solid ${color.grey2};
  ${preferenceStyle};

  &:active {
    background-color: ${darken(0.05, color.white)};
    -webkit-tap-highlight-color: ${darken(0.05, color.white)};
  }
`;

const Category = styled.h3`
  color: ${color.cod};
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  flex-grow: 2;
`;

const Value = styled.div`
  color: ${color.cod};
  margin-top: 0;
  font-weight: ${fontWeight.light};
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  white-space: nowrap;
`;
