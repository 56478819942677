import { makeFeatureSearchParam } from "../../feature/internal/searchParams/useSearchParamsFeatures";
import { useFeatureConfig } from "../../feature/useFeatureConfig";
import { encodeSearchParamsForUrl } from "../encodeSearchParamsForUrl";
import {
  getBackendFeatureOverrideString,
  useBackendCookieFeatures,
} from "./useBackendFeatures";
import { useTypedLocation } from "./useTypedLocation";

// Generate a permalink of the current page that contains search parameter overrides for the current state of features.
export function useGetPermaLink() {
  // Currently frontend & backend features only
  const location = useTypedLocation();

  // This will make the permalink override every feature, even if it is the default.
  // We need to do this because experiment variants might alter features in different sessions.
  const frontendFeatures = useFeatureConfig();

  // Want to use the cookie features so visitors without the same cookies get the same experience.
  // We don't need to use the `backendFeatures` query param, because anything in there will have
  // already been put into the cookie (useSetFeatureCookie.ts).
  const backendCookieFeatures = useBackendCookieFeatures();

  const frontendFeaturesSlug = makeFeatureSearchParam(frontendFeatures);
  const backendFeaturesSlug = getBackendFeatureOverrideString(
    backendCookieFeatures
  );

  const searchParams = new URLSearchParams(location.search);
  searchParams.set("features", frontendFeaturesSlug);
  searchParams.set("backendFeatures", backendFeaturesSlug);

  const url = new URL(window.location.href);
  url.search = encodeSearchParamsForUrl(searchParams) ?? "";

  return url;
}
