import { defineMessages } from "react-intl";

const messages = defineMessages({
  //- exact and baseline
  minutes: {
    id: "durations.minutes",
    description: "A journey duration in minutes",
    defaultMessage: "{minutes}min",
  },
  hours: {
    id: "durations.hours",
    description: "A journey duration that has exactly some number of hours",
    defaultMessage: "{hours}h",
  },
  hoursAndMinutes: {
    id: "durations.hoursAndMinutes",
    description: "A journey duration in hours and minutes",
    defaultMessage: "{hours}h {minutes}min",
  },
  daysAndHours: {
    id: "durations.daysAndHours",
    description:
      "A journey duration in days and hours, with a minimum of 2 days",
    defaultMessage: "{days}d {hours}h",
  },
});

export default messages;
