import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getRouteCanonicalFromHash } from "src/utils/location/routeCanonicalFromHash";
import { getRouteIndexFromHash } from "src/utils/location/routeIndexFromHash";
import { useTripSearchResponse } from "./useTripSearchResponse";

/**
 * @deprecated use getRouteCanonicalFromHash instead
 */
export function useTripRouteCanonical(): string | undefined {
  const location = useTypedLocation();
  return getRouteCanonicalFromHash(location.hash);
}

export function useTripRouteIndex(): number | undefined {
  const { tripSearchResponse } = useTripSearchResponse();
  const location = useTypedLocation();
  const index = getRouteIndexFromHash(location.hash, tripSearchResponse);
  return index !== undefined && index >= 0 ? index : undefined;
}
