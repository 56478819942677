type ExperimentDefinition = {
  experimentId: string;
  variants: string[];
};

// Maps experiments to their ids and variant definitions as defined in the backend.
// The order of the variants matters and should match the order on the backend.
//
// See:
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Experiments/ExperimentName.cs:
// * https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio.common/Experiments/ExperimentDefinitions.cs
export const experimentDefinitions = {
  ExploreSearchResultComparison: {
    experimentId: "89",
    variants: ["Baseline", "Tags", "Sections"],
  },
  ApproxDurationFormatExperiment: {
    experimentId: "8y",
    variants: [
      "Baseline",
      "Rounded",
      "RoundedRange",
      "Range",
      "RoundedFraction",
    ],
  },
  ExploreSplitRouteList: {
    experimentId: "9i",
    variants: ["Baseline", "SplitRouteList"],
  },
  MultiPassengers: {
    experimentId: "9x",
    variants: [
      "Baseline",
      "MultiPassengersBaseline",
      "MultiPassengersLiveControl",
      "MultiPassengersLive",
    ],
  },
  TimelineInterchangeUI: {
    experimentId: "9y",
    variants: [
      "Baseline",
      "Transfer",
      "Distance",
      "Duration",
      "TransferWithDistance",
      "TransferWithDuration",
    ],
  },
  BookableTransitUrls: {
    experimentId: "a1",
    variants: ["Baseline", "BookableTransitUrls"],
  },
  TripPlannerMVP: {
    experimentId: "bc",
    variants: ["Baseline", "MVP", "Baseline2", "MVP2"],
  },
  ShortNav: {
    experimentId: "c6",
    variants: ["Baseline", "Short"],
  },
  DesktopTripPlanner: {
    experimentId: "c7",
    variants: ["Baseline", "DesktopTrips", "Baseline2", "DesktopTrips2"],
  },
  TripsUXRefinements: {
    experimentId: "cj",
    variants: ["Baseline", "TripsUXRefinements"],
  },
  OneTimePasswordRolloutExperiment: {
    experimentId: "d4",
    variants: [
      "Baseline",
      "OneTimePasswordRollout",
      "Baseline2",
      "OneTimePasswordRollout2",
    ],
  },
  USStatesConsentExperiment: {
    experimentId: "e3",
    variants: ["Baseline", "USStatesConsent"],
  },
  HotelsStrikethroughDeals2: {
    experimentId: "e5",
    variants: [
      "Baseline",
      "StrikethroughDeals",
      "StrikethroughDealsWithDefaultDates",
    ],
  },
  ServiceSummaryScreenExperiment: {
    experimentId: "e9",
    variants: [
      "Baseline",
      "ServiceSummaryScreen",
      "Baseline",
      "ServiceSummaryScreen",
    ],
  },
  TripsAsCoreHoldback5050: {
    experimentId: "ef",
    variants: [
      "Baseline",
      "FullExperience",
      "Baseline",
      "FullExperience",
      "Baseline",
      "FullExperience",
    ],
  },
  TripPanePromo: {
    experimentId: "f4",
    variants: ["Baseline", "NoColor", "Color"],
  },
  DesktopInlineSerpAd90: {
    experimentId: "hb",
    variants: ["Baseline", "InlineAd"],
  },
  FavoritesButton: {
    experimentId: "g3",
    variants: ["Baseline", "FavoritesButton"],
  },
  HSBInput: {
    experimentId: "gf",
    variants: ["Baseline", "Prompt", "Input"],
  },
  HotelsThinMobileCard: {
    experimentId: "gv",
    variants: ["Baseline", "ThinMobileCard"],
  },
  HotelsScrollNudge: {
    experimentId: "h0",
    variants: ["Baseline", "ScrollNudge"],
  },
  SimpleSearchV3: {
    experimentId: "hc",
    variants: ["Baseline", "SimpleSearchV3"],
  },
  PreDestination: {
    experimentId: "h8",
    variants: ["Baseline", "TransitInfo"],
  },
  TimeOfDayFilter: {
    experimentId: "he",
    variants: ["Baseline", "TimeOfDayFilter", "Baseline", "TimeOfDayFilter"],
  },
  ClearerMessageV2: {
    experimentId: "hg",
    variants: ["Baseline", "WithOperatorHeading", "WithoutOperatorHeading"],
  },
  HotelsRefineSearch: {
    experimentId: "hh",
    variants: [
      "Baseline",
      "SuburbTop",
      "SuburbMid",
      "AttractionTop",
      "AttractionMid",
    ],
  },
  TransportCardUxV2: {
    experimentId: "hk",
    variants: ["Baseline", "TransportCardUx", "Baseline", "TransportCardUx"],
  },
  LPINPDiagnosis: {
    experimentId: "hl",
    variants: ["Baseline", "NoCMP", "NoAds", "NoDataSharing"],
  },
  LPHotelsMapWidget: {
    experimentId: "hn",
    variants: ["Baseline", "LPHotelsPromoMapWidget"],
  },
  LPHotelsBanner: {
    experimentId: "ho",
    variants: ["Baseline", "LPHotelsPromoBannerA", "LPHotelsPromoBannerB"],
  },
} as const satisfies Record<string, ExperimentDefinition>;

export type ExperimentName = keyof typeof experimentDefinitions;

export type ExperimentVariant<T extends ExperimentName> =
  | (typeof experimentDefinitions)[T]["variants"][number]
  | "None";

// Given an experiment id it returns the human-readable string name for it or
// undefined if the experiment hasn't been added to experimentDefinitions above.
export function getExperimentNameFromId(
  experimentId: string
): ExperimentName | undefined {
  return experimentIdMap[experimentId];
}

// Given an experiment name and variant id it returns the human-readable string
// name for it or undefined if the experiment hasn't been added to
// `experimentDefinitions` above.
export function getExperimentVariantFromExperiment<T extends ExperimentName>(
  experiment: T,
  variantId: number
): ExperimentVariant<T> | undefined {
  const variantDefinition = experimentDefinitions[experiment]?.variants;
  if (!variantDefinition) {
    return undefined;
  }

  if (variantId === 0) {
    return "None";
  } else {
    return variantDefinition[variantId - 1];
  }
}

// Create a map of Experiment ids -> Experiment names once so we can efficiently
// get the ExperimentName for each id.
const experimentNames = Object.keys(experimentDefinitions) as ExperimentName[];
const experimentIdMap: {
  [id: string]: ExperimentName | undefined;
} = experimentNames.reduce((previousValue, currentValue) => {
  const experimentId = experimentDefinitions[currentValue].experimentId;
  return {
    ...previousValue,
    [experimentId]: currentValue,
  };
}, {});
