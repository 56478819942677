import { uuid } from "../utils/uuid";
import { safeSessionStorage } from "../utils/safeLocalStorage";

const BROWSER_SESSION_ID_KEY = "BROWSER_SESSION_KEY";
function getBrowserSessionId() {
  const browserSessionId = safeSessionStorage.getItem(BROWSER_SESSION_ID_KEY);
  if (!browserSessionId) {
    const newBrowserSessionId = uuid();
    safeSessionStorage.setItem(BROWSER_SESSION_ID_KEY, newBrowserSessionId);
    return newBrowserSessionId;
  } else {
    return browserSessionId;
  }
}

let BROWSER_DOM_ID: String | undefined;
export function browserDomId() {
  if (!BROWSER_DOM_ID) {
    BROWSER_DOM_ID = uuid();
  }
  return BROWSER_DOM_ID;
}

let BROWSER_SESSION_ID: String | undefined;
export function browserSessionId() {
  if (!BROWSER_SESSION_ID) {
    BROWSER_SESSION_ID = getBrowserSessionId();
  }
  return BROWSER_SESSION_ID;
}
