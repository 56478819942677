import { SvgProps, useColor } from "./common";

export default function TransferIcon(props: SvgProps) {
  const tintColor = useColor(props.tint);
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{props.title}</title>
      <circle cx="8" cy="8" r="5" stroke={tintColor} strokeWidth="2" />
    </svg>
  );
}
