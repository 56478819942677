import { useRef } from "react";
import styled from "styled-components";
import { useSignInWithGoogleButton } from "../../sdks/GoogleAuthSdk";

type Props = {
  onClick: () => void;
};
export function GoogleSignIn(props: Props) {
  const googleSignInParentElement = useRef<HTMLDivElement>(null);
  const state = useSignInWithGoogleButton(
    googleSignInParentElement,
    props.onClick
  );
  return (
    <GoogleSignInContainer
      ref={googleSignInParentElement}
      key={`${state}`}
    ></GoogleSignInContainer>
  );
}

const GoogleSignInContainer = styled.div`
  width: 100%;
  height: 44px;
  & iframe {
    // There is a unexpected margin from google sign in button that causes the button to be moved up by a negative margin.
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
`;
