import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useIntl } from "react-intl";
import { localeToLanguageCode } from "../conversions/languageCode";
import { getPath } from "../url";
import { useTypedLocation } from "./useTypedLocation";

// usePath ensures that the URL is correctly formatted and contains the
// canonicals and language codes required. The language code is included in the
// URL if the language is not en.
export function usePath(
  originCanonical?: string,
  destinationCanonical?: string
) {
  const navigate = useNavigate();
  const location = useTypedLocation();
  const intl = useIntl();
  const origin = originCanonical ?? "";
  const destination = destinationCanonical ?? "";
  const languageCode = localeToLanguageCode(intl.locale);
  const { hash, state, search } = location;

  const updateHistoryRef = useRef<(pathname: string) => void>();
  useEffect(() => {
    updateHistoryRef.current = (pathname: string) => {
      navigate(
        {
          pathname,
          hash,
          search,
        },
        { replace: true, state }
      );
    };
  }, [navigate, hash, state, search]);

  useEffect(() => {
    if (origin && destination && isUpdateRef.current) {
      const path = getPath(origin, destination, languageCode);
      updateHistoryRef.current && updateHistoryRef.current(path);
    }
  }, [origin, destination, languageCode]);

  // This Ref is true once the first update has been executed, it's used
  // to ensure we don't replace the initial location as part of the first
  // update.
  const isUpdateRef = useRef(false);
  useEffect(() => {
    isUpdateRef.current = true;
  }, []);
}
