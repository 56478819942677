import styled from "styled-components";
import { color, fontSize, spacing } from "../../theme";

export const InputBase = styled.input`
  box-sizing: border-box;
  border: 1px solid ${color.n30};
  border-radius: 4px;
  font-size: ${fontSize.body};
  padding: ${spacing.lg} ${spacing.xl};
  min-height: 48px;
  width: 100%;

  // Remove appearance because we don't want browsers to try to style our inputs.
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;

  &:active,
  :focus {
    border: 1px solid ${color.n50};
  }

  &::placeholder {
    color: ${color.n60};
  }
`;
