import Animal from "src/svg/transit/Animal.svg?react";
import Bike from "src/svg/transit/Bike.svg?react";
import Bus from "src/svg/transit/Bus.svg?react";
import BusFerry from "src/svg/transit/BusFerry.svg?react";
import CableCar from "src/svg/transit/CableCar.svg?react";
import Car from "src/svg/transit/Car.svg?react";
import CarFerry from "src/svg/transit/CarFerry.svg?react";
import Circle from "src/svg/Circle.svg?react";
import Ferry from "src/svg/transit/Ferry.svg?react";
import Helicopter from "src/svg/transit/Helicopter.svg?react";
import Plane from "src/svg/transit/Plane.svg?react";
import Rideshare from "src/svg/transit/Rideshare.svg?react";
import Taxi from "src/svg/transit/Taxi.svg?react";
import Towncar from "src/svg/transit/Towncar.svg?react";
import Train from "src/svg/transit/Train.svg?react";
import Tram from "src/svg/transit/Tram.svg?react";
import Unknown from "src/svg/transit/Unknown.svg?react";
import Walk from "src/svg/transit/Walk.svg?react";
import BusMultiple from "src/svg/transit/BusMultiple.svg?react";
import PlaneMultiple from "src/svg/transit/PlaneMultiple.svg?react";
import CableCarMultiple from "src/svg/transit/CableCarMultiple.svg?react";
import CarMultiple from "src/svg/transit/CarMultiple.svg?react";
import FerryMultiple from "src/svg/transit/FerryMultiple.svg?react";
import HelicopterMultiple from "src/svg/transit/HelicopterMultiple.svg?react";
import RideshareMultiple from "src/svg/transit/RideshareMultiple.svg?react";
import TaxiMultiple from "src/svg/transit/TaxiMultiple.svg?react";
import TrainMultiple from "src/svg/transit/TrainMultiple.svg?react";
import TramMultiple from "src/svg/transit/TramMultiple.svg?react";
import { Color, useColor } from "src/svg/common";
import { Mode } from "src/utils/types/mode";
import React from "react";

export const transitIcons = {
  animal: Animal,
  bike: Bike,
  bus: Bus,
  busferry: BusFerry,
  cablecar: CableCar,
  car: Car,
  carferry: CarFerry,
  ferry: Ferry,
  helicopter: Helicopter,
  multi: (props: React.SVGProps<SVGSVGElement>) => (
    <Circle title="multi" {...props} />
  ),
  plane: Plane,
  rideshare: Rideshare,
  shuttle: Bus,
  taxi: Taxi,
  towncar: Towncar,
  train: Train,
  tram: Tram,
  unknown: Unknown,
  walk: Walk,
};

export const transitMultipleIcons = {
  bus: BusMultiple,
  cablecar: CableCarMultiple,
  car: CarMultiple,
  ferry: FerryMultiple,
  helicopter: HelicopterMultiple,
  plane: PlaneMultiple,
  rideshare: RideshareMultiple,
  taxi: TaxiMultiple,
  train: TrainMultiple,
  tram: TramMultiple,
};

type TransitIconProps = {
  transitMode: Mode;
  multiple?: boolean;
  tint: Color;
  className?: string;
};

export function TransitIcon({
  transitMode,
  multiple,
  tint,
  className,
}: TransitIconProps) {
  const color = useColor(tint);

  if (multiple && transitMode in transitMultipleIcons) {
    const TransitIcon =
      transitMultipleIcons[transitMode as keyof typeof transitMultipleIcons];
    return <TransitIcon {...{ color, className }} />;
  } else {
    const TransitIcon = transitIcons[transitMode];
    return <TransitIcon {...{ color, className }} />;
  }
}
