import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { TripPlannerMobileMap } from "src/components/Map/TripPlannerMap/TripPlannerMobileMap";
import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { useTripTransportIndex } from "src/utils/hooks/useTripTransportIndex";
import styled, { css } from "styled-components";
import { useFeature } from "src/feature/useFeature";
import { primitive } from "src/design-system/tokens/color";
import { Button } from "src/design-system/components/Button/Button";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { Icon } from "src/design-system/components/Icon/Icon";
import { ChevronLeft } from "src/svg/designSystem/ChevronLeft";
import { useAnalyticsCoreExperience } from "../../analytics/useAnalyticsCoreExperience/useAnalyticsCoreExperience";
import { color, spacing } from "../../theme";
import { useAnalyticsPageView } from "../../utils/hooks/useAnalyticsPageView";
import { desktopLayout, useLayout } from "../../utils/hooks/useLayout";
import useSearch from "../../utils/hooks/useSearch";
import { useTheme } from "../../utils/hooks/useTheme";
import {
  ReturnStage,
  useTypedLocation,
} from "../../utils/hooks/useTypedLocation";
import { TripPlannerOnboarding } from "./Onboarding/TripPlannerOnboarding";
import messages from "./TripPlannerScreen.messages";
import { TripPlannerScreenLoaded } from "./TripPlannerScreenLoaded";
import { useTripPlanOnboarding } from "./hooks/useTripPlanOnboarding";
import { useTripPlannerContext } from "./hooks/useTripPlannerContext";

import { MemoizedTripPlannerDrawer } from "./Drawer/TripPlannerDrawer";
import LargeHeading from "./Heading/LargeHeading";
import SmallHeading from "./Heading/SmallHeading";
import { StackedNavigation } from "./StackedNavigation/StackedNavigation";
import { useTripPlannerComponents } from "./hooks/useTripPlannerComponents";
import { TripPlannerSkeleton } from "./TripPlannerSkeleton";
import { SavedTripsScreen } from "./SavedTripsScreen/SavedTripsScreen";
import { CreateTripScreen } from "./CreateTripScreen/CreateTripScreen";
import { getScreenKey } from "./util/getScreenKey";
import { TripPlannerAutocomplete } from "./TripPlannerAutocomplete/TripPlannerAutocomplete";
import LazyReorderTrip from "./ReorderTrip/ReorderTripScreen/LazyReorderTrip";

type Props = {
  returnsFlowLocation?: ReturnStage;
};

export function TripPlannerScreen({ returnsFlowLocation }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const layout = useLayout();
  const transportIndex = useTripTransportIndex();
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();

  const { tripPlannerDetails, tripRoutes, apiState, isMultiTrip } =
    useTripPlannerContext();
  const { setOnboardingStep, onboardingStep } = useTripPlanOnboarding(
    apiState,
    true
  );
  const { searchResponse } = useSearch();
  const components = useTripPlannerComponents(onboardingStep);
  const theme = useTheme();
  theme.breadcrumbs = theme.tripHeader;
  const showLargeHeading = isMultiTrip && layout !== "mobile";
  const isTripsV2OnMobile =
    useFeature("TripsV2OnMobile") && layout === "mobile";
  const isDesktop = layout === "desktop";
  const screenKey = getScreenKey(location.hash);
  const isSavedUrl = screenKey === "saved";
  const isCreateUrl = screenKey === "create";

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "TripPlanner",
    undefined,
    components
  );

  useAnalyticsCoreExperience({
    experienceHash: `${location.pathname}:r${location.hash}/`,
    analyticsLabel: "TripPlanner Screen",
  });

  function startTrainingCallback() {
    setOnboardingStep("training");
  }

  function hideTrainingCallback() {
    setOnboardingStep("complete");
  }

  const onboardingProps = {
    startTrainingCallback,
    hideTrainingCallback,
    components,
    onboardingStep,
  };

  function handleShowSavedTrips() {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  function tripHeading() {
    if (isTripsV2OnMobile || isDesktop) {
      return (
        <SmallHeading
          titleText={intl.formatMessage(messages.waysToTravel, {
            ways: tripRoutes.queries[0]?.data?.routes.length,
            destination: tripPlannerDetails.places[1]?.shortName,
          })}
        />
      );
    } else {
      return <SmallHeading titleText={intl.formatMessage(messages.myTrip)} />;
    }
  }

  function getColor() {
    if (layout === "desktop") {
      return primitive.neutral_400;
    } else if (isTripsAsCoreHoldback && (!isTripsV2OnMobile || isCreateUrl)) {
      return color.white;
    } else {
      return color.grey1;
    }
  }

  const TripPlannerContent = (
    <>
      {showLargeHeading && <LargeHeading />}
      <Content
        $isReordering={components.includes("ReorderPage")}
        isTripsAsCoreHoldback={isTripsAsCoreHoldback}
        $color={getColor()}
      >
        {!isSavedUrl && !isCreateUrl && isTripsV2OnMobile && (
          <ButtonWrapper>
            <Button
              onPress={handleShowSavedTrips}
              size="small"
              variant="subtle"
            >
              <Icon size="small">
                <ChevronLeft tint="cod" />
              </Icon>{" "}
              {intl.formatMessage(messages.savedTrips)}
            </Button>
          </ButtonWrapper>
        )}

        {isCreateUrl || isSavedUrl ? (
          <>
            {isCreateUrl && <CreateTripScreen />}
            {isSavedUrl && <SavedTripsScreen />}

            {components.includes("TripPlannerEdit") && (
              <TripPlannerAutocomplete onSelectOption={() => {}} />
            )}
          </>
        ) : (
          <>
            {!showLargeHeading &&
              components.includes("TripPlannerTitle") &&
              tripHeading()}

            {components.includes("TripPlannerMobileMap") && (
              <TripPlannerMobileMap />
            )}

            {components.includes("TripPlannerCards") &&
              apiState.fetchState !== "fetching" &&
              !components.includes("ReorderPage") && (
                <TripPlannerScreenLoaded />
              )}

            {components.includes("ReorderPage") && (
              <LazyReorderTrip places={tripPlannerDetails.places} />
            )}

            {components.includes("TripPlannerSkeleton") && (
              <TripPlannerSkeleton />
            )}

            {(components.includes("TripPlannerIntroduction") ||
              components.includes("TripPlannerTraining")) && (
              <TripPlannerOnboarding {...onboardingProps} />
            )}
            {isTripsAsCoreHoldback && <Divider />}
          </>
        )}
      </Content>
    </>
  );

  if (layout === "desktop") {
    return (
      <StackedNavigation
        {...{ transportIndex, returnsFlowLocation, TripPlannerContent }}
      />
    );
  } else {
    return (
      <>
        {TripPlannerContent}
        {transportIndex !== undefined && (
          <>
            <MemoizedTripPlannerDrawer
              returnsFlowLocation={returnsFlowLocation}
              transportIndex={transportIndex}
            />
          </>
        )}
      </>
    );
  }
}

const Content = styled.div<{
  $isReordering?: boolean;
  isTripsAsCoreHoldback: boolean;
  $color?: string;
}>`
  width: 100%;
  background-color: ${(props) => props.$color};
  position: relative;
  padding: ${spacing.xxl} ${spacing.xl} ${spacing.xl};
  // This is the height minus nav, fixed button and safari menu
  min-height: calc(100vh - 55px - 44px - env(safe-area-inset-bottom));

  ${desktopLayout} {
    max-width: 100%;
    min-height: unset;
  }

  ${({ $isReordering }) =>
    $isReordering &&
    css`
      padding-bottom: 64px;

      ${desktopLayout} {
        min-height: calc(100vh - 56px);
      }
    `}
`;

const Divider = styled.hr`
  border: none;
  height: ${spacing.xl};

  // These props ensure the divider sits ontop of the pane left/right padding.
  position: relative;
  width: calc(100% + ${spacing.xl} * 2);
  left: -${spacing.xl};

  background: ${color.n10};
  margin-top: ${spacing.xxxxl};
  margin-bottom: ${spacing.xxxl};
  border-top: 1px solid ${color.n30};
  border-bottom: 1px solid ${color.n30};
`;

const ButtonWrapper = styled.div`
  margin-left: -${spacing.lg};
  margin-bottom: ${spacing.lg};
`;
