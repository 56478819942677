import { Place, SearchResponse } from "../../api/SearchResponse";
import { originPlaceFromRoute, originPlaceFromSegment } from "./place";

export function originUtcOffsetFromRoute(
  response: SearchResponse,
  routeIndex: number
): number {
  const originRoute = originPlaceFromRoute(response, routeIndex);
  return utcOffsetFromPlace(response, originRoute);
}

export function originUtcOffsetFromSegment(
  response: SearchResponse,
  segmentIndex: number
): number {
  const originSegment = originPlaceFromSegment(response, segmentIndex);
  return utcOffsetFromPlace(response, originSegment);
}

export function utcOffsetFromPlace(response: SearchResponse, place: Place) {
  const timeZoneName = place.timeZone;

  return utcOffsetFromTimeZoneName(response, timeZoneName);
}

export function utcOffsetFromTimeZoneName(
  response: SearchResponse,
  timeZoneName: string
): number {
  const timeZone = response.timeZones.find((tz) => tz.name === timeZoneName);

  // This function should only be used when we know the timeZoneName exists in the timeZones of the response
  if (!timeZone) {
    throw new Error(
      `Time zone of ${timeZoneName} not in search response timeZone list`
    );
  }

  return timeZone.utcOffsetHours;
}
