import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "heading.tripItinerary",
    defaultMessage: "Trip Itinerary",
    description: "The heading for the route screen",
  },
});

export default messages;
