import { useQuery } from "@tanstack/react-query";
import { ONE_DAY_IN_MILLISECONDS } from "src/utils/conversions/time";
import getDiscoveryPlaces from "../util/getDiscoveryPlaces";
import { City } from "../data/places";

export default function useDiscover(requestOrigin?: {
  canonicalName: string;
  lat: number;
  lng: number;
}) {
  return useQuery<City[]>({
    queryKey: ["discovery", requestOrigin],
    queryFn: () => getDiscoveryPlaces(requestOrigin),
    staleTime: ONE_DAY_IN_MILLISECONDS,
    gcTime: ONE_DAY_IN_MILLISECONDS,
  });
}
