import { Mode, transitModes } from "../../utils/types/mode";

export function compressMode(mode?: Mode | string) {
  if (mode === undefined) {
    return "na";
  }

  // Casting the mode is fine here because we are explicitly trying to determine
  // if mode is present in the set of transitModes.
  if (!transitModes.includes(mode as Mode)) {
    return "unknown";
  }

  return mode;
}
