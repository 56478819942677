import styled from "styled-components";

// ButtonBase contains as few styles as possible, it aims to be a simple
// building block for creating a button that works on all browsers in a
// predictable way. As such, it mostly only resets styles applied by browsers
// that we don't want for our buttons.
export const ButtonBase = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 100%;
  justify-content: center;
  margin: 0;
  overflow: visible;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  // Remove appearance because we don't want browsers to try to style our buttons.
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    // Remove the dotted line Firefox adds on focus.
    border: none;
  }
`;
