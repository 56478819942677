import { useNavigate } from "react-router";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { hashChange } from "../../../utils/location/hashChange";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";
import { color } from "../../../theme";
import { useLayout } from "../../../utils/hooks/useLayout";
import { ButtonBase } from "../../../components/Button/ButtonBase";
import messages from "./TripPlannerOnboarding.messages";

export function TripPlannerOnboardingExitButton() {
  const layout = useLayout();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const handleClick = function () {
    navigate(hashChange(undefined, location), {
      state: {
        highlightedTab: "search",
      },
    });
  };

  if (layout !== "desktop") return null;
  return (
    <ExitButton onClick={handleClick}>
      {intl.formatMessage(messages.return)}
    </ExitButton>
  );
}

const ExitButton = styled(ButtonBase)`
  text-decoration: underline;
  text-underline-offset: 0.25em;
  text-decoration-color: ${color.n60};
  &:focus-visible {
    outline: 2px solid ${color.n100};
  }
`;
