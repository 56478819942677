import { useIntl } from "react-intl";
import styled from "styled-components";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
} from "../../../theme";
import { largeDesktopLayout, useLayout } from "../../../utils/hooks/useLayout";
import { ButtonContainer, PictureContainer } from "./UserDetails";
import { messages } from "./UserDetails.messages";

export function UserDetailsLoading() {
  const intl = useIntl();
  const isMobile = useLayout() === "mobile";
  return (
    <LoadingContainer>
      <PictureContainer>
        <Skeleton height="100%" />
      </PictureContainer>
      <Skeleton
        height={isMobile ? 29 : 24 + 8}
        width="100%"
        margin={`0 0 ${isMobile ? spacing.md : spacing.sm}`}
        padding={isMobile ? `0 ${spacing.xl}` : `0 0 ${spacing.md} 0`}
      />
      <Skeleton
        height={18}
        width="100%"
        margin={`0 0 ${spacing.xxl}`}
        padding={`0 ${isMobile ? spacing.lg : 0}`}
      />
      <Skeleton
        height={isMobile ? 18 : 22}
        width={170}
        margin={`0 0 ${spacing.xxl}`}
      />
      {!isMobile && (
        <Skeleton height={1} width="100%" margin={`0 0 ${spacing.xl}`} />
      )}
      <ButtonContainer>
        <Skeleton height={isMobile ? 48 : 24} width={isMobile ? "100%" : 170} />
      </ButtonContainer>
      <LoadingMessage>
        {intl.formatMessage(messages.loadingMessage, {
          lineBreaker: <br />,
        })}
      </LoadingMessage>
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  position: relative;
  // Alignment
  display: flex;
  flex-direction: column;
  align-items: center;
  ${largeDesktopLayout} {
    align-items: flex-start;
  }
`;

const LoadingMessage = styled.p`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  // Text Style
  font-size: ${fontSize.h4};
  text-align: center;
  color: ${color.cod};
  font-style: normal;
  font-weight: ${fontWeight.semibold};
  line-height: ${lineHeight.snug};
  // Size
  width: 264px;
  height: 136px;
  // Card Style
  border: 1px solid ${color.n30};
  border-radius: ${borderRadius.md};
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
