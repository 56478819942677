import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Search } from "src/svg/Search";
import {
  borderRadius,
  color,
  fontSize,
  fontWeight,
  iconSize,
  lineHeight,
  spacing,
} from "src/theme";
import { largeDesktopLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import { Icon } from "../Icon/Icon";
import { VisuallyHidden } from "../VisuallyHidden/VisuallyHidden";

type SearchFilterProps<T> = {
  data: T[];
  onFilter: (filteredData: T[]) => void;
  filteringFunction: (item: T, filterText: string) => boolean;
  description?: string;
};

/**
 * SearchFilter component
 * @param data - Array of strings to filter
 * @param onFilter - Callback function to call with filtered data
 * @param description - Description for screen readers
 * @returns SearchFilter component
 * @example
 * <SearchFilter data={data} onFilter={setFilteredData} />
 **/
export function SearchFilter<T>({
  data,
  onFilter,
  filteringFunction,
  description,
}: SearchFilterProps<T>) {
  const [filterText, setFilterText] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setFilterText(text);

    // Filter data based on filterText
    const filteredData = data.filter((item) => filteringFunction(item, text));

    // Call onFilter callback with filteredData
    onFilter(filteredData);
  };

  useEffect(() => {
    // remove focus on input when user scrolls
    function handleScroll() {
      inputRef.current?.blur();
      window.removeEventListener("scroll", handleScroll, true);
    }
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <Label
      htmlFor="input-filter"
      aria-describedby="input-filter-description"
      $active={filterText.length > 0}
    >
      <StyledIcon size="md">
        <Search title="Search" tint="cod" />
      </StyledIcon>
      <VisuallyHidden>
        <span id="input-filter-description">{description}</span>
      </VisuallyHidden>
      <Input
        ref={inputRef}
        type="text"
        id="input-filter"
        value={filterText}
        onChange={handleFilterChange}
        placeholder="Search"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
    </Label>
  );
}

const Input = styled.input`
  border: none;
  outline: none;
  background: transparent;
  color: ${color.cod};
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  line-height: ${lineHeight.tight};
  max-width: 80%;
  padding: ${spacing.md} ${spacing.xl};
  // hide the input by changing the placeholder color
  // This is because safari does not allow autofocusing on inputs if the input is hidden
  // so we hide the placeholder instead
  &::placeholder {
    color: transparent;
  }
  ${largeDesktopLayout} {
    max-width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${spacing.md};
`;
const ActiveStyle = css`
  background: ${color.n20};
  border-style: solid;
  max-width: 250px;
  & > ${Input} {
    &::placeholder {
      color: ${color.n60};
    }
  }
`;

const Label = styled.label<{ $active?: boolean }>`
  // Aligment and spacing
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.lg};
  position: relative;

  // Size
  height: 34px;
  width: auto;
  // 2 time the padding + the icon size
  max-width: calc(2 * ${spacing.xl} + ${iconSize.md});

  // Border
  border-radius: ${borderRadius.sm};
  border-color: ${color.n10};
  border-width: 1px;
  border-style: none;

  // Animation
  transition: max-width 0.3s ease-out, background 0.3s ease-out,
    border-style 0.3s ease-out;
  ${({ $active }) => $active && ActiveStyle}
  &:hover {
    ${ActiveStyle}
  }
  &:focus-within {
    ${ActiveStyle}
  }
`;
