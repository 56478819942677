import { useIntl } from "react-intl";
import styled from "styled-components";
import Plane from "src/svg/transit/Plane.svg?react";
import { SearchResultsPromo } from "../SearchResultsPromo/SearchResultsPromo";
import { color } from "../../../theme";
import { useLogExitCallback } from "../../../analytics/useLogExitCallback/useLogExitCallback";
import { buildUrl } from "../../../utils/url";
import useSearch from "../../../utils/hooks/useSearch";
import { sendAnalyticsInteractionEvent } from "../../../analytics/sendAnalyticsInteractionEvent";
import { useJayrideTicketUrl } from "../../../utils/hooks/useJayrideTicketUrl";
import { generateNavigationEventId } from "../../../analytics/generateNavigationEventId/generateNavigationEventId";
import messages from "./SearchResultsAirportTransferPromo.messages";

type SearchResultAirportTransferPromoProps = {
  className?: string;
};

export function SearchResultsAirportTransferPromo(
  props: SearchResultAirportTransferPromoProps
) {
  const { searchResponse } = useSearch();
  const ticketLink = useJayrideTicketUrl(searchResponse);

  // Use shuttle transitMode for all transfer CTA
  // exits for more consistent tracking of this exit.
  const logTicketExit = useLogExitCallback(
    "AirportTransfers",
    "searchResults",
    "airportTransferCta",
    "shuttle"
  );

  function handleAirportTransferPromoClicked() {
    if (!searchResponse) {
      return;
    }

    const eventId = generateNavigationEventId();
    const jayrideHomepageUrl = buildUrl(`https://www.jayride.com/`, {
      utm_source: "member-27907",
      utm_medium: "web",
      utm_campaign: "r2r_search_results",
      utm_content: eventId,
    });

    const url = ticketLink ? ticketLink : jayrideHomepageUrl;

    logTicketExit(url, "Jayride", undefined, undefined, eventId);
    sendAnalyticsInteractionEvent(
      "SearchResults",
      "Click:AirportTransferPromo"
    );
    window.open(url, "_blank", "noopener noreferrer");
  }

  const intl = useIntl();

  return (
    <StyledSearchResultsPromo
      header={intl.formatMessage(messages.header)}
      tagline={intl.formatMessage(messages.tagline)}
      iconBackgroundColor="mint"
      icon={<Plane color={color.white} />}
      onClick={handleAirportTransferPromoClicked}
      className={props.className}
    />
  );
}

const StyledSearchResultsPromo = styled(SearchResultsPromo)`
  border-bottom: 1px solid ${color.grey2};
`;
