import { useDetectAdBlock } from "adblock-detect-react";

export function useDetectAdBlocker() {
  let adBlockerDetected = false;

  // If the GPT script fails to load, it was probably blocked by an adblocker
  if (window.gptLoadFailed) adBlockerDetected = true;

  // If our cheeky ad.js file fails to load, something probably dropped that
  if (window.adScriptLoadFailed) adBlockerDetected = true;

  // ublock serves a fake googletag file which noops most commands, returns "" as version
  if (googletag && googletag.getVersion && googletag.getVersion() === "")
    adBlockerDetected = true;

  // googletagCmdRun will be true when our first googletag.cmd gets executed.
  // When that happens we are safe to check if googletag is succesfully loaded
  // The theory being that some ad blockers do serve a fake gpt that no-ops every command
  if (window.googletagCmdRun && !window.googletag.apiReady)
    adBlockerDetected = true;

  const adBlockingDetected = useDetectAdBlock();

  if (adBlockingDetected) adBlockerDetected = true;

  return adBlockerDetected;
}
