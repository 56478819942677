import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { BaseCalendar } from "src/components/DatePicker/BaseCalendar";
import { asDay } from "../../../utils/dateTime";
import { getTimeInTimeZone } from "./DateHelpers";

type CalendarValue = Date | [Date | null, Date | null] | null | undefined;

type Props = {
  isReturn: boolean;

  // Dates are as at midnight local time
  departureDate?: Date;
  returnDate?: Date;
  onDatesChange(departureDate: Date, returnDate?: Date): void;

  maxDaysAhead?: number; // TODO: Source this internally
  className?: string; // TODO: Not allow - use a wrapper instead (for now)
  timeZone?: string | number; // TODO: Source this internally
  showDoubleView?: boolean;
  value?: CalendarValue;
  isProjectingTripNights?: boolean;
};

export function SearchDatesPicker({
  isReturn,
  departureDate,
  returnDate,
  onDatesChange,
  maxDaysAhead,
  className,
  timeZone,
  showDoubleView = false,
  value,
  isProjectingTripNights,
}: Props) {
  const now = getTimeInTimeZone(timeZone);
  let maxDate;
  if (maxDaysAhead) {
    maxDate = new Date(now);
    maxDate.setDate(now.getDate() + maxDaysAhead);
  }

  const fallbackValue: CalendarValue = departureDate
    ? returnDate && isReturn
      ? [departureDate, returnDate]
      : departureDate
    : null;

  return (
    <BaseCalendar
      // Due to a bug in react-calendar, when the returnDate is empty we can't clear/unset the departureDate for a ranged calendar.
      // To counter this, we switch it back to a single date picker when there's no departureDate so it appears cleared.
      // See: https://github.com/wojtekmaj/react-calendar/issues/167, https://github.com/wojtekmaj/react-calendar/issues/281.
      // NOT FIXED as recently as v4.9.0
      selectRange={isReturn && departureDate !== undefined}
      allowPartialRange={isReturn}
      minDate={now}
      // Passing undefined results in the calendar not clearing the selection, so we pass null instead.
      maxDate={maxDate}
      value={value ?? fallbackValue}
      isProjectingTripNights={isProjectingTripNights}
      onChange={(value: Date | [Date] | [Date, Date]) => {
        // Note: the asDay's used are because dates returned from react-calendar are in UTC midnight, not local midnight.
        // We will eventually want to just use UTC everywhere for convenience, but for now we'll keep it as local midnight
        if (Array.isArray(value)) {
          onDatesChange(
            asDay(value[0]),
            isReturn && value?.[1] ? asDay(value[1]) : undefined
          );
        } else {
          onDatesChange(asDay(value));
        }
      }}
      onActiveStartDateChange={() => {
        // See BaseCalendar.tsx - this will only be called when next/previous month is selected.
        sendAnalyticsInteractionEvent(
          "MultiFunctionDatePicker",
          "Scroll:Month"
        );
      }}
      showDoubleView={showDoubleView}
      className={className}
    />
  );
}
