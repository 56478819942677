import { defineMessages } from "react-intl";

const messages = defineMessages({
  allCurrencies: {
    id: "currencyScreen.allCurrencies",
    description: "Label for the select box that contains all currencies",
    defaultMessage: "All currencies",
  },
  heading: {
    id: "currencyScreen.heading",
    description: "Heading of page where user can change their currency",
    defaultMessage: "Change your currency",
  },
  newHeading: {
    id: "currencyScreen.newHeading",
    description: "Heading of page where user can change their currency",
    defaultMessage: "Currency",
  },
  suggestedCurrencies: {
    id: "currencyScreen.suggestedCurrencies",
    description: "Label for the select box that contains suggested currencies",
    defaultMessage: "Suggested",
  },
  description: {
    id: "currencyScreen.description",
    description: "Description of page where user can change their currency",
    defaultMessage: "Choose your currency",
  },
});

export default messages;
