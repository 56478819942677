import styled from "styled-components";
import { spacing } from "src/theme";
import { Timeline } from "../../Timeline";

export type JointCell = {
  type: "joint";
  id: string;
};

export function TroniconTimelineJoint() {
  return (
    <TransferWrapper>
      <Timeline type="transfer" transitMode="walk" />
    </TransferWrapper>
  );
}
const TransferWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${spacing.md};
`;
