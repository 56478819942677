import { defineMessages } from "react-intl";

const messages = defineMessages({
  travelOptions: {
    id: "searchresult.travelOptions",
    description: "Title for the search results screen",
    defaultMessage: "Travel options",
  },
});

export default messages;
