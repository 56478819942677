import { defineMessages } from "react-intl";

export const messages = defineMessages({
  signOut: {
    defaultMessage: "Sign Out",
    description: "Sign out button text",
    id: "UserDetails.signOut",
  },
  loadingMessage: {
    defaultMessage: "One moment, {lineBreaker} loading your profile...",
    description: "Message displayed while loading user details",
    id: "UserDetails.loadingMessage",
  },
});
