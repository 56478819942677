import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

export type PortalProps = PropsWithChildren<{}>;

// Portal provides a way to render children into a DOM node that exists outside
// the DOM hierarchy of the parent component.
//
// Inspired by Material UI's Portal: https://material-ui.com/components/portal/
export function Portal(props: PortalProps) {
  return createPortal(props.children, document.body);
}
