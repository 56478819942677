import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import {
  Segment,
  Route,
  IndicativePrice,
  Hop,
  SearchResponse,
} from "../../api/SearchResponse";
import { PassengerDetails } from "../../PassengerDetailsProvider";

export type PriceRange = {
  low: number;
  high: number;
  currencyCode: string;
};

/**
 * `Price` can be one of two types:
 * - `PriceRange`: An indicative price range. This is used when the exact price is not known.
 * - `string`: A live price received from an API. This is used when the exact price is known.

 * Always check the type of `Price` at runtime before using it.
 */
export type Price = PriceRange | string;

export function isPriceRange(price: Price): price is PriceRange {
  return typeof price !== "string";
}

export const displayPriceRangeFromIndicativePrice = (
  price: IndicativePrice
): PriceRange | undefined => {
  if (!price.isDisplay) {
    return undefined;
  }

  const low = price.priceLow ? price.priceLow : price.price!;
  const high = price.priceHigh ? price.priceHigh : price.price!;

  if (low === undefined || high === undefined) {
    return undefined;
  }

  return {
    low: price.priceLow ? price.priceLow : price.price!,
    high: price.priceHigh ? price.priceHigh : price.price!,
    currencyCode: price.currencyCode,
  };
};

export function getDerivationPrice(prices: IndicativePrice[] | undefined) {
  return prices?.[0]?.debug ?? null;
}

export const displayPriceRangeFromIndicativePrices = (
  prices?: IndicativePrice[],
  passengerDetails?: PassengerDetails,
  isBookable?: boolean
): PriceRange | undefined => {
  if (!prices || prices?.length === 0) {
    return undefined;
  }

  let low: number | undefined;
  let high: number | undefined;

  for (const current of prices) {
    if (current.useInRange) {
      if (low) {
        const currentLow = current.priceLow ? current.priceLow : current.price!;
        low = Math.min(currentLow, low);
      } else {
        low = current.priceLow ? current.priceLow : current.price!;
      }

      if (high) {
        const currentHigh = current.priceHigh
          ? current.priceHigh
          : current.price!;
        high = Math.max(currentHigh, high);
      } else {
        high = current.priceHigh ? current.priceHigh : current.price!;
      }
    }
  }

  // If either of this are still undefined, then we don't have a valid price range
  if (low === undefined || high === undefined) {
    return undefined;
  }

  // For bookable segments multiply prices by number of passengers selected.
  if (passengerDetails && passengerDetails.length > 1 && isBookable) {
    const numOfPassengers = passengerDetails.length;
    low = low * numOfPassengers;
    high = high * numOfPassengers;
  }

  return {
    low,
    high,
    currencyCode: prices[0].currencyCode, // Assuming all of the currencyCodes in an array of IndicativePrices are equal
  };
};

export const priceRangeFromHop = (hop: Hop): PriceRange | undefined => {
  const prices = hop.indicativePrices;
  return displayPriceRangeFromIndicativePrices(prices);
};

export const priceRangeFromSegment = (
  segment: Segment
): PriceRange | undefined => {
  return displayPriceRangeFromIndicativePrices(segment.indicativePrices);
};

export const priceRangeFromRoute = (route: Route): PriceRange | undefined => {
  return displayPriceRangeFromIndicativePrices(route.indicativePrices);
};

export const lowestPriceRangeFromSearch = (
  search: SearchResponse | PartialSearchResponse
): PriceRange | undefined => {
  const priceRanges = search.routes.map(priceRangeFromRoute);
  return sortPricesByLow(priceRanges)[0];
};

export function sortPricesByLow(
  range: (PriceRange | undefined)[]
): (PriceRange | undefined)[] {
  return range.sort((leftPrice, rightPrice) => {
    return (leftPrice?.low ?? 0) - (rightPrice?.low ?? 0);
  });
}

export function sortPricesByHigh(
  range: (PriceRange | undefined)[]
): (PriceRange | undefined)[] {
  return range.sort((leftPrice, rightPrice) => {
    return (leftPrice?.high ?? 0) - (rightPrice?.high ?? 0);
  });
}
