import { defineMessages } from "react-intl";

const messages = defineMessages({
  search: {
    id: "bottomnNavbar.search",
    defaultMessage: "Search",
    description: "Text on navbar button that goes to search screen",
  },
  hotels: {
    id: "bottomnNavbar.hotels",
    defaultMessage: "Hotels",
    description: "Text on navbar button that goes to hotels screen",
  },
  more: {
    id: "bottomnNavbar.more",
    defaultMessage: "More",
    description: "Text on navbar button that shows more information",
  },
  profile: {
    id: "bottomnNavbar.profile",
    defaultMessage: "Profile",
    description: "Text on navbar button that goes to profile screen",
  },
  map: {
    id: "bottomnNavbar.map",
    defaultMessage: "Map",
    description: "Label for the button that switches to a map view with hotels",
  },
  list: {
    id: "bottomnNavbar.list",
    defaultMessage: "List",
    description: "Label for the button that switches to a list view of hotels",
  },
  trips: {
    id: "bottomNavbar.trips",
    defaultMessage: "Trips",
    description: "Link that goes to the trip planner",
  },
});

export default messages;
