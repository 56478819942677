import Cookies from "js-cookie";
import { useSearchParams } from "./useSearchParams";

// Returns a backend experiment string extracted from the current search params & experiment cookie.
export function useBackendExperiments(): string {
  const searchParams = useSearchParams();
  const cookieExperiments = Cookies.get("experiment") ?? "";
  const paramExperiments = searchParams.get("backendExperiments") ?? "";

  return `${cookieExperiments},${paramExperiments}`;
}
