import { useIntl } from "react-intl";
import { color, fontSize, spacing } from "src/theme";
import styled from "styled-components";
import { Button } from "src/components/Button/Button";
import messages from "../TripPlannerMap.messages";

type MapErrorProps = {
  hideCallback: () => void;
};

export function MapError(props: MapErrorProps) {
  const intl = useIntl();

  return (
    <ErrorContainer>
      <ErrorMessage>{intl.formatMessage(messages.error)}</ErrorMessage>
      <ButtonContainer>
        <Button
          size="extra-small"
          onClick={props.hideCallback}
          textColor="primaryOnDark"
          backgroundColor="pink"
        >
          {intl.formatMessage(messages.hideError)}
        </Button>
      </ButtonContainer>
    </ErrorContainer>
  );
}

const ErrorContainer = styled.div`
  position: relative;
  padding: ${spacing.md};
  border: 1px solid ${color.n30};
`;
const ErrorMessage = styled.p`
  font-size: ${fontSize.small};
`;

const ButtonContainer = styled.div`
  margin: ${spacing.md} 0 0;
`;
