import { SvgProps, useColor } from "./common";

export const QuestionMark = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 2.50004C5.85778 2.50004 2.49992 5.8579 2.49992 10C2.49992 14.1422 5.85778 17.5 9.99992 17.5C14.1421 17.5 17.4999 14.1422 17.4999 10C17.4999 5.8579 14.1421 2.50004 9.99992 2.50004ZM0.833252 10C0.833252 4.93743 4.93731 0.833374 9.99992 0.833374C15.0625 0.833374 19.1666 4.93743 19.1666 10C19.1666 15.0626 15.0625 19.1667 9.99992 19.1667C4.93731 19.1667 0.833252 15.0626 0.833252 10Z"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2151 6.68699C9.82718 6.62045 9.42821 6.69335 9.08888 6.89278C8.74954 7.09221 8.49174 7.4053 8.36113 7.7766C8.2084 8.21075 7.73264 8.4389 7.29848 8.28617C6.86432 8.13344 6.63618 7.65768 6.7889 7.22352C7.05013 6.48093 7.56574 5.85475 8.24441 5.45589C8.92308 5.05703 9.72101 4.91123 10.4969 5.04431C11.2727 5.1774 11.9765 5.58077 12.4834 6.183C12.9903 6.7851 13.2677 7.54713 13.2667 8.33415C13.2663 9.60945 12.3208 10.4516 11.6456 10.9018C11.2826 11.1438 10.9255 11.3217 10.6624 11.4387C10.5297 11.4976 10.418 11.5424 10.3374 11.5731C10.2971 11.5884 10.2644 11.6003 10.2406 11.6088L10.2117 11.6189L10.2024 11.6221L10.1992 11.6232L10.1979 11.6236C10.1977 11.6237 10.1969 11.624 9.93335 10.8334L10.1969 11.624C9.76025 11.7695 9.28832 11.5335 9.14278 11.0969C8.99734 10.6606 9.23289 10.189 9.66895 10.0431L9.66789 10.0435C9.66797 10.0434 9.66805 10.0434 9.66895 10.0431L9.68225 10.0384C9.69507 10.0339 9.71608 10.0263 9.74411 10.0156C9.8003 9.99418 9.88386 9.96082 9.98552 9.91563C10.1912 9.82421 10.4591 9.68967 10.7211 9.51502C11.2958 9.13191 11.6 8.72434 11.6 8.33339L11.6 8.33215C11.6006 7.93855 11.4619 7.55745 11.2084 7.25633C10.9549 6.95522 10.603 6.75353 10.2151 6.68699Z"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16658 14.1667C9.16658 13.7065 9.53968 13.3334 9.99992 13.3334H10.0083C10.4685 13.3334 10.8416 13.7065 10.8416 14.1667C10.8416 14.6269 10.4685 15 10.0083 15H9.99992C9.53968 15 9.16658 14.6269 9.16658 14.1667Z"
        fill={tintColor}
      />
    </svg>
  );
};
