import { padNum } from "src/utils/dateTime";

// Note: Rob / Jingle: There should already be code to handle dates.
// The below code is just for the MVP and can be replaced with existing code if required.

// formatLocationDates formats the check-in and check-out dates used in each location card of the trip planner.
export function formatLocationDates(checkInDate: Date, checkOutDate: Date) {
  // Extract the year, month, and day from the check-in date.
  const checkInYear = checkInDate.getFullYear();
  const checkInMonth = checkInDate.toLocaleString("default", {
    month: "short",
  });
  const checkInDay = padNum(checkInDate.getDate());

  // Extract the year, month, and day from the check-out date.
  const checkOutYear = checkOutDate.getFullYear();
  const checkOutMonth = checkOutDate.toLocaleString("default", {
    month: "short",
  });
  const checkOutDay = padNum(checkOutDate.getDate());

  // Determine whether the years and months of the check-in and check-out dates are different.
  const includeYears = checkInYear !== checkOutYear;
  const includeCheckInMonth = checkInMonth !== checkOutMonth;

  // If the years are different, include the years in the formatted string.
  if (includeYears) {
    return `${checkInDay} ${checkInMonth} ${checkInYear} — ${checkOutDay} ${checkOutMonth} ${checkOutYear}`;
    // If the months are different, include the months in the formatted string.
  } else if (includeCheckInMonth) {
    return `${checkInDay} ${checkInMonth}—${checkOutDay} ${checkOutMonth}`;
  }

  // If the years and months are the same, only include the days along with the checkout month in the formatted string.
  return `${checkInDay}—${checkOutDay} ${checkOutMonth} `;
}

// getEndDateOfTrip calculates the end date of a trip given the start date and the number of nights.
export function getEndDateOfTrip(startDate: Date, nights: number) {
  const msInDay = 24 * 60 * 60 * 1000;
  const startDateMs = startDate.getTime();
  const endDateMs = Math.round(Math.abs(startDateMs + nights * msInDay));
  const endDate = new Date(endDateMs);

  return endDate;
}
