// Global site tag (gtag.js) - Google Analytics
// copied from https://developers.google.com/gtagjs/devguide/snippet

import { registerOnConsent } from "@rome2rio/tcf-api";
import { sendAnalyticsNonInteractionEvent } from "./sendAnalyticsNonInteractionEvent";

export const GOOGLE_TAG_MANAGER_CONSENT_PURPOSES_REQUIRED = [1, 8, 9, 10];
export const GOOGLE_TAG_MANAGER_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] =
  [];

export const GA_TRACKING_ID = "UA-22361422-1";
const GA_MARKETING_ID = "AW-968290294";

function addGoogleTagManager() {
  sendAnalyticsNonInteractionEvent("Gtm", "Loading");
  if (!document.getElementById("googletagmanager")) {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.id = "googletagmanager";
    document.head.appendChild(script);
  } else {
    // This is the case where consent was given, revoked, then given again
    // @ts-ignore
    window[`ga-disable-${GA_TRACKING_ID}`] = false;
    // @ts-ignore
    window[`ga-disable-${GA_MARKETING_ID}`] = false;
    gtag("set", "allow_ad_personalization_signals", true);
  }
}

function disableGoogleTagManager() {
  // @ts-ignore
  window[`ga-disable-${GA_TRACKING_ID}`] = true;
  // @ts-ignore
  window[`ga-disable-${GA_MARKETING_ID}`] = true;
}

export function registerTagManagerConsent() {
  registerOnConsent({
    onGiven: addGoogleTagManager,
    onRevoked: disableGoogleTagManager,
    gdpr: {
      consentPurposesRequired: GOOGLE_TAG_MANAGER_CONSENT_PURPOSES_REQUIRED,
      legitimateInterestPurposesRequired:
        GOOGLE_TAG_MANAGER_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    },
  });
}
