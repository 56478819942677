export const spacing = {
  /** (2px) */ xs: "0.125rem",
  /** (4px) */ sm: "0.25rem",
  /** (8px) */ md: "0.5rem",
  /** (12px) */ lg: "0.75rem",
  /** (16px) */ xl: "1rem",
  /** (24px) */ xxl: "1.5rem",
  /** (32px) */ xxxl: "2rem",
  /** (48px) */ xxxxl: "3rem",
  /** (72px) */ xxxxxl: "4.5rem",
  /** (104px) */ xxxxxxl: "6.5rem",
} as const;
/**
 * padding: ${spacing.md};
 */
