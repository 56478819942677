export const interactionEntitySchemaURI =
  "iglu:com.rome2rio/event_trigger/jsonschema/1-0-0";

export const serverRequestEntitySchemaURI =
  "iglu:com.rome2rio/server_request/jsonschema/1-0-0";

export const windowIdentifiersEntitySchemaURI =
  "iglu:com.rome2rio/window_identifiers/jsonschema/1-0-0";

export const requestIdentifiersEntitySchemaURI =
  "iglu:com.rome2rio/request_identifiers/jsonschema/1-0-0";

export const searchEntitySchemaURI =
  "iglu:com.rome2rio/search/jsonschema/1-0-0";

export const seoStoreEntitySchemaURI =
  "iglu:com.rome2rio/seo_store/jsonschema/1-0-0";

export const experimentEntitySchemaURI =
  "iglu:com.rome2rio/experiments/jsonschema/1-0-0";

export const contentSourcesEntitySchemaURI =
  "iglu:com.rome2rio/content_sources/jsonschema/1-0-0";

export const screenLayoutEntitySchemaURI =
  "iglu:com.rome2rio/screen_layout/jsonschema/1-0-0";

export const screenContentEntitySchemaURI =
  "iglu:com.rome2rio/screen_content/jsonschema/1-0-0";

export const gtmPreviewURI =
  "iglu:com.google.tag-manager.server-side/preview_mode/jsonschema/1-0-0";
