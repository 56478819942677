import {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useLayout } from "src/utils/hooks/useLayout";
import { borderRadius, fontSize, fontWeight, spacing } from "../../theme";

export type DropdownAnchor = "left" | "right" | "center";

// This is exposed in case you want the components inside the dropdown to
// look like they have the same padding even though the actual component might
// extend to edge of the dropdown. (Ex: hover state might cover the width of the dropdown,
// but text inside of it should line up with title correctly)
export const dropdownPadding = spacing.xxl;

type DropdownProps = {
  anchor?: DropdownAnchor;
  noPadding?: boolean;
  hidden?: boolean;
  borderRadius?: keyof typeof borderRadius;
} & ComponentPropsWithoutRef<"div">;

export function Dropdown(props: PropsWithChildren<DropdownProps>) {
  const { anchor, noPadding, children, ...other } = props;
  const isMobile = useLayout() === "mobile";
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState("");

  useEffect(() => {
    if (dropdownRef.current) {
      // We need to know how far this is from the top of the window so we can
      // give it a max height.
      const heightFromTop = dropdownRef.current.getBoundingClientRect().y;
      // The total amount of space we want to preserve is the dropdowns y
      // position (e.g. top) + 55px at the bottom (the height of the nav on mobile).
      const whitespaceToPreserve = isMobile
        ? heightFromTop + 55
        : heightFromTop + 5;
      const maxHeight = `${whitespaceToPreserve}px`;
      setDropdownMaxHeight(maxHeight);
    }
  }, [isMobile]);

  return (
    <DropdownContentWrapper
      anchor={anchor}
      noPadding={noPadding}
      ref={dropdownRef}
      dropdownMaxHeight={dropdownMaxHeight}
      $borderRadius={props.borderRadius ?? "sm"}
      {...other}
    >
      {children}
    </DropdownContentWrapper>
  );
}

export const DropdownContentWrapper = styled.div<{
  anchor?: DropdownAnchor;
  noPadding?: boolean;
  dropdownMaxHeight: string;
  hidden?: boolean;
  $borderRadius: keyof typeof borderRadius;
}>`
  ${(props) => (props.hidden ? `display: none;` : ``)}

  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.12);
  position: absolute;
  border-radius: ${(props) => borderRadius[props.$borderRadius]};
  z-index: 50;
  overflow-y: auto;
  background-color: ${(props) => props.theme.dropdown.background};
  color: ${(props) => props.theme.dropdown.text};

  // On first render we haven't calculated the max height of the dropdown, so
  // we default to 100vh until the next render.
  max-height: ${(props) =>
    props.dropdownMaxHeight === ""
      ? "100vh"
      : `calc(100vh - ${props.dropdownMaxHeight})`};

  ${(props) => {
    if (!props.noPadding) {
      return `
        padding-top: ${spacing.lg};
        padding-bottom: ${spacing.lg};
      `;
    }
  }}

  ${(props) => {
    if (props.anchor === "left") {
      return "left: 0;";
    } else if (props.anchor === "right") {
      return "right: 0;";
    } else {
      return `
        transform: translateX(-50%);
        left: 50%;
      `;
    }
  }}
`;

export const DropdownTitle = styled.h3`
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.medium};
  /* Take padding the dropdown container already has into account */
  padding-top: calc(${dropdownPadding} - ${spacing.lg});
  padding-left: ${dropdownPadding};
  padding-right: ${dropdownPadding};
  padding-bottom: ${spacing.sm};
`;
