export type DurationFormat = {
  minutes: number;
  hours: number;
  days: number;
};

export const parseDurationFormat = (minuteDuration: number): DurationFormat => {
  const hours = Math.floor((minuteDuration / 60) % 24);
  let minutes = Math.floor(minuteDuration % 60);
  const days = Math.floor(minuteDuration / 1440);

  // Show 1m instead of 0m
  if (!hours && !days && !minutes) {
    minutes = 1;
  }

  return {
    minutes,
    hours,
    days,
  };
};
