import { LocationState, TypedLocation } from "../hooks/useTypedLocation";
import { hashChange } from "./hashChange";
import { stateChange } from "./stateChange";

export function hashAndStateChange(
  newHash: string | undefined,
  newState: Partial<LocationState>,
  current: TypedLocation
): TypedLocation {
  const hashState = hashChange(newHash, current);
  return stateChange(newState, hashState);
}
