import { defineMessages } from "react-intl";

const messages = defineMessages({
  pageTitleProfile: {
    id: "userPreferences.pageTitleProfile",
    description: 'Page title of the "Profile options" screen',
    defaultMessage: "Profile",
  },
});

export default messages;
