import styled from "styled-components";
import { Mode } from "../../../../utils/types/mode";
import { Skeleton } from "../../../../components/Skeleton/Skeleton";
import { color, spacing } from "../../../../theme";
import { useLayout } from "../../../../utils/hooks/useLayout";

type Props = {
  transitMode: Mode;
};

export function ScheduleLoadingRow(props: Props) {
  const layout = useLayout();
  return (
    <Wrapper>
      {layout === "mobile" && (
        <Skeleton height={18} width={18} margin={`0 0 ${spacing.sm}`} />
      )}
      <Skeleton height={38} />
      {props.transitMode === "plane" && (
        <Skeleton height={13} width="30%" margin={`${spacing.sm} 0 0`} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: white;
  border-top: 1px solid ${color.grey2};
  padding: ${spacing.xl};

  &:first-child {
    border-top: none;
  }
`;
