import styled from "styled-components";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import { spacing } from "src/design-system/tokens/spacing";
import { PerNight } from "./PricingInfo";

export const TitleLoading = () => (
  <TitleLoadingContainer>
    <TitleLoadingHeading>
      <Skeleton height={20} />
      <Skeleton width={27} height={9} />
    </TitleLoadingHeading>
    <Skeleton width={90} height={12} margin="6px 0 0 0" />
  </TitleLoadingContainer>
);

export const RatingWithScoreLoading = () => (
  <Skeleton width={120} height={16} />
);
export const RatingWithBoxLoading = () => <Skeleton width={150} height={24} />;

export const TagsLoading = () => <Skeleton width={117} height={16} />;

export const GettingHereLoading = () => <Skeleton width={150} height={24} />;
export const DistanceToPlaceLoading = () => (
  <Skeleton width={150} height={18} />
);

export const PricingInfoLoading = ({ nightsStay }: { nightsStay: number }) => (
  <PricingInfo>
    <Skeleton width={60} height={24} />
    <PerNight nightsStay={nightsStay} />
  </PricingInfo>
);

const TitleLoadingContainer = styled.div`
  width: 100%;
`;

const TitleLoadingHeading = styled.span`
  max-width: 260px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.sm};
`;

const PricingInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  row-gap: ${spacing.sm};
`;
