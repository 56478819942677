import { SvgProps, useColor } from "./common";

type BookmarkProps = {
  active?: boolean;
} & SvgProps;

export const UserCircle = (props: BookmarkProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <mask
        id="mask0_1103_15478"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="17"
        height="17"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3305 11.7116C14.1839 11.7116 15.6863 10.2092 15.6863 8.35585C15.6863 6.5025 14.1839 5.00006 12.3305 5.00006C10.4771 5.00006 8.97471 6.5025 8.97471 8.35585C8.97471 10.2092 10.4771 11.7116 12.3305 11.7116ZM20.653 20.7836C20.7204 21.3318 20.2716 21.779 19.7193 21.779H4.94035C4.38807 21.779 3.93929 21.3318 4.00673 20.7836C4.32201 18.2211 5.93114 13.3895 12.3298 13.3895C18.7285 13.3895 20.3377 18.2211 20.653 20.7836Z"
          fill={tintColor}
        />
      </mask>
      <g mask="url(#mask0_1103_15478)">
        <circle cx="12.3298" cy="11.1123" r="10.6667" fill={tintColor} />
      </g>
      <circle
        cx="12.0002"
        cy="12"
        r="9.96667"
        stroke={tintColor}
        strokeWidth="1.4"
      />
    </svg>
  );
};
