import { cva, type VariantProps } from "class-variance-authority";
import type {
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementType,
} from "react";
import styles from "./Typography.module.css";

export const typographyVariants = cva(styles["text-base"], {
  variants: {
    variant: {
      "label-sm": styles["label-sm"],
      "label-md": styles["label-md"],
      "label-lg": styles["label-lg"],
      "label-xl": styles["label-xl"],

      "body-sm": styles["body-sm"],
      "body-md": styles["body-md"],
      "body-lg": styles["body-lg"],

      "heading-xs": styles["heading-xs"],
      "heading-sm": styles["heading-sm"],
      "heading-md": styles["heading-md"],
      "heading-lg": styles["heading-lg"],
      "heading-xl": styles["heading-xl"],
    },
    weight: {
      normal: styles["normal"],
      medium: styles["medium"],
      bold: styles["bold"],
    },
    decoration: {
      none: "",
      underline: styles["underline"],
      "line-through": styles["line-through"],
    },
  },
  defaultVariants: {
    variant: "body-md",
    decoration: "none",
  },
});

type TypographyVariantProps = VariantProps<typeof typographyVariants> & {
  color?: CSSProperties["color"];
};
// These types exists to allow an optional 'as' prop. When this prop is defined, it will be used as the component to render and
// props from that component should show up as required/available as well.
type ComponentPropsWithDefault<T extends ElementType = "span"> =
  | ({ as?: T } & ComponentPropsWithoutRef<T extends ElementType ? T : never>)
  | ({ as?: never } & ComponentPropsWithoutRef<"span">);

type TypographyProps<As extends ElementType> = ComponentPropsWithDefault<As> &
  TypographyVariantProps;
export function Typography<T extends ElementType>({
  as,
  variant,
  weight,
  decoration,
  color,
  className,
  ...props
}: TypographyProps<T>) {
  const Component = as ?? "span";
  return (
    <Component
      style={{ color: color }}
      {...props}
      className={typographyVariants({
        variant,
        weight,
        decoration,
        className,
      })}
    />
  );
}

type LabelVariantProps = Omit<TypographyVariantProps, "variant"> & {
  size: "sm" | "md" | "lg" | "xl";
};
type LabelProps<T extends ElementType> = ComponentPropsWithDefault<T> &
  LabelVariantProps;
export function Label<T extends ElementType>(props: LabelProps<T>) {
  return <Typography variant={`label-${props.size}`} {...props} />;
}

type BodyVariantProps = Omit<TypographyVariantProps, "variant"> & {
  size: "sm" | "md" | "lg";
};
type BodyProps<T extends ElementType> = ComponentPropsWithDefault<T> &
  BodyVariantProps;
export function Body<T extends ElementType>(props: BodyProps<T>) {
  return <Typography variant={`body-${props.size}`} {...props} />;
}

type HeadingVariantProps = Omit<TypographyVariantProps, "variant"> & {
  size: "xs" | "sm" | "md" | "lg" | "xl";
};
type HeadingProps<T extends ElementType> = ComponentPropsWithDefault<T> &
  HeadingVariantProps;
export function Heading<T extends ElementType>(props: HeadingProps<T>) {
  return <Typography variant={`heading-${props.size}`} {...props} />;
}

// Components for each variant and size - LabelSm, LabelMd, LabelLg, e.g.
type SizedLabelProps<T extends ElementType> = ComponentPropsWithDefault<T> &
  Omit<LabelVariantProps, "size">;
export function LabelSm<T extends ElementType>(props: SizedLabelProps<T>) {
  return <Typography variant="label-sm" {...props} />;
}
export function LabelMd<T extends ElementType>(props: SizedLabelProps<T>) {
  return <Typography variant="label-md" {...props} />;
}
export function LabelLg<T extends ElementType>(props: SizedLabelProps<T>) {
  return <Typography variant="label-lg" {...props} />;
}
export function LabelXl<T extends ElementType>(props: SizedLabelProps<T>) {
  return <Typography variant="label-xl" {...props} />;
}

// Body Typography components.
type SizedBodyProps<T extends ElementType> = ComponentPropsWithDefault<T> &
  Omit<BodyVariantProps, "size">;
export function BodySm<T extends ElementType>(props: SizedBodyProps<T>) {
  return <Typography variant="body-sm" {...props} />;
}
export function BodyMd<T extends ElementType>(props: SizedBodyProps<T>) {
  return <Typography variant="body-md" {...props} />;
}
export function BodyLg<T extends ElementType>(props: SizedBodyProps<T>) {
  return <Typography variant="body-lg" {...props} />;
}

// Heading Typography components.
type SizedHeadingProps<T extends ElementType> = ComponentPropsWithDefault<T> &
  Omit<HeadingVariantProps, "size">;
export function HeadingXs<T extends ElementType>(props: SizedHeadingProps<T>) {
  return <Typography variant="heading-xs" {...props} />;
}
export function HeadingSm<T extends ElementType>(props: SizedHeadingProps<T>) {
  return <Typography variant="heading-sm" {...props} />;
}
export function HeadingMd<T extends ElementType>(props: SizedHeadingProps<T>) {
  return <Typography variant="heading-md" {...props} />;
}
export function HeadingLg<T extends ElementType>(props: SizedHeadingProps<T>) {
  return <Typography variant="heading-lg" {...props} />;
}
export function HeadingXl<T extends ElementType>(props: SizedHeadingProps<T>) {
  return <Typography variant="heading-xl" {...props} />;
}
