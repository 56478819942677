import { defineMessages } from "react-intl";

const messages = defineMessages({
  close: {
    id: "tripPlanner.signInTitle.close",
    description: "Title for close button",
    defaultMessage: "Close",
  },
  multipleTripsHeading: {
    id: "tripPlanner.signInTitle.multipleTripsHeading",
    description: "Title for popup to sign in and save multiple trips",
    defaultMessage: "Sign in to create and compare multiple trips",
  },
  singleTripsHeading: {
    id: "tripPlanner.signInTitle.singleTripsHeading",
    description: "Title for popup to sign in and save trip",
    defaultMessage:
      "Sign in or create an account to keep planning on any device",
  },
});

export default messages;
