import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "carriers.single.heading",
    defaultMessage: "Operator details",
    description:
      "The heading of a section that shows a single carrier's information",
  },
  multipleHeading: {
    id: "carriers.multiple.heading",
    defaultMessage: "Multiple operators service this route",
    description:
      "The heading of a section that shows multiple carrier's information",
  },
  Unknown: {
    id: "carriers.advance.unknown",
    defaultMessage: "Unknown",
    description: "The text for if we don't have info on advance bookings",
  },
  ReservationCompulsory: {
    id: "carriers.advance.compulsory",
    defaultMessage: "Compulsory",
    description: "The text for if the customer must make an advance booking",
  },
  ReservationCompulsoryGroups: {
    id: "carriers.advance.compulsoryGroups",
    defaultMessage: "Compulsory for groups",
    description:
      "The text for if the customer must make an advance booking for groups",
  },
  ReservationRecommended: {
    id: "carriers.advance.recommended",
    defaultMessage: "Recommended",
    description: "The text for if advance booking is recommended",
  },
  ReservationNotRequired: {
    id: "carriers.advance.notRequired",
    defaultMessage: "Not required",
    description: "The text for if advance booking is not required",
  },
  ReservationRequiredAirportPickup: {
    id: "carriers.advance.requiredAirportPickup",
    defaultMessage: "Required for airport pickup",
    description:
      "The text for if advance booking is required for airport pickup",
  },
  ReservationByPhoneOnly: {
    id: "carriers.advance.requiredByPhone",
    defaultMessage: "Required, by phone",
    description:
      "The text for if advance booking is required and must be done by phone",
  },
  ReservationCompulsoryBikes: {
    id: "carriers.advance.requiredByBike",
    defaultMessage: "Required for bikes",
    description:
      "The text for if advance booking is required if you have a bike",
  },
  OperatorInfoTitle: {
    id: "carriers.operatorInfoTitle",
    defaultMessage: "Operator information",
    description: "The title is for operator details section heading",
  },
});

export default messages;
