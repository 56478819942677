import { lighten } from "polished";
import { useState } from "react";
import Calendar, { CalendarProps } from "react-calendar";
import { useIntl } from "react-intl";
import { ChevronRight } from "src/svg/designSystem/ChevronRight";
import { ChevronLeft } from "src/svg/designSystem/ChevronLeft";
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
  textColor,
} from "src/theme";
import { desktopLayout, mobileLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import { Icon } from "../Icon/Icon";
import messages from "./DatePicker.messages";

type Props = CalendarProps & {
  isSelectingReturn?: boolean;
  isProjectingTripNights?: boolean;
};

export function BaseCalendar(props: Props) {
  const intl = useIntl();

  const [activeStartDate, setActiveStartDate] = useState(
    props.defaultActiveStartDate ??
      (Array.isArray(props.value) ? props.value[0] : props.value) ??
      (Array.isArray(props.defaultValue)
        ? props.defaultValue[0]
        : props.defaultValue) ??
      props.minDate
  );

  return (
    <StyledCalendar
      minDetail="month"
      maxDetail="month"
      calendarType="US" // week starts on Sunday
      locale={intl.locale}
      nextLabel={
        <Icon size="lg">
          <ChevronRight tint="primaryOnLight" strokeWidth="4" />
        </Icon>
      }
      prevLabel={
        <Icon size="lg">
          <ChevronLeft tint="primaryOnLight" strokeWidth="4" />
        </Icon>
      }
      nextAriaLabel={intl.formatMessage(messages.nextMonth)}
      prevAriaLabel={intl.formatMessage(messages.prevMonth)}
      showFixedNumberOfWeeks
      activeStartDate={activeStartDate}
      {...props}
      // Ignore the active start date being reset to the range start when a selection is made, OR when selecting next months date in a double view.
      // This is the default behavior, but we want to keep the calendar static unless the user explicitly wants to move forward or backward a month.
      onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
        if (action === "next" || action === "prev") {
          setActiveStartDate(activeStartDate);
          props.onActiveStartDateChange?.({
            action,
            activeStartDate,
            value,
            view,
          });
        }
      }}
    />
  );
}

/* Style the Calendar */
const StyledCalendar = styled(Calendar)<{
  // TODO: This is only used by TicketExitConfirmation.tsx and TicketPicker.tsx. We should remove it eventually.
  isSelectingReturn?: boolean;
  isProjectingTripNights?: boolean;
}>`
  background-color: ${color.white};
  color: ${textColor.primaryOnLight};

  ${(props) => {
    if (props.isSelectingReturn) {
      return `
          .react-calendar__tile:not(:disabled):first-child,
          .react-calendar__tile:disabled + .react-calendar__tile:not(:disabled) {
            background-color: ${lighten(0.5, color.pink)}
          }
        `;
    }
  }};

  // showNeighboringMonth = false doesn't work when coupled with showDoubleView = true, because
  // showDoubleView = true forces showFixedNumberOfWeeks = true which forces showNeighboringMonth = true
  // so we have to hide the neighbouring month with CSS
  .react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
  }

  .react-calendar__navigation {
    display: flex;
  }
  /* Month, year heading label */
  .react-calendar__navigation__label {
    background-color: transparent;
    font-size: ${fontSize.body};
    font-weight: ${fontWeight.medium};
    color: ${textColor.primaryOnLight};
    padding: ${spacing.lg};
    display: flex;
    justify-content: space-evenly;
    gap: ${spacing.xxl};
  }
  /* Next month arrows */
  .react-calendar__navigation__arrow {
    background-color: transparent;
    padding: ${spacing.lg};
    -webkit-tap-highlight-color: transparent;
    /* Hide it if disabled */
    &:disabled {
      opacity: ${(props) => (props.showDoubleView ? "25%" : 0)};
    }
    &:active {
      color: ${textColor.secondaryOnDark};
    }
    &:focus-visible {
      outline: 3px solid ${color.pink};
      outline-offset: -2px;
      z-index: 1;
    }
    // Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.
    &:focus:not(:focus-visible) {
      outline: 0;
    }
  }
  /* Hide next year buttons */
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  /* Show two months side-by-side */
  .react-calendar__viewContainer {
    display: flex;
    justify-content: space-around;
    gap: ${spacing.xxxl};
  }
  /* Weekday headings */
  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    text-transform: uppercase;
    font-size: ${fontSize.label};
    padding: ${spacing.md} 0;
    abbr {
      text-decoration: none;
    }
  }

  /* Weekend labels */
  .react-calendar__month-view__weekdays__weekday--weekend {
    font-weight: ${fontWeight.bold};
  }

  /* Days */
  .react-calendar__tile {
    cursor: pointer;
    padding: ${spacing.md} 0;
    line-height: ${lineHeight.normal};

    ${desktopLayout} {
      font-size: ${fontSize.h6}; // TODO: Better name?
    }
    ${mobileLayout} {
      font-size: ${fontSize.body};
    }

    /* Current date */
    &.react-calendar__tile--now {
      color: ${color.pink};
    }

    /* Weekends */
    &.react-calendar__month-view__days__day--weekend {
      ${(props) => !props.showDoubleView && `font-weight: ${fontWeight.bold};`}
    }

    &:disabled {
      cursor: default;
      color: ${lighten(0.7, textColor.primaryOnLight)};
    }

    &:not([disabled]) {
      &:hover {
        background-color: ${color.grey1};
      }

      // add outline for users tabbing through
      &:focus-visible {
        outline: 3px solid ${color.pink};
        outline-offset: -2px;
      }
      // Gets rid of the annoying outline for mouse users but preserves it for keyboard users, and is ignored by browsers that don’t support :focus-visible.
      &:focus:not(:focus-visible) {
        outline: 0;
      }

      /* Range hover */
      &.react-calendar__tile--hover {
        background-color: ${color.fadedPink};
      }

      &.react-calendar__tile--range {
        background-color: ${color.fadedPink};

        &:hover {
          background-color: ${color.pink};
          color: ${color.primaryOnDark};
        }

        /* Selected range start */
        &.react-calendar__tile--rangeStart {
          font-weight: ${fontWeight.bold};
          background-color: ${color.pink};
          color: ${textColor.primaryOnDark};
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        /* Selected range end */
        &.react-calendar__tile--rangeEnd {
          font-weight: ${fontWeight.bold};
          background-color: ${color.pink};
          color: ${textColor.primaryOnDark};
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          ${({ isProjectingTripNights }) =>
            isProjectingTripNights &&
            css`
              font-weight: ${fontWeight.normal};
              background-color: ${color.fadedPink};
              color: ${textColor.primaryOnLight};
            `}
        }
      }
    }
  }

  .react-calendar__navigation__label__divider {
    visibility: hidden;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${color.greyMap};
  }
`;
