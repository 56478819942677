import { defineMessages } from "react-intl";

const messages = defineMessages({
  newTrip: {
    id: "myTripsModal.newTrip",
    description: "CTA for creating a new trip in the trip planner",
    defaultMessage: "New trip",
  },
  infoHeading: {
    id: "myTripsModal.infoHeading",
    description:
      "Banner letting you know your trip will be deleted if you dont sign in",
    defaultMessage: "Don't lose your trip plan",
  },
  infoMessage: {
    id: "myTripsModal.infoMessage",
    description:
      "Banner letting you know if you sign in all your saved trips will show",
    defaultMessage: "Sign in and your searches will be saved here as trips.",
  },
  successHeading: {
    id: "myTripsModal.successHeading",
    description: "Banner showing a heading when youve signed in",
    defaultMessage: "You've signed in",
  },
  successMessage: {
    id: "myTripsModal.successMessage",
    description: "Banner showing a message when youve signed in",
    defaultMessage: "Your trips will be saved here.",
  },
  signIn: {
    id: "myTripsModal.signIn",
    description: "Sign in button",
    defaultMessage: "Sign in to save",
  },
  loadMore: {
    id: "myTripsModal.loadMore",
    description: "Load more button",
    defaultMessage: "Show {numMore} more trips",
  },
});

export default messages;
