import { GeocodeResult } from "../../api/GeocodeResponse";
import { Route, SearchResponse } from "../../api/SearchResponse";
import { Geocoded } from "../../PrefetchData";

import { transitModeFromSegment } from "../adapters/transitMode";
import { Mode } from "../types/mode";
import useSearch, { SearchPlace } from "./useSearch";

export type TripPointAlternativeNames = {
  tripOrigin?: string;
  tripDestination?: string;
  tripSegmentCityNames?: (string | undefined)[];
};

export const specialCanonicalNames = [
  "Nearby-Airports",
  "Direct-Flights",
  "Hotels-Near",
  "Transit-Near",
  "Nearby-Places",
];

function placeIsSpecial(place: Geocoded | SearchPlace): boolean {
  // Geocoded Result (result directly from geocoder)
  const geocodedResult = place as GeocodeResult;
  if (geocodedResult !== undefined) return geocodedResult.isSpecial === 1;

  // We don't otherwise have an isSpecial flag :(
  return specialCanonicalNames.includes(place.canonicalName);
}

function segmentOrRouteIsSchedulableOrFlight(
  searchResponse: SearchResponse,
  routeIndex: number,
  routeSegmentIndex: number | undefined
): boolean {
  const route = searchResponse.routes[routeIndex];

  if (routeSegmentIndex === undefined) {
    // We don't yet "skip" flights where we would have an undefined segmentIndex, so we don't need to check for a flight segment here.
    return !!route.scheduleInfo;
  }

  const segmentIndex = route.segments[routeSegmentIndex];
  const segment = searchResponse.segments[segmentIndex];

  return (
    !!segment.scheduleInfo ||
    transitModeFromSegment(searchResponse, routeIndex, routeSegmentIndex) ===
      "plane"
  );
}

export default function useTimelineTripPointAlternativeNames(
  routeIndex: number | undefined,
  segmentIndexInRoute: number | undefined,
  isRouteTimeline?: boolean
): TripPointAlternativeNames {
  // We want to pull out "alternative names" of the origin/destination of the route, to show in the schedule/route timeline.
  // For schedules, We should only show the original trip names if the trip origin/destination points are scheduled all the way
  //  to the trip origin/destination point, so we will be confident that the route Origin/destination points will be descriptive
  //  of the first/final schedule stop name.
  //  For example, in a search to a street address, The final segment is often an unscheduled segment (walk or drive)
  //  so showing the street address as an alternative to the first/final station name will be misleading as the scheduled section
  // is only takign the user to a nearby station.
  // For routes, we don't have this limitation

  const { origin, destination, searchResponse } = useSearch();

  let alternativeNames: TripPointAlternativeNames = {};

  if (!searchResponse || !origin || !destination || routeIndex === undefined)
    return alternativeNames;

  if (
    !placeIsSpecial(origin) &&
    (segmentIndexInRoute === undefined || segmentIndexInRoute === 0)
  ) {
    // We can do the origin of the first segment or the entire route
    alternativeNames.tripOrigin = getAlternativeTripPointName(
      searchResponse,
      routeIndex,
      origin,
      segmentIndexInRoute,
      isRouteTimeline
    );
  }

  if (
    !placeIsSpecial(destination) &&
    (segmentIndexInRoute === undefined ||
      segmentIndexInRoute ===
        searchResponse.routes[routeIndex].segments.length - 1)
  ) {
    // We're either the last segment of a route, or the entire route
    alternativeNames.tripDestination = getAlternativeTripPointName(
      searchResponse,
      routeIndex,
      destination,
      segmentIndexInRoute,
      isRouteTimeline
    );
  }

  alternativeNames.tripSegmentCityNames = getCityNamesForRouteSegments(
    searchResponse,
    routeIndex
  );

  return alternativeNames;
}

const getAlternativeTripPointName = (
  searchResponse: SearchResponse,
  routeIndex: number,
  originOrDestination: Geocoded | SearchPlace,
  segmentIndexInRoute?: number,
  isRouteTimeline?: boolean
) => {
  const isValidForAlternateName =
    isRouteTimeline ||
    segmentOrRouteIsSchedulableOrFlight(
      searchResponse,
      routeIndex,
      segmentIndexInRoute
    );
  return isValidForAlternateName ? originOrDestination.longName : undefined;
};

/**
 * @returns the shortName for the nearby city of each place in the route
 */
export const getCityNamesForRouteSegments = (
  searchResponse: SearchResponse,
  routeIndex: number
) => {
  const route = searchResponse.routes[routeIndex];
  // loop through each place in the route, returning the nearbyCity shortname
  return route.places.map((index: number) => {
    const cityIndex = searchResponse.places[index].nearbyCity;
    if (!cityIndex) return undefined;
    return searchResponse.places[cityIndex].shortName;
  });
};

export const getAlternativeNameForRouteSegment = (
  segmentIndex: number,
  route: Route,
  transitMode: Mode,
  tripPointAlternatives?: TripPointAlternativeNames
) => {
  const isLastSegment = segmentIndex === route.segments.length;
  const isFirstSegment = segmentIndex === 0;
  if (isFirstSegment) {
    return tripPointAlternatives?.tripOrigin;
  }
  if (isLastSegment) {
    return tripPointAlternatives?.tripDestination;
  }
  if (!tripPointAlternatives?.tripSegmentCityNames) {
    return undefined;
  }
  if (transitMode === "plane") {
    return undefined;
  }
  return tripPointAlternatives.tripSegmentCityNames[segmentIndex];
};
