// Appends the current page URL (encoded) in a parameter named 'initialReferrer'
// The intent is for this parameter to be retrieved by the backend
// to be used as exit URL
export function appendInitialReferrer(originalUrl: string) {
  if (originalUrl.indexOf("?") > -1) {
    originalUrl += "&";
  } else {
    originalUrl += "?";
  }

  return `${originalUrl}initialReferrer=${encodeURIComponent(
    window.location.href
  )}`;
}
