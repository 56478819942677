import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addDestination: {
    id: "discover.addDestination.add",
    description: "Default description inside input to add destination",
    defaultMessage: "Add destination",
  },
  findGetaways: {
    id: "discover.findGetaways.heading",
    description: "Heading for when a user is searching for places to discover",
    defaultMessage:
      "Find quick getaways to iconic Italian destinations starting from:",
  },
  autocompleteFilter: {
    id: "discover.autocomplete.filter",
    description:
      "Description for when autocomplete dropdown only shows Italy recommendations",
    defaultMessage: "Starting points only available in Italy",
  },
});
