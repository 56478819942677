import { useIntl, IntlShape } from "react-intl";
import {
  roundToNearestTen,
  roundToOneDecimalPlace,
  metersToFeet,
  metersToMiles,
  FEET_IN_MILE,
} from "../../utils/conversions/distance";
import useUser from "../../utils/hooks/useUser";
import { DistanceSystem } from "../../utils/distanceSystem";

type Props = {
  meters: number;
  className?: string;
  imprecise?: boolean;
};

export function FormattedDistance(props: Props) {
  const intl = useIntl();
  const { distanceSystem } = useUser();
  return (
    <span className={props.className}>
      {formatDistance(props.meters, distanceSystem, intl, props.imprecise)}
    </span>
  );
}

export function formatDistance(
  meters: number,
  distanceSystem: DistanceSystem,
  intl: IntlShape,
  imprecise?: boolean // To 2 significant figures.
): string {
  return distanceSystem === "imperial"
    ? getImperialDistance(intl, meters, imprecise)
    : getMetricDistance(intl, meters, imprecise);
}

function getMetricDistance(
  intl: IntlShape,
  meters: number,
  imprecise?: boolean
) {
  if (meters < 10) {
    const roundedMeters = Math.round(meters);
    return getMeterText(roundedMeters, intl, imprecise);
  } else if (meters < 1000) {
    const nearestTenMeters = roundToNearestTen(meters);
    return getMeterText(nearestTenMeters, intl, imprecise);
  } else {
    const roundedKms = roundToOneDecimalPlace(meters / 1000);
    return getKilometreText(roundedKms, intl, imprecise);
  }
}

function getImperialDistance(
  intl: IntlShape,
  meters: number,
  imprecise?: boolean
) {
  const feet = metersToFeet(meters);

  if (feet < 10) {
    const roundedFeet = Math.round(feet);
    return getFeetText(roundedFeet, intl, imprecise);
  } else if (feet < FEET_IN_MILE) {
    const nearestTenFeet = roundToNearestTen(feet);
    return getFeetText(nearestTenFeet, intl, imprecise);
  } else {
    const miles = metersToMiles(meters);
    const roundedMiles = roundToOneDecimalPlace(miles);
    return getMileText(roundedMiles, intl, imprecise);
  }
}

function getMeterText(
  value: number,
  intl: IntlShape,
  imprecise?: boolean
): string {
  return intl.formatNumber(value, {
    style: "unit",
    unit: "meter",
    unitDisplay: "long",
    maximumSignificantDigits: imprecise ? 2 : undefined,
  });
}

function getKilometreText(
  value: number,
  intl: IntlShape,
  imprecise?: boolean
): string {
  return intl.formatNumber(value, {
    style: "unit",
    unit: "kilometer",
    unitDisplay: "short",
    maximumSignificantDigits: imprecise ? 2 : undefined,
  });
}

function getFeetText(
  value: number,
  intl: IntlShape,
  imprecise?: boolean
): string {
  return intl.formatNumber(value, {
    style: "unit",
    unit: "foot",
    unitDisplay: "long",
    maximumSignificantDigits: imprecise ? 2 : undefined,
  });
}

function getMileText(
  value: number,
  intl: IntlShape,
  imprecise?: boolean
): string {
  return intl.formatNumber(value, {
    style: "unit",
    unit: "mile",
    unitDisplay: "long",
    maximumSignificantDigits: imprecise ? 2 : undefined,
  });
}
