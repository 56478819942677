import { useEffect, useRef, useState } from "react";

/**
 * The useResizeObserver hook provides one reusable ResizeObserver so that we do not recreate
 * multiple observers thus optimising performance. It also ensures that it only runs when it is
 * supported by the browser. If it is unsupported, it will return `null` so it is important any
 * fallback requirements are handled when using this hook.
 *
 * @param resizeEl - ref object of the element which should be observed by the Resize Observer
 * @param callback - optional callback function to be called when the element is resized
 * @returns DOMRect object if ResizeObserver is supported, otherwise returns null.
 */

export function useResizeObserver(
  resizeEl: React.MutableRefObject<HTMLElement | null>
) {
  const [contentRect, setContentRect] = useState<DOMRect | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>();

  useEffect(() => {
    if (typeof window.ResizeObserver !== "undefined") {
      resizeObserver.current = new ResizeObserver(([entry]) => {
        setContentRect(entry.contentRect);
      });

      if (resizeEl.current) {
        resizeObserver.current.observe(resizeEl.current);
      }

      return () => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect();
        }
      };
    }
  }, [resizeEl]);

  return contentRect;
}
