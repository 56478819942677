import * as Sentry from "@sentry/react";

export default function logBreadcrumbs(
  category: string,
  action: string,
  label?: string,
  value?: number
) {
  const message = [action, label, value].join(" ");
  Sentry.addBreadcrumb({
    category: category,
    message: message,
    level: "info",
  });
}
