import { getDesktopScreenIndex } from "src/domain/SearchScreen/getDesktopScreenIndex";
import { useIsHotelsUrlDeeplink } from "./useNavigateToHotelsPage";
import { useLayout } from "./useLayout";
import { useTypedLocation } from "./useTypedLocation";

/**
 * @deprecated
 * This hook is used to determine if the current screen is the TripPlanner screen.
 * Our components should be blind to this
 */
export const useIsTripScreen = () => {
  const location = useTypedLocation();
  const layout = useLayout();
  const isHotel = useIsHotelsUrlDeeplink();
  const screenIndex = getDesktopScreenIndex({
    isHotel,
  });

  if (layout === "desktop") {
    return screenIndex === "TripPlanner";
  } else {
    const parts = location.hash.slice(1).split("/");
    return parts.includes("trips");
  }
};
