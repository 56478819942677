import { SearchResponse } from "../../api/SearchResponse";
import { getJayrideTicketLink } from "../getJayrideTicketLink";
import { getJayrideUrlFromTicketLink } from "../getJayrideUrlFromTicketLink";

export function useJayrideTicketUrl(
  searchResponse: SearchResponse | undefined
) {
  if (!searchResponse) return undefined;
  const ticketLink = getJayrideTicketLink(searchResponse);
  return getJayrideUrlFromTicketLink(ticketLink, searchResponse);
}
