import { SearchResponse } from "src/api/SearchResponse";

export function isSaveableSearchResponse(searchResponse?: SearchResponse) {
  if (!searchResponse || searchResponse.places.length < 2) return false;
  if (
    searchResponse.result.originPlace === searchResponse.result.destinationPlace
  ) {
    return false;
  }

  return searchResponse.routes.length > 0;
}
