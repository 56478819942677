import { defineMessages } from "react-intl";

export const messages = defineMessages({
  homepage: {
    id: "header.homepage",
    defaultMessage: "Rome2Rio homepage",
    description: "The label on a logo image that links to the homepage",
  },
  transport: {
    id: "header.transport",
    defaultMessage: "Transport",
    description: "Link that goes to Romer2io homepage",
  },
  tickets: {
    id: "header.tickets",
    defaultMessage: "Tickets",
    description: "Link that goes to Romer2io tickets homepage",
  },
  hotels: {
    id: "header.hotels",
    defaultMessage: "Hotels",
    description: "Link that goes to a hotel website",
  },
  cars: {
    id: "header.cars",
    defaultMessage: "Cars",
    description: "Link that goes to a car hire website",
  },
  trips: {
    id: "topNav.trips",
    defaultMessage: "Trip Planner",
    description: "Link that goes to the trip planner",
  },
  preferences: {
    id: "header.preferences",
    defaultMessage: "Preferences",
    description: "Label on the button to open preferences screen",
  },
  primaryNavigation: {
    id: "header.primaryNavigation",
    defaultMessage: "Primary navigation",
    description:
      "The title for the main or primary navigation bar to improve accessibilty",
  },
});
