/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-sequences */
// @ts-nocheck
import { registerOnConsent } from "@rome2rio/tcf-api";

const CRIMTAN_CONSENT_PURPOSES_REQUIRED = [1, 3, 5];
const CRIMTAN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED: number[] = [];

export function registerCrimtanConsent(isCrimtanEnabled?: boolean) {
  registerOnConsent({
    onGiven: isCrimtanEnabled && loadCrimtanConsentTag,
    onRevoked: disableCrimtan,
    gdpr: {
      consentPurposesRequired: CRIMTAN_CONSENT_PURPOSES_REQUIRED,
      legitimateInterestPurposesRequired:
        CRIMTAN_LEGITIMATE_INTEREST_PURPOSES_REQUIRED,
    },
  });
}

const loadCrimtanConsentTag = () => {
  !(function (n, t, e, a, c) {
    function i(e, a) {
      var c;
      let i;
      // Ensure we only load the script once
      if (t.getElementById("consentag")) {
        return;
      }
      (c = function () {
        if (!n.consenTag) {
          console.warn("consenTag was not available");
          return;
        }
        n.consenTag.init({ containerId: e, silentMode: !0 }, a || !1);
      }),
        ((i = t.createElement("script")).src =
          "https://consentag.eu/public/3.1.1/consenTag.js"),
        (i.id = "consentag"),
        (i.async = !0),
        (i.onload = c),
        t.head.appendChild(i);
    }
    (a = a || 2),
      c
        ? n.__tcfapi?.("ping", a, function (t) {
            t.cmpLoaded &&
              (t.gdprApplies
                ? n.__tcfapi?.("addEventListener", a, function (n, t) {
                    t &&
                      ("useractioncomplete" === n.eventStatus ||
                        "tcloaded" === n.eventStatus) &&
                      n.tcString &&
                      i(e, n.tcString);
                  })
                : i(e, !0));
          })
        : i(e, !0);
  })(window, document, "80775034", 2, !0);
};

const disableCrimtan = () => {
  // TODO: how to disable a script that has already been loaded when the
  // user revokes consent?
};
