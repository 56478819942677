import { defineMessages } from "react-intl";

const messages = defineMessages({
  close: {
    id: "searchEdit.close",
    defaultMessage: "Close",
    description: "The label for the close button on the search edit modal",
  },
  search: {
    id: "searchEdit.search",
    defaultMessage: "Search",
    description: "The label for the search button on the search edit modal",
  },
  createTrip: {
    id: "searchEdit.createTrip",
    defaultMessage: "Create trip",
    description:
      "The label for the create trip button on the search edit modal",
  },
  addDestination: {
    id: "searchEdit.addDestination",
    defaultMessage: "Add destination",
    description:
      "The label for the add destination button on the search edit modal",
  },
  confirmationModalTitle: {
    id: "searchEdit.confirmationModalTitle",
    defaultMessage: "Do you want to create a new trip?",
    description:
      "The title for the confirmation modal on the create trip confirmation modal that appears when the user already has a trip plan",
  },
  confirmationModalDescription: {
    id: "searchEdit.confirmationModalDescription",
    defaultMessage:
      "Doing this multi-city search will create a new trip and override your current plan, {link}.",
    description:
      "The description for the confirmation modal on the create trip confirmation modal, the link will be `My trip` and will link to the trip planner screen",
  },
  confirmationModalConfirmText: {
    id: "searchEdit.confirmationModalConfirmText",
    defaultMessage: "Yes continue",
    description:
      "The confirm text for the confirmation modal on the create trip confirmation modal",
  },
  confirmationModalCancelText: {
    id: "searchEdit.confirmationModalCancelText",
    defaultMessage: "Cancel",
    description:
      "The cancel text for the confirmation modal on the create trip confirmation modal",
  },
});

export default messages;
