import { color, textColor, transitColor } from "../theme";

type ColorKey = keyof typeof color;
type TextColorKey = keyof typeof textColor;
type TransitColorKey = keyof typeof transitColor;
export type Color = ColorKey | TextColorKey | TransitColorKey;

export type SvgProps = {
  tint: Color;
  title?: string;
  strokeWidth?: string;
};

export function useColor(colorArgument: Color): string {
  if (isColor(colorArgument)) {
    return color[colorArgument];
  } else if (isTextColor(colorArgument)) {
    return textColor[colorArgument];
  } else {
    return transitColor[colorArgument];
  }
}

function isColor(value: any): value is ColorKey {
  return Object.keys(color).includes(value);
}

function isTextColor(value: any): value is TextColorKey {
  return Object.keys(textColor).includes(value);
}
