import { Hop, Place, SearchResponse, Segment } from "src/api/SearchResponse";

type TrimmedSearchResponseProperties = {
  segments: { [key: number]: SearchResponse["segments"][number] };
  options: { [key: number]: SearchResponse["options"][number] };
  hops: { [key: number]: SearchResponse["hops"][number] };
  vehicles: { [key: number]: SearchResponse["vehicles"][number] };
  lines: { [key: number]: SearchResponse["lines"][number] };
  paths: { [key: number]: SearchResponse["paths"][number] };
  places: { [key: number]: PartialPlace };
};

export type PartialSearchResponse = {
  routes: SearchResponse["routes"];
} & TrimmedSearchResponseProperties;

type PartialPlace = {
  canonicalName: Place["canonicalName"];
  shortName: Place["shortName"];
  longName: Place["longName"];
  lat: Place["lat"];
  lng: Place["lng"];
  kind: Place["kind"];
  timeZone: Place["timeZone"];
};

type PartialSegment = {
  options: Segment["options"];
  duration: Segment["duration"];
  isMajor: Segment["isMajor"];
};

type PartialHop = {
  line: Hop["line"];
  vehicle: Hop["vehicle"];
  duration: Hop["duration"];
};

function trimSearchResponseSegment(
  searchResponseSegment: Segment
): PartialSegment {
  return {
    options: searchResponseSegment.options,
    duration: searchResponseSegment.duration,
    isMajor: searchResponseSegment.isMajor,
  };
}

function trimSearchResponseHop(searchResponseHop: Hop): PartialHop {
  return {
    line: searchResponseHop.line,
    vehicle: searchResponseHop.vehicle,
    duration: searchResponseHop.duration,
  };
}

function trimSearchResponsePlace(place: Place): PartialPlace {
  return {
    canonicalName: place.canonicalName,
    shortName: place.shortName,
    longName: place.longName,
    lat: place.lat,
    lng: place.lng,
    kind: place.kind,
    timeZone: place.timeZone,
  };
}

function getOptionsFromSegments(
  searchResponse: SearchResponse,
  segments: number[]
): number[] {
  return segments
    .map((segmentIndex) => searchResponse.segments[segmentIndex].options)
    .flat();
}

function getHopsFromOptions(
  searchResponse: SearchResponse,
  options: number[]
): number[] {
  return options
    .map((optionIndex) => searchResponse.options[optionIndex].hops)
    .flat();
}

function getLinesFromHops(
  searchResponse: SearchResponse,
  hops: number[]
): number[] {
  return hops.map((hopIndex) => searchResponse.hops[hopIndex].line);
}

function getVehiclesFromHops(
  searchResponse: SearchResponse,
  hops: number[]
): number[] {
  return hops.map((hopIndex) => searchResponse.hops[hopIndex].vehicle);
}

function getPathsFromLines(
  searchResponse: SearchResponse,
  lines: number[]
): number[] {
  return lines.map((lineIndex) => searchResponse.lines[lineIndex].path);
}

function getPlacesFromLines(
  searchResponse: SearchResponse,
  lines: number[]
): number[] {
  return lines
    .map((lineIndex) => searchResponse.lines[lineIndex].places)
    .flat();
}

function extractPlaces(
  places: number[],
  searchResponse: SearchResponse
): PartialSearchResponse["places"] {
  let partialPlaces: PartialSearchResponse["places"] = {
    0: trimSearchResponsePlace(searchResponse.places[0]),
    1: trimSearchResponsePlace(searchResponse.places[1]),
  };
  places.forEach(
    (placeIndex) =>
      (partialPlaces[placeIndex] = trimSearchResponsePlace(
        searchResponse.places[placeIndex]
      ))
  );
  return partialPlaces;
}

function extractSegments(
  segments: number[],
  searchResponse: SearchResponse
): PartialSearchResponse["segments"] {
  let partialSegments: PartialSearchResponse["segments"] = {};
  segments.forEach(
    (segmentIndex) =>
      (partialSegments[segmentIndex] = trimSearchResponseSegment(
        searchResponse.segments[segmentIndex]
      ))
  );
  return partialSegments;
}

function extractOptions(
  options: number[],
  searchResponse: SearchResponse
): PartialSearchResponse["options"] {
  let partialOptions: PartialSearchResponse["options"] = {};
  options.forEach((optionIndex) => {
    partialOptions[optionIndex] = searchResponse.options[optionIndex];
  });
  return partialOptions;
}

function extractHops(
  hops: number[],
  searchResponse: SearchResponse
): PartialSearchResponse["hops"] {
  let partialHops: PartialSearchResponse["hops"] = {};
  hops.forEach(
    (hopIndex) =>
      (partialHops[hopIndex] = trimSearchResponseHop(
        searchResponse.hops[hopIndex]
      ))
  );
  return partialHops;
}

function extractLines(lines: number[], searchResponse: SearchResponse) {
  let partialLines: PartialSearchResponse["lines"] = {};
  lines.forEach((lineIndex) => {
    partialLines[lineIndex] = searchResponse.lines[lineIndex];
  });
  return partialLines;
}

function extractPaths(paths: number[], searchResponse: SearchResponse) {
  let partialPaths: PartialSearchResponse["paths"] = {};
  paths.forEach((pathIndex) => {
    partialPaths[pathIndex] = searchResponse.paths[pathIndex];
  });
  return partialPaths;
}

function extractVehicles(vehicles: number[], searchResponse: SearchResponse) {
  let partialVehicles: PartialSearchResponse["vehicles"] = {};
  vehicles.forEach((vehicleIndex) => {
    partialVehicles[vehicleIndex] = searchResponse.vehicles[vehicleIndex];
  });
  return partialVehicles;
}

export function getPartialSearchResponse(
  searchResponse: SearchResponse,
  routeIndex: number
): PartialSearchResponse | null {
  if (searchResponse.routes.length === 0) {
    return null;
  }

  const route = searchResponse.routes[routeIndex ?? 0];
  const segments = route.segments;
  const options = getOptionsFromSegments(searchResponse, segments);
  const hops = getHopsFromOptions(searchResponse, options);
  const lines = getLinesFromHops(searchResponse, hops);
  const vehicles = getVehiclesFromHops(searchResponse, hops);
  const paths = getPathsFromLines(searchResponse, lines);
  const places = [
    ...route.places,
    ...getPlacesFromLines(searchResponse, lines),
  ];

  const partialSearchResponse: PartialSearchResponse = {
    places: extractPlaces(places, searchResponse),
    segments: extractSegments(segments, searchResponse),
    options: extractOptions(options, searchResponse),
    hops: extractHops(hops, searchResponse),
    vehicles: extractVehicles(vehicles, searchResponse),
    lines: extractLines(lines, searchResponse),
    paths: extractPaths(paths, searchResponse),
    routes: searchResponse.routes,
  };

  return partialSearchResponse;
}
