import { useRouteIndex } from "../../utils/hooks/useRoute";
import useSearch from "../../utils/hooks/useSearch";
import { RouteScreenLoading } from "./RouteScreenLoading/RouteScreenLoading";
import { RouteScreenLoaded } from "./RouteScreenLoaded/RouteScreenLoaded";
import { useEnsureValidRoute } from "./useEnsureValidRoute";

type Props = {
  context: "transport" | "tripPlanner";
};

export function RouteScreen(props: Props) {
  const { searchResponse } = useSearch();
  const routeIndex = useRouteIndex();
  const isValidRouteUrl = useEnsureValidRoute(searchResponse, routeIndex);

  // If we don’t have a valid route url show the loading screen since
  // we’re still waiting on a search response or about to redirect.
  if (!isValidRouteUrl || !searchResponse || routeIndex === undefined) {
    return <RouteScreenLoading />;
  }

  return (
    <RouteScreenLoaded
      context={props.context}
      searchResponse={searchResponse}
      routeIndex={routeIndex}
    />
  );
}
