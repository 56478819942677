import { defineMessages } from "react-intl";

const messages = defineMessages({
  origin: {
    id: "label.origin",
    defaultMessage: "origin",
    description: "The label for an origin's input (Eg: Origin: Melbourne)",
  },
  destination: {
    id: "label.destination",
    defaultMessage: "destination",
    description:
      "The label for a destination's input (Eg: Destination: Melbourne)",
  },
  switch: {
    id: "label.switch",
    defaultMessage: "Switch origin and destination",
    description:
      "The label for a button that switches the origin and destination",
  },
  date: {
    id: "label.date",
    defaultMessage: "Departure date",
    description: "The label for a date's input (Eg: Date: 1 April 2022)",
  },
  datePlaceholder: {
    id: "date.placeholder",
    defaultMessage: "Departure date",
    description: "Placeholder for the date input",
  },
  anyTime: {
    id: "date.anyTime",
    defaultMessage: "Any Time",
    description: "The default departure date value when a date is not selected",
  },
  removePlace: {
    id: "label.removePlace",
    defaultMessage: "Remove {place}",
    description:
      "The label for a button that removes a place from the list. Eg: Remove Melbourne",
  },
});

export default messages;
