import { safeLocalStorage } from "../../utils/safeLocalStorage";

const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
const lastInteractionKey = "lastInteraction";
const sessionKey = "currentSession";

// In order to create our own idea of a session we need to know
// when a user's last interaction was.
export function updateSessionInfo() {
  const lastInteractionTimestamp = getLastInteractionTimestamp();
  const currentTimestamp = Date.now();

  // If there has been a period of no activity for two hours before
  // this call, then start the next session.
  if (lastInteractionTimestamp && !isNaN(lastInteractionTimestamp)) {
    const isNewSession =
      lastInteractionTimestamp < currentTimestamp - twoHoursInMilliseconds;
    if (isNewSession) {
      const session = getSession();
      safeLocalStorage.setItem(sessionKey, String(session + 1));
    }
  } else {
    safeLocalStorage.setItem(sessionKey, String(0));
  }

  safeLocalStorage.setItem(lastInteractionKey, String(currentTimestamp));
}

export function getSession(): number {
  const session = Number(safeLocalStorage.getItem(sessionKey));
  return !isNaN(session) ? session : 0;
}

function getLastInteractionTimestamp(): number | undefined {
  const lastInteraction = Number(safeLocalStorage.getItem(lastInteractionKey));
  return !isNaN(lastInteraction) ? lastInteraction : undefined;
}
