import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillNumberFormat } from "@formatjs/intl-numberformat/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";

export async function loadLocaleData(locale?: string) {
  if (shouldPolyfillLocale()) {
    await import(
      /* webpackChunkName: 'intl-locale.polyfill' */
      "@formatjs/intl-locale/polyfill"
    );
  }

  const unsupportedPluralRulesLocale = shouldPolyfillPluralRules(locale);
  if (unsupportedPluralRulesLocale) {
    await import(
      /* webpackChunkName: 'intl-pluralrules.polyfill' */
      "@formatjs/intl-pluralrules/polyfill-force"
    );

    // We specifically enumerate the locales we support so that we don't
    // generate chunks for every possible locales.
    switch (unsupportedPluralRulesLocale) {
      case "en":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.en' */
          `@formatjs/intl-pluralrules/locale-data/en`
        );
        break;
      case "de":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.de' */
          `@formatjs/intl-pluralrules/locale-data/de`
        );
        break;
      case "es":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.es' */
          `@formatjs/intl-pluralrules/locale-data/es`
        );
        break;
      case "fr":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.fr' */
          `@formatjs/intl-pluralrules/locale-data/fr`
        );
        break;
      case "it":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.it' */
          `@formatjs/intl-pluralrules/locale-data/it`
        );
        break;
      case "pt":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.pt' */
          `@formatjs/intl-pluralrules/locale-data/pt`
        );
        break;
      case "ru":
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.ru' */
          `@formatjs/intl-pluralrules/locale-data/ru`
        );
        break;
      default:
        await import(
          /* webpackChunkName: 'intl-pluralrules.locale-data.en' */
          `@formatjs/intl-pluralrules/locale-data/en`
        );
        break;
    }
  }
  const unsupportedNumberFormatLocale = shouldPolyfillNumberFormat(locale);
  if (unsupportedNumberFormatLocale) {
    await import(
      /* webpackChunkName: 'intl-numberformat.polyfill' */
      "@formatjs/intl-numberformat/polyfill-force"
    );

    // We specifically enumerate the locales we support so that we don't
    // generate chunks for every possible locales.
    switch (unsupportedPluralRulesLocale) {
      case "en":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.en' */
          `@formatjs/intl-numberformat/locale-data/en`
        );
        break;
      case "de":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.de' */
          `@formatjs/intl-numberformat/locale-data/de`
        );
        break;
      case "es":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.es' */
          `@formatjs/intl-numberformat/locale-data/es`
        );
        break;
      case "fr":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.fr' */
          `@formatjs/intl-numberformat/locale-data/fr`
        );
        break;
      case "it":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.it' */
          `@formatjs/intl-numberformat/locale-data/it`
        );
        break;
      case "pt":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.pt' */
          `@formatjs/intl-numberformat/locale-data/pt`
        );
        break;
      case "ru":
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.ru' */
          `@formatjs/intl-numberformat/locale-data/ru`
        );
        break;
      default:
        await import(
          /* webpackChunkName: 'intl-numberformat.locale-data.en' */
          `@formatjs/intl-numberformat/locale-data/en`
        );
        break;
    }
  }
}
