export const FIFTEEN_MINUTES_IN_MILLISECONDS = 1000 * 60 * 15;
export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000;

// expects string as 24 hour time. ex: '02:23', '23:45'
export const twentyFourToTwelveHour = (twentyFourHourTime: string) => {
  const [hour, minutes] = twentyFourHourTime.split(":");
  const hourNum = parseInt(hour);
  const time = `${hourNum % 12 || 12}:${minutes}`;

  return {
    time,
    isAm: hourNum < 12 || hourNum === 24,
  };
};

export const convertToMinutesSinceMidnight = (twentyFourHourTime: string) => {
  const [hour, minutes] = twentyFourHourTime.split(":");
  return parseInt(hour) * 60 + parseInt(minutes);
};

export const convertToTwentyFourHourTime = (minutesSinceMidnight: number) => {
  const hours = Math.floor(minutesSinceMidnight / 60);
  const minutes = minutesSinceMidnight % 60;
  return `${hours}:${minutes.toString().padStart(2, "0")}`;
};
