import { defineMessages } from "react-intl";

const messages = defineMessages({
  save: {
    defaultMessage: "Save",
    id: "tripPlanner.saveButton.save",
    description: "Accessible title for the save button",
  },
  unsave: {
    defaultMessage: "Unsave",
    id: "tripPlanner.saveButton.unsave",
    description: "Accessible title for the save button",
  },
  addToTrip: {
    defaultMessage: "Add to trip",
    id: "tripPlanner.saveButton.add",
    description: "To let users know they can save item to their trip",
  },
  addedToTrip: {
    defaultMessage: "Added to trip",
    id: "tripPlanner.saveButton.added",
    description: "To let users know they have saved an item to their trip",
  },
  removeFromTrip: {
    defaultMessage: "Remove from trip",
    id: "tripPlanner.saveButton.remove",
    description: "To let users know they can remove item from their trip",
  },
});

export default messages;
