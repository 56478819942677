import { defineMessages } from "react-intl";

export const messages = defineMessages({
  save: {
    id: "tripPlanner.tripCard.save",
    description: "Title for the bookmark icon button when unsaved",
    defaultMessage: "Save",
  },
  unsave: {
    id: "tripPlanner.tripCard.unsave",
    description: "Title for the bookmark icon button when saved",
    defaultMessage: "Unsave",
  },
  bookmarkIcon: {
    id: "tripPlanner.tripCard.bookmarkIcon",
    description: "Title for the bookmark icon",
    defaultMessage: "Save icon",
  },
  passengerIcon: {
    id: "tripPLanner.tripCard.passengerIcon",
    description: "The title for the passenger count icon",
    defaultMessage: "Passengers",
  },
  schedulesCTA: {
    id: "tripPlanner.tripCard.schedulesCTA",
    description: "Label for the CTA under the schedule trip card",
    defaultMessage: "Schedules",
  },
  overview: {
    id: "tripPlanner.tripCard.overview",
    description: "Label for the CTA under an unscheduled trip card",
    defaultMessage: "Overview",
  },
  fromPrice: {
    id: "tripPlanner.tripCard.fromPrice",
    description: "Subtitle for the CTA under the Search trip card",
    defaultMessage: "from {price}",
  },
  multiModal: {
    id: "tripPlanner.tripCard.multiModal",
    description: "Title for the multimodal trip card",
    defaultMessage: "Mixed travel",
  },
});
