import { useEffect, useState } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";

export type CcpaStatus =
  | "CCPA_DOES_NOT_APPLY"
  | "NOT_OPTED_OUT"
  | "OPTED_OUT"
  | "UNKNOWN";

function useCcpaManagement(): [CcpaStatus, () => void] {
  const [ccpaStatus, setCcpaStatus] = useState<CcpaStatus>("UNKNOWN");

  useEffect(() => {
    window.googlefc?.callbackQueue?.push({
      INITIAL_CCPA_DATA_READY: () => {
        if (
          googlefc.ccpa?.getInitialCcpaStatus() ===
          googlefc.ccpa?.InitialCcpaStatusEnum.NOT_OPTED_OUT
        ) {
          setCcpaStatus("NOT_OPTED_OUT");
        }
      },
    });
  }, []);

  function handleCcpaLinkClick() {
    const ccpaCompletionCallback = (userOptedOut: boolean) => {
      if (userOptedOut) {
        setCcpaStatus("OPTED_OUT");
        sendAnalyticsInteractionEvent("UsConsentModal", "OptOut");
      }
    };
    window.googlefc?.ccpa.openConfirmationDialog?.(ccpaCompletionCallback);
  }

  return [ccpaStatus, handleCcpaLinkClick];
}

export default useCcpaManagement;
