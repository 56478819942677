import { RefObject, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import logError from "src/utils/logError";
import { useAuthProviders } from "../contexts/AuthContext";

const clientId =
  "736771455123-ttjjkfnroislf3r41m0ct2hhc1f8deh1.apps.googleusercontent.com";

export const gapiParams = {
  client_id: clientId,
  ux_mode: "popup",
};

type onGoogleSigninLoadOptions = {
  locale: string;
  clickListener: VoidFunction;
};

export function initializeGoogleSignin(onSessionInit: (token: string) => void) {
  google.accounts.id.initialize({
    client_id: clientId,
    callback: (response) => onSessionInit(response.credential),
    auto_select: true,
  });
}

function onGoogleSigninLoad(
  parentElement: RefObject<HTMLDivElement>,
  { locale, clickListener }: onGoogleSigninLoadOptions
) {
  try {
    google?.accounts?.id?.renderButton(parentElement.current!, {
      type: "standard",
      shape: "rectangular",
      theme: "outline",
      text: "continue_with",
      size: "large",
      locale,
      logo_alignment: "center",
      click_listener: clickListener,
      // 'width' property defines the minimum button width, in pixels.
      // Since google api doesn't let us set the button width more than 400px or 100%,
      // This is a workaround to make the button responsive.
      // More info in https://stackoverflow.com/a/69552808/15975629

      width: parentElement.current?.offsetWidth,
    });
  } catch (error) {
    logError(error, ["user-accounts"]);
  }
}

export function useSignInWithGoogleButton(
  parentElement: RefObject<HTMLDivElement>,
  clickListener: VoidFunction
) {
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
  const { googleLoading } = useAuthProviders();
  const intl = useIntl();
  useEffect(() => {
    if (parentElement.current && !googleLoading && !hasBeenInitialized) {
      onGoogleSigninLoad(parentElement, {
        locale: intl.locale,
        clickListener,
      });
      setHasBeenInitialized(true);
    }
  }, [
    clickListener,
    intl.locale,
    parentElement,
    googleLoading,
    hasBeenInitialized,
  ]);
}

export function logout_google() {
  // This will invoke immediately if it's in the same session that was used to login.
  // Otherwise, it's a no-op, which is fine for our purposes
  google?.accounts?.id?.disableAutoSelect();
  googleGapiSignOut();
}

export type LoginCallback = (token: string) => Promise<boolean>;

export function googleGapiLogin(): Promise<{ id_token: string }> {
  return new Promise((resolve, reject) => {
    window.gapi.load("auth2", async function () {
      try {
        const googleAuth = await window.gapi.auth2.init(gapiParams);
        const reponse = await googleAuth.signIn();
        const authResponse = reponse.getAuthResponse();
        if (!authResponse?.id_token) {
          throw new Error("No auth response");
        }
        resolve(authResponse);
      } catch (error) {
        reject(error);
      }
    });
  });
}

function googleGapiSignOut() {
  window.gapi.load("auth2", async function () {
    await window.gapi.auth2.init(gapiParams);
    const auth2 = window.gapi.auth2.getAuthInstance();
    await auth2.signOut();
    await auth2.disconnect();
  });
}
