import { SearchResponse } from "../../api/SearchResponse";
import { ScreenComponent } from "./ScreenComponent";

export function routeScreenComponentList(
  response: SearchResponse | undefined,
  routeIndex: number | undefined
): ScreenComponent[] {
  const result: ScreenComponent[] = [];

  if (response === undefined || routeIndex === undefined) {
    return result;
  }

  result.push("RouteSummary");
  result.push("Timeline");

  const entireRouteIsTicketable = response?.routes[routeIndex].links?.some(
    (link) => link.type === "tickets"
  );
  if (entireRouteIsTicketable) {
    result.push("TicketCta");
  }

  result.push("HotelCtaTimeline");

  result.push("TieredDisplayAd");
  result.push("CsaAd");

  return result;
}
