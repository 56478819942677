import { Icon } from "src/components/Icon/Icon";
import { ArrowBackward } from "src/svg/ArrowBackward";
import { useIntl } from "react-intl";
import { useTheme } from "src/utils/hooks/useTheme";
import { LoadingBreadcrumbs } from "../NavigationBreadcrumbs/NavigationBreadcrumbs";
import { BackButton, PaneNavWrapper } from "./PaneNav";
import messages from "./PaneNav.messages";

export function PaneNavSkeleton() {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <PaneNavWrapper>
      <BackButton
        aria-label={intl.formatMessage(messages.backLabel)}
        themeColor={theme.breadcrumbs.iconTint}
      >
        <Icon size="lg">
          <ArrowBackward tint={theme.breadcrumbs.iconTint} />
        </Icon>
      </BackButton>
      <LoadingBreadcrumbs />
    </PaneNavWrapper>
  );
}
