import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import styled from "styled-components";
import ChevronsDown from "src/svg/ChevronsDown.svg?react";
import { useHotelList } from "src/utils/hooks/useHotelList";
import { bottomNavBarHeight } from "src/components/BottomNavbar/BottomNavbar";
import { spacing } from "src/design-system/tokens/spacing";
import { safeSessionStorage } from "src/utils/safeLocalStorage";
import { desktopLayout, mobileLayout } from "src/utils/hooks/useLayout";

export function ScrollNudge({
  hidden,
  setHidden,
}: {
  hidden: boolean;
  setHidden: any;
}) {
  const { hotelList } = useHotelList();
  const hotelListRendered = document.getElementById("hotel-list-wrapper");

  if (hotelList && hotelListRendered !== null) {
    return (
      <BackgroundGradient $scrollNudgeHide={hidden}>
        <NudgeIndicator
          $scrollNudgeHide={hidden}
          onClick={() => {
            if (hidden) return;

            sendAnalyticsInteractionEvent("Hotels", "Click:ScrollNudge");

            if (hotelList) {
              const nudgeScrollTarget = document.querySelector(
                `#desktop-list-card-${hotelList[1].hotelId}`
              );
              nudgeScrollTarget?.scrollIntoView({
                behavior: "smooth",
              });
            }

            setHidden(true);
            safeSessionStorage.setItem("scrollNudgeHidden", "true");
          }}
        >
          <NudgeIcon color={color.icon.onBgFill} height={22} width={22} />
        </NudgeIndicator>
      </BackgroundGradient>
    );
  } else return null;
}

const BackgroundGradient = styled.div<{
  $scrollNudgeHide: boolean;
}>`
  background: linear-gradient(
    to bottom,
    rgba(243, 243, 243, 0),
    rgba(243, 243, 243, 1)
  );

  position: fixed;
  height: 90px;
  pointer-events: none;
  opacity: ${({ $scrollNudgeHide }) => ($scrollNudgeHide ? 0 : 1)};
  transition: opacity 0.2s ease-out;
  display: flex;
  align-items: center;

  // Non-mobile styles
  bottom: 0;
  width: inherit;
  justify-content: center;

  ${mobileLayout} {
    bottom: ${bottomNavBarHeight};
    width: 100%;
    justify-content: flex-end;
    padding: 0 ${spacing.xl} ${spacing.xl};
    align-items: flex-end;
  }
`;

const NudgeIndicator = styled.div<{ $scrollNudgeHide: boolean }>`
  pointer-events: auto;
  cursor: ${({ $scrollNudgeHide }) =>
    $scrollNudgeHide ? undefined : "pointer"};
  border-radius: ${border_radius.rounded_full};
  background-color: #131b60; // primitive.blue_1000 - not yet a DSYS token for it.
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${desktopLayout} {
    margin-top: 20px;
  }
`;

const NudgeIcon = styled(ChevronsDown)`
  color: ${color.text.onBgFill};
`;
