import { SyntheticEvent } from "react";
import { useIntl } from "react-intl";
import styled, { CSSProperties } from "styled-components";
import { color, fontSize, fontWeight, spacing, transitColor } from "src/theme";
import { Mode } from "src/utils/types/mode";
import { Bookmark } from "../../../svg/tripplanner/Bookmark";
import {
  desktopLayout,
  mobileLayout,
  useLayout,
} from "../../../utils/hooks/useLayout";
import messages from "./SaveButton.messages";

type SaveButtonProps = {
  saveStateClick: () => void;
  padding?: CSSProperties["padding"];
  isActive?: boolean;
  isDisabled?: boolean;
  isInsideDrawer?: boolean;
  transitMode?: Mode;
  isGreyHeader?: boolean;
};

export function SaveScreenButton({
  padding,
  saveStateClick,
  isDisabled,
  isInsideDrawer,
  isActive,
  transitMode,
  isGreyHeader,
}: SaveButtonProps) {
  const intl = useIntl();
  const layout = useLayout();
  const bookmarkTitle = isActive ? messages.unsave : messages.save;

  function clickHandler(event: SyntheticEvent) {
    // the click will bubble to other elements that
    // may open drawers etc- we don't want this to happen
    event.stopPropagation();
    event.preventDefault();
    saveStateClick();
  }

  // We need to serve 3 types of save icons depending on the background colour.
  // This should be just two types when we remove the coloured backgrounds from
  // the segment screen in the future.
  function SaveButtonComponent() {
    if (!transitMode && !isGreyHeader) {
      return (
        <SaveButtonIconWrapper isInsideDrawer={isInsideDrawer}>
          <Bookmark active={isActive} tint={isDisabled ? "n40" : "pink"} />
        </SaveButtonIconWrapper>
      );
    } else if (
      (transitMode && transitColor[transitMode].includes("grey")) ||
      isGreyHeader
    ) {
      return (
        <SaveButtonIconWrapper>
          <Bookmark active={isActive} tint={isDisabled ? "n40" : "logoBlack"} />
        </SaveButtonIconWrapper>
      );
    } else {
      return (
        <SaveButtonIconWrapper>
          <Bookmark active={isActive} tint={isDisabled ? "n40" : "white"} />
        </SaveButtonIconWrapper>
      );
    }
  }

  return (
    <>
      {layout !== "mobile" && isInsideDrawer && (
        <AddToTrip htmlFor="tripplanner-save-button">
          {intl.formatMessage(
            isActive ? messages.addedToTrip : messages.addToTrip
          )}
        </AddToTrip>
      )}
      <SaveButton
        id="tripplanner-save-button"
        data-testid="save-button"
        title={intl.formatMessage(bookmarkTitle)}
        onClick={isDisabled ? () => {} : clickHandler}
        isInsideDrawer={isInsideDrawer}
        padding={padding}
      >
        {SaveButtonComponent()}
      </SaveButton>
    </>
  );
}

const SaveButton = styled.button<{
  isInsideDrawer?: boolean;
  padding?: CSSProperties["padding"];
}>`
  cursor: pointer;
  padding: ${(props) => props.padding};

  ${mobileLayout} {
    ${(props) => {
      if (props.isInsideDrawer) {
        return `
          width: 18px;
          top: 2px;
        `;
      } else {
        return `
          width: 16px;
          top: 2px;
        `;
      }
    }};
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: unset;

    // Safari needs this
    svg {
      width: 100%;
    }
  }

  ${desktopLayout} {
    width: 24px;
    height: 24px;
    position: relative;

    svg {
      display: block;
      width: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const SaveButtonIconWrapper = styled.span<{ isInsideDrawer?: boolean }>`
  ${(props) => {
    if (props.isInsideDrawer) {
      return `
        max-width: 16px;
      `;
    } else {
      return `
        ${mobileLayout} {
          max-width: 12px;
        }

        ${desktopLayout} {
          max-width: 18px;
        }
      `;
    }
  }};
`;

const AddToTrip = styled.label`
  cursor: pointer;
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.normal};
  color: ${color.pink};
  margin-right: ${spacing.sm};
`;
