import { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import useSearch from "../../utils/hooks/useSearch";

export function useEmulateReload() {
  const location = useTypedLocation();
  const history = createBrowserHistory();
  const { origin, destination, fromCache } = useSearch();

  // When a user searches for an already cached a/b pair we want to indicate that their
  // action was registered and has updated the results. We're doing this by emulating a
  // reload which shows the loading skeleton for 600ms.
  const [emulateReload, setEmulateReload] = useState(false);

  useEffect(() => {
    if (location.state?.userSearch) {
      fromCache && setEmulateReload(true);
      // Imemediately set the userSearch state to false so we don't trigger an
      // emulation when the user navigates via the browser.
      history.replace(location.pathname, {
        ...location.state,
        userSearch: false,
      });
    }
    // Ignore exhaustive deps because we don't care about the history object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    origin?.canonicalName,
    destination?.canonicalName,
    fromCache,
    location.state,
    location.pathname,
  ]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (emulateReload) {
      timer = setTimeout(() => setEmulateReload(false), 600);
    }
    return () => clearTimeout(timer);
  }, [emulateReload]);

  return {
    emulateReload,
    setEmulateReload,
  };
}
