import { IntlShape, useIntl, FormatNumberOptions } from "react-intl";

const MAX_NUMBER_WITH_DECIMALS = 99999;

export type FormattedPriceProps = {
  value: number;
  currency?: string;
  showDecimals?: boolean;
  hideCurrency?: boolean;
  extraCost?: boolean;
};

export function FormattedPrice(props: FormattedPriceProps) {
  const intl = useIntl();
  const { showDecimals = false, hideCurrency = false, ...other } = props;
  return (
    <>
      {formatPrice(
        {
          showDecimals,
          hideCurrency,
          ...other,
        },
        intl
      )}
    </>
  );
}

export function formatPrice(
  props: FormattedPriceProps,
  intl: IntlShape
): string {
  let { value } = props;

  const formatOptions: FormatNumberOptions = {};
  // NOTE: if value bigger than MAX_NUMBER_WITH_DECIMALS we don't want decimals
  //        because number becomes too long
  if (!props.showDecimals || value > MAX_NUMBER_WITH_DECIMALS) {
    // stop decimals from being shown
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  // For price ranges, sometimes we want to hide the currency on the low or high
  // this is done based on the users language
  if (!props.hideCurrency) {
    formatOptions.style = "currency";
  }
  formatOptions.currency = props.currency;

  // We need to remove whitespace from the translated currency,
  // it's added as a IntlNumberFormatPart
  let parts = intl
    .formatNumberToParts(value, formatOptions)
    .map(({ value, type }) => {
      // IDR itself can take up alot of space, keep it compact
      const shouldAddSpace = type === "currency" && props.currency !== "IDR";
      if (shouldAddSpace) return " " + value;
      return value.trim();
    })
    .join("")
    .trimStart();

  // If we're showing a return schedule to the user the price will be an extra cost
  // so we have to prepend the price with a plus.
  if (props.extraCost) {
    parts = `+ ${parts}`;
  }

  return parts;
}
