import { v7 as uuidv7 } from "uuid";

// Helper to generate an event ID to be used to track partner conversions.
// This ID is passed to:
//   1. The partner on the exit URL
//   2. The logExit call, along with information about the exit event
//
// The two pieces of information are then joined at analysis time to allow attribution
// of conversion events to the exit.
export function generateNavigationEventId() {
  return `r2r07--${uuidv7()}`;
}
