import { TripPlannerDetails } from "../TripPlannerProvider";
import { makeDefaultTripTitle } from "./makeDefaultTripTitle";

type TripViewmodel = {
  id: string | undefined;
  title: string;
  numDestinations: number;
};

export function tripPlanAdapter(tripPlan: TripPlannerDetails): TripViewmodel {
  const title = makeDefaultTripTitle(tripPlan.places);
  return {
    id: tripPlan.id,
    title,
    numDestinations: tripPlan.places.length,
  };
}
