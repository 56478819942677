import { memo, useEffect, useState } from "react";
import { SearchResponse } from "src/api/SearchResponse";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { useIsRouteSkipped } from "src/utils/hooks/useIsRouteSkipped";
import { LayoutType, useLayout } from "src/utils/hooks/useLayout";
import { useRouteSegmentIndex } from "src/utils/hooks/useSegment";
import {
  ReturnStage,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import { SearchResultsScreen } from "src/domain/SearchResultsScreen/SearchResultsScreen";
import { RouteScreen } from "src/domain/RouteScreen/RouteScreen";
import useSearch from "src/utils/hooks/useSearch";
import LazySegmentScreen from "src/domain/SegmentScreen/LazySegmentScreen";
import { useScroll } from "src/ScrollContext";
import {
  useTripRouteCanonical,
  useTripRouteIndex,
} from "../hooks/useTripRoute";

// These are also used as identifiers in 'useAnalyticsCoreExperience'
export type TripPlannerTransportScreen = "Segment" | "Route" | "Search Results";

// Encodes the "Hierarchy" of the screens, used for determining which "direction" of user flow we are going
const screenHierarchy: TripPlannerTransportScreen[] = [
  "Search Results",
  "Route",
  "Segment",
];

type Props = {
  context: "transport" | "tripPlanner";
  transportIndex?: number;
  returnsFlowLocation?: ReturnStage;
};

export const MemoizedTripPlannerTransport = memo(
  TripPlannerTransport,
  (prev, next) => {
    return (
      prev.transportIndex === next.transportIndex &&
      prev.returnsFlowLocation === next.returnsFlowLocation
    );
  }
);

export function TripPlannerTransport({ returnsFlowLocation }: Props) {
  const { searchResponse } = useSearch();
  const routeIndex = useTripRouteIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  const routeCanonical = useTripRouteCanonical();
  const segmentIndex = useRouteSegmentIndex();
  const location = useTypedLocation();

  const tripScheduleIndex = location.state?.tripScheduleIndex;

  const layout = useLayout();

  const [screen, setScreen] = useState<TripPlannerTransportScreen>(
    getScreenIndex({
      searchResponse,
      routeCanonical,
      segmentIndex,
      isRouteSkipped,
      layout,
      tripScheduleIndex,
    })
  );

  const [screenPositions, setScreenPosition] = useState([0]);
  const [isNavigatingBackwards, setIsNavigatingBackwards] = useState(false);
  const { getScrollTop, setScrollTop } = useScroll();

  const newScreen = getScreenIndex({
    searchResponse,
    routeCanonical,
    segmentIndex,
    isRouteSkipped,
    layout,
    tripScheduleIndex,
  });

  if (newScreen !== screen) {
    // Updating `screenIndex` will trigger the transition and tell it which screen to show.
    setScreen(newScreen);

    const newScreenIndex = screenHierarchy.indexOf(newScreen);
    const screenIndex = screenHierarchy.indexOf(screen);
    setIsNavigatingBackwards(newScreenIndex < screenIndex);

    const screenPositionsCopy = [...screenPositions];
    screenPositionsCopy[screenIndex] = getScrollTop();
    setScreenPosition(screenPositionsCopy);
  }

  useEffect(() => {
    setScrollTop(0);
  }, [searchResponse, setScrollTop]);

  useEffect(() => {
    if (isNavigatingBackwards) {
      const screenIndex = screenHierarchy.indexOf(screen);
      setScrollTop(screenPositions[screenIndex]);
    } else {
      setScrollTop(0);
    }
  }, [screen, isNavigatingBackwards, setScrollTop, screenPositions]);

  const screens: {
    [key in TripPlannerTransportScreen]: React.ComponentType<
      React.PropsWithChildren<Props>
    >;
  } = {
    "Search Results": SearchResultsScreen,
    Route: RouteScreen,
    Segment: LazySegmentScreen,
  };

  const StaticScreen = screens[screen];

  return (
    <DefaultErrorBoundary defaultMessage>
      <StaticScreen
        returnsFlowLocation={returnsFlowLocation}
        context="tripPlanner"
      />
    </DefaultErrorBoundary>
  );
}

function getScreenIndex({
  searchResponse,
  routeCanonical,
  segmentIndex,
  isRouteSkipped,
  layout,
  fullScreenTripPage,
  tripScheduleIndex,
}: {
  searchResponse?: SearchResponse;
  routeCanonical?: string;
  segmentIndex?: number;
  isRouteSkipped?: boolean;
  layout?: LayoutType;
  fullScreenTripPage?: boolean;
  tripScheduleIndex?: number;
}): TripPlannerTransportScreen {
  if (segmentIndex !== undefined) {
    // Show segment screen
    return "Segment";
  }

  if (!routeCanonical) {
    return "Search Results";
  }

  if (searchResponse === undefined) {
    // Looking for Route but no searchResponse; RouteLoading
    return "Route"; // RouteScreen will catch undefined searchResponse
  }

  if (isRouteSkipped) {
    // If the route is skipped we go straight to the segment screen which
    // will default to the first segment but displaying full schedules.
    return "Segment";
  }

  // RouteScreen
  return "Route";
}
