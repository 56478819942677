const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const changeTimeInIsoDateTime = (
  dateTime: string /* ISO */,
  time: string /* HH:mm */
) => {
  return `${dateFromIsoDateTime(dateTime)}T${time}`;
};

export const changeDateInIsoDateTime = (
  dateTime: string /* ISO */,
  date: string /* YYYY-MM-DD */
) => {
  return `${date}T${timeFromIsoDateTime(dateTime)}`;
};

export const timeFromIsoDateTime = (dateTime: string /* ISO */) => {
  return dateTime.slice(11, 16);
};

export const dateFromIsoDateTime = (dateTime: string /* ISO */) => {
  return dateTime.slice(0, 10);
};

export const formatIsoDate = (date: Date) => {
  const year = date.getFullYear();
  const month = padNum(date.getMonth() + 1);
  const day = padNum(date.getDate());

  return `${year}-${month}-${day}`;
};

export const formatIsoDateUTC = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = padNum(date.getUTCMonth() + 1);
  const day = padNum(date.getUTCDate());

  return `${year}-${month}-${day}`;
};

export const formatIsoTime = (date: Date) => {
  const hours = padNum(date.getHours());
  const minutes = padNum(date.getMinutes());

  return `${hours}:${minutes}`;
};

export const localDateObj = (
  date: string /* YYYY-MM-DD */,
  referenceDate?: Date
) => {
  const [year, month, day] = date.split("-");

  // Create date object that's the beginning of the date in user's timezone
  const localDate = new Date(
    parseInt(year),
    parseInt(month) - 1, // months in a JS Date are zero-indexed,
    parseInt(day),
    referenceDate?.getHours() ?? 0,
    referenceDate?.getMinutes() ?? 0,
    referenceDate?.getSeconds() ?? 0,
    referenceDate?.getMilliseconds() ?? 0
  );

  return localDate;
};

export const localDateTimeObj = (date: string, time: string) => {
  const [year, month, day] = date.split("-");
  const [hour = "0", minute = "0", second = "0"] = time.split(":");

  return new Date(
    parseInt(year),
    parseInt(month) - 1, // months in a JS Date are zero-indexed,
    parseInt(day),
    parseInt(hour),
    parseInt(minute),
    parseInt(second)
  );
};

export function nowInUtcOffset(utcOffset: number) {
  const now = new Date();
  return dateInUtcOffset(now, utcOffset);
}

// We want the ability to get the time in a certain time zone, and this is the best we can do.
// It's impossible to decouple JS Dates from time zone information, since dates
// are generally displayed in a user's local time. This function creates a Date object that,
// when displayed in local time, has the same properties that we would expect to have
// in the utc offset's current time.
// Ex: UTC offset is +2 and the current UTC date time is July 12 2020 at 1pm. This function
/// will return a date that's July 12 2020 at 3pm in user's LOCAL time.
export function dateInUtcOffset(date: Date, utcOffset: number) {
  // This creates a date object whose local date/time looks like what UTCs current date time is
  const dateUtc = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
  // Now adjust the hours based on the UTC offset.
  // This creates a date object whose local date/time looks like the current time of the UTC offset.
  dateUtc.setHours(dateUtc.getHours() + utcOffset);

  return dateUtc;
}

/**
 * This is used to get whatever day it currently is for the given utcOffset.
 *
 * Note: We specifically return a yyyy-mm-dd string, because this should *never* be used
 * to get the time of day, and we should *never* pass around Date objects that aren't UTC
 * (i'm looking at you `dateInUtcOffset`).
 */
export const getDayInUtcOffset = (date: Date, utcOffset: number) => {
  const dateInUtcOffset = new Date(date);
  dateInUtcOffset.setHours(dateInUtcOffset.getHours() + utcOffset);
  return formatIsoDateUTC(dateInUtcOffset);
};

export const formatIsoDateTime = (date: Date) => {
  return `${formatIsoDate(date)}T${formatIsoTime(date)}`;
};

export const formatExitDateUTC = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = padNum(date.getUTCMonth() + 1);
  const day = padNum(date.getUTCDate());
  const hours = padNum(date.getUTCHours());
  const minutes = padNum(date.getUTCMinutes());

  return `${year}${month}${day}${hours}${minutes}`;
};

export const padNum = (num: number) => {
  return num >= 10 ? String(num) : `0${num}`;
};

export function asDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

// Returns true when the two Dates are the same day/month/year. When both dates
// are undefined it returns true, if one is undefined but the other is defined
// it will return false.
export function isSameDate(first?: Date, second?: Date): boolean {
  const firstDateValue = first ? formatIsoDate(first) : undefined;
  const secondDateValue = second ? formatIsoDate(second) : undefined;
  return (!first && !second) || firstDateValue === secondDateValue;
}

// Returns the amount of full days between today and the given date parameter.
// We set the hours to zero so we can calculate from the beginning of the day.
export function daysFromToday(date: Date): number {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const future = new Date(date);
  future.setHours(0, 0, 0, 0);

  const millisecondsInDay = 86400000;
  const diff = (future.valueOf() - today.valueOf()) / millisecondsInDay;

  return Math.round(diff);
}

export const getIsoDateTime = (date: string, time: string) => {
  return `${date}T${time}`;
};

export function compareIsoDateTimes(a: string, b: string): number {
  if (a < b) return -1;
  else if (a > b) return 1;
  else return 0;
}

// This will return dates in the browser's timezone.
export function dateFromComponents(date: string, time: string): Date {
  const [year, month, day] = date.split("-");
  const [hours, minutes] = time.split(":");

  return new Date(
    parseInt(year),
    parseInt(month) - 1, // months in a JS Date are zero-indexed,
    parseInt(day),
    parseInt(hours),
    parseInt(minutes)
  );
}

export function utcDateFromComponents(date: string, time?: string): Date {
  // If no time is supplied, treat date as a date-time string, and try split it
  if (time === undefined) {
    [date, time = "00:00"] = date.split("T");
  }

  const [year, month, day] = date.split("-");
  const [hours = "0", minutes = "0"] = time.split(":");

  return new Date(
    Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // months in a JS Date are zero-indexed,
      parseInt(day),
      parseInt(hours),
      parseInt(minutes)
    )
  );
}

export function numDaysBetweenTwoDates(
  firstDate: Date,
  secondDate: Date
): number {
  return Math.round(
    Math.abs(secondDate.getTime() - firstDate.getTime()) / MS_IN_DAY
  );
}

export function numNightsBetweenTwoDates(
  firstDate: Date,
  secondDate: Date
): number {
  const timeDiff = Math.abs(
    asDay(firstDate).getTime() - asDay(secondDate).getTime()
  );
  return Math.round(timeDiff / MS_IN_DAY);
}

export function getStartOfDayInLocalTimeZone(date: Date) {
  date.setUTCHours(0, 1, 0, 0);
  return dateFromIsoDateTime(formatIsoTime(date));
}

export function addDays(date: Date, days: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}
