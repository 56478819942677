import styled from "styled-components";
import { SvgProps } from "../common";
import { ChevronRight } from "./ChevronRight";

type Props = {
  className?: string;
  strokeWidth?: string;
} & SvgProps;

export const ChevronLeft = (props: Props) => {
  return (
    <StyledChevronLeft
      className={props.className}
      tint={props.tint}
      strokeWidth={props.strokeWidth}
    />
  );
};

const StyledChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`;
