import { transparentize } from "polished";
import { useIntl } from "react-intl";
import {
  useIsHotelsUrlDeeplink,
  useIsTripHotelsUrlDeeplink,
} from "src/utils/hooks/useNavigateToHotelsPage";
import { Mode } from "src/utils/types/mode";
import styled from "styled-components";
import { sendAnalyticsInteractionEvent } from "../../analytics/sendAnalyticsInteractionEvent";
import { Kind } from "../../api/Kind";
import { ButtonBase } from "../../components/Button/ButtonBase";
import { Icon } from "../../components/Icon/Icon";
import { SaveScreenButton } from "../../components/TripPlanner/SaveButton/SaveButton";
import { ArrowBackward } from "../../svg/ArrowBackward";
import { color, spacing } from "../../theme";
import { useTheme } from "../../utils/hooks/useTheme";
import { NavigationBreadcrumbs } from "../NavigationBreadcrumbs/NavigationBreadcrumbs";
import messages from "./PaneNav.messages";

export type PaneNavProps = {
  routeCanonical?: string;
  showRouteBreadcrumb?: boolean;
  showHotelBreadcrumb?: boolean;
  segment?: SegmentBreadcrumbProps;
  onBackClick: () => void;
  className?: string;
  onTripSaveClick?: () => void;
  isSaveableScreen?: boolean;
  showSummaryBreadcrumb?: boolean;
  transitMode?: Mode;
  isGreyHeader?: boolean;
  showDestinationBreadcrumb?: boolean;
};

export type SegmentBreadcrumbProps = {
  indexInRoute?: number;
  transitKind: Kind;
};

export function PaneNav(props: PaneNavProps) {
  const theme = useTheme();
  const intl = useIntl();
  const backLabel = intl.formatMessage(messages.backLabel);
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const isTripPlannerHotelsScreen = useIsTripHotelsUrlDeeplink();

  const displayTripPlannerSaveBtn =
    !isHotelsScreen && !isTripPlannerHotelsScreen;

  return (
    <PaneNavWrapper className={props.className}>
      {props.onBackClick && (
        <BackButton
          aria-label={backLabel}
          onClick={handleClick(props.onBackClick)}
          themeColor={theme.breadcrumbs.iconTint}
        >
          <Icon size="lg">
            <ArrowBackward tint={theme.breadcrumbs.iconTint} />
          </Icon>
        </BackButton>
      )}
      <NavigationBreadcrumbs
        routeCanonical={props.routeCanonical}
        showRouteBreadcrumb={props.showRouteBreadcrumb}
        segment={props.segment}
        showHotelBreadcrumb={props.showHotelBreadcrumb}
        showTripSummaryBreadcrumb={props.showSummaryBreadcrumb}
        showDestinationBreadcrumb={props.showDestinationBreadcrumb}
      />
      {displayTripPlannerSaveBtn && (
        <TopRightIconWrapper>
          <SaveScreenButton
            saveStateClick={props.onTripSaveClick ?? (() => {})}
            isDisabled={!props.isSaveableScreen}
            transitMode={props.transitMode}
            isGreyHeader={props.isGreyHeader}
          />
        </TopRightIconWrapper>
      )}
    </PaneNavWrapper>
  );
}

function handleClick(callback: () => void) {
  return () => {
    sendAnalyticsInteractionEvent("PaneNav", "Click:BackButton");
    callback();
  };
}

export const PaneNavWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const BackButton = styled(ButtonBase)<{
  themeColor: string;
}>`
  margin-right: ${spacing.xxl};
  position: relative;

  /* This adds a semi-transparent circle around the back button on hover */
  &:hover:before {
    content: "";
    border-radius: 50%;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) =>
      transparentize(0.8, props.theme.breadcrumbs.text)};
  }

  &:focus-visible {
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${(props) => {
      if (props.themeColor === "cod") {
        return `${color.black}`;
      } else {
        return `${color.white}`;
      }
    }};
    outline-offset: 5px;
    border-radius: 50%;
  }
`;

const TopRightIconWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${spacing.xl};
`;
