import styled from "styled-components";

type Props = {
  open: boolean;
};

export const Backdrop = styled.div.attrs((props) => ({
  "aria-hidden": true,
  "data-testid": "backdrop",
}))<Props>`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  pointer-events: ${(props) => (props.open ? "initial" : "none")};
`;
