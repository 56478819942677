import { SvgProps, useColor } from "./common";

export const MapReverseFill = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M6.29984 12.4842L6.29984 2.11483L9.34985 3.51579V13.8851L6.29984 12.4842ZM10.8499 13.753L14.6759 12.3151C14.8709 12.2419 15 12.0554 15 11.8471V2.7559C15 2.41548 14.6672 2.17458 14.3438 2.28092L10.8499 3.42987V13.753ZM4.79984 12.5249H4.86468L1.69102 13.8368C1.36185 13.9729 1 13.731 1 13.3748V4.08752C1 3.88516 1.12197 3.70275 1.30898 3.62544L4.79984 2.18234L4.79984 12.5249Z"
        fill={tintColor}
      />
    </svg>
  );
};
