import { defineMessages } from "react-intl";

const messages = defineMessages({
  nextMonth: {
    defaultMessage: "Next month",
    id: "datePicker.nextMonth",
    description: "The text in a button on calendar to go to next month",
  },
  prevMonth: {
    defaultMessage: "Previous month",
    id: "datePicker.prevMonth",
    description: "The text in a button on calendar to go to previous month",
  },
});

export default messages;
