import styled from "styled-components";
import { fontWeight } from "../../theme";

export const FontLoader = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;

  &:before {
    content: " ";
    font-weight: ${fontWeight.normal};
  }

  &:after {
    content: " ";
    font-weight: ${fontWeight.medium};
  }
`;
