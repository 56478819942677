// The full list of GeoKinds in the backend is massive and not well defined;
// It's free-form text thast we get from a variety of places (google, OSM, etc.).
// We define the values that we will use, the rest is left freeform.

// TODO: Check how often some of these types actually come up
const cityKinds = ["city", "megalopolis", "metropolis", "capital"] as const;
const subCityKinds = ["town", "village", "suburb"] as const;
const placeKinds = ["place", "station", "airport"] as const;
const countryKinds = ["country"] as const;
const admin1Kinds = [
  "administrative_area_level_1",
  "admin1",
  "state",
  "region",
] as const;
const subRegionKinds = ["locality", "county"] as const;
const islandKinds = ["island"] as const;
const geoKinds = [
  ...cityKinds,
  ...subCityKinds,
  ...placeKinds,
  ...countryKinds,
  ...admin1Kinds,
  ...subRegionKinds,
  ...islandKinds,
  "node",
] as const;

export type GeoKind = (typeof geoKinds)[number] | string;

// Note: Necessary as e.g. cityKinds.includes('node') would give a type error
const isOneOf = <Item>(
  element: any,
  ...items: readonly Item[]
): element is Item => items.includes(element);

export const hasUsefulCentre = (kind: GeoKind) =>
  isOneOf(kind, ...cityKinds, ...subCityKinds);

export const isCityKind = (kind: GeoKind) => isOneOf(kind, ...cityKinds);

/**
 * True for places that aren't an area, i.e. would not contain landmarks, hotels, etc.
 */
export const isPointKind = (kind: GeoKind) => isOneOf(kind, ...placeKinds);

export const canShowHotelsForPlace = (kind: GeoKind) =>
  !isOneOf(kind, ...countryKinds, ...admin1Kinds, ...islandKinds);
