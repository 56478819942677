import { SearchResponse } from "../../api/SearchResponse";
import { transitModeFromVehicleKind } from "../../utils/adapters/transitMode";
import { vehicleFromSegment } from "../../utils/adapters/vehicle";

export function generateRouteLabel(
  searchResponse: SearchResponse,
  routeIndex: number
) {
  const route = searchResponse.routes[routeIndex];

  // Generate the modes of the major segments.
  const segmentModes = route.segments
    .map((segmentIndex) => {
      const segment = searchResponse.segments[segmentIndex];
      if (segment.isMajor) {
        const vehicle = vehicleFromSegment(searchResponse, segment);
        return transitModeFromVehicleKind(vehicle.kind);
      }
      return null;
    })
    .filter((mode) => mode);

  // Make a route label of the format:
  // rd1:<route#>:<routeCanonical>:<majorSegmentMode1|majorSegmentMode2|…>
  return `rd1:${routeIndex}:${route.canonicalName}:${segmentModes.join("|")}`;
}
