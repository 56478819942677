import { SupportedCurrencyCode } from "../utils/currency";
import { HotelProviderKind } from "../utils/types/accommodationProviders";

export type HotelListResponse = {
  hotelList: Hotel[];
  lat: number;
  lng: number;
  currencyCode: SupportedCurrencyCode;
  pricePoints: number[];
  listViewUrl?: string;
  bounds: Bounds;
  cityName?: string;
  maxHotels: number;
  cityId?: string;
  zoom: number;
  providerName: string;
  hotelFacilities: AmenityType[];
  provider: HotelProviderKind;
  message?: string; // Error message on api error.
  maxReviewScore: number;
  hotelTypes: HotelPropertyKind[];
  minPriceBound: number;
  maxPriceBound: number;
  minPricePerNight: number;
  selectedHotel?: Hotel;
};

type Bounds = {
  maxLat: number;
  minLat: number;
  maxLng: number;
  minLng: number;
};

export type Hotel = {
  lat: number;
  lng: number;
  hotelId: string;
  hotelName: string;
  rating: number;
  reviewScore: number;
  hotelTypeId: number;
  minRate: number;
  cityIds: string[];
  ranking: number;
  deal?: HotelDealInfo;
  freeCancellation?: boolean;
};

export type HotelDealTag = "Mobile";

export type HotelDealInfo = {
  discountPercentage: number;
  originalPrice: number;
  tags: HotelDealTag[];
};

export type AmenityType = {
  facilityId: number;
  facilityName: string;
};

export type HotelPropertyKind = {
  hotelTypeId: number;
  hoteTypeName: string;
};

// Although the Hotel Response returns hotelTypes: HotelPropertyKind[], all providers use the same type -> name mapping
export enum HotelType {
  Apartment = 201,
  Hostel = 203,
  Hotel = 204,
  Motel = 205,
  Resort = 206,
  BedAndBreakfast = 208,
  HolidayHomes = 220,
}
