import styled from "styled-components";
import useUser from "../../../utils/hooks/useUser";
import { UserAccess } from "../UserAccess/UserAccess";
import { UserDetails } from "../UserDetails/UserDetails";
import { UserDetailsLoading } from "../UserDetails/UserDetailsLoading";

type UserAccountsProps = {
  title?: string;
};

export function UserAccounts(props: UserAccountsProps) {
  const { user, loadingSession } = useUser();

  if (loadingSession) {
    return (
      <StyledUserAccounts>
        <UserDetailsLoading />
      </StyledUserAccounts>
    );
  }

  if (user) {
    return (
      <StyledUserAccounts data-hj-suppress>
        <UserDetails user={user} />
      </StyledUserAccounts>
    );
  }

  return (
    <StyledUserAccounts>
      <UserAccess title={props.title} />
    </StyledUserAccounts>
  );
}

const StyledUserAccounts = styled.div`
  // Google sign in button max width is 400px
  // and we cannot override it.
  // More info in https://stackoverflow.com/a/69552808/15975629
  max-width: 400px;
  width: 100%;
`;
