import { GeocodedPlace } from "src/PrefetchData";
import { TripPlannerTransportKey } from "../TripPlannerProvider";
import { createTransportKey } from "./createTransportKey";

export function getPlaceTransportKey(
  index: number,
  places: GeocodedPlace[]
): TripPlannerTransportKey | undefined {
  if (index + 1 === places.length || index < 0) return undefined;
  return createTransportKey(
    places[index].canonicalName,
    places[index + 1].canonicalName
  );
}
