import { defineMessages } from "react-intl";

export const HotelPromoMessages = defineMessages({
  from: {
    id: "destinationsExplorer.from",
    description:
      "Label for the median price of a hotel in an area e.g. Find hotels from US$399 per night",
    defaultMessage: "Find hotels at {price} per night",
  },
  compare: {
    id: "tripHotelMapPin.compare",
    description: "Label for a button to compare hotels at the destination",
    defaultMessage: "Compare stays",
  },
  findGreatDeals: {
    id: "tripHotelPromo.findGreatDeals",
    description: "Label for a button to explore hotels at a destination",
    defaultMessage: "Find great deals",
  },
  viewHotels: {
    defaultMessage: "View hotels",
    id: "MapPinHotelEntryPoint.header",
    description: "A label for a map pin that directs user to the Hotels page",
  },
});
