import { SvgProps, useColor } from "./common";

type MinusProps = {
  active?: boolean;
} & SvgProps;

export const OutlineMinus = (props: MinusProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <circle cx="7" cy="7" r="6.5" stroke={tintColor} />
      <rect
        x="3.33333"
        y="6.66665"
        width="7.33333"
        height="0.666667"
        rx="0.333333"
        fill={tintColor}
        strokeWidth="0.666667"
      />
    </svg>
  );
};
