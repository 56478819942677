import { useIntl } from "react-intl";
import styled from "styled-components";

import { PlaceName } from "src/utils/useCreateTripFromSearch";
import { AutocompletePlace } from "src/api/AutocompleteResponse";
import { SyntheticEvent, useCallback, useRef, useState } from "react";
import { Button } from "src/components/Button/Button";
import { color, spacing } from "../../../../theme";
import { sendAnalyticsInteractionEvent } from "../../../../analytics/sendAnalyticsInteractionEvent";
import {
  largeDesktopLayout,
  useLayout,
} from "../../../../utils/hooks/useLayout";
import { TripPlannerOnboardingExitButton } from "../TripPlannerOnboardingExitButton";

import { TripTrainingInput } from "./TripTrainingInput";
import { messages } from "./TripTraining.messages";

type TripTrainingProps = {
  hideTrainingCallback: VoidFunction;
  createTripCallback: VoidFunction;
  places: PlaceName[];
  setOrigin: (origin: PlaceName) => void;
  setDestination: (destination: PlaceName) => void;
};

export function TripTrainingFields({
  createTripCallback,
  hideTrainingCallback,
  setDestination,
  setOrigin,
  places,
}: TripTrainingProps) {
  const intl = useIntl();
  const layout = useLayout();

  const [isDisabled, setIsDisabled] = useState(false);

  const createTripButtonRef = useRef<HTMLButtonElement>(null);

  function selectOriginCallback(place: AutocompletePlace) {
    setOrigin(place);
    setIsDisabled(false);
  }
  function selectDestinationCallback(place: AutocompletePlace) {
    setDestination(place);
    setIsDisabled(false);
  }

  function onChangeCallback(query: string) {
    if (!isDisabled) {
      setIsDisabled(true);
    }
  }

  function focusCallback(event: SyntheticEvent) {
    const target = event.target as HTMLInputElement;
    target.select();
  }

  const createTrip = useCallback(() => {
    const gaLabel = `tc0:${places
      .map((place) => place.canonicalName)
      .join(":")}`;
    sendAnalyticsInteractionEvent(
      "TripPlanner",
      "Click:CreateNewTrip",
      gaLabel
    );
    createTripCallback();
    hideTrainingCallback();
  }, [places, createTripCallback, hideTrainingCallback]);

  const originLabel = intl.formatMessage(messages.startInputLabel);
  const destinationLabel = intl.formatMessage(messages.destinationInputLabel);
  const addDestinationText = intl.formatMessage(messages.add);

  if (!places.length) return null;

  return (
    <Container>
      <Row>
        <TripTrainingInput
          key={places[0].canonicalName}
          type="origin"
          id="origin"
          label={originLabel}
          onSelectOption={selectOriginCallback}
          initialValue={places[0].longName ?? places[0].shortName}
          onChange={onChangeCallback}
          onFocus={focusCallback}
          floatingList
        />
      </Row>
      <Row>
        <TripTrainingInput
          key={places[1].canonicalName}
          type="destination"
          id="destination"
          label={destinationLabel}
          onSelectOption={selectDestinationCallback}
          initialValue={places[1].longName ?? places[1].shortName}
          onChange={onChangeCallback}
          onFocus={focusCallback}
          floatingList
        />
      </Row>
      <Row justify={layout === "desktop" ? "space-between" : undefined}>
        <TripPlannerOnboardingExitButton />
        <StyledButton
          data-testid="create-trip"
          fontSize="body"
          onClick={createTrip}
          backgroundColor="pink"
          textColor="primaryOnDark"
          justify="center"
          title={addDestinationText}
          inline={layout === "desktop"}
          disabled={isDisabled}
          ref={createTripButtonRef}
        >
          {addDestinationText}
        </StyledButton>
      </Row>
    </Container>
  );
}

const Container = styled.div`
  padding: ${spacing.xxl} 0;
  overflow: hidden;
  ${largeDesktopLayout} {
    padding: 0;
    overflow: visible;
  }
`;

const Row = styled.div<{ justify?: "space-between" | "flex-start" }>`
  padding: ${spacing.xs} 0;
  ${({ justify }) =>
    justify &&
    `display: flex;  align-items: center; justify-content: ${justify};`};

  &:last-child {
    margin-top: ${spacing.xxl};
  }

  ${largeDesktopLayout} {
    margin: ${spacing.xl} 0;
    &:last-child {
      margin: calc(${spacing.xxl} * 2) 0 ${spacing.xl};
    }
  }
`;
const StyledButton = styled(Button)`
  &:focus-visible {
    border: 2px solid ${color.cod};
  }
`;
