import { SupportedLanguageCode } from "../language";

export const localeToLanguageCode = (locale: string): SupportedLanguageCode => {
  const languageCode = locale.slice(0, 2);

  switch (languageCode) {
    case "de":
      return "de";
    case "en":
      return "en";
    case "es":
      return "es";
    case "fr":
      return "fr";
    case "it":
      return "it";
    case "pt":
      return "pt";
    case "ru":
      return "ru";
    case "pl":
      return "pl";
    case "nl":
      return "nl";
    case "sv":
      return "sv";
    case "no":
      return "no";
    case "da":
      return "da";
    case "ja":
      return "ja";
    case "ko":
      return "ko";
    case "th":
      return "th";
    case "zh":
      return "zh";
    case "ro":
      return "ro";
    case "ms":
      return "ms";
    case "cs":
      return "cs";
    case "hu":
      return "hu";
    case "el":
      return "el";
    case "fi":
      return "fi";
    case "sk":
      return "sk";
    case "hr":
      return "hr";
    default:
      return "en";
  }
};

export function urlLanguageToLanguageCode(urlLanguage?: string) {
  return urlLanguage ?? "en";
}

export const getHelpLanguageCode = (code: SupportedLanguageCode) => {
  let language = {
    en: "en",
    de: "de",
    es: "es",
    pt: "pt-BR",
    it: "it",
    fr: "fr",
    ru: "ru-RU",
    pl: "pl",
    nl: "nl",
    sv: "sv",
    no: "no",
    da: "da",
    ja: "ja",
    ko: "ko",
    th: "th",
    zh: "zh",
    ro: "ro",
    ms: "ms",
    cs: "cs",
    hu: "hu",
    el: "el",
    fi: "fi",
    sk: "sk",
    hr: "hr",
  };

  return language[code];
};
