import { defineMessages } from "react-intl";

const messages = defineMessages({
  hint: {
    id: "hint",
    defaultMessage: "Hint",
    description:
      "This message will help a user to understand that it is a hint. e.g. From hint",
  },
  originLabel: {
    id: "origin.label",
    defaultMessage: "From",
    description: "Label for the origin search box",
  },
  originPlaceholder: {
    id: "origin.placeholder",
    defaultMessage: "Enter a location",
    description: "Placeholder for the origin search box",
  },
  destinationLabel: {
    id: "destination.label",
    defaultMessage: "To",
    description: "Label for the destination search box",
  },
  destinationPlaceholder: {
    id: "destination.placeholder",
    defaultMessage: "Where to?",
    description: "Placeholder for the destination search box",
  },
  hotelDestinationPlaceholder: {
    id: "hotelDestination.placeholder",
    defaultMessage: "Destination",
    description: "Placeholder for the hotel destination search box",
  },
  dateLabel: {
    id: "date.label",
    defaultMessage: "Departure date",
    description: "Label for the date input",
  },
  datePlaceholder: {
    id: "date.placeholder",
    defaultMessage: "Departure date",
    description: "Placeholder for the date input",
  },
});

export default messages;
