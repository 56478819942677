import { useEffect } from "react";
import { safeLocalStorage } from "../../utils/safeLocalStorage";
import { sendAnalyticsNonInteractionEvent } from "../sendAnalyticsNonInteractionEvent";
import { Screen } from "../../domain/SearchScreen/SearchScreen";
import { getSession } from "./session";

type PathName = string;
type RouteCanonical = string;
type SegmentIndex = number;

export type CoreExperienceProps = {
  experienceHash:
    | `${PathName}:r${RouteCanonical}`
    | `${PathName}:r${RouteCanonical}:s${SegmentIndex}`;
  analyticsLabel: `${Screen} Screen`;
};

export const coreExperiencesKey = "uniqueCoreExperiences_v1";

// This hook is responsible for sending a CoreExperience Interaction event
// each time a unique core experience happens and a CoreExperience Engaged event
// each time a user reaches three unique core experiences. An experience is considered
// unique if that experience hasn't yet happened this "session".
export function useAnalyticsCoreExperience({
  experienceHash,
  analyticsLabel,
}: CoreExperienceProps) {
  // Clean up core experiences from past sessions because
  // they don't affect whether we send GA events in this session.
  const currentSession = getSession();
  useEffect(() => {
    const experiences = getUniqueCoreExperiences();
    const experiencesFromCurrentSession = deleteExperiencesFromOtherSessions(
      experiences,
      currentSession
    );

    safeLocalStorage.setItem(
      coreExperiencesKey,
      JSON.stringify(experiencesFromCurrentSession)
    );
  }, [currentSession]);

  // Send a GA event for every unique core experience and store the unique core
  // experience in local storage.
  useEffect(() => {
    let uniqueCoreExperiences = getUniqueCoreExperiences();

    // If this experience hasn't been accounted for in this session then we
    // want to send a core experience event to GA and update local storage.
    if (isNewCoreExperience(experienceHash, uniqueCoreExperiences)) {
      sendAnalyticsNonInteractionEvent(
        "CoreExperience",
        "Interaction",
        analyticsLabel
      );

      // Add the new unique core experience to local storage so we know
      // we've sent an event for this experience already.
      uniqueCoreExperiences.push({
        hash: experienceHash,
        session: getSession(),
      });

      safeLocalStorage.setItem(
        coreExperiencesKey,
        JSON.stringify(uniqueCoreExperiences)
      );

      // If the addition of the last core experience brings the total
      // number of core experiences to three, the user is now considered engaged
      // and an Engaged event will be sent to GA.
      const isNewlyEngaged = Object.keys(uniqueCoreExperiences).length === 3;
      if (isNewlyEngaged) {
        sendAnalyticsNonInteractionEvent("CoreExperience", "Engaged");
      }
    }
  }, [experienceHash, analyticsLabel]);
}

type CoreExperience = {
  hash: string;
  session: number;
};

type CoreExperiences = CoreExperience[];

function getUniqueCoreExperiences(): CoreExperiences {
  const localStorageValue = safeLocalStorage.getItem(coreExperiencesKey);
  return localStorageValue ? JSON.parse(localStorageValue) : [];
}

function isNewCoreExperience(
  experienceHash: string,
  coreExperiences: CoreExperiences
): boolean {
  return !coreExperiences.some(
    (experience) => experience.hash === experienceHash
  );
}

function deleteExperiencesFromOtherSessions(
  coreExperiences: CoreExperiences,
  currentSession: number
): CoreExperiences {
  return coreExperiences.filter(
    (experience) => experience.session === currentSession
  );
}
