import { defineMessages } from "react-intl";

const messages = defineMessages({
  ukraineReduced: {
    id: "searchNotification.ukraineReduced",
    description:
      "Text to warn users of transport disruptions due to current situation in Uklraine.",
    defaultMessage:
      "Transport is unpredictable due to the Russia-Ukraine war. Contact operators or",
  },
  readMore: {
    id: "searchNotification.readMore",
    defaultMessage: "read more.",
    description:
      "The text on a button that comes after text explaining travel restrictions.",
  },
});

export default messages;
