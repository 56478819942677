import { defineMessages } from "react-intl";

const messages = defineMessages({
  requestFailed: {
    id: "discover.requestError",
    description: "Error message when the destination request has an error",
    defaultMessage: "Sorry! There was an error retrieving destinations.",
  },
  retry: {
    id: "discover.retry",
    description: "Retry button text",
    defaultMessage: "Retry",
  },
});

export default messages;
