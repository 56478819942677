export function removeDuplicateConsequential<T>(list: T[], prop: keyof T): T[] {
  const filteredList: T[] = list.filter((item, index, array) => {
    if (index === 0) {
      return true;
    }

    const previousItem = array[index - 1];

    const previousItemName = previousItem[prop];
    const currentItemName = item[prop];

    return previousItemName !== currentItemName;
  });
  return filteredList;
}
