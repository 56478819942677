import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useIsTripScreen } from "src/utils/hooks/useIsTripScreen";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { SearchResponse } from "../../api/SearchResponse";
import { hashChange } from "../../utils/location/hashChange";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { useSkipToFlightSegment } from "../../utils/hooks/useSkipToFlightSegment";
import logError from "../../utils/logError";

// Redirect users to search results screen if there's an invalid route canonical in the url.
// Redirect users to segment screen if the route is a single-segment route.
export function useEnsureValidRoute(
  searchResponse: SearchResponse | undefined,
  routeIndex: number | undefined
) {
  // TODO: Something is broken here when we need to skip a flight route.
  // We seem to get a flash render of the route before redirecting.

  const navigate = useNavigate();
  const location = useTypedLocation();
  const [hasRedirected, setHasRedirected] = useState(false);

  const skipToFlightSegment = useSkipToFlightSegment(routeIndex);
  const isTripScreen = useIsTripScreen();
  const tripHash = tripHashFromSearchResponse(searchResponse);

  useEffect(() => {
    // If we're not on a route url then do nothing
    // because we're already redirecting.
    if (hasRedirected) {
      return;
    }

    // We need to wait for a search response to determine validity.
    if (!searchResponse) {
      return;
    }

    if (
      routeIndex === undefined ||
      routeIndex >= searchResponse.routes.length
    ) {
      // The route index might be out of bounds because we use useLastValidIndex
      // to keep track of the last valid route, which, when the search results
      // change might be out of bounds.
      setHasRedirected(true);

      // If we're inside of the trip planner modal, we navigate via the hash
      if (isTripScreen) {
        navigateToNewStateHash(
          navigate,
          {
            highlightedTab: "trips",
          },
          `#trips`,
          {
            ...location,
          },
          true
        );
      } else {
        navigate(hashChange(undefined, location), { replace: true });
      }
    } else if (!isMultiSegmentRoute(searchResponse, routeIndex)) {
      // We should never show single-segment routes because we should've gone
      // straight to the segment screen (or search results screen if pressed back) instead.
      setHasRedirected(true);

      if (isTripScreen) {
        navigateToNewStateHash(
          navigate,
          {
            highlightedTab: "trips",
          },
          `${tripHash}/r/${searchResponse.routes[routeIndex].canonicalName}/s/0`,
          {
            ...location,
          },
          true
        );
      } else {
        navigate(
          hashChange(
            `#r/${searchResponse.routes[routeIndex].canonicalName}/s/0`,
            location
          ),
          { replace: true }
        );
      }
    } else if (skipToFlightSegment !== undefined) {
      // We should never show single-segment routes because we should've gone
      // straight to the segment screen (or search results screen if pressed back) instead.
      setHasRedirected(true);

      if (isTripScreen) {
        navigateToNewStateHash(
          navigate,
          {
            highlightedTab: "trips",
          },
          `${tripHash}/r/${searchResponse.routes[routeIndex].canonicalName}/s/${skipToFlightSegment}`,
          {
            ...location,
          },
          true
        );
      } else {
        navigate(
          hashChange(
            `#r/${searchResponse.routes[routeIndex].canonicalName}/s/${skipToFlightSegment}`,
            location
          ),
          { replace: true }
        );
      }
    }
  }, [
    searchResponse,
    navigate,
    location,
    routeIndex,
    hasRedirected,
    skipToFlightSegment,
    isTripScreen,
    tripHash,
  ]);

  return isValidRoute(searchResponse, routeIndex);
}

export function isValidRoute(
  searchResponse: SearchResponse | undefined,
  routeIndex: number | undefined
) {
  // We can’t know if it’s valid until we get a search response.
  if (!searchResponse) {
    return false;
  }
  if (routeIndex === undefined) {
    return false;
  }

  // We should never show single-segment routes because we should’ve gone
  // straight to the segment screen (or search results screen if pressed back) instead.
  return isMultiSegmentRoute(searchResponse, routeIndex);
}

function isMultiSegmentRoute(
  searchResponse: SearchResponse | undefined,
  routeIndex: number | undefined
) {
  // We can only know it’s a multi-segment route once we have a search response.
  if (searchResponse && routeIndex !== undefined) {
    const route = searchResponse.routes[routeIndex];
    if (!route) {
      // We've seen errors caused by the route being null because the routeIndex
      // is out-of-bounds. So, if the route is undefined we're not a multi-segment
      // route.
      logError(
        Error("route is undefined"),
        [],
        [
          {
            routeIndex,
            searchResponseRouteLength: searchResponse.routes.length,
          },
        ]
      );
      return false;
    }
    const numSegments = route.segments.length;
    if (numSegments > 1) {
      return true;
    }
  }
  return false;
}
