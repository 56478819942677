import { useEffect } from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { color, transitColor } from "../../../../theme";
import { Mode } from "../../../../utils/types/mode";

type PolylineProps = {
  googleMap: google.maps.Map;
  blurred?: boolean;
} & TransitPolyline;

export type TransitPolyline = {
  path: google.maps.LatLngLiteral[];
  transitMode: Mode;
};

export function Polyline(props: PolylineProps) {
  const { Polyline } = useMapsLibrary("maps") || {};
  const isGeodesic = ["plane", "helicopter", "ferry"].includes(
    props.transitMode
  );

  const strokeColor = props.blurred
    ? color.grey3
    : transitColor[props.transitMode];

  const zIndex = props.blurred ? 1 : 2;

  const strokeOpacity = props.blurred ? 0.5 : 1;

  const strokeWeight = props.blurred ? 3 : 4;

  useEffect(() => {
    if (!Polyline) {
      return;
    }
    const flightPath = new Polyline({
      path: props.path,
      geodesic: isGeodesic,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      zIndex: zIndex,
    });

    flightPath.setMap(props.googleMap);
    return () => flightPath.setMap(null);
  }, [
    isGeodesic,
    strokeColor,
    props.path,
    props.googleMap,
    zIndex,
    strokeOpacity,
    strokeWeight,
    Polyline,
  ]);

  return null;
}
