/**
 * Creates a local date at the given datetime as if it were in the given timezone.
 *
 * Please use with caution
 *
 * @param date
 * @param timeZone
 * @returns
 */
export function getTimeInTimeZone(
  timeZone?: string | number,
  date: Date = new Date()
) {
  if (typeof timeZone === "string") {
    // Using a string timezone is available on a vast majority of browsers
    // https://caniuse.com/mdn-javascript_builtins_date_tolocaletimestring_iana_time_zone_names
    const result = new Date(date.toLocaleString("en-US", { timeZone }));
    result.setMilliseconds(date.getMilliseconds());
    return result;
  } else if (typeof timeZone === "number") {
    const result = new Date(date);
    result.setUTCMinutes(
      result.getUTCMinutes() + result.getTimezoneOffset() + timeZone
    );
    return result;
  }
  return date;
}
