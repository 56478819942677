import { SearchResponse, TicketLink } from "../api/SearchResponse";

type LinkTypes = "tickets" | "onDemandPromo";

export function getTicketLinks(
  response: SearchResponse | undefined,
  routeIndex: number,
  segmentIndexOfRoute?: number,
  type?: LinkTypes
): TicketLink[] {
  const ticketType = type ?? "tickets";

  return getLinks(response, routeIndex, segmentIndexOfRoute).filter(
    (link) => link.type === ticketType
  );
}

function getLinks(
  response: SearchResponse | undefined,
  routeIndex: number,
  segmentIndexOfRoute?: number
): TicketLink[] {
  if (!response) {
    return [];
  } else if (segmentIndexOfRoute === undefined) {
    // The absence of a segment index means we have to look at the route.
    return response.routes[routeIndex].links ?? [];
  } else {
    const route = response.routes[routeIndex];
    const segmentIndex = route.segments[segmentIndexOfRoute];
    return response.segments[segmentIndex].links ?? [];
  }
}
