import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "languageScreen.heading",
    defaultMessage: "Change your language",
    description: "Heading of page where user can change their language",
  },
  newHeading: {
    id: "languageScreen.newHeading",
    defaultMessage: "Language",
    description: "Heading of page where user can change their language",
  },
  description: {
    id: "languageScreen.description",
    defaultMessage: "Choose your language",
    description: "Description of page where user can change their language",
  },
});

export default messages;
