import { defineMessages } from "react-intl";

const messages = defineMessages({
  departureTitle: {
    id: "ticketExitConfirmation.depart.title",
    description: "Presented to the user above a date range for ticket search",
    defaultMessage: "Choose a departure date",
  },
  returnTitle: {
    id: "ticketExitConfirmation.return.title",
    description: "Presented to the user above a date range for ticket search",
    defaultMessage: "Choose a returning date",
  },
});

export default messages;
