import { getBoundsFromSegments } from "./internal/getBoundsFromSegments";
import type { MapSegment } from "./mapSegments";

type CenterZoomForSegments = {
  map: google.maps.Map;
  boundsSegments: MapSegment[];
  bounds: google.maps.LatLngBounds;
};

export function centerZoomMapForSegments({
  map,
  boundsSegments,
  bounds,
}: CenterZoomForSegments) {
  const segmentBounds = getBoundsFromSegments(boundsSegments, bounds);
  if (!segmentBounds) {
    return;
  }

  centerZoomMapForBounds({ map, bounds: segmentBounds });
}

type CenterZoomForBounds = {
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
};
export function centerZoomMapForBounds({ map, bounds }: CenterZoomForBounds) {
  if (!map) {
    return;
  }

  const mapRef = map.getDiv();
  const mapWidth = mapRef.clientWidth;
  const mapHeight = mapRef.clientHeight;

  // We must set a min width and height to prevent map dimensions from becoming negative.
  // Otherwise, the map will throw an error and break.
  const MIN_WIDTH = 200;
  const MIN_HEIGHT = 200;

  // 10% padding between the corners of the origin + destination pin and the edge of the Map.
  const padding = {
    width: Math.max(mapWidth * 0.1, MIN_WIDTH),
    height: Math.max(mapHeight * 0.1, MIN_HEIGHT),
  };

  map.fitBounds(bounds, {
    bottom: padding.height,
    right: padding.width,
    top: padding.height,
    left: padding.width,
  });
}
