import { defineMessages } from "react-intl";

const messages = defineMessages({
  currentTrip: {
    id: "myTripsModal.currentTrip",
    description: "Label for the current trip in the list",
    defaultMessage: "CURRENT TRIP",
  },
  deleteTrip: {
    id: "myTripsModal.deleteTrip",
    description: "Label for the delete trip button",
    defaultMessage: "Delete trip",
  },
  destinations: {
    id: "myTripsModal.destinations",
    description: "Label for the number of destinations in the trip",
    defaultMessage: "{numDestinations} destinations",
  },
  confirmDelete: {
    id: "myTripsModal.confirmDelete",
    description:
      "Message to delete an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Confirm Delete",
  },
  confirmDeleteMessage: {
    id: "myTripsModal.confirmDeleteMessage",
    description:
      "Message to delete an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Are you sure you want to delete {element}?",
  },
  delete: {
    id: "myTripsModal.delete",
    description:
      "Message to delete an element in a list. This helps screen readers to understand what the user is doing.",
    defaultMessage: "Delete",
  },
});

export default messages;
