import { useRef } from "react";
import { useNavigate } from "react-router";
import { spacing } from "src/theme";
import useSearch from "src/utils/hooks/useSearch";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import styled from "styled-components";
import { Button } from "src/design-system/components/Button/Button";
import { useIntl } from "react-intl";
import useDeleteTrip from "../hooks/useDeleteTrip";
import useSetTrip from "../hooks/useSetTrip";
import { useTripList } from "../hooks/useTripList";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { getScreenKey } from "../util/getScreenKey";
import { tripPlanAdapter } from "../util/tripPlanAdapter";
import { MyTripCardContent, MyTripCardSkeleton } from "./MyTripCardContent";
import MyTripCard from "./MyTripCard";
import messages from "./MyTrips.messsages";

type Props = {
  numVisibleTrips: number;
  setNumVisibleTrips: (num: number) => void;
  onSetTrip?: (id: string) => void;
  isLoggedOut?: boolean;
  isFullScreen?: boolean;
};

const PAGE_SIZE = 5;

export function TripList({
  onSetTrip,
  isLoggedOut,
  numVisibleTrips,
  setNumVisibleTrips,
  isFullScreen,
}: Props) {
  const { tripPlannerDetails } = useTripPlannerContext();
  const { isLoading: isSearchResponseLoading } = useSearch();
  const { data, isLoading } = useTripList();
  const setTrip = useSetTrip();
  const deleteTrip = useDeleteTrip();
  const location = useTypedLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const scrollRef = useRef<HTMLUListElement>(null);

  const visibleTrips = data?.slice(0, numVisibleTrips);
  const numTrips = data?.length ?? 0;

  function maybeNavigateToTripsScreen() {
    if (
      location.hash === "#h" ||
      !!getScreenKey(location.hash) ||
      isFullScreen
    ) {
      navigateToNewStateHash(navigate, { highlightedTab: "trips" }, "#trips", {
        ...location,
      });
    }
  }

  function handleSetTrip(id: string) {
    onSetTrip?.(id);
    setTrip(id);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    maybeNavigateToTripsScreen();
  }

  if (isLoading || isSearchResponseLoading) {
    return (
      <ScrollContainer ref={scrollRef} isFullScreen={isFullScreen}>
        <TripListSkeleton />
      </ScrollContainer>
    );
  }

  return (
    <ScrollContainer ref={scrollRef} isFullScreen={isFullScreen}>
      {isLoggedOut ? (
        <MyTripCardContent
          {...tripPlanAdapter(tripPlannerDetails)}
          isLoggedOut
        />
      ) : (
        <>
          <MyTripCardContent
            key={tripPlannerDetails.id ?? "current-trip-plan"}
            {...tripPlanAdapter(tripPlannerDetails)}
            onClick={maybeNavigateToTripsScreen}
            onClickDelete={() => deleteTrip(tripPlannerDetails.id!)}
            isCurrent
          />
          {visibleTrips?.map((tripAttributes) => {
            if (tripAttributes.id === tripPlannerDetails.id) return null;
            return (
              <MyTripCard
                key={tripAttributes.id}
                attributes={tripAttributes}
                onSetTrip={handleSetTrip}
                onDeleteTrip={deleteTrip}
                isLoggedOut={isLoggedOut}
              />
            );
          })}
        </>
      )}
      {numTrips > numVisibleTrips && (
        <Button
          variant="subtle"
          size="small"
          onPress={() =>
            setNumVisibleTrips(
              numVisibleTrips + Math.min(PAGE_SIZE, numTrips - numVisibleTrips)
            )
          }
        >
          {intl.formatMessage(messages.loadMore, {
            numMore: Math.min(PAGE_SIZE, numTrips - numVisibleTrips),
          })}
        </Button>
      )}
    </ScrollContainer>
  );
}
function TripListSkeleton() {
  return <MyTripCardSkeleton />;
}

const ScrollContainer = styled.ul<{ isFullScreen?: boolean }>`
  overflow-y: auto;
  max-height: 0 ${(props) => (props.isFullScreen ? "unset" : "60vh")};
  max-width: 100%;
  padding: 0 ${(props) => (props.isFullScreen ? 0 : spacing.xl)};

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
  }
`;
