import { useRef } from "react";
import { useHandleClickOffElement } from "../../utils/hooks/useHandleClickOffElement";
import { MergeElementProps } from "../../utils/MergeElementProps";

type Props = MergeElementProps<
  "div",
  {
    className?: string;
    onClickAway?: (e: MouseEvent) => void;
  }
>;

export function ClickAwayListener({
  onClickAway,
  className,
  children,
  ...other
}: Props) {
  const elRef = useRef<HTMLDivElement>(null);

  useHandleClickOffElement(elRef, onClickAway);

  return (
    <div {...other} className={className} ref={elRef}>
      {children}
    </div>
  );
}
