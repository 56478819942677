import { ReactNode, useState } from "react";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { color, fontSize, fontWeight, spacing } from "src/theme";
import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import styled, { css } from "styled-components";
import { Button } from "../Button/Button";
import { Dialog } from "../Dialog/Dialog";
import { Input } from "../Input/Input";

type Question = {
  title: string;
  correctAnswer: string;
};

type ConfirmationModalProps = {
  showModal: boolean;
  onCloseModal: () => void;
  onConfirm: () => void;
  title?: string;
  description?: ReactNode;
  confirmButtonText: string;
  cancelButtonText?: string;
  question?: Question;
  $height?: string;
};

export function ConfirmationModal({
  showModal,
  onCloseModal,
  onConfirm,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
  question,
  $height,
}: ConfirmationModalProps) {
  const isMobile = useLayout() === "mobile";
  const [answer, setAnswer] = useState("");

  return (
    <Dialog
      isOpen={showModal}
      onBackdropClicked={onCloseModal}
      variant={isMobile ? "extra-large" : "small"}
    >
      <ModalContainer $height={$height}>
        <CloseButtonWrapper>
          <CloseButton tint="cod" onClick={onCloseModal} />
        </CloseButtonWrapper>
        <ModalHeader>{title && <ModalTitle>{title}</ModalTitle>}</ModalHeader>
        {description && <Description>{description}</Description>}
        {question && (
          <Input
            id="confirmation-modal-question"
            name="confirmation-modal-question"
            type="text"
            label={question.title}
            onChange={(event) => setAnswer(event.currentTarget.value)}
            autoComplete="off"
            inputMode="text"
            required
          />
        )}
        <ButtonsWrapper>
          <Button
            data-testid="confirm-button"
            onClick={onConfirm}
            textColor="primaryOnDark"
            backgroundColor="pink"
            disabled={question && question.correctAnswer !== answer}
          >
            {confirmButtonText}
          </Button>
          {cancelButtonText && (
            <CancelButton
              onClick={onCloseModal}
              textColor="primaryOnLight"
              backgroundColor="transparent"
              inline
              size="large"
            >
              {cancelButtonText}
            </CancelButton>
          )}
        </ButtonsWrapper>
      </ModalContainer>
    </Dialog>
  );
}

const ModalContainer = styled.div<{ $height?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spacing.xl};
  gap: ${spacing.xxl};
  ${desktopLayout} {
    max-width: 432px;
    ${({ $height }) => $height && css({ height: $height })}
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: ${spacing.xl};
  top: ${spacing.xl};
`;

const ModalHeader = styled.div`
  width: 90%;
`;
const ModalTitle = styled.h5`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h5};
  color: ${color.cod};
  width: 100%;
`;

const CancelButton = styled(Button)`
  height: 18px;
  min-height: unset;
  width: fit-content;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  margin-left: auto;
  margin-right: auto;
  span {
    font-weight: ${fontWeight.normal};
  }
  ${desktopLayout} {
    min-width: 40%;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${spacing.xl};
  ${desktopLayout} {
    flex-direction: row-reverse;
    margin-top: auto;
    gap: 0;
  }
`;

const Description = styled.p``;
