import { defineMessages } from "react-intl";

const messages = defineMessages({
  logoLabel: {
    id: "searchbox.logo",
    description: "The name of our small letter logo for screen readers.",
    defaultMessage: "logo",
  },
  search: {
    id: "searchbox.search",
    description: "The text for the search button.",
    defaultMessage: "Search",
  },
});

export default messages;
