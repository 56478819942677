import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { Geocoded } from "../../PrefetchData";
import { Place, SearchResponse } from "../../api/SearchResponse";
import { SearchPlace } from "../hooks/useSearch";

export const placesFromSegment = (
  response: SearchResponse | PartialSearchResponse,
  segmentIndex: number
): Place[] => {
  const segment = response.segments[segmentIndex];
  const option = response.options[segment.options[0]];
  return option.hops.flatMap((hopIndex) => {
    const hop = response.hops[hopIndex];
    const line = response.lines[hop.line];
    return line.places.map((placeIndex) => response.places[placeIndex]);
  });
};

export const originPlaceFromSearch = (response: SearchResponse): Place => {
  return response.places[response.result.originPlace];
};

export const originPlaceFromRouteOrSegment = (
  response: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  segmentIndexInRoute?: number
): Place => {
  if (segmentIndexInRoute === undefined || isNaN(segmentIndexInRoute)) {
    return originPlaceFromRoute(response, routeIndex);
  } else {
    return originPlaceFromSegment(
      response,
      response.routes[routeIndex].segments[segmentIndexInRoute]
    );
  }
};

export const originPlaceFromRoute = (
  response: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  getSegmentPlace?: boolean
): Place => {
  const route = response.routes[routeIndex];
  if (!getSegmentPlace) {
    return response.places[route.places[0]];
  }
  return originPlaceFromSegment(response, route.segments[0]);
};

export const originPlaceFromSegment = (
  response: SearchResponse | PartialSearchResponse,
  segmentIndex: number
): Place => {
  const segment = response.segments[segmentIndex];
  return originPlaceFromOption(response, segment.options[0]);
};

export const originPlaceFromOption = (
  response: SearchResponse | PartialSearchResponse,
  optionIndex: number
): Place => {
  const option = response.options[optionIndex];
  const firstHopIndex = option.hops[0];
  return originPlaceFromHop(response, firstHopIndex);
};

export const destAirportNameFromPlace = (
  response: SearchResponse,
  flightDestCode?: string
) => {
  const destAirportPlace = response.places
    .filter((place) => place.kind === "airport")
    .find((place) => place.code === flightDestCode);
  // Trim the IATA present at the end of the short name e.g London Heathrow (LHR)
  const destAirportName = destAirportPlace?.shortName.replace(
    /^(.*)\s\(\w{3}\)$/,
    "$1"
  );

  return destAirportName;
};

export const originPlaceFromHop = (
  response: SearchResponse | PartialSearchResponse,
  hopIndex: number
): Place => {
  const hop = response.hops[hopIndex];
  const lineIndex = hop.line;
  const firstPlaceIndex = response.lines[lineIndex].places[0];
  return response.places[firstPlaceIndex];
};

export const destinationPlaceFromSearch = (response: SearchResponse): Place => {
  return response.places[response.result.destinationPlace];
};

export const destinationPlaceFromRouteOrSegment = (
  response: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  segmentIndexInRoute?: number
): Place => {
  if (segmentIndexInRoute === undefined) {
    return destinationPlaceFromRoute(response, routeIndex);
  } else {
    return destinationPlaceFromSegment(
      response,
      response.routes[routeIndex].segments[segmentIndexInRoute]
    );
  }
};

export const destinationPlaceFromRoute = (
  response: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  getSegmentPlace?: boolean
): Place => {
  const route = response.routes[routeIndex];
  if (!getSegmentPlace) {
    return response.places[route.places[route.places.length - 1]];
  }
  return destinationPlaceFromSegment(
    response,
    route.segments[route.segments.length - 1]
  );
};

export const destinationPlaceFromSegment = (
  response: SearchResponse | PartialSearchResponse,
  segmentIndex: number
): Place => {
  const segment = response.segments[segmentIndex];
  return destinationPlaceFromOption(response, segment.options[0]);
};

export const destinationPlaceFromOption = (
  response: SearchResponse | PartialSearchResponse,
  optionIndex: number
): Place => {
  const option = response.options[optionIndex];
  const lastHopindex = option.hops[option.hops.length - 1];
  return destinationPlaceFromHop(response, lastHopindex);
};

export const destinationPlaceFromHop = (
  response: SearchResponse | PartialSearchResponse,
  hopIndex: number
): Place => {
  const hop = response.hops[hopIndex];
  const lineIndex = hop.line;
  const places = response.lines[lineIndex].places;
  const lastPlaceIndex = places[places.length - 1];
  return response.places[lastPlaceIndex];
};

export function preferredNameFromPlace(place: Geocoded | SearchPlace | Place) {
  return place?.canonicalName ?? place?.longName ?? place?.shortName;
}
