import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { uuid } from "./utils/uuid";

export type PassengerType = "adult" | "senior" | "youth";
export type Passenger = {
  type: PassengerType;
  age: string;
  id: string;
};

export type PassengerDetails = Passenger[];

type PassengerDetailsProps = {
  passengerDetails: PassengerDetails;
  setPassengerDetails: Dispatch<SetStateAction<PassengerDetails>>;
};

const DEFAULT_PASSENGER_DETAILS: PassengerDetails = [
  {
    type: "adult",
    age: "",
    id: uuid(),
  },
];

export type TicketableSchedulesPassengerDetails = {
  paxTypes: string;
  paxCards: string;
  paxAges: string;
};

export type StaticSchedulesPassengerDetails = {
  adults: string;
  seniors: string;
  youths: string;
  ages: string;
};

export const PassengerDetailsContext = createContext<
  PassengerDetailsProps | undefined
>(undefined);

export function PassengerDetailsProvider({
  passengerDetails: initial,
  ...props
}: PropsWithChildren<{ passengerDetails?: PassengerDetails }>) {
  // Passenger details for adding and removing passengers. Default 1 adult passenger.
  const [passengerDetails, setPassengerDetails] = useState<PassengerDetails>(
    initial ?? DEFAULT_PASSENGER_DETAILS
  );

  return (
    <PassengerDetailsContext.Provider
      value={{ passengerDetails, setPassengerDetails }}
      {...props}
    />
  );
}

export function usePassengerDetailsContext() {
  const context = useContext(PassengerDetailsContext);
  if (!context) {
    throw new Error(
      `usePassengerDetailsContext must be used within PassengerDetailsContext`
    );
  }
  return context;
}

export function getTicketableSchedulesPassengerDetails(
  passengerDetails: PassengerDetails
): TicketableSchedulesPassengerDetails {
  let paxTypes = "";
  let paxCards = "";
  let paxAges = "";

  // Evaluate the passengers in order of adults, seniors then youths.
  passengerDetails
    .sort((a, b) => a.type.localeCompare(b.type))
    .forEach((passenger, index) => {
      let onLastPassengerIndex = index !== passengerDetails.length - 1;
      paxTypes +=
        "pax" +
        passenger.type.charAt(0).toUpperCase() +
        passenger.type.slice(1);
      paxAges += passenger.age;
      paxTypes += onLastPassengerIndex ? "|" : "";
      paxCards += onLastPassengerIndex ? "|" : "";
      paxAges += onLastPassengerIndex ? "|" : "";
    });

  return {
    paxTypes,
    paxCards,
    paxAges,
  };
}

export function getStaticSchedulesPassengerDetails(
  passengerDetails: PassengerDetails
): StaticSchedulesPassengerDetails {
  let adults = 0;
  let seniors = 0;
  let youths = 0;
  let ages = "";

  // Evaluate the passengers in order of adults, seniors then youths.
  passengerDetails
    .sort((a, b) => a.type.localeCompare(b.type))
    .forEach((passenger, index) => {
      let onLastPassengerIndex = index !== passengerDetails.length - 1;
      switch (passenger.type) {
        case "adult":
          adults += 1;
          break;
        case "senior":
          seniors += 1;
          break;
        case "youth":
          youths += 1;
          break;
      }
      ages += passenger.age;
      ages += onLastPassengerIndex ? "," : "";
    });

  return {
    adults: adults.toString(),
    seniors: seniors.toString(),
    youths: youths.toString(),
    ages,
  };
}
