import { defineMessages } from "react-intl";

const messages = defineMessages({
  start: {
    id: "tripPlanner.introduction.start",
    defaultMessage: "Start planning",
    description: "CTA text prompting user to start planning a trip",
  },
  title: {
    id: "tripPlanner.introduction.title",
    defaultMessage: "Introducing Trips",
    description:
      "Title introducing the trip planner page, when nothing has been saved",
  },
  caption: {
    id: "tripPlanner.introduction.caption",
    defaultMessage: "Choose some places or cities to visit.",
    description: "Short description of the trip planner",
  },
  listtitle: {
    id: "tripplanner.introduction.list.title",
    defaultMessage: "What's new",
    description: "Title just before the list of features",
  },
  search: {
    id: "tripplanner.introduction.list.search",
    defaultMessage: "Search for travel options",
    description: "Description of the search icon",
  },
  save: {
    id: "tripplanner.introduction.list.save",
    defaultMessage: "Tap to get started ",
    description: "Description of the save icon",
  },
  add: {
    id: "tripplanner.introduction.list.add",
    defaultMessage: "Add your stops and travel",
    description: "Description of the plus icon",
  },
  feedbackLink: {
    id: "triplanner.introduction.feedbacklink",
    defaultMessage: "What do you think of our new trip planning tool?",
    description: "Text prompting to leave feedback via a link",
  },
  feedbackLinkMobile: {
    id: "triplanner.introduction.feedbacklinkMobile",
    defaultMessage: "What do you think of our trip planning tool?",
    description: "Text prompting to leave feedback via a link on mobile",
  },
  return: {
    id: "tripplanner.introduction.return",
    defaultMessage: "Return to your search",
    description: "CTA allowing user to return to search",
  },
  keepPlanning: {
    id: "tripplanner.introduction.keepPlanning",
    defaultMessage: "Keep planning",
    description: "Title above hotel and car rental promotions",
  },
});

export default messages;
