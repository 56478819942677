/**
 * Used to check the equality of two primitive arrays. Returns true if every
 * element of a is equal to the corresponding element of b.
 * @param a the first array value
 * @param b the second array value
 * @example
 * primitiveArrayEquals(['apple', 'banana'], ['apple']]) // returns false.
 * primitiveArrayEquals(['apple', 'banana'], ['banana', 'apple']]) // returns false.
 * primitiveArrayEquals(['apple', 'banana'], ['apple', 'banana']]) // returns true.
 */
export function primitiveArrayEquals(a: unknown[], b: unknown[]): boolean {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}
