import { defineMessages } from "react-intl";

const messages = defineMessages({
  backLabel: {
    id: "label.back",
    description: "The label on the back button for screen readers",
    defaultMessage: "Back",
  },
});

export default messages;
