type ConfettiProps = {
  size?: "large" | "small";
};

export function Confetti({ size = "small" }: ConfettiProps) {
  const SizedConfetti = size === "small" ? SmallConfetti : LargeConfetti;
  return <SizedConfetti />;
}

function SmallConfetti() {
  return (
    <svg
      width="360"
      height="226"
      viewBox="0 0 360 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          id="rect2"
          fill="
    #ffad00"
          d="M 29.0181 104.609 L 36.7727 100.553 L 50.8323 128.717 L 43.0776 132.773 Z"
        />
        <path
          id="rect4"
          fill="
    #04c9a6"
          d="M 208.522 7.09924 L 213.788 9.79882e-05 L 238.397 19.1265 L 233.131 26.2256 Z"
        />
        <path
          id="rect6"
          fill="
    #de007b"
          d="M 66 9.05591 L 73.7546 5.00001 L 87.8142 33.1639 L 80.0595 37.2198 Z"
        />
        <path
          id="rect8"
          fill="
    #de007b"
          d="M 324.515 193.801 L 332.008 197.834 L 317.628 225.828 L 310.134 221.795 Z"
        />
        <path
          id="rect10"
          fill="
    #ffad00"
          d="M 264.381 65 L 271.874 69.0332 L 257.494 97.0268 L 250 92.9936 Z"
        />
        <path
          id="rect12"
          fill="
    #04c9a6"
          d="M 35.3745 57 L 39.8184 64.4529 L 13.4437 80.9298 L 8.99982 73.4769 Z"
        />
        <path
          id="rect14"
          fill="
    #734286"
          d="M 355.556 103.039 L 360 110.492 L 333.625 126.969 L 329.181 119.516 Z"
        />
        <path
          id="rect16"
          fill="
    #734286"
          d="M 126.375 74 L 130.819 81.4529 L 104.444 97.9298 L 100 90.4769 Z"
        />
        <path
          id="rect18"
          fill="
    #ffad00"
          d="m 146,136 h 8.42698 v 31.6277 H 146 Z"
        />
        <path
          id="rect20"
          fill="
    #04c9a6"
          d="m 182,83 h 8.42698 v 31.6277 H 182 Z"
        />
        <path
          id="rect22"
          fill="
    #de007b"
          d="M 77.293 176.396 L 78.3432 168.089 L 108.987 172.147 L 107.937 180.455 Z"
        />
        <path
          id="rect24"
          fill="
    #de007b"
          d="M 0 198.153 L 1.05015 189.846 L 31.6937 193.904 L 30.6435 202.212 Z"
        />
        <path
          id="rect26"
          fill="
    #04c9a6"
          d="M 268.785 154.475 L 269.835 146.168 L 300.479 150.226 L 299.429 158.534 Z"
        />
      </g>
    </svg>
  );
}

function LargeConfetti() {
  return (
    <svg
      width="724"
      height="216"
      viewBox="0 0 724 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.7"
        width="8.67721"
        height="31.0984"
        transform="matrix(0.512135 0.858905 -0.848104 0.52983 488.375 8)"
        fill="#734286"
      />
      <g opacity="0.5">
        <rect
          opacity="0.7"
          width="8.75127"
          height="31.4782"
          transform="matrix(0.886116 -0.463464 0.446643 0.894712 683.389 187.055)"
          fill="#FFAD00"
        />
        <rect
          width="8.83919"
          height="31.1674"
          transform="matrix(-0.595786 -0.803144 -0.789565 0.613667 545.875 58.0996)"
          fill="#04C9A6"
        />
        <rect
          width="8.75127"
          height="31.4782"
          transform="matrix(-0.886116 -0.463464 -0.446643 0.894712 646.875 4.05591)"
          fill="#DE007B"
        />
        <rect
          width="8.67721"
          height="31.0984"
          transform="matrix(-0.512135 0.858905 0.848104 0.52983 697.5 58)"
          fill="#04C9A6"
        />
        <rect
          opacity="0.7"
          width="8.67721"
          height="31.0984"
          transform="matrix(-0.512135 0.858905 0.848104 0.52983 631.5 103)"
          fill="#734286"
        />
        <rect
          width="8.37341"
          height="30.9111"
          transform="matrix(0.125415 -0.992104 0.991343 0.1313 557.51 167.307)"
          fill="#DE007B"
        />
      </g>
      <g opacity="0.5">
        <rect
          opacity="0.7"
          width="8.75127"
          height="31.4782"
          transform="matrix(-0.886116 -0.463464 -0.446643 0.894712 40.4856 187.055)"
          fill="#FFAD00"
        />
        <rect
          width="8.83919"
          height="31.1674"
          transform="matrix(0.595786 -0.803144 0.789565 0.613667 202 10.0991)"
          fill="#04C9A6"
        />
        <rect
          width="8.75127"
          height="31.4782"
          transform="matrix(0.886116 -0.463464 0.446643 0.894712 77.0002 4.05591)"
          fill="#DE007B"
        />
        <rect
          width="8.67721"
          height="31.0984"
          transform="matrix(0.512135 0.858905 -0.848104 0.52983 26.3747 58)"
          fill="#04C9A6"
        />
        <rect
          opacity="0.7"
          width="8.67721"
          height="31.0984"
          transform="matrix(0.512135 0.858905 -0.848104 0.52983 96.3746 91)"
          fill="#734286"
        />
        <rect
          opacity="0.7"
          width="8.42698"
          height="31.6277"
          transform="matrix(-1 0 0 1 183.427 51)"
          fill="#FFAD00"
        />
        <rect
          width="8.37341"
          height="30.9111"
          transform="matrix(-0.125415 -0.992104 -0.991343 0.1313 110.365 167.307)"
          fill="#DE007B"
        />
        <rect
          width="8.37341"
          height="30.9111"
          transform="matrix(0.125415 -0.992104 0.991343 0.1313 329 25.3076)"
          fill="#DE007B"
        />
      </g>
    </svg>
  );
}
