import styled from "styled-components";
import { useIntl } from "react-intl";
import { useState } from "react";
import useSearch from "src/utils/hooks/useSearch";
import {
  PlaceName,
  useCreateTripFromSearch,
} from "src/utils/useCreateTripFromSearch";
import { fontSize, fontWeight, lineHeight, spacing } from "../../../../theme";
import messages from "../TripPlannerOnboarding.messages";
import { largeDesktopLayout } from "../../../../utils/hooks/useLayout";
import * as training from "./TripTraining.messages";
import { TripTrainingFields } from "./TripTrainingFields";

type TripTrainingProps = {
  hideTrainingCallback: VoidFunction;
};

export function TripTraining({ hideTrainingCallback }: TripTrainingProps) {
  const intl = useIntl();

  const { origin: searchOrigin, destination: searchDestination } = useSearch();

  const [selectedOrigin, setSelectedOrigin] = useState<PlaceName | undefined>(
    searchOrigin
  );
  const [selectedDestination, setSelectedDestination] = useState<
    PlaceName | undefined
  >(searchDestination);

  const { places, createTrip } = useCreateTripFromSearch({
    origin: selectedOrigin,
    destination: selectedDestination,
    setOrigin: setSelectedOrigin,
    setDestination: setSelectedDestination,
  });

  return (
    <Container>
      <Heading>
        {intl.formatMessage(training.messages.startDrawerTitle)}
      </Heading>
      <Caption>{intl.formatMessage(messages.caption)}</Caption>
      <TripTrainingFields
        key={`${selectedOrigin?.canonicalName}-${selectedDestination?.canonicalName}`}
        createTripCallback={createTrip}
        hideTrainingCallback={hideTrainingCallback}
        places={places}
        setOrigin={setSelectedOrigin}
        setDestination={setSelectedDestination}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 328px;
  max-width: 100%;
  margin: ${spacing.xl} auto;
  ${largeDesktopLayout} {
    width: 592px;
    max-width: 100%;
    margin: calc(${spacing.xl} * 3) auto calc(${spacing.xl} * 4);
  }
`;
const Heading = styled.h3`
  text-align: center;
  margin-bottom: ${spacing.sm};
  font-size: ${fontSize.h2};
`;
const Caption = styled.p`
  text-align: center;
  line-height: ${lineHeight.tight};
  font-weight: ${fontWeight.medium};
  margin: ${spacing.xl} 0 ${spacing.xxl};
  ${largeDesktopLayout} {
    font-size: ${fontSize.h4};
  }
`;
