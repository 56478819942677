import { useRef, useEffect } from "react";
import { safeLocalStorage } from "src/utils/safeLocalStorage";
import { useTripPlannerContext } from "./useTripPlannerContext";

export function useIsIntroductionIncomplete() {
  const { tripPlannerDetails } = useTripPlannerContext();
  const isIntroductionIncomplete = useRef<boolean>(getIsFreshUser());

  useEffect(() => {
    if (tripPlannerDetails.places.length > 0) {
      markIntroductionComplete();
    }
  }, [tripPlannerDetails.places.length]);

  return isIntroductionIncomplete.current;
}

function getIsFreshUser() {
  return !safeLocalStorage.getItem("tripOnboardingComplete");
}

export function markIntroductionComplete() {
  safeLocalStorage.setItem("tripOnboardingComplete", "true");
}
