import { SvgProps, useColor } from "../common";

export function Plane(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        d="M13.126 14.204L21 17.143v-1.837l-7.874-6.061V5.194c0-.747-.274-1.468-.77-2.034a.474.474 0 00-.711 0 3.083 3.083 0 00-.77 2.034v4.05L3 15.307v1.837l7.874-2.939v2.755c0 .258.065.51.188.736L8.25 19.53V21L12 19.531 15.75 21v-1.469l-2.814-1.836c.123-.227.188-.478.188-.736v-2.755h.002z"
      />
    </svg>
  );
}
