// The original source for setRef came from Material UI.
// See: https://github.com/mui-org/material-ui

import { MutableRefObject, RefCallback } from "react";

type Ref<T> = MutableRefObject<T | null> | RefCallback<T> | null | undefined;

/**
 * WARNING: Be sure to only call this inside a callback that is passed as a ref.
 * Otherwise, make sure to cleanup the previous {ref} if it changes. See
 * https://github.com/mui-org/material-ui/issues/13539
 *
 * Useful if you want to expose the ref of an inner component to the public API
 * while still using it inside the component.
 * @param ref A ref callback or ref object. If anything falsy, this is a no-op.
 */
export default function setRef<T>(ref: Ref<T>, value: T | null): void {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
