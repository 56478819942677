import { defineMessages } from "react-intl";

export const tripDateMessages = defineMessages({
  setStartDate: {
    id: "tripDate.setStartDate",
    defaultMessage: "Set your start date",
    description:
      "The label on calendar form that asks the user to choose a start date",
  },
  tripStarts: {
    id: "tripDate.tripStarts",
    defaultMessage: "Trip starts",
    description:
      "The text next to the date that tells the user which date their journey begins",
  },
  clear: {
    id: "tripDate.clear",
    defaultMessage: "Clear",
    description:
      "A button label that clears all date information from the calendar ",
  },
  update: {
    id: "tripDate.update",
    defaultMessage: "Update",
    description: "A button label that updates the start date when pressed",
  },
  updateAria: {
    id: "tripDate.updateAria",
    defaultMessage: "Update start date",
    description: "A button label that updates the start date when pressed",
  },
  instructions: {
    id: "tripDate.instructions",
    defaultMessage: "Choose when to begin your trip",
    description: "A label on a form that instructs the user to pick a date",
  },
  night: {
    id: "tripDate.night",
    defaultMessage: "night",
    description: "A label that displays a single night count. E.g. 1 night",
  },
  nights: {
    id: "tripDate.nights",
    defaultMessage: "nights",
    description:
      "A label that displays a multiple night count. E.g. 0 nights, 2 nights",
  },
});
