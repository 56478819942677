import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "calendarConfirmation.title",
    description: "Presented to the user above a date range for ticket search",
    defaultMessage: "Confirm your selection",
  },
  departureDate: {
    id: "calendarConfirmation.departure",
    description:
      "As part of a form for a ticket search. e.g. Departing: 20 Jan 2020",
    defaultMessage: "Departing: {date}",
  },
  returnDate: {
    id: "calendarConfirmation.return",
    description:
      "As part of a form for a ticket search. e.g. Returning: 30 Jan 2020",
    defaultMessage: "Returning: {date}",
  },
  next: {
    id: "calendarConfirmation.continue",
    description: "Prompt that opens up the ticket product",
    defaultMessage: "Find tickets now",
  },
  addReturnDate: {
    id: "calendarConfirmation.missingReturn",
    description: "Displayed as a hint to the user in a ticket search form",
    defaultMessage: "Add return date",
  },
});

export default messages;
