import { defineMessages } from "react-intl";

const messages = defineMessages({
  logoLabel: {
    id: "label.logo",
    description:
      "The name of our logo (that links to the home page) for screen readers e.g. home",
    defaultMessage: "home",
  },
  backLabel: {
    id: "label.back",
    description: "The label on the back button for screen readers",
    defaultMessage: "Back",
  },
});

export default messages;
