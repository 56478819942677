import { SvgProps, useColor } from "./common";

export const Settings = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 2.50004C5.85778 2.50004 2.49992 5.8579 2.49992 10C2.49992 14.1422 5.85778 17.5 9.99992 17.5C14.1421 17.5 17.4999 14.1422 17.4999 10C17.4999 5.8579 14.1421 2.50004 9.99992 2.50004ZM0.833252 10C0.833252 4.93743 4.93731 0.833374 9.99992 0.833374C15.0625 0.833374 19.1666 4.93743 19.1666 10C19.1666 15.0626 15.0625 19.1667 9.99992 19.1667C4.93731 19.1667 0.833252 15.0626 0.833252 10Z"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833252 10C0.833252 9.5398 1.20635 9.16671 1.66659 9.16671H18.3333C18.7935 9.16671 19.1666 9.5398 19.1666 10C19.1666 10.4603 18.7935 10.8334 18.3333 10.8334H1.66659C1.20635 10.8334 0.833252 10.4603 0.833252 10Z"
        fill={tintColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 0.833374C10.234 0.833374 10.4573 0.931841 10.6152 1.10469C12.8358 3.5358 14.0978 6.69075 14.1664 9.98268C14.1666 9.99425 14.1666 10.0058 14.1664 10.0174C14.0978 13.3093 12.8358 16.4643 10.6152 18.8954C10.4573 19.0682 10.234 19.1667 9.99992 19.1667C9.76582 19.1667 9.54251 19.0682 9.38463 18.8954C7.16399 16.4643 5.90201 13.3093 5.83343 10.0174C5.83319 10.0058 5.83319 9.99425 5.83343 9.98268C5.90201 6.69075 7.16399 3.5358 9.38463 1.10469C9.54251 0.931841 9.76582 0.833374 9.99992 0.833374ZM7.50011 10C7.55722 12.5624 8.43846 15.0293 9.99992 17.041C11.5614 15.0293 12.4426 12.5624 12.4997 10C12.4426 7.43767 11.5614 4.97074 9.99992 2.95904C8.43846 4.97074 7.55722 7.43767 7.50011 10Z"
        fill={tintColor}
      />
    </svg>
  );
};
