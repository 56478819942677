import Cookies from "js-cookie";

export const setLanguageCookie = (languageCode: SupportedLanguageCode) => {
  // set to expire in 1000 days because that's how it works in old MapView code
  Cookies.set("language", languageCode, {
    expires: 1000,
  });
};

export const supportedLanguageNames = {
  cs: "čeština",
  da: "Dansk",
  de: "Deutsch",
  el: "Ελληνικά",
  en: "English",
  es: "Español",
  fi: "Suomen kieli",
  fr: "Français",
  hr: "Hrvatski",
  hu: "Magyar",
  it: "Italiano",
  ja: "日本語",
  ko: "한글",
  ms: "Bahasa Melayu",
  nl: "Nederlands",
  no: "Norsk",
  pl: "Polski",
  pt: "Português",
  ro: "Românește",
  ru: "Русский",
  sk: "Slovenčina",
  sv: "Svenska",
  th: "ไทย",
  zh: "中文简体",
};

export type SupportedLanguageCode = keyof typeof supportedLanguageNames;

export const supportedLanguageCodes = Object.keys(
  supportedLanguageNames
) as SupportedLanguageCode[];

export function isSupportedLanguageCode(
  value: string
): value is SupportedLanguageCode {
  // This conversion is safe because value is a string and SupportedLanguageCode
  // is a union of strings.
  return supportedLanguageCodes.includes(value as SupportedLanguageCode);
}
