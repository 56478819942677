import Cookies from "js-cookie";
import { defineMessages } from "react-intl";

export const getDistanceSystemFromCookie = (): DistanceSystem | undefined => {
  const distanceUnit = Cookies.get("distanceUnit");

  if (distanceUnit === "km") {
    return "metric";
  } else if (distanceUnit === "mi") {
    return "imperial";
  } else {
    return undefined;
  }
};

export const setDistanceSystemCookie = (distanceSystem: DistanceSystem) => {
  const cookieValue = distanceSystem === "imperial" ? "mi" : "km";
  // set to expire in 1000 days because that's how it works in old MapView code
  Cookies.set("distanceUnit", cookieValue, {
    expires: 1000,
  });
};

export const distanceSystemMessages = defineMessages({
  imperial: {
    id: "distanceSystem.imperial",
    defaultMessage: "Miles",
    description: "Option for showing distances in miles",
  },
  metric: {
    id: "distanceSystem.metric",
    defaultMessage: "Kilometres",
    description: "Option for showing distances in kilometres",
  },
});

export function isDistanceSystem(value: string): value is DistanceSystem {
  // This conversion is safe because value is a string and DistanceSystem is
  // a union of strings.
  return supportedDistanceSystems.includes(value as DistanceSystem);
}

export type DistanceSystem = keyof typeof distanceSystemMessages;

export const supportedDistanceSystems = Object.keys(
  distanceSystemMessages
) as DistanceSystem[];
