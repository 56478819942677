import { defineMessages } from "react-intl";

const messages = defineMessages({
  savedTrips: {
    id: "tripPlanner.saved.heading",
    description: "Heading for saved trips screen",
    defaultMessage: "Saved trips",
  },
  newButton: {
    id: "tripPlanner.saved.newButton",
    description: "Button to create new trip",
    defaultMessage: "New",
  },
});

export default messages;
