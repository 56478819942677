import { spacing } from "src/design-system/tokens/spacing";
import styled from "styled-components";

import { Card, CardSkeleton } from "./Card/Card";
import useDiscover from "./hooks/useDiscover";

export type DiscoverInfoProps = {
  isGeocoding: boolean;
  origin:
    | {
        canonicalName: string;
        lat: number;
        lng: number;
      }
    | undefined;
};
export function DiscoverPaneCards({ origin, isGeocoding }: DiscoverInfoProps) {
  const { data: places, isLoading } = useDiscover(origin);

  if (isLoading || isGeocoding) {
    return <PaneCardsSkeleton />;
  }

  if (!origin) {
    return null;
  }

  return (
    <DiscoverPaneCardsDiv>
      {places?.map((place) => (
        <Card origin={origin} destination={place} key={place.canonicalName} />
      ))}
    </DiscoverPaneCardsDiv>
  );
}

export function PaneCardsSkeleton() {
  return (
    <DiscoverPaneCardsDiv>
      {Array.from({ length: 10 }).map((_, index) => (
        <CardSkeleton key={index} />
      ))}
    </DiscoverPaneCardsDiv>
  );
}

const DiscoverPaneCardsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.xl};
`;
