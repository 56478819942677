import Cookies from "js-cookie";
import { useSearchParams } from "./useSearchParams";

// Returns a backend feature string extracted from the current search params.
export function useBackendParamFeatures(): string[] {
  const searchParams = useSearchParams();
  return searchParams.get("backendFeatures")?.split(",") ?? [];
}

export function useBackendCookieFeatures(): string[] {
  return Cookies.get("feature")?.split(",") ?? [];
}

export function getBackendFeatureOverrideString(features: string[]): string {
  const allFeats = features.map((f) => parseBackendFeature(f));

  // We might have repeated feature names, so we want to
  // apply the last included value for each feature name.
  const backendFeatureState = allFeats.reduce(
    (features: { [feat: string]: boolean | undefined }, feat) => {
      features[feat.featureName] = feat.value;
      return features;
    },
    {}
  );

  const featureString = Object.entries(backendFeatureState)
    .map((feat) => makeBackendFeatureFlag(feat[0], feat[1]))
    .join(",");

  return featureString;
}

export function parseBackendFeature(feature: string): {
  featureName: string;
  value: boolean | undefined;
} {
  let value: boolean | undefined = true;
  let featureName = feature;
  if (feature.startsWith("!")) {
    value = false;
    featureName = feature.substring(1);
  }
  if (feature.startsWith("~")) {
    value = undefined;
    featureName = feature.substring(1);
  }
  return { featureName, value };
}

export function makeBackendFeatureFlag(
  featureName: string,
  value: boolean | undefined
): string {
  if (value === undefined) return `~${featureName}`;
  if (value) return featureName;
  return `!${featureName}`;
}
