import { defineMessages } from "react-intl";

const messages = defineMessages({
  requestFailed: {
    id: "destinationScreen.requestError",
    description: "Error message when the destination request has an error",
    defaultMessage:
      "Sorry! There was an error retrieving the destination information.",
  },
  requestNoResults: {
    id: "destinationScreen.noResults",
    description: "Error message when the destination request has no results",
    defaultMessage:
      "Sorry! We couldn't find any information for this destination.",
  },
  retry: {
    id: "destinationScreen.retry",
    description: "Retry button text",
    defaultMessage: "Retry",
  },
  close: {
    id: "destinationScreen.close",
    description: "Close button text",
    defaultMessage: "Go back",
  },
});

export default messages;
