import { SvgProps, useColor } from "../common";

export const ConnectedDots = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7.84C5 7.36851 5.15587 6.63217 5.6 6.04C6.00774 5.49634 6.70905 5 8 5C9.29095 5 9.99226 5.49634 10.4 6.04C10.8441 6.63217 11 7.36851 11 7.84V9V16V16.5C11 17.3267 11.2398 18.5445 11.9885 19.5843C12.7739 20.6751 14.0741 21.5 16 21.5C17.9259 21.5 19.2261 20.6751 20.0115 19.5843C20.7602 18.5445 21 17.3267 21 16.5V10C21 9.44771 20.5523 9 20 9C19.4477 9 19 9.44771 19 10V16.5C19 17.0066 18.8398 17.7889 18.3885 18.4157C17.9739 18.9915 17.2741 19.5 16 19.5C14.7259 19.5 14.0261 18.9915 13.6115 18.4157C13.1602 17.7889 13 17.0066 13 16.5V16V9V7.84C13 7.03149 12.7559 5.84783 12 4.84C11.2077 3.78366 9.90905 3 8 3C6.09095 3 4.79226 3.78366 4 4.84C3.24413 5.84783 3 7.03149 3 7.84V14.04C3 14.5923 3.44772 15.04 4 15.04C4.55228 15.04 5 14.5923 5 14.04V7.84ZM6 19C6 20.1046 5.10457 21 4 21C2.89543 21 2 20.1046 2 19C2 17.8954 2.89543 17 4 17C5.10457 17 6 17.8954 6 19ZM20 7C21.1046 7 22 6.10457 22 5C22 3.89543 21.1046 3 20 3C18.8954 3 18 3.89543 18 5C18 6.10457 18.8954 7 20 7Z"
        fill={tintColor}
      />
    </svg>
  );
};
