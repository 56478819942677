import { SvgProps, useColor } from "./common";

export const Key = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="#fff" d="M0 0h32v32H0z">
        <path
          fillRule="evenodd"
          d="M26.293 3.913c.33-.389.787-.761 1.206-.877a.825.825 0 01.498.004c.172.056.453.205.814.616.147.167.205.363.178.617-.031.282-.17.588-.369.816a2.023 2.023 0 00-.477 1.186c-.024.308.012.597.053.818.082.444.235.88.335 1.152.072.192.081.267.076.333-.005.05-.028.24-.299.588a1 1 0 01-.334.178.633.633 0 01-.244.028.565.565 0 01-.251-.094l-.015-.009-.847-.524a1.5 1.5 0 00-1.904.273l-1.254 1.394a1.5 1.5 0 00-.27 1.578l.556 1.34c.02.064.022.098.022.11 0 .012-.001.03-.016.07-.037.097-.158.31-.532.67a.769.769 0 01-.429.155c-.2.015-.458-.028-.747-.255a1.5 1.5 0 00-2.004.137l-2.888 2.985a11.454 11.454 0 00-.073-.1 9.474 9.474 0 00-1.562-1.7 10.654 10.654 0 00-.145-.125L26.23 3.982l.062-.069zm-13.608 9.828L24.039 1.933c.495-.574 1.42-1.446 2.663-1.789a3.822 3.822 0 012.224.044c.786.256 1.5.762 2.139 1.488 1.449 1.648.993 3.725.11 5.005l.048.169c.037.118.079.237.122.352.12.323.31.894.252 1.62-.06.741-.366 1.485-.952 2.225-.375.475-1.038.94-1.813 1.172-.673.2-1.499.242-2.337-.09l.04.098.029.075c.263.744.28 1.51-.006 2.266-.263.698-.743 1.277-1.26 1.774-.857.824-2.383 1.303-3.889.774l-2.903 3c.212.785.325 1.61.325 2.463 0 5.202-4.215 9.42-9.416 9.42C4.215 32 0 27.781 0 22.58s4.214-9.421 9.415-9.421c1.15 0 2.252.206 3.27.583zm.86 3.924a6.387 6.387 0 00-4.13-1.507A6.418 6.418 0 003 22.578 6.418 6.418 0 009.415 29a6.418 6.418 0 006.416-6.42 6.395 6.395 0 00-1.183-3.717 8.33 8.33 0 00-1.102-1.197zm-.81 4.914a3.32 3.32 0 11-6.64 0 3.32 3.32 0 016.64 0zm-2 0a1.32 1.32 0 11-2.64 0 1.32 1.32 0 012.64 0z"
          fill={tintColor}
        />
      </g>
    </svg>
  );
};
