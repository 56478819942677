import { defineMessages } from "react-intl";

const messages = defineMessages({
  departing: {
    id: "tabbedDatePicker.departing",
    defaultMessage: "Departing",
    description: "Label displayed above the users selected departure date.",
  },
  returning: {
    id: "tabbedDatePicker.returning",
    defaultMessage: "Returning",
    description: "Label displayed above the users selected return date.",
  },
  selectADate: {
    id: "tabbedDatePicker.selectADate",
    defaultMessage: "Select a date",
    description:
      "Message displayed below the departing or returning label prompting the users to select a date.",
  },
  datePicker: {
    id: "tabbedDatePicker.datePicker",
    defaultMessage: "Date Picker",
    description: "The heading for the date picker modal.",
  },
  oneWay: {
    id: "tabbedDatePicker.oneWay",
    defaultMessage: "One-way",
    description: "Text displayed on the tab title for the one way date picker.",
  },
  roundTrip: {
    id: "tabbedDatePicker.roundTrip",
    defaultMessage: "Round trip",
    description:
      "Text displayed on the tab title for the round trip date picker.",
  },
  searchOneWay: {
    id: "tabbedDatePicker.searchOneWay",
    defaultMessage: "Search one-way",
    description:
      "Text displayed on the search button for the one way date picker.",
  },
  searchRoundTrip: {
    id: "tabbedDatePicker.searchRoundTrip",
    defaultMessage: "Search round trip",
    description:
      "Text displayed on the search button for the round trip date picker.",
  },
  reset: {
    id: "tabbedDatePicker.reset",
    defaultMessage: "Reset",
    description: "Text displayed on the reset button to clear all date info.",
  },
  selectDepartureAndReturn: {
    id: "tabbedDatePicker.selectDepartureAndReturn",
    defaultMessage: "Select a departure and return date",
    description:
      "Message displayed when a user has not selected both a departure and return date.",
  },
  selectDeparture: {
    id: "tabbedDatePicker.selectDeparture",
    defaultMessage: "Select a departure date",
    description:
      "Message displayed when a user has not selected a departure date.",
  },
  selectReturn: {
    id: "tabbedDatePicker.selectReturn",
    defaultMessage: "Select a return date",
    description:
      "Message displayed when a user has not selected a return date.",
  },
});

export default messages;
