import { generateExitPoint } from "../analytics/generateExitPoint/generateExitPoint";
import { SearchResponse, TicketLink } from "../api/SearchResponse";
import { ticketMinDate } from "./adapters/ticketInfo";
import { formatIsoDate } from "./dateTime";
import { getTicketsUrlFromTemplate } from "./url";
import { getUtcOriginOffset } from "./useTicketForm";

export function getJayrideUrlFromTicketLink(
  ticketLink: TicketLink | undefined,
  searchResponse: SearchResponse | undefined
) {
  if (!searchResponse || !ticketLink) return null;

  const originUtcOffset = getUtcOriginOffset(searchResponse, 0);
  const minimumDate = ticketMinDate(originUtcOffset, ticketLink.pickerConfig);

  let ticketUrl = getTicketsUrlFromTemplate(
    ticketLink.url,
    ticketLink.templatedParameters,
    generateExitPoint("searchResults", "airportTransferCta", "shuttle"),
    minimumDate ? formatIsoDate(minimumDate) : undefined
  );

  return ticketUrl || null;
}
