import * as Sentry from "@sentry/react";

// Unfortunate, but we can make error 'any' here, as we can't guarentee that JS will throw `Error`s
export default function logError(
  error: any,
  tags: string[] = [],
  customData: any = undefined
) {
  if (import.meta.env.PROD) {
    Sentry.captureException(error, { contexts: { customData } });
  } else {
    console.error(error, customData, tags);
  }
}
