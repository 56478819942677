export type LatLng = {
  lat: number;
  lng: number;
};

// Returns the distance between two LatLngs in meters using the Haversine formula.
// Ported from google maps android api https://github.com/googlemaps/android-maps-utils/
// See SphericalUtil.java -> computeDistanceBetween.

export function distanceBetween(from: LatLng, to: LatLng): number {
  // Earth's radius in metres as defined by google maps api.
  // https://developers.google.com/maps/documentation/javascript/reference/geometry#spherical
  const radius = 6378137;

  const lat1 = degreesToRadians(from.lat);
  const lng1 = degreesToRadians(from.lng);
  const lat2 = degreesToRadians(to.lat);
  const lng2 = degreesToRadians(to.lng);

  const latDiff = angleToRadians(lat1 - lat2);
  const lngDiff = angleToRadians(lng1 - lng2);

  const dist = latDiff + lngDiff * Math.cos(lat1) * Math.cos(lat2);
  const arc = 2 * Math.asin(Math.sqrt(dist));

  return arc * radius;
}

function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

function angleToRadians(angle: number): number {
  const sinHalf = Math.sin(angle * 0.5);
  return sinHalf * sinHalf;
}
