import { defineMessages } from "react-intl";

const messages = defineMessages({
  contact: {
    id: "extraInfo.contact",
    description: "Label for link to the contact/feedback page",
    defaultMessage: "Contact / Feedback",
  },
  about: {
    id: "extraInfo.about",
    description: "Label for link to the R2r about page",
    defaultMessage: "About Rome2Rio",
  },
  faq: {
    id: "extraInfo.faq",
    description: "Label for faq page",
    defaultMessage: "Frequently Asked Questions",
  },
  travelGuides: {
    id: "extraInfo.travelGuides",
    description: "Label for link to the travel guide page",
    defaultMessage: "Travel Guides",
  },
  blog: {
    id: "extraInfo.blog",
    description: "Label for link to the R2r blog",
    defaultMessage: "Rome2Rio Blog",
  },
  press: {
    id: "extraInfo.press",
    description: "Label for link to the press page",
    defaultMessage: "Press",
  },
  careers: {
    id: "extraInfo.careers",
    description: "Label for link to the careers page",
    defaultMessage: "Careers",
  },
  facebook: {
    id: "extraInfo.facebook",
    description: "Label for link to the Facebook page",
    defaultMessage: "Facebook",
  },
  twitter: {
    id: "extraInfo.twitter",
    description: "Label for link to the Twitter page",
    defaultMessage: "Follow @rome2rio on Twitter",
  },
  manageCookies: {
    id: "extraInfo.manageCookies",
    defaultMessage: "Manage cookies",
    description:
      'Label on link to the Rome2Rio "Manage cookies" modal or banner',
  },
});

export default messages;
