import { defineMessages } from "react-intl";

const messages = defineMessages({
  transportError: {
    id: "tripPlanner.transportError",
    description:
      "Error message when a we fail to render the transport details.",
    defaultMessage:
      "Sorry! We couldn't connect these places for you. Please try a new destination.",
  },
  viewHotels: {
    id: "tripPlanner.hotels.view",
    description: "The text for the cta to view hotels.",
    defaultMessage: "View hotels",
  },
});

export default messages;
