import { IntlShape } from "react-intl";
import { SearchPlace } from "src/utils/hooks/useSearch";
import { Geocoded } from "src/PrefetchData";
import messages from "./CsaAd.messages";
import { prettyCanonicalName } from "./CsaAd";

export function getSearchText(
  intl: IntlShape,
  isFlightAd: boolean,
  originCanonical?: string,
  destinationCanonical?: string
) {
  if (!originCanonical || !destinationCanonical) {
    return undefined;
  }

  // The current MapView product uses canonical names that have been prettified in this way.
  // For now, we are going to keep the logic the same so we have parity when comparing revenue. In the
  // future we might just be able to use the short or long name instead of prettifying a canonical.
  const locations = {
    origin: prettyCanonicalName(originCanonical),
    destination: prettyCanonicalName(destinationCanonical),
  };

  const message = isFlightAd ? messages.flight : messages.transport;

  return intl.formatMessage(message, locations);
}

export function getPlacesTravelText(
  intl: IntlShape,
  origin?: SearchPlace | Geocoded,
  destination?: SearchPlace | Geocoded
) {
  if (!origin || !destination) {
    return undefined;
  }

  let originLocation = prettyCanonicalName(origin.canonicalName);
  let destinationLocation = prettyCanonicalName(destination.canonicalName);

  if (isPopulationCentre(origin.kind) && origin.longName) {
    originLocation = origin.longName;
  }

  if (isPopulationCentre(destination.kind) && destination.longName) {
    destinationLocation = destination.longName;
  }

  const locations = {
    origin: originLocation,
    destination: destinationLocation,
  };

  return intl.formatMessage(messages.travel, locations);
}

export function getPopulationTravelText(
  intl: IntlShape,
  origin?: SearchPlace | Geocoded,
  destination?: SearchPlace | Geocoded
) {
  if (!origin || !destination) {
    return undefined;
  }

  let originLocation = prettyCanonicalName(origin.canonicalName);
  let destinationLocation = prettyCanonicalName(destination.canonicalName);

  if (!isPopulationCentre(origin.kind) && origin.longName) {
    originLocation = trimmedLongName(origin.longName);
  }

  if (!isPopulationCentre(destination.kind) && destination.longName) {
    destinationLocation = trimmedLongName(destination.longName);
  }

  const locations = {
    origin: originLocation,
    destination: destinationLocation,
  };

  return intl.formatMessage(messages.travel, locations);
}

export function getSegmentModeText(
  intl: IntlShape,
  origin?: SearchPlace | Geocoded,
  destination?: SearchPlace | Geocoded,
  mode?: string
) {
  if (!origin || !destination || !mode) {
    return undefined;
  }

  let originLocation =
    origin.longName ?? prettyCanonicalName(origin.canonicalName);
  let destinationLocation =
    destination.longName ?? prettyCanonicalName(destination.canonicalName);

  if (!isPopulationCentre(origin.kind) && origin.longName) {
    originLocation = trimmedLongName(origin.longName);
  }

  if (!isPopulationCentre(destination.kind) && destination.longName) {
    destinationLocation = trimmedLongName(destination.longName);
  }

  const locations = {
    mode: mode,
    origin: originLocation,
    destination: destinationLocation,
  };

  return intl.formatMessage(messages.mode, locations);
}

export function isPopulationCentre(kind?: string) {
  return (
    kind === "admin1" ||
    kind === "admin2" ||
    kind === "airport" ||
    kind === "capital" ||
    kind === "city" ||
    kind === "continent" ||
    kind === "country" ||
    kind === "island" ||
    kind === "megalopolis" ||
    kind === "metropolis" ||
    kind === "town" ||
    kind === "village"
  );
}

/**
 * Returns the long name without the most granular part.
 * For example, 'Melbourne Cricket Ground, Victoria, Australia' becomes 'Victoria, Australia'.
 */
export function trimmedLongName(longName: string) {
  const splitName = longName.split(",");
  return splitName.slice(1).join(",").trim();
}

export function modeFromLocationHash(locationHash: string) {
  if (!locationHash) return undefined;

  const urlMode = locationHash.split("/")[1];
  const possibleModes = ["Taxi", "Shuttle", "Ferry", "Towncar", "Rideshare"];

  if (typeof urlMode !== "string") return undefined;

  if (urlMode.includes("Fly")) return "Flights";
  if (urlMode.includes("Drive")) return "Car hire";
  return possibleModes.find((element) => urlMode.includes(element));
}
