import { useIntl } from "react-intl";

import { useNavigateToHotelsPage } from "src/utils/hooks/useNavigateToHotelsPage";
import { GeocodedPlace } from "src/PrefetchData";
import { HotelAlternative } from "src/svg/HotelAlternative";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import messages from "../TripHotelMapPin/TripHotelMapPin.messages";
import { PanePromoButton } from "../PanePromoButton/PanePromoButton";

export function HotelsPromo({ location }: { location: GeocodedPlace }) {
  const intl = useIntl();
  const { navigateToHotels } = useNavigateToHotelsPage();

  const promoText = intl.formatMessage(messages.hotelsIn, {
    location: location.shortName,
  });
  const tagline = intl.formatMessage(messages.compare);

  return (
    <PanePromoButton
      onClick={() => {
        sendAnalyticsInteractionEvent(
          "SearchResults",
          "Click:HotelPromoSingleDest"
        );

        navigateToHotels();
      }}
      promoText={promoText}
      tagline={tagline}
    >
      <HotelAlternative tint="cod" />
    </PanePromoButton>
  );
}
