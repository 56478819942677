import { Mode } from "src/utils/types/mode";

type BaseEvent = {
  origin?: string;
  destination?: string;
};

type TransportEvent = BaseEvent & {
  travelMode?: Mode;
  outboundDate?: string;
  inboundDate?: string;
  passengerCount?: number;
};

type TransportSearch = TransportEvent & {
  event: "transportSearch";
  travelCarriers: string[];
};

type TransportExit = TransportEvent & {
  event: "transportExit";
  ticketCarriers: string[];
  totalPrice?: string;
};

type HotelExit = BaseEvent & {
  event: "hotelExit";
  hotelName?: string;
  checkinDate?: string;
  checkoutDate?: string;
};

type CarHireExit = BaseEvent & {
  event: "carHireExit";
};

export type CrimtanEvent =
  | TransportSearch
  | TransportExit
  | HotelExit
  | CarHireExit;

export function pushCrimtanEvent(event: CrimtanEvent) {
  window.cmtnDataLayer.push(event);
}
