import { defineMessages } from "react-intl";

const messages = defineMessages({
  homepage: {
    id: "createTrip.homepage",
    defaultMessage: "Rome2Rio homepage",
    description: "The label on a logo image that links to the homepage",
  },
  heading: {
    id: "createTrip.heading",
    defaultMessage: "Where do you want to go?",
    description: "Heading asking the user where they'd like to go to",
  },
  cta: {
    id: "createTrip.cta",
    defaultMessage: "Create trip",
    description: "CTA message for user to create their trip",
  },
});

export default messages;
