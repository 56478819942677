import { CustomDimensions } from "src/api/SearchResponse";

const useAdForm = (
  isAdFormEnabled: boolean,
  customDimensions?: CustomDimensions
) => {
  if (
    !window.adf ||
    !isAdFormEnabled ||
    !customDimensions ||
    (customDimensions.dimension21 === "" && customDimensions.dimension22 === "")
  ) {
    return;
  }

  const dummyElement = document.createElement("p");
  window.adf.ClickTrack(dummyElement, 3116568, "mapViewSearch", {
    sv1: customDimensions?.dimension21, // originCanonical
    sv2: customDimensions?.dimension23, // originKind
    sv3: customDimensions?.dimension25, // originCountry
    sv4: customDimensions?.dimension43, // originGeocoderSource
    sv5: customDimensions?.dimension22, // destinationCanonical
    sv6: customDimensions?.dimension24, // destinationKind
    sv7: customDimensions?.dimension26, // destinationCountry
    sv8: customDimensions?.dimension44, // destGeocoderSource
  });
};

export default useAdForm;
