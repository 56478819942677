// This returns a hint if `query` is an acceptable case-insensitive prefix match
// for `placeName` (ignoring commas in `placeName`). A "hint" is the part of the
// `placeName` that isn't a prefix match with `query`.
// If the `query` isn't a prefix match for `placeName` then undefined is returned.
// Ex: getAutocompleteHint('H', 'hi') => 'i'
// Ex: getAutocompleteHint('Boston M', 'Boston, MA, USA') => 'A, USA'
// Ex: getAutocompleteHint('York', 'New York') => undefined
export function getAutocompleteHint(
  query?: string,
  placeName?: string
): string | undefined {
  if (!query || !placeName || !canDisplayAutocompleteHint(query, placeName)) {
    return undefined;
  }

  return generateAutocompleteHint(query, placeName);
}

function generateAutocompleteHint(
  query: string,
  placeName: string
): string | undefined {
  // Convert the query and placeName into arrays of terms without whitespace or
  // commas. For example, "Lyon, France" becomes ["lyon", "france"]. Each
  // part of the query or placeName is called a term. In the above example,
  // "lyon" is called a term.
  const queryTerms = query.toLowerCase().match(MATCH_WORDS_PATTERN) ?? [];
  const placeTerms = placeName.toLowerCase().match(MATCH_WORDS_PATTERN) ?? [];

  // This finds the last place term that the query partially or fully covers.
  // For example, if the query terms were ["lyon", "air"] and the place terms
  // were ["lyon", "airport", "(lys)", "france"], this would set the variable to
  // "airport".
  const lastMatchedPlaceTerm = placeTerms[queryTerms.length - 1];

  // We can't start searching for the match at the start of the string because
  // we might accidentally find a duplicated term. For example, if the place name
  // was "Paw Paw, MI, USA" and the user typed "Paw P" and we started from 0 we
  // would end up finding the first Paw and so would return "aw Paw, MI, USA"
  // instead of "aw, MI, USA".
  //
  // So, we calculate an approximate search start position that is just before
  // the start of the last query term by summing the length of all the query
  // terms together assuming there is one space between terms. For example,
  // given ["paw", "paw", "mi", "us"] the start search position would be 11.
  const searchStartPosition = queryTerms
    .slice(0, -1)
    .reduce((accumulator, term) => accumulator + term.length + 1, 0);

  // This finds the index of the last matching place term that is covered
  // partially or fully by the query. For example, given the place name
  // "Paw Paw, MI, USA" and the query "Paw p" this will find the second "Paw",
  // e.g. 4.
  const lastMatchedPlaceTermPosition = placeName
    .toLowerCase()
    .indexOf(lastMatchedPlaceTerm, searchStartPosition);

  if (LAST_CHARACTER_SPACE.test(query)) {
    // The last character of the query was a space which means we're not in the
    // middle of a term. So, we want need to trim the space off the placeName to
    // ensure we don't double up on whitespace. e.g. A query of "Lyon " and
    // placeName of "Lyon, France" should return "France".
    return placeName
      .substring(
        lastMatchedPlaceTermPosition + lastMatchedPlaceTerm.length,
        placeName.length
      )
      .replace(/^[, ]+/, "");
  } else if (LAST_CHARACTER_COMMA.test(query)) {
    // The last character of the query was a comma which means we're not in the
    // middle of a term. e.g. A query of "Lyon," and placeName of "Lyon, France"
    // should return " France".
    return placeName
      .substring(
        lastMatchedPlaceTermPosition + lastMatchedPlaceTerm.length,
        placeName.length
      )
      .replace(/^[, ]+/, " ");
  } else {
    // We're in the middle of a term, so we need to use the length of the last
    // query term to calculate the rest of the hint.
    const lastQueryTermLength = queryTerms[queryTerms.length - 1].length;

    return placeName.substring(
      lastMatchedPlaceTermPosition + lastQueryTermLength,
      placeName.length
    );
  }
}

function canDisplayAutocompleteHint(query: string, placeName: string): boolean {
  // We need to set a max character limit for the input. Otherwise the input
  // will scroll and the hinting will be misaligned.
  const maxInputCharacterLimit = 24;
  if (query.length >= maxInputCharacterLimit) {
    return false;
  }

  query = query.toLowerCase().replace(CONSECUTIVE_WHITESPACE, " ");
  placeName = placeName.toLowerCase().replace(CONSECUTIVE_WHITESPACE, " ");

  const queryTerms = query.split(" ");
  const placeNameTerms = placeName.split(" ");

  if (queryTerms.length > placeNameTerms.length) {
    // if the query has more terms than the place name then it's can't be a match.
    return false;
  }

  return queryTerms.every((queryTerm, index) => {
    const queryTermLastCharacter = queryTerm.slice(-1);
    const placeNameTerm = placeNameTerms[index];

    if (index === queryTerms.length - 1) {
      // The last term is the easiest to match, it only needs to be a prefix match.
      // e.g. for the final term, "ly" should match with "lyon".
      return placeNameTerm.indexOf(queryTerm) === 0;
    } else if (queryTermLastCharacter === ",") {
      // If the current query term includes a comma, the associated placeName term
      // must also have the same comma. e.g. a query of "boston," and a place
      // term of "boston" shouldn't match.
      return placeNameTerm === queryTerm;
    } else {
      // Either the terms need to match exactly, or, they need to match without
      // commas in the placeName. e.g. query term "Lyon" and placeName term of
      // "Lyon," should match.
      return (
        placeNameTerm === queryTerm ||
        placeNameTerm.replace(",", "") === queryTerm
      );
    }
  });
}

const MATCH_WORDS_PATTERN = /[^, ]+/gi;
const LAST_CHARACTER_SPACE = /[ ]$/;
const LAST_CHARACTER_COMMA = /[,]$/;
const CONSECUTIVE_WHITESPACE = /\s\s+/g;
