import { useCallback, useEffect, useState } from "react";
import { LayoutType, useLayout } from "src/utils/hooks/useLayout";
import {
  TypedLocation,
  useTypedLocation,
} from "src/utils/hooks/useTypedLocation";
import { ScreenComponent } from "src/analytics/generateScreenComponentsRendered/ScreenComponent";
import { getTripPlannerComponentsToRender } from "../util/getTripPlannerComponentsToRender";
import { ApiState } from "../util/api";
import { useTripPlannerContext } from "./useTripPlannerContext";
import { TripPlanOnboardStep } from "./useTripPlanOnboarding";

type ComponentLayout = {
  layout: LayoutType;
  location: TypedLocation;
  onboardingStep: TripPlanOnboardStep;
  apiState: ApiState;
  components: ScreenComponent[];
};

/**
 * This hook determines which components to render based on:
 * - the current layout
 * - the current onboarding step (could be complete)
 * - the current api state
 * @param onboardingStep
 */
export function useTripPlannerComponents(onboardingStep: TripPlanOnboardStep) {
  const layout = useLayout();
  const location = useTypedLocation();
  const { apiState } = useTripPlannerContext();

  const [tripPlannerComponentLayout, setTripPlannerComponentLayout] =
    useState<ComponentLayout>({
      layout,
      onboardingStep,
      apiState,
      location,
      components: getTripPlannerComponentsToRender(
        onboardingStep,
        layout,
        apiState,
        location
      ),
    });

  const updateComponents = useCallback(() => {
    setTripPlannerComponentLayout({
      layout,
      onboardingStep,
      apiState,
      location,
      components: getTripPlannerComponentsToRender(
        onboardingStep,
        layout,
        apiState,
        location
      ),
    });
  }, [layout, onboardingStep, apiState, location]);

  useEffect(() => {
    if (
      layout !== tripPlannerComponentLayout.layout ||
      onboardingStep !== tripPlannerComponentLayout.onboardingStep ||
      apiState.fetchState !== tripPlannerComponentLayout.apiState.fetchState ||
      location.state?.reorderingTripPlan !==
        tripPlannerComponentLayout.location.state?.reorderingTripPlan ||
      location.state?.editTripPlan !==
        tripPlannerComponentLayout.location.state?.editTripPlan
    ) {
      updateComponents();
    }
  }, [
    layout,
    updateComponents,
    tripPlannerComponentLayout.layout,
    onboardingStep,
    tripPlannerComponentLayout.onboardingStep,
    apiState.fetchState,
    tripPlannerComponentLayout.apiState.fetchState,
    location.state?.reorderingTripPlan,
    tripPlannerComponentLayout.location.state?.reorderingTripPlan,
    location.state?.editTripPlan,
    tripPlannerComponentLayout.location.state?.editTripPlan,
  ]);

  return tripPlannerComponentLayout.components;
}
