import { SvgProps, useColor } from "../common";

export function City(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <title>{props.title}</title>
      <path
        fill={tintColor}
        fillRule="evenodd"
        d="M10.5 5.5v2h3v-2h-3zM10 4a1 1 0 00-1 1v3a1 1 0 001 1h4a1 1 0 001-1V5a1 1 0 00-1-1h-4z"
      />
      <path
        fill={tintColor}
        fillRule="evenodd"
        d="M9 9v10h6V9H9zM8 7a1 1 0 00-1 1v12a1 1 0 001 1h8a1 1 0 001-1V8a1 1 0 00-1-1H8z"
      />
      <path
        fill={tintColor}
        fillRule="evenodd"
        d="M14 16.5a.5.5 0 01-.5.5h-3a.5.5 0 010-1h3a.5.5 0 01.5.5zM14 12.5a.5.5 0 01-.5.5h-3a.5.5 0 010-1h3a.5.5 0 01.5.5zM12.847 3a.75.75 0 01.75.75v.5a.75.75 0 01-1.5 0v-.5a.75.75 0 01.75-.75z"
      />
    </svg>
  );
}
