import React from "react";
import { Rome2rioLogo } from "src/svg/Rome2rioLogo";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { spacing } from "src/design-system/tokens/spacing";
import { Typography } from "src/design-system/components/Typography/Typography";
import { Button } from "src/design-system/components/Button/Button";
import color from "src/design-system/tokens/color";
import Close from "src/svg/Close.svg?react";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { useNavigate } from "react-router";
import { bottomNavBarHeight } from "src/components/BottomNavbar/BottomNavbar";
import { navigateToNewState } from "src/utils/location/navigateToNewState";
import { PlaceInputList } from "../TripEditorModal/PlaceInputList";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import messages from "./CreateTripScreen.messages";

export function CreateTripScreen() {
  const intl = useIntl();
  const { tripPlannerDetails } = useTripPlannerContext();
  const navigate = useNavigate();
  const location = useTypedLocation();

  function handleCloseCreateTrip() {
    navigateToNewStateHash(
      navigate,
      {
        highlightedTab: "trips",
      },
      `#trips/saved/`,
      {
        ...location,
      }
    );
  }

  function handleEdit(index: number) {
    navigateToNewState(navigate, { editTripPlan: true }, location);
  }

  return (
    <>
      <Header>
        <LogoWrapper>
          <a href="/">
            <Rome2rioLogo
              title={intl.formatMessage(messages.homepage)}
              tint="cod"
              dotTint="pink"
            />
          </a>
        </LogoWrapper>
        <IconWrapper onClick={handleCloseCreateTrip}>
          <Close width={14} height={14} color={color.icon.icon} />
        </IconWrapper>
      </Header>
      <HeadingWrapper>
        <Typography variant="heading-md">
          {intl.formatMessage(messages.heading)}
        </Typography>
      </HeadingWrapper>
      <ContentWrapper>
        <PlaceInputList
          places={tripPlannerDetails.places.map((place) => place.canonicalName)}
          handleEdit={(index) => handleEdit(index)}
          handleRemove={() => {}}
        />
      </ContentWrapper>
      <FixedBanner>
        <ButtonWrapper>
          <Button size="large" onPress={() => {}}>
            {intl.formatMessage(messages.cta)}
          </Button>
        </ButtonWrapper>
      </FixedBanner>
    </>
  );
}

const Header = styled.div`
  margin-bottom: ${spacing.xxl};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div`
  svg {
    width: 120px;
  }
`;

const IconWrapper = styled.div`
  margin: 0 ${spacing.md};
`;

const HeadingWrapper = styled.div`
  margin-bottom: ${spacing.xl};
`;

const ButtonWrapper = styled.div`
  margin: ${spacing.xl};
`;

const ContentWrapper = styled.div`
  padding-bottom: 77px; // height of fixed container
`;

const FixedBanner = styled.div`
  width: 100%;
  position: fixed;
  bottom: ${bottomNavBarHeight};
  left: 0;
  border-top: 1px solid ${color.border.border};

  button {
    width: 100%;
  }
`;
