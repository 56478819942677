import styled from "styled-components";
import { Minus } from "src/svg/Minus";
import { BarePlus } from "src/svg/BarePlus";
import { fontSize, color, spacing, fontWeight } from "src/theme";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import { getEndDateOfTrip } from "../util/getTripDates";

type NightsModalProps = {
  locationNights: number;
  setLocationNights: (nights: number) => void;
  setNightsModalIsOpen: (isOpen: boolean) => void;
  safeCheckInDate: Date;
  setCheckOutDate: (date: Date) => void;
};

export function NightsModal({
  locationNights,
  setLocationNights,
  setNightsModalIsOpen,
  safeCheckInDate,
  setCheckOutDate,
}: NightsModalProps) {
  function handleAdjustTripNights(nights: number) {
    setCheckOutDate(getEndDateOfTrip(safeCheckInDate, locationNights + nights));
    setLocationNights(locationNights + nights);
  }

  function NumberOfNights() {
    return (
      <NightsCounter>
        <NumNights>{locationNights}</NumNights>
        <NumNights>{locationNights === 1 ? `night` : "nights"}</NumNights>
      </NightsCounter>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => setNightsModalIsOpen(false)}>
      <NightsModalContainer>
        <ModalButtonWrapper>
          <AdjustNightButton
            disabled={locationNights < 1}
            onClick={() => handleAdjustTripNights(-1)}
          >
            <Minus tint="primaryOnDark" strokeWidth="3" />
          </AdjustNightButton>
        </ModalButtonWrapper>
        <NumberOfNights />
        <ModalButtonWrapper>
          <AdjustNightButton onClick={() => handleAdjustTripNights(1)}>
            <BarePlus tint="primaryOnDark" strokeWidth="3" />
          </AdjustNightButton>
        </ModalButtonWrapper>
      </NightsModalContainer>
    </ClickAwayListener>
  );
}

const NightsModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  position: absolute;
  left: 150px;
  top: 80px;
  width: 173px;
  height: 72px;

  background-color: #fff;
  border-radius: ${spacing.md};
  box-shadow: 0px 8px 24px 0px #00000033;

  z-index: 1001;
`;

const ModalButtonWrapper = styled.div``;

const AdjustNightButton = styled.button`
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${spacing.md};
  background-color: ${color.pink};
  text-align: center;
  font-weight: ${fontWeight.bold};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${color.n30};
  }
`;

const NightsCounter = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color.black};
  align-items: center;
  justify-content: center;
  min-width: 58px;
`;

const NumNights = styled.div`
  font-weight: ${fontWeight.semibold};
  font-size: ${fontSize.h3};
`;
