import { getHotelAppropriateDestination } from "src/utils/hooks/useHotelDestinationFromUrl";
import { generateExitPoint } from "../../../analytics/generateExitPoint/generateExitPoint";
import { HotelProviderKind } from "../../../utils/types/accommodationProviders";
import { SearchResponse } from "../../../api/SearchResponse";
import { GeoKind, isPointKind } from "../../../utils/geoKind";
import { SupportedLanguageCode } from "../../../utils/language";
import { getHotelRedirectDeeplinkUrl } from "../../../utils/url";
import { ScheduledTimelineCell } from "../ScheduleTimeline/cell/ScheduledCell";
import { Cell } from "../UnscheduledTimeline/cell/Cell";

export type AccommodationPromoTimelineProps =
  | { response?: never }
  | {
      response: SearchResponse;
      routeIndex: number;
      destination?: {
        canonicalName: string;
        shortName: string;
        kind?: GeoKind;
      };
      languageCode?: SupportedLanguageCode;
    };

export function createScheduledAccommodationPromoCells(
  cells: ScheduledTimelineCell[],
  props: AccommodationPromoTimelineProps
) {
  const cell = createAccommodationCellFromProps(props);
  if (cell) cells.push(cell);
}

export function createUnScheduledAccommodationPromoCells(
  cells: Cell[],
  props: AccommodationPromoTimelineProps
) {
  const cell = createAccommodationCellFromProps(props);
  if (cell) cells.push(cell);
}

export function createAccommodationCellFromProps(
  props: AccommodationPromoTimelineProps
): AccommodationPromoCell | undefined {
  if (props.response && props.destination) {
    return createAccommodationCellFromPlace(
      props.response,
      {
        canonicalName: props.destination.canonicalName,
        ...getHotelAppropriateDestination(
          props.response,
          props.destination,
          props.routeIndex
        ),
      },
      props.languageCode,
      props.response.routes[props.routeIndex].hotelInfo.providerKind
    );
  }
}

export function createAccommodationCellFromPlace(
  response: SearchResponse,
  place: { canonicalName: string; shortName: string; kind?: GeoKind },
  languageCode: SupportedLanguageCode | undefined,
  hotelProvider: HotelProviderKind
): AccommodationPromoCell {
  const exitPoint = generateExitPoint("hotels", "hotelSearch");
  const deeplink = getHotelRedirectDeeplinkUrl(
    languageCode ?? "en",
    exitPoint,
    response.request.requestId,
    place.canonicalName,
    undefined,
    undefined,
    hotelProvider
  );

  return {
    type: "accommodation",
    id: `accommodation-${place.canonicalName}`,
    placeName: place.shortName,
    provider: hotelProvider,
    deeplinkUrl: deeplink,
    useNearbyCopy: !place.kind || isPointKind(place.kind), // Uses 'near' instead of 'in'
  };
}

export type AccommodationPromoCell = {
  type: "accommodation";
  id: string;
  placeName: string;
  provider: HotelProviderKind;
  deeplinkUrl: string;
  useNearbyCopy: boolean;
};
