import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { getScreenKey } from "../util/getScreenKey";
import { useTripSearchResponse } from "./useTripSearchResponse";

// This hook is used to determine if the URL is matching a singular place, rather than a pair.
// ie. #trips/hotels/Paris should be treated as a singular place, rather than a pair.
// We only need to do this for entries into the hotels experience from the trip planner via the first place.
export function useUnpairedDestination() {
  const location = useTypedLocation();
  const { tripOrigin } = useTripSearchResponse();

  const screenKey = getScreenKey(location.hash);
  const transportPart = location.hash.split(`/${screenKey}/`)[1];

  if (!transportPart || !tripOrigin) {
    return false;
  }

  const parts = transportPart.split("/");

  if (parts.length === 1 && tripOrigin.canonicalName === parts[0]) {
    return tripOrigin;
  }

  return false;
}
