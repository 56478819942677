import { useSignInWithAppleButton } from "src/auth/sdks/AppleAuthSdk";

export type AppleSignInProps = {
  children: (props: { signInWithApple: () => void }) => JSX.Element;
};

export function AppleSignIn({ children }: AppleSignInProps) {
  const { signInWithApple } = useSignInWithAppleButton();

  return (
    <>
      {children({
        signInWithApple,
      })}
    </>
  );
}
