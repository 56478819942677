import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { Mode } from "../types/mode";
import { SearchResponse } from "../../api/SearchResponse";
import { vehicleFromSegment } from "./vehicle";

export const transitModeFromSegment = (
  response: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  segmentIndexOfRoute: number
): Mode => {
  const route = response.routes[routeIndex];
  const segmentIndex = route.segments[segmentIndexOfRoute];
  const segment = response.segments[segmentIndex];

  const vehicle = vehicleFromSegment(response, segment);
  return transitModeFromVehicleKind(vehicle.kind);
};

export const transitModeFromVehicleKind = (vehicleKind?: string): Mode => {
  switch (vehicleKind) {
    case "train":
    case "subway":
    case "cartrain":
    case "nighttrain":
      return "train";

    case "tram":
      return "tram";

    case "cablecar":
      return "cablecar";

    case "bus":
    case "nightbus":
      return "bus";

    case "busferry":
      return "busferry";

    case "rideshare":
      return "rideshare";

    case "shuttle":
      return "shuttle";

    case "plane":
    case "flight":
      return "plane";

    case "helicopter":
      return "helicopter";

    case "ferry":
      return "ferry";

    case "carferry":
      return "carferry";

    case "car":
      return "car";

    case "towncar":
      return "towncar";

    case "taxi":
      return "taxi";

    case "transfer":
    case "walk":
    case "foot":
      return "walk";

    case "animal":
      return "animal";

    case "bicycle":
    case "cycle":
      return "bike";

    case "unknown":
    case "none":
    case undefined:
      return "unknown";

    default:
      throw new Error(`Unrecognised vehicleKind ${vehicleKind}`);
  }
};
