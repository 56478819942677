import { TicketLink, PickerConfig } from "../../api/SearchResponse";
import { nowInUtcOffset } from "../dateTime";

export type TicketInfo = {
  templatedUrl: TemplatedTicketUrl;
  minDate: Date;
  now: Date;
};

export type TemplatedTicketUrl = {
  url: string;
  templatedParameters: string[];
};

export function ticketInfoFromLinks(
  links: TicketLink[] | undefined,
  originUtcOffset: number
): TicketInfo | undefined {
  const ticketLink = links?.filter((link) => link.type === "tickets")[0];

  if (!ticketLink) {
    return undefined;
  }

  // We don't need all of the information in a TicketLink,
  // so we'll just take what we need.
  return {
    templatedUrl: {
      url: ticketLink.url,
      templatedParameters: ticketLink.templatedParameters,
    },
    minDate: ticketMinDate(originUtcOffset, ticketLink.pickerConfig),
    now: nowInUtcOffset(originUtcOffset),
  };
}

// We want the ability to get the time in a certain time zone, and this is the best we can do.
// It's impossible to decouple JS Dates from time zone information, since dates
// are generally displayed in a user's local time. This function creates a Date object that,
// when displayed in local time, has the same properties that we would expect to have
// in the utc offset's current time, +- the picker config values.
// Ex: If UTC offset is +2, hour offset is+ 1, date offset is +2 and the current UTC date time
// is July 12 2020 at 1pm, this function will return a date that's July 14 2020 at 4pm in user's LOCAL time.
export function ticketMinDate(
  originUtcOffset: number,
  pickerConfig?: PickerConfig
) {
  const localDateAsStationDate = nowInUtcOffset(originUtcOffset);

  if (pickerConfig) {
    // Offset the date and time based on picker configuration
    localDateAsStationDate.setDate(
      localDateAsStationDate.getDate() + pickerConfig.initialDateOffset
    );
    localDateAsStationDate.setHours(
      localDateAsStationDate.getHours() + pickerConfig.initialHourOffset
    );
  }

  return localDateAsStationDate;
}
