import { lazy } from "react";
import logBreadcrumbs from "src/utils/logBreadcrumbs";

export const LazyTripPlannerMap = lazy(async () => {
  logBreadcrumbs("Map", "Requesting Trip Planner Map chunk");
  return await import(
    /* webpackChunkName: "TripPlannerMap" */
    "./TripPlannerMap"
  );
});
