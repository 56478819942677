import { defineMessages } from "react-intl";

const messages = defineMessages({
  searchTravel: {
    id: "SearchCard.searchTravel",
    description:
      "A message on a clickable card that takes a user to a saved trip search. e.g. Search travel from St Gallen to Zürich Switzerland",
    defaultMessage: "Travel to {destination}",
  },
});

export default messages;
