import styled from "styled-components";
import { FormattedPrice } from "src/components/FormattedPrice/FormattedPrice";
import { useIntl } from "react-intl";
import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { createTripHashForCard } from "src/utils/location/createTripHashForCard";
import { SchedulesResponse, Vehicle } from "../../../../api/SchedulesResponse";
import { SearchResponse } from "../../../../api/SearchResponse";
import { TripPlannerURL } from "../../../../domain/TripPlanner/TripPlannerProvider";
import {
  CardDetailSubtitle,
  CardDetailTitle,
  getTripCardAnalyticsLabel,
  getTripRouteTransitModes,
} from "../TripCard";
import { lineHeight } from "../../../../theme";
import {
  durationInMinutesFromRoute,
  durationInMinutesFromSegment,
} from "../../../../utils/adapters/duration";

import {
  displayPriceRangeFromIndicativePrices,
  PriceRange,
  priceRangeFromSegment,
} from "../../../../utils/adapters/priceRange";
import {
  transitModeFromSegment,
  transitModeFromVehicleKind,
} from "../../../../utils/adapters/transitMode";
import { FormattedDuration } from "../../../FormattedDuration/FormattedDuration";
import { TripCard, TripCardTypeProps } from "../TripCard";
import { Mode } from "../../../../utils/types/mode";
import { getTransitModeFromFirstMajorSegment } from "../../../../domain/RouteScreen/createRouteScreenViewModel";
import { messages } from "./ScheduleTripCard.messages";

// When determining the mode of transport to
// display we'll skip over these
const IGNORED_MODES = ["walk", "animal"];

export type ScheduleOrSegmentTripCardProps = {
  title?: string;
  priceRange?: PriceRange;
  majorModes?: Mode[];
} & TripCardTypeProps;

export function ScheduleOrSegmentTripCard(
  props: ScheduleOrSegmentTripCardProps
) {
  return (
    <TripCard
      {...props}
      TimelineDetailsContent={<ScheduleTripCardDetails {...props} />}
    />
  );
}

function ScheduleTripCardDetails({
  durationInMinutes,
  priceRange,
  title,
}: ScheduleOrSegmentTripCardProps) {
  const intl = useIntl();

  return (
    <ScheduleDetailsWrapper>
      <CardDetailTitle>{title}</CardDetailTitle>
      <CardDetailSubtitle>
        {priceRange &&
          intl.formatMessage(messages.fromPrice, {
            price: (
              <FormattedPrice
                value={priceRange.low}
                currency={priceRange.currencyCode}
              />
            ),
          })}
        {durationInMinutes && (
          <>
            {priceRange && ", "}
            <Duration totalMinutes={durationInMinutes} />
          </>
        )}
      </CardDetailSubtitle>
    </ScheduleDetailsWrapper>
  );
}

export function getSegmentPropsFromScheduleResponse(
  searchResponse: SearchResponse | PartialSearchResponse,
  schedulesResponse: SchedulesResponse,
  url: TripPlannerURL,
  routeIndex?: number,
  segmentIndex?: number
): ScheduleOrSegmentTripCardProps {
  // At the moment, we need to collect some information
  // from the first itinerary to avoid confusion
  let durationInMinutes, priceRange, majorModes, title;
  let transitMode = firstMajorVehicleMode(schedulesResponse.vehicles);

  // If we have a route index, we can get some better transit mode
  // and price range values from the search response
  if (routeIndex !== undefined) {
    transitMode = getTransitModeFromFirstMajorSegment(
      searchResponse,
      routeIndex
    );
    majorModes = getTripRouteTransitModes(searchResponse, routeIndex);
    priceRange = displayPriceRangeFromIndicativePrices(
      searchResponse.routes[routeIndex].indicativePrices
    );
    title = searchResponse.routes[routeIndex].name;

    // We want to use the same logic from the search results to show duration
    // as we can't save specific schedules yet. This doesn't apply to flights
    // though as we'll need to get it from the sched response otherwise it'll
    // display the duration of the entire route.
    if (transitMode !== "plane") {
      durationInMinutes = durationInMinutesFromRoute(
        searchResponse,
        searchResponse.routes[routeIndex]
      );
    } else {
      durationInMinutes = schedulesResponse.outboundItineraries[0]?.duration;
    }
  }

  // If we can't get the duration from our itinerary or route get it from
  // the segment instead.
  if (!durationInMinutes && segmentIndex) {
    durationInMinutes = durationInMinutesFromSegment(
      searchResponse,
      searchResponse.segments[segmentIndex]
    );
  }

  const origin = searchResponse.places[0];
  const destination = searchResponse.places[1];

  return {
    origin,
    destination,
    transitMode,
    majorModes,
    url: {
      ...url,
      hash: createTripHashForCard(searchResponse, url.hash),
    },
    durationInMinutes,
    type: "schedule",
    priceRange,
    title,
    gaLabel: getTripCardAnalyticsLabel(
      false,
      searchResponse,
      routeIndex,
      segmentIndex
    ),
    searchResponse,
    isScheduleable: true,
  };
}

export function getSegmentPropsFromSearchResponse(
  searchResponse: SearchResponse | PartialSearchResponse,
  routeIndex: number,
  segmentIndexInRoute: number,
  url: TripPlannerURL
): ScheduleOrSegmentTripCardProps {
  // Get the segment index for SearchResponse
  const segmentIndex =
    searchResponse.routes[routeIndex].segments[segmentIndexInRoute];
  const segment = searchResponse.segments[segmentIndex];

  const origin = searchResponse.places[0];
  const destination = searchResponse.places[1];

  const transitMode = transitModeFromSegment(
    searchResponse,
    routeIndex,
    segmentIndexInRoute
  );
  const durationInMinutes = durationInMinutesFromSegment(
    searchResponse,
    segment
  );
  const priceRange = priceRangeFromSegment(segment);
  const majorModes = getTripRouteTransitModes(searchResponse, routeIndex);
  const title = searchResponse.routes[routeIndex].name;
  const isScheduleable = !!segment.scheduleInfo;

  return {
    destination,
    origin,
    title,
    transitMode,
    durationInMinutes,
    priceRange,
    majorModes: majorModes ?? [transitMode],
    url: {
      ...url,
      hash: createTripHashForCard(searchResponse, url.hash),
    },
    type: "segment",
    gaLabel: getTripCardAnalyticsLabel(
      false,
      searchResponse,
      routeIndex,
      segmentIndex
    ),
    searchResponse,
    isScheduleable,
  };
}

function firstMajorVehicleMode(vehicles: Vehicle[]): Mode {
  const vehicle = vehicles.find(
    (vehicle) => !IGNORED_MODES.includes(vehicle.kind)
  );
  return vehicle ? transitModeFromVehicleKind(vehicle.kind) : "multi";
}

const ScheduleDetailsWrapper = styled.div``;

const Duration = styled(FormattedDuration)`
  line-height: ${lineHeight.tight};
`;
