import { css } from "styled-components";

// Gives a Component a compliant target size via a pseudo, if required, without changing existing layout.
export const A11yTargetSize = css`
  position: relative;
  &::after {
    --min-a11y-click-target: 44px; // https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
    content: "";
    position: absolute;
    top: min(0px, calc((100% - var(--min-a11y-click-target)) / 2));
    left: min(0px, calc((100% - var(--min-a11y-click-target)) / 2));
    right: min(0px, calc((100% - var(--min-a11y-click-target)) / 2));
    bottom: min(0px, calc((100% - var(--min-a11y-click-target)) / 2));
    cursor: pointer;
  }
`;

// Themes how the focus outline looks, but not when it appears.
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
export const A11yOutline = css`
  &:focus-visible {
    outline: 2px solid var(--color-ring); // R2R themed outline style.
  }
`;

export function onEnterKey(callback: () => void) {
  return (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      callback();
    }
  };
}
