import { getSegmentIndexFromHash } from "../location/segmentIndexFromHash";
import { useTypedLocation } from "./useTypedLocation";
import { useRouteIndex } from "./useRoute";
import { useIsRouteSkipped } from "./useIsRouteSkipped";

export function useRouteSegmentIndex(): number | undefined {
  const location = useTypedLocation();
  const segmentIndex = getSegmentIndexFromHash(location.hash);

  const routeIndex = useRouteIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);

  return isRouteSkipped ? undefined : segmentIndex;
}
