import { defineMessages } from "react-intl";

export const messages = defineMessages({
  email: {
    defaultMessage: "Email",
    description:
      "The label shown above the email input on the update profile form.",
    id: "manageProfile.email",
  },
  manageProfile: {
    defaultMessage: "Manage profile",
    description:
      'The title shown on the update profile form. This form is shown when the user clicks on the "Manage profile" button.',
    id: "manageProfile.manageProfile",
  },
  updateYourDetails: {
    defaultMessage: "Update your details",
    description:
      'The title shown on the update profile form. This form is shown when the user clicks on the "Manage profile" button for desktop.',
    id: "manageProfile.updateYourDetails",
  },
  name: {
    defaultMessage: "Name",
    description:
      "The label shown above the name input on the update profile form.",
    id: "manageProfile.name",
  },
  update: {
    defaultMessage: "Update",
    description:
      "The label shown on the update profile button on the update profile form.",
    id: "manageProfile.update",
  },
  emailMarketingLabel: {
    defaultMessage:
      "Get travel inspiration, trip ideas and updates from Rome2Rio",
    description:
      "The toggle on the update profile form displays a label indicating that it asks for permission from the user to receive marketing emails and other communications.",
    id: "manageProfile.emailMarketingLabel",
  },
  deleteProfileButtonText: {
    defaultMessage: "Delete profile",
    description:
      "The label shown on the delete profile button on the update profile form.",
    id: "manageProfile.deleteProfileButtonText",
  },
  deleteProfileTitle: {
    defaultMessage: "Permanently delete your data and profile?",
    description:
      'The title shown on the delete profile confirmation modal. This modal is shown when the user clicks on the "Delete profile" button.',
    id: "manageProfile.deleteProfileTitle",
  },
  deleteProfileDescription: {
    defaultMessage:
      "We’ll delete all your profile information permanently. You’ll no longer be able to access anything you’ve saved to Rome2Rio like trips or schedule information.{br}To delete enter the primary email address you use for this profile.",
    description:
      'The description shown on the delete profile confirmation modal. This modal is shown when the user clicks on the "Delete profile" button.',
    id: "manageProfile.deleteProfileDescription",
  },
  deleteProfileConfirmButtonText: {
    defaultMessage: "Permanently delete profile",
    description:
      'The label shown on the delete profile confirmation button on the delete profile confirmation modal. This modal is shown when the user clicks on the "Delete profile" button.',
    id: "manageProfile.deleteProfileConfirmButtonText",
  },
  cancel: {
    defaultMessage: "Cancel",
    description:
      'The label shown on the cancel button on the delete profile confirmation modal. This modal is shown when the user clicks on the "Delete profile" button.',
    id: "manageProfile.cancel",
  },
  primaryEmail: {
    defaultMessage: "Primary email",
    description:
      'The label shown above the primary email input on the delete profile confirmation modal. This modal is shown when the user clicks on the "Delete profile" button.',
    id: "manageProfile.primaryEmail",
  },
});
