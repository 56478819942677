import styled from "styled-components";
import { PropsWithChildren } from "react";
import { iconSize } from "../../theme";

type Props = PropsWithChildren<{
  size: keyof typeof iconSize;
  className?: string;
}>;

export function Icon(props: Props) {
  return <IconWrapper {...props}>{props.children}</IconWrapper>;
}

type IconWrapperProps = {
  size: Props["size"];
};

const IconWrapper = styled.span<IconWrapperProps>`
  width: ${(props) => iconSize[props.size]};
  min-width: ${(props) => iconSize[props.size]};
  height: ${(props) => iconSize[props.size]};
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;
