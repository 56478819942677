import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "../../components/Icon/Icon";
import { ChevronDown } from "../../svg/ChevronDown";
import { spacing, fontSize, fontWeight } from "../../theme";

import { useBackendFlags } from "../../utils/hooks/useBackendFlags";
import { useTheme } from "../../utils/hooks/useTheme";

export function BackendFlagSelector() {
  const theme = useTheme();
  const {
    addBackendFlag,
    removeBackendFlag,
    getBackendFlagValue,
    backendFlagsOptions,
  } = useBackendFlags();

  const setbackendFlagsParam = (
    featureName: string,
    value: boolean | undefined
  ) => {
    if (value) {
      addBackendFlag(featureName);
    } else {
      removeBackendFlag(featureName);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    if (isExpanded) setIsExpanded(false);
    else setIsExpanded(true);
  };

  return (
    <>
      <FeatureItem onClick={toggleExpand} pointer={true}>
        <h3>Backend Flags</h3>
        <StyledChevronIcon pointUp={isExpanded} size="sm">
          <ChevronDown tint={theme.topNav.iconTint} />
        </StyledChevronIcon>
      </FeatureItem>

      {isExpanded &&
        backendFlagsOptions.map((backendFeature) => {
          const value = getBackendFlagValue(backendFeature);

          const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            setbackendFlagsParam(backendFeature, checked);
          };

          return (
            <FeatureItem key={backendFeature}>
              <FeatureName htmlFor={backendFeature}>
                {backendFeature}
              </FeatureName>
              <StyledCheckbox
                id={backendFeature}
                type="checkbox"
                defaultChecked={value}
                onChange={onCheckboxChange}
              />
            </FeatureItem>
          );
        })}
    </>
  );
}

const FeatureItem = styled.div<{ pointer?: boolean }>`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.h6};
  font-weight: ${fontWeight.normal};
  border-bottom: 1px solid #ebebeb;
  cursor: ${(props) => (props.pointer ? "pointer" : "auto")};
`;

const FeatureName = styled.label`
  flex-grow: 2;
`;

const StyledCheckbox = styled.input`
  width: 18px;
  height: 18px;
`;

const StyledChevronIcon = styled(Icon)<{
  pointUp?: boolean;
}>`
  margin-left: ${spacing.md};

  ${(props) => {
    if (props.pointUp) {
      return "transform: rotate(180deg);";
    }
  }}
`;
