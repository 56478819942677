type GoogleLogoProps = {
  opacity?: number;
};

export function GoogleLogo({ opacity }: GoogleLogoProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 10.2398C20 9.56448 19.9333 8.86733 19.8222 8.21375H10.1973V12.0699H15.7099C15.4876 13.3117 14.7541 14.401 13.6649 15.0981L16.9547 17.6035C18.8886 15.8389 20 13.2681 20 10.2398Z"
        fill="#4280EF"
        opacity={opacity}
      />
      <path
        d="M10.1971 19.9999C12.9535 19.9999 15.2652 19.1067 16.9546 17.5817L13.6648 15.0981C12.7534 15.7081 11.5753 16.0567 10.1971 16.0567C7.52973 16.0567 5.28466 14.292 4.46221 11.9391L1.0835 14.4881C2.81731 17.8649 6.3294 19.9999 10.1971 19.9999Z"
        fill="#34A353"
        opacity={opacity}
      />
      <path
        d="M4.46235 11.9173C4.04001 10.6755 4.04001 9.32477 4.46235 8.08296L1.08363 5.51221C-0.361212 8.3444 -0.361212 11.6777 1.08363 14.4881L4.46235 11.9173Z"
        fill="#F6B704"
        opacity={opacity}
      />
      <path
        d="M10.1971 3.96545C11.642 3.94366 13.0646 4.48831 14.1093 5.46869L17.0213 2.59292C15.1763 0.893609 12.7312 -0.0214058 10.1971 0.00038023C6.3294 0.00038023 2.81731 2.13542 1.0835 5.51226L4.46221 8.08301C5.28466 5.70833 7.52973 3.96545 10.1971 3.96545Z"
        fill="#E54335"
        opacity={opacity}
      />
    </svg>
  );
}
