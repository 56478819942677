import { SvgProps, useColor } from "./common";

/**
 * @deprecated Use the Close svg where possible instead
 */
export const Close = (props: SvgProps) => {
  const tintColor = useColor(props.tint);
  const strokeWidth = props.strokeWidth;
  return (
    <svg
      data-testid="close-button-svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <title>{props.title}</title>
      <path
        stroke={tintColor}
        strokeWidth={strokeWidth}
        fill={tintColor}
        fillRule="evenodd"
        d="M.31 22.293a1 1 0 001.415 1.413l8.691-8.709L12 13.413l10.294 10.28a1 1 0 101.413-1.415l-10.294-10.28L23.687 1.706A1 1 0 1022.272.293L11.997 10.584l-1.582-1.58L1.706.313A1 1 0 10.294 1.728l8.708 8.692L10.584 12l-1.582 1.584L.31 22.293z"
      />
    </svg>
  );
};
