import { useIntl } from "react-intl";
import { FormattedPrice } from "src/components/FormattedPrice/FormattedPrice";
import { Icon } from "src/components/Icon/Icon";
import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { ConnectedDots } from "src/svg/tripplanner/ConnectedDots";
import { borderRadius, color, spacing } from "src/theme";
import { tripHashFromSearchResponse } from "src/utils/location/createTripHashForCard";
import styled, { keyframes } from "styled-components";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";

import { useIsTripsAsCoreFullExperience } from "src/utils/hooks/useIsTripsAsCoreFullExperience";
import { TransitIcon } from "src/components/Icon/TransitIcon";
import { ChevronRight } from "src/svg/ChevronRight";
import { SearchResponse } from "../../../../api/SearchResponse";
import { TripPlannerURL } from "../../../../domain/TripPlanner/TripPlannerProvider";
import { lowestPriceRangeFromSearch } from "../../../../utils/adapters/priceRange";
import {
  CardDetailSubtitle,
  CardDetailTitle,
  getTripCardAnalyticsLabel,
  TripCard,
  TripCardTypeProps,
} from "../TripCard";
import { placeNumberOffsetPx } from "../../Headings/PlaceTitle";
import { messages } from "./SearchTripCard.messages";

export function SearchTripCard(props: TripCardTypeProps) {
  return (
    <TripCard
      {...props}
      TimelineDetailsContent={<SearchTripCardDetails {...props} />}
    />
  );
}

export function SearchTripCardDetails({
  searchResponse,
  priceRange,
  modeSummary,
}: TripCardTypeProps) {
  const intl = useIntl();
  const isTripsAsCoreHoldback = useIsTripsAsCoreFullExperience();
  const { tripPlannerDetails } = useTripPlannerContext();

  return (
    <SearchTripCardContainer $isTripsAsCoreHoldback={isTripsAsCoreHoldback}>
      {!isTripsAsCoreHoldback && <ConnectedDots tint="n80" />}
      <DetailContainer $isTripsAsCoreHoldback={isTripsAsCoreHoldback}>
        {isTripsAsCoreHoldback ? (
          <CardDetailTitle $isTripsAsCoreHoldback={isTripsAsCoreHoldback}>
            {tripPlannerDetails.places.length === 2 ? (
              <>{intl.formatMessage(messages.travelOptions)}</>
            ) : (
              <>
                {intl.formatMessage(messages.waysToTravel, {
                  ways: searchResponse?.routes.length,
                  destination: searchResponse?.places[1].shortName,
                })}
              </>
            )}
          </CardDetailTitle>
        ) : (
          <>
            {searchResponse && (
              <CardDetailTitle>
                {intl.formatMessage(messages.waysToTravel, {
                  ways: searchResponse.routes.length,
                  destination: searchResponse.places[1].shortName,
                })}
              </CardDetailTitle>
            )}
            <CardDetailSubtitle>
              {priceRange &&
                intl.formatMessage(messages.fromPrice, {
                  price: (
                    <FormattedPrice
                      value={priceRange.low}
                      currency={priceRange.currencyCode}
                    />
                  ),
                })}
            </CardDetailSubtitle>
          </>
        )}
      </DetailContainer>
      <RightHandWrapper>
        {modeSummary && (
          <ModeSummary>
            {modeSummary.map((mode, i) => {
              return (
                <TransitIcon
                  transitMode={mode}
                  tint="dolphin"
                  key={`${mode}-${i}`}
                />
              );
            })}
          </ModeSummary>
        )}
        <Icon size="sm">
          <ChevronRight tint="n300" />
        </Icon>
      </RightHandWrapper>
    </SearchTripCardContainer>
  );
}

export function getSearchPropsFromSearchResponse(
  searchResponse: SearchResponse | PartialSearchResponse,
  url: TripPlannerURL
): TripCardTypeProps {
  return {
    origin: searchResponse.places[0],
    destination: searchResponse.places[1],
    searchResponse,
    priceRange: lowestPriceRangeFromSearch(searchResponse),
    transitMode: "multi",
    url: {
      ...url,
      hash: tripHashFromSearchResponse(searchResponse),
    },
    type: "search",
    gaLabel: getTripCardAnalyticsLabel(false, searchResponse),
  };
}

const loadSkeleton = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(.99);
  }
  100% {
    transform: scale(1);
  }
`;

export const AnimatedSkeleton = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.lg} ${spacing.md} ${spacing.lg}
    ${placeNumberOffsetPx - 5}px;
  border-radius: ${borderRadius.md};
  background-color: ${color.n10};
  margin-top: ${spacing.sm};
  height: 54px;
  overflow: hidden;
  animation: ${loadSkeleton} 0.3s ease-in-out forwards;
`;
export const SearchTripCardContainer = styled.div<{
  $isTripsAsCoreHoldback?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  > * {
    flex-shrink: 0;
  }

  padding: ${({ $isTripsAsCoreHoldback }) => $isTripsAsCoreHoldback && `8px`};
  border-radius: ${({ $isTripsAsCoreHoldback }) =>
    $isTripsAsCoreHoldback && `${borderRadius.md}`};
`;

const DetailContainer = styled.div<{
  $isTripsAsCoreHoldback?: boolean;
}>`
  margin-left: ${({ $isTripsAsCoreHoldback }) =>
    $isTripsAsCoreHoldback ? "0" : spacing.md};
  flex-shrink: 1;
`;

const RightHandWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const ModeSummary = styled.div`
  height: 16px;
  display: flex;
  margin-right: ${spacing.xxl};

  svg {
    margin-left: ${spacing.sm};
  }
`;
