import { defineMessages } from "react-intl";

const messages = defineMessages({
  trips: {
    id: "tripPlanner.headings.trips",
    description: "Title for the Nav on the trip planner screen",
    defaultMessage: "Trips",
  },
  myTrip: {
    id: "tripPlanner.headings.myTrip",
    description: "Title for the trip planner screen",
    defaultMessage: "My trip",
  },
  searchScreen: {
    id: "tripPlanner.headings.searchScreen",
    description: "Title for back button to search screen",
    defaultMessage: "Search Screen",
  },
  tripPlannerToast: {
    id: "tripPlannerToast.label",
    defaultMessage: `Saved to 'My Trip'. We can't save more than one option, so your previous selection has been removed.`,
    description: `When a user saves content into the trip planner we can currently only allow one piece of content per a/b search. This is a warning to let them know their previous content has been overwritten.`,
  },
  destinationsSingular: {
    id: "tripPlanner.headings.destinationsSingular",
    defaultMessage: "{destinationCount} destination",
    description:
      "Subheading that display that only one destination has been selected",
  },
  destinationsPlural: {
    id: "tripPlanner.headings.destinationsPlural",
    defaultMessage: "{destinationCount} destinations",
    description:
      "Subheading that display that only zero or multiple destination has been selected",
  },
  travel: {
    id: "tripPlanner.headings.travel",
    defaultMessage: "Travel",
    description:
      "label that informs users that the price range they are seeing is for total transport travel costs only",
  },
  save: {
    id: "tripPlanner.buttons.save",
    defaultMessage: "Save",
    description: "Button that prompts the user to save the trip plan",
  },
  waysToTravel: {
    id: "tripPlanner.headings.waysToTravel",
    description: "Title for the CTA under the Search trip card",
    defaultMessage: "{ways} ways to travel to {destination}",
  },
  tripOverview: {
    id: "tripPlanner.headings.tripOverview",
    description: "Title for your trip",
    defaultMessage: "Trip Overview",
  },
  savedTrips: {
    id: "tripPlanner.saved.link",
    description: "Link to saved trips screen",
    defaultMessage: "Saved trips",
  },
});

export default messages;
