import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { CloseButton } from "src/auth/components/CloseButton/CloseButton";
import { Button } from "src/components/Button/Button";
import { Dropdown } from "src/components/Dropdown/Dropdown";
import { TextButton } from "src/components/TextButton/TextButton";
import { VisuallyHidden } from "src/components/VisuallyHidden/VisuallyHidden";
import { SearchDatesPicker } from "src/domain/SegmentScreen/SearchDatesPicker/SearchDatesPicker";
import messages from "src/domain/SegmentScreen/SegmentHeader/DatePicker/TabbedDatePicker/TabbedDatePicker.message";
import { color, fontWeight, spacing } from "src/theme";
import { desktopLayout, mobileLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { getEndDateOfTrip } from "../util/getTripDates";
import { SelectionNightsInfo } from "./SelectionNightsInfo";
import { tripDateMessages } from "./TripDatePicker.messages";

type TripDatePickerProps = {
  onSearch: () => void;
  closePicker: () => void;
};

export const TripDatePicker = ({
  onSearch,
  closePicker,
}: TripDatePickerProps) => {
  const intl = useIntl();

  const { tripPlannerDetails, dispatch } = useTripPlannerContext();
  const nightsCount: number | undefined = 8; // TODO: Rob / Jingle Link to lifted state when available

  const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>(
    tripPlannerDetails.startDate
      ? new Date(tripPlannerDetails.startDate)
      : undefined
  );

  const datePickerRef = useRef<HTMLDivElement>(null);

  function setDateClick() {
    setSelectedStartDate(undefined);
    dispatch({
      type: "EDIT_START_DATE",
      date: undefined,
    });
  }

  function searchClick() {
    if (!selectedStartDate) {
      return;
    }
    dispatch({
      type: "EDIT_START_DATE",
      date: selectedStartDate,
    });
    onSearch();
  }

  function handleDateChange(startDate: Date) {
    setSelectedStartDate(startDate);
  }

  // At the start when no nights are undefined, only allow for single date selection

  function getCalendarValue(): [Date, Date] | undefined {
    if (nightsCount === undefined || !selectedStartDate) {
      return undefined;
    }
    return [
      selectedStartDate,
      getEndDateOfTrip(selectedStartDate, nightsCount),
    ];
  }

  function TitleCloseTop() {
    return (
      <TitleBox>
        <TitleSpan>
          {intl.formatMessage(tripDateMessages.setStartDate)}
        </TitleSpan>
        <CloseButtonWrapper>
          <CloseButton size="md" onClick={closePicker} />
        </CloseButtonWrapper>
      </TitleBox>
    );
  }

  function VisuallyHiddenTitle() {
    return (
      <VisuallyHidden>
        <h1>{intl.formatMessage(messages.datePicker)}</h1>
      </VisuallyHidden>
    );
  }

  function ActionSelectionBottom() {
    return (
      <Bottom>
        <Section>
          <SelectionNightsInfo
            departureDate={selectedStartDate}
            nightsCount={nightsCount}
          />
        </Section>
        <ActionSection>
          <TextButton
            textColor="primaryOnLight"
            underline
            onClick={setDateClick}
          >
            {intl.formatMessage(tripDateMessages.clear)}
          </TextButton>
          <Button
            textColor="primaryOnDark"
            backgroundColor="pink"
            inline
            onClick={searchClick}
            size="large"
            disabled={!selectedStartDate}
          >
            {intl.formatMessage(tripDateMessages.update)}
          </Button>
        </ActionSection>
      </Bottom>
    );
  }

  return (
    <DropdownWrapper>
      <StyledDropdown
        anchor="left"
        data-testid="schedule-picker-dropdown"
        doubleWidth={true}
      >
        <DatePickerWrapper tabIndex={0} ref={datePickerRef}>
          <TitleCloseTop />
          <VisuallyHiddenTitle />
          <SearchDatesPicker
            isReturn={false}
            departureDate={selectedStartDate}
            onDatesChange={handleDateChange}
            value={getCalendarValue()}
            showDoubleView={true}
            isProjectingTripNights={!!tripPlannerDetails.startDate}
          />
          <ActionSelectionBottom />
        </DatePickerWrapper>
      </StyledDropdown>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div`
  position: fixed;
  z-index: 1000;
`;

const TitleBox = styled.div`
  width: 100%;
  border-bottom: 1px solid ${color.n30};
`;
const TitleSpan = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: ${spacing.lg};
  font-weight: ${fontWeight.medium};
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing.lg};
  right: ${spacing.xxl};
`;

// Styles below sourced from DatePickerDropdown.tsx and TabbedDatePicker.tsx
// Once these designs become stable and still share the same style,
// consider exporting the original styles instead of copying them.

const StyledDropdown = styled(Dropdown)<{ doubleWidth: boolean }>`
  margin-top: ${spacing.md};
  padding: ${spacing.md} ${spacing.xl};
  width: ${(props) => (props.doubleWidth ? "680px" : "360px")};
  // On very small desktop screens the max-height is unreasonably low, especially for a calendar.
  max-height: unset;
`;

// Hide focus outline as the entire calendar is not interactable but needs to be focused
// on open so that the modal can be tabbed through by users to access interactable elements.
const DatePickerWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: ${spacing.md};

  padding-top: ${spacing.md};
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;

  ${mobileLayout} {
    // Put space between us and the calendar
    flex-grow: 1;
    justify-self: flex-end;

    flex-direction: column;
    justify-content: flex-end;
  }
  ${desktopLayout} {
    border-top: 1px solid ${color.n30};
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing.lg} ${spacing.xl};

  ${mobileLayout} {
    border-top: 1px solid ${color.n30};
  }
`;

const ActionSection = styled(Section)`
  gap: ${spacing.xxxl};

  ${mobileLayout} {
    justify-content: space-between;
  }
`;
