import { defineMessages } from "react-intl";

export const messages = defineMessages({
  germanFlag: {
    id: "topNav.language.germanFlag",
    defaultMessage: "German flag",
    description:
      "The title visible to screen readers for an image of the German flag",
  },
  frenchFlag: {
    id: "topNav.language.frenchFlag",
    defaultMessage: "French flag",
    description:
      "The title visible to screen readers for an image of the French flag",
  },
  spanishFlag: {
    id: "topNav.language.spanishFlag",
    defaultMessage: "Spanish flag",
    description:
      "The title visible to screen readers for an image of the Spanish flag",
  },
  brazilianFlag: {
    id: "topNav.language.brazilianFlag",
    defaultMessage: "Brazilian flag",
    description:
      "The title visible to screen readers for an image of the Brazilian flag",
  },
  italianFlag: {
    id: "topNav.language.italianFlag",
    defaultMessage: "Italian flag",
    description:
      "The title visible to screen readers for an image of the Italian flag",
  },
  unitedKingdomFlag: {
    id: "topNav.language.unitedKingdomFlag",
    defaultMessage: "United Kingdom's flag",
    description:
      "The title visible to screen readers for an image of the flag of the United Kingdom",
  },
  russianFlag: {
    id: "topNav.language.russianFlag",
    defaultMessage: "Russia's flag",
    description:
      "The title visible to screen readers for an image of the flag of Russia",
  },
  polishFlag: {
    id: "topNav.language.polishFlag",
    defaultMessage: "Polish flag",
    description:
      "The title visible to screen readers for an image of the flag of Poland",
  },
  netherlandsFlag: {
    id: "topNav.language.netherlandsFlag",
    defaultMessage: "Netherlands flag",
    description:
      "The title visible to screen readers for an image of the flag of Netherlands",
  },
  swedenFlag: {
    id: "topNav.language.swedenFlag",
    defaultMessage: "Sweden flag",
    description:
      "The title visible to screen readers for an image of the flag of Sweden",
  },
  norwayFlag: {
    id: "topNav.language.norwayFlag",
    defaultMessage: "Norway flag",
    description:
      "The title visible to screen readers for an image of the flag of Norway",
  },
  denmarkFlag: {
    id: "topNav.language.denmarkFlag",
    defaultMessage: "Denmark flag",
    description:
      "The title visible to screen readers for an image of the flag of Denmark",
  },
  japanFlag: {
    id: "topNav.language.japanFlag",
    defaultMessage: "Japan flag",
    description:
      "The title visible to screen readers for an image of the flag of Japan",
  },
  southKoreaFlag: {
    id: "topNav.language.southKoreaFlag",
    defaultMessage: "South Korea flag",
    description:
      "The title visible to screen readers for an image of the flag of South Korea",
  },
  thailandFlag: {
    id: "topNav.language.thailandFlag",
    defaultMessage: "Thailand flag",
    description:
      "The title visible to screen readers for an image of the flag of Thailand",
  },
  chinaFlag: {
    id: "topNav.language.chinaFlag",
    defaultMessage: "China flag",
    description:
      "The title visible to screen readers for an image of the flag of China",
  },
  romainiaFlag: {
    id: "topNav.language.romaniaFlag",
    defaultMessage: "Romainia flag",
    description:
      "The title visible to screen readers for an image of the flag of Romania",
  },
  malaysiaFlag: {
    id: "topNav.language.malaysiaFlag",
    defaultMessage: "Malaysia flag",
    description:
      "The title visible to screen readers for an image of the flag of Malaysia",
  },
  czechiaFlag: {
    id: "topNav.language.czechiaFlag",
    defaultMessage: "Czechia flag",
    description:
      "The title visible to screen readers for an image of the flag of Czechia",
  },
  hungaryFlag: {
    id: "topNav.language.hungaryFlag",
    defaultMessage: "Hungary flag",
    description:
      "The title visible to screen readers for an image of the flag of Hungary",
  },
  greeceFlag: {
    id: "topNav.language.greeceFlag",
    defaultMessage: "Greek flag",
    description:
      "The title visible to screen readers for an image of the flag of Greece",
  },
  finlandFlag: {
    id: "topNav.language.finlandFlag",
    defaultMessage: "Finland flag",
    description:
      "The title visible to screen readers for an image of the flag of Finland",
  },
  slovakiaFlag: {
    id: "topNav.language.slovakiaFlag",
    defaultMessage: "Slovakia flag",
    description:
      "The title visible to screen readers for an image of the flag of Slovakia",
  },
  croatiaFlag: {
    id: "topNav.language.croatiaFlag",
    defaultMessage: "Croatia flag",
    description:
      "The title visible to screen readers for an image of the flag of Croatia",
  },
});
