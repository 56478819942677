import { defineMessages } from "react-intl";

const messages = defineMessages({
  showStops: {
    id: "segment.timeline.detail.showStops",
    description: "Message that prompts users to show additional stops",
    defaultMessage: "Show stops",
  },
  hideStops: {
    id: "segment.timeline.detail.hideStops",
    description: "Message that prompts users to hide additional stops",
    defaultMessage: "Hide stops",
  },
  showHideDetails: {
    id: "segment.timeline.detail.showDetails",
    description: "Message that prompts users to toggle additional details",
    defaultMessage: "Details",
  },
  durationLabel: {
    id: "segment.duration.label",
    description: "Label for screen readers that describes a duration, e.g. 1h",
    defaultMessage: "Duration",
  },
  towards: {
    id: "schedule.towards",
    description:
      "Rendered in a timeline. e.g. 48 towards City or TLD towards King St Station",
    defaultMessage: "{lineName} towards {direction}",
  },
  expTowards: {
    id: "schedule.expTowards",
    description:
      "Rendered in a timeline. e.g. Towards City or Towards King St Station",
    defaultMessage: "Towards {direction}",
  },
  line: {
    id: "schedule.line",
    description: "Rendered in a timeline detail. e.g. Train line",
    defaultMessage: "{transitMode} line",
  },
  flightNumber: {
    id: "schedule.flightNumber",
    description: "Rendered in a timeline detail for flights",
    defaultMessage: "Flight number",
  },
  findTickets: {
    id: "segment.timeline.detail.findTickets",
    description:
      "CTA message on a button that redirects the user to a ticket search for a part of their journey",
    defaultMessage: "Find Tickets",
  },
});

export default messages;
