import { useRef, useEffect, PropsWithChildren } from "react";

type Props = PropsWithChildren<{ isActive?: boolean }>;

/**
 * The FocusTrap wrapper ensures that if `isActive` is true (default),
 * the focus stays trapped within the children of this component
 * and loops back to the first focusable element
 * when reaching the end of the focusable elements list.
 */
export function FocusTrap({ children, isActive = true }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerEl = containerRef.current;
    if (!isActive || !containerEl) {
      return;
    }
    const focusableElements = containerEl.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusableElement = focusableElements?.[0] as HTMLElement;
    const lastFocusableElement = focusableElements?.[
      focusableElements.length - 1
    ] as HTMLElement;

    // Direct focus to the first element if focus is not already within the trap
    if (!containerEl.contains(document.activeElement)) {
      firstFocusableElement.focus();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== "Tab") {
        return;
      }
      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    };

    containerEl.addEventListener("keydown", handleKeyDown);

    return () => {
      containerEl.removeEventListener("keydown", handleKeyDown);
    };
  }, [isActive]);

  return (
    <div ref={containerRef} style={{ display: "contents" }}>
      {children}
    </div>
  );
}
