import { registerOnConsent } from "@rome2rio/tcf-api";
import { useEffect, useState } from "react";
import { useFeature } from "src/feature/useFeature";

/**
 * useRegisterOnConsent is a hook used to determine if a user has consented to the required purposes and legitimate interests
 *
 * @param consentPurposes - a list of required consent purposes
 * @param legitimateInterests - a list of required legitimate interests
 * @returns boolean - whether the user has consented to the required purposes and legitimate interests
 */
export function useRegisterOnConsent(
  consentPurposes: number[],
  legitimateInterests: number[]
) {
  // Assume we have consent on dev builds. Our current CMP
  // solution does not work on non-rome2rio.com domains so we'll
  // never achieve consent.
  const defaultAssumedConsent = useFeature("CmpDefaultConsent");

  const [hasConsent, setHasConsent] = useState(defaultAssumedConsent);
  useEffect(() => {
    registerOnConsent({
      onGiven: () => setHasConsent(true),
      onRevoked: () => setHasConsent(false),
      gdpr: {
        consentPurposesRequired: consentPurposes,
        legitimateInterestPurposesRequired: legitimateInterests,
      },
    });
  }, [consentPurposes, legitimateInterests]);
  return hasConsent;
}
