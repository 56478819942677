import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    defaultMessage: "Hotels",
    id: "hotels.heading",
    description: "The title of the hotels tab",
  },
  noHotels: {
    defaultMessage: "Unfortunately, no hotels were found for your search.",
    id: "hotels.noHotels",
    description: "Message that shows when no hotels were found for a search.",
  },
  noHotelsV2: {
    defaultMessage: "No hotels found. Try changing your search.",
    id: "hotels.noHotelsV2",
    description: "Message that shows when no hotels were found for a search.",
  },
  hotelsFound: {
    defaultMessage: "{ NUMBER_OF_HOTELS } hotels found",
    id: "hotels.hotelsFound",
    description:
      'Message describing the number of hotels found. eg: 32 "hotels found"',
  },
  hotelFound: {
    defaultMessage: "1 hotel found",
    id: "hotels.hotelFound",
    description: 'Message descrbing a single hotel found. e.g. "1 hotel found"',
  },
  searchingForHotels: {
    defaultMessage: "Searching for hotels...",
    id: "hotels.searchingForHotels",
    description:
      "Message to inform user that the app is searching for hotels data.",
  },
  noHotelsFound: {
    defaultMessage: "No hotels found.",
    id: "hotels.noHotelsFound",
    description: "Message to inform user that no hotels were found.",
  },
  checkIn: {
    defaultMessage: "Check-in",
    id: "hotels.checkIn",
    description: "Label for the check-in date",
  },
  checkOut: {
    defaultMessage: "Check-out",
    id: "hotels.checkOut",
    description: "Label for the check-out date",
  },
  hotelsOnly: {
    defaultMessage: "Hotels only",
    id: "hotels.hotelsOnly",
    description: "Label for the hotels only checkbox",
  },
  wifi: {
    defaultMessage: "Wifi",
    id: "hotels.wifi",
    description: "Label for the wifi checkbox",
  },
});

export default messages;
