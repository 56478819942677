import { createContext, useContext } from "react";

export type RadioGroupState = {
  name: string;
  value: string;
  onChange: (value: string) => void;
};

export const RadioGroupContext = createContext<RadioGroupState | undefined>(
  undefined
);

export function useRadioGroup() {
  return useContext(RadioGroupContext);
}
