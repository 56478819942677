import { PropsWithChildren, FocusEvent } from "react";

type Props = PropsWithChildren<{
  className?: string;
  onBlurAway: () => void;
  blurAfterClick?: boolean;
}>;

export function BlurAwayListener(props: Props) {
  const handleBlur = (e: FocusEvent) => {
    const current = e.currentTarget;
    const related = e.relatedTarget;

    const isClickWithinContainer =
      related instanceof Node && current.contains(related);

    // onBlur happens before onClick, so if we want to wait until after the
    // click events we have to wait until the next tick.
    if (!isClickWithinContainer) {
      if (props.blurAfterClick) {
        setTimeout(props.onBlurAway, 0);
      } else {
        props.onBlurAway();
      }
    }
  };

  return (
    <div className={props.className} tabIndex={-1} onBlur={handleBlur}>
      {props.children}
    </div>
  );
}
