import {
  OnChangeDateCallback,
  OnChangeDateRangeCallback,
} from "react-calendar";
import { useReturnFlowState } from "../../domain/SegmentScreen/ReturnFlowStateProvider";

import { BaseCalendar } from "./BaseCalendar";

type Props = {
  selectedDate?: Date;
  onDateChange(date: Date): void;
  minDate: Date;
  maxDaysAhead?: number;

  isSelectingReturn?: boolean;
  className?: string;
  onMonthChange?: () => void;
};

export function DatePicker(props: Props) {
  const { setReturnFlowState } = useReturnFlowState();

  const handleChange: OnChangeDateCallback | OnChangeDateRangeCallback = (
    jsDate: Date | Date[]
  ) => {
    // in some cases OnChangeDateCallback will return a range so have to make sure it's a date
    // it will always return a Date in our use case though
    if (!Array.isArray(jsDate)) {
      props.onDateChange(jsDate);
    }

    setReturnFlowState({
      isReturn: false,
    });
  };

  let maxDate: Date | undefined;
  if (props.maxDaysAhead) {
    maxDate = new Date(props.minDate);
    maxDate.setDate(props.minDate.getDate() + props.maxDaysAhead);
  }

  return (
    <BaseCalendar
      className={props.className}
      isSelectingReturn={props.isSelectingReturn}
      minDate={props.minDate}
      maxDate={maxDate}
      defaultValue={props.selectedDate}
      onChange={handleChange}
      // BaseCalendar.tsx ensures this is only called when the month changes
      onActiveStartDateChange={props.onMonthChange}
    />
  );
}
