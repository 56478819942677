import { SvgProps, useColor } from "./common";

export const Hotel = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg fill="none" viewBox="0 0 32 32">
      <title>{props.title}</title>
      <path
        d="M1.5 29.999V17.493c0-1.595 1.236-2.993 2.9-2.993h23.202c1.673 0 2.898 1.408 2.898 2.993V30M1.5 24.551h29"
        stroke={tintColor}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 14.5V3.996c0-1.6.928-2.337 2.403-2.337h18.083c1.624 0 2.514.747 2.514 2.337V14.5"
        stroke={tintColor}
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
