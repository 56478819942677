import { Mode } from "src/utils/types/mode";
import { UNSAFE_featureConfigEscapeHatch } from "../feature/UNSAFE_useConfigureFeatureEscapeHatch";
import {
  CustomDimensions,
  GaPageConfig,
  configureAnalytics,
} from "./configureAnalytics";
import { ScreenLocations } from "./getLocations/getLocations";
import { Screen } from "./generateScreenComponentsRendered/Screen";
import { ScreenComponent } from "./generateScreenComponentsRendered/ScreenComponent";
import { updateSessionInfo } from "./useAnalyticsCoreExperience/session";

export const PAGE_VIEW_GROUP = import.meta.env.PROD ? "general" : "development";

export function sendAnalyticsPageView(
  pageConfig: GaPageConfig,
  customDimensions?: CustomDimensions,
  screen?: Screen,
  modes?: Mode[],
  components?: ScreenComponent[],
  locations?: ScreenLocations
) {
  updateSessionInfo();

  // Analytics has to be configured before sending a PageView so that the correct
  // page information is attributed to the PageView and all subsequent Analytics events (until
  // Analytics is configured again).
  configureAnalytics({
    pageConfig,
    customDimensions,
    locations,
    screen,
    modes,
    components,
  });

  window.gtag &&
    window.gtag("event", "page_view", { send_to: PAGE_VIEW_GROUP });
  !!window.snowplow && window.snowplow("trackPageView");

  const target = !!window.snowplow ? "Ga+Sp" : "Ga";

  if (UNSAFE_featureConfigEscapeHatch.LogAnalyticsEventsToConsole) {
    logPageViewToConsole(pageConfig, target);
  }
}

function logPageViewToConsole(config: GaPageConfig, target?: string) {
  console.log(
    `» %c${target} Pageview %c${config.pagePath} %c${config.pageLocation}`,
    "color:green;",
    "color:blue;",
    "color:#48104d;"
  );
}
