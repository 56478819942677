import { iconSize } from "../theme";
import { SvgProps, useColor } from "./common";

type CalendarIconProps = SvgProps & {
  size?: keyof typeof iconSize;
};

/**
 * @deprecated Use the CalendarIcon svg instead
 */

export const CalendarIcon = (props: CalendarIconProps) => {
  const tintColor = useColor(props.tint);
  const size = props.size ? iconSize[props.size] : "16";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 1 16 16"
      fill="none"
    >
      <path
        d="M12.6668 2.66659H11.3335V1.99992C11.3335 1.82311 11.2633 1.65354 11.1382 1.52851C11.0132 1.40349 10.8436 1.33325 10.6668 1.33325C10.49 1.33325 10.3204 1.40349 10.1954 1.52851C10.0704 1.65354 10.0002 1.82311 10.0002 1.99992V2.66659H6.00016V1.99992C6.00016 1.82311 5.92992 1.65354 5.8049 1.52851C5.67988 1.40349 5.51031 1.33325 5.3335 1.33325C5.15669 1.33325 4.98712 1.40349 4.86209 1.52851C4.73707 1.65354 4.66683 1.82311 4.66683 1.99992V2.66659H3.3335C2.80306 2.66659 2.29436 2.8773 1.91928 3.25237C1.54421 3.62744 1.3335 4.13615 1.3335 4.66659V12.6666C1.3335 13.197 1.54421 13.7057 1.91928 14.0808C2.29436 14.4559 2.80306 14.6666 3.3335 14.6666H12.6668C13.1973 14.6666 13.706 14.4559 14.081 14.0808C14.4561 13.7057 14.6668 13.197 14.6668 12.6666V4.66659C14.6668 4.13615 14.4561 3.62744 14.081 3.25237C13.706 2.8773 13.1973 2.66659 12.6668 2.66659ZM13.3335 12.6666C13.3335 12.8434 13.2633 13.013 13.1382 13.138C13.0132 13.263 12.8436 13.3333 12.6668 13.3333H3.3335C3.15669 13.3333 2.98712 13.263 2.86209 13.138C2.73707 13.013 2.66683 12.8434 2.66683 12.6666V7.99992H13.3335V12.6666ZM13.3335 6.66659H2.66683V4.66659C2.66683 4.48977 2.73707 4.32021 2.86209 4.19518C2.98712 4.07016 3.15669 3.99992 3.3335 3.99992H4.66683V4.66659C4.66683 4.8434 4.73707 5.01297 4.86209 5.13799C4.98712 5.26301 5.15669 5.33325 5.3335 5.33325C5.51031 5.33325 5.67988 5.26301 5.8049 5.13799C5.92992 5.01297 6.00016 4.8434 6.00016 4.66659V3.99992H10.0002V4.66659C10.0002 4.8434 10.0704 5.01297 10.1954 5.13799C10.3204 5.26301 10.49 5.33325 10.6668 5.33325C10.8436 5.33325 11.0132 5.26301 11.1382 5.13799C11.2633 5.01297 11.3335 4.8434 11.3335 4.66659V3.99992H12.6668C12.8436 3.99992 13.0132 4.07016 13.1382 4.19518C13.2633 4.32021 13.3335 4.48977 13.3335 4.66659V6.66659Z"
        fill={tintColor}
        stroke={tintColor}
        strokeWidth="0.2"
      />
    </svg>
  );
};
