import { defineMessages } from "react-intl";

const messages = defineMessages({
  header: {
    id: "searchResultsAirportTransferPromo.header",
    defaultMessage: "Airport Shuttle Bus",
    description: "Header for an airport transfer promo.",
  },
  tagline: {
    id: "searchResultsAirportTransferPromo.tagline",
    defaultMessage: "Compare and book airport shuttles and private transfers",
    description: "Tagline in the Airport Transfer promo",
  },
});

export default messages;
