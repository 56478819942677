import { useNavigate } from "react-router";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useFeature } from "src/feature/useFeature";
import { getUidCookie } from "src/utils/uid";
import { useIsHotelsUrlDeeplink } from "src/utils/hooks/useNavigateToHotelsPage";
import { useTransportDataPartners } from "src/data-partners/useDataPartner";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { mobileLayout } from "../../utils/hooks/useLayout";
import { searchResultScreenComponentList } from "../../analytics/generateScreenComponentsRendered/searchResultScreenComponentList";
import { DefaultErrorBoundary } from "../../components/DefaultErrorBoundary/DefaultErrorBoundary";
import { color, spacing } from "../../theme";
import { useAnalyticsPageView } from "../../utils/hooks/useAnalyticsPageView";
import useSearch from "../../utils/hooks/useSearch";
import { useTypedLocation } from "../../utils/hooks/useTypedLocation";
import { useTripPlannerContext } from "../TripPlanner/hooks/useTripPlannerContext";
import { updateTripPlanningState } from "../TripPlanner/hooks/useTripPlanningState";
import { TripNavbar } from "../TripPlanner/TripNavbar/TripNavbar";
import { useLayout } from "../../utils/hooks/useLayout";
import { SearchBar } from "./SearchBar/SearchBar";
import { UkraineSearchNotification } from "./SearchNotification/UkraineSearchNotification";
import {
  Errors,
  SearchResultError,
} from "./SearchResultError/SearchResultError";
import { SearchResultsAirportTransferPromo } from "./SearchResultsAirportTransferPromo/SearchResultsAirportTransferPromo";
import { SearchResultsCarHirePromo } from "./SearchResultsCarHirePromo/SearchResultsCarHirePromo";
import { SearchResultsContent } from "./SearchResultsContent/SearchResultsContent";
import { SearchResultsHotelPromo } from "./SearchResultsHotelPromo/SearchResultsHotelPromo";
import { SearchResultsLoading } from "./SearchResultsLoading/SearchResultsLoading";
import { useEmulateReload } from "./useEmulateReload";
import messages from "./SearchResultsScreen.messages";

type Props = {
  context: "transport" | "tripPlanner";
};

export function SearchResultsScreen(props: Props) {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const layout = useLayout();
  const intl = useIntl();
  const uid = getUidCookie();
  const isHotelsDeeplink = useIsHotelsUrlDeeplink();
  const jayridePromosEnabled = useFeature("JayrideTransfersPromos");
  const { searchResponse } = useSearch();
  const { emulateReload } = useEmulateReload();

  const components = searchResultScreenComponentList(
    searchResponse,
    jayridePromosEnabled
  );

  useTransportDataPartners(
    searchResponse,
    searchResponse?.analytics?.interest_data,
    uid
  );

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    searchResponse,
    "SearchResults",
    undefined,
    components
  );

  const { tripPlannerDetails, dispatch, tripPlanningState } =
    useTripPlannerContext();

  function handleTripSave(routeIndex?: string) {
    if (searchResponse) {
      dispatch({
        type: "SAVE_SEARCH",
        searchResponse,
        url: {
          pathname: location.pathname,
          hash: `#trips/r/${
            searchResponse.routes[Number(routeIndex)].canonicalName
          }`,
        },
        routeIndex: routeIndex ? Number(routeIndex) : undefined,
      });

      updateTripPlanningState(
        tripPlanningState,
        tripPlannerDetails,
        searchResponse,
        undefined,
        undefined,
        "Search"
      );
    }
  }

  function navigateToTripsScreen() {
    navigateToNewStateHash(navigate, {}, "#trips", {
      ...location,
    });
  }

  return (
    <Wrapper>
      {props.context === "tripPlanner" && (
        <TripNavbar
          variant={layout === "desktop" ? "fullScreen" : "default"}
          showSearchBreadcrumb
          onBackClick={layout !== "mobile" ? navigateToTripsScreen : undefined}
          headingText={intl.formatMessage(messages.travelOptions)}
        />
      )}
      {props.context === "transport" && layout === "mobile" && (
        <SearchBarWrapper>
          <SearchBar />
        </SearchBarWrapper>
      )}
      <Content>
        {components.includes("SearchNotification") && (
          <UkraineSearchNotification />
        )}
        {components.includes("RouteList") && (
          <DefaultErrorBoundary defaultMessage>
            <RouteList
              context={props.context}
              isLoading={emulateReload}
              handleTripSave={handleTripSave}
              isHotelsScreen={isHotelsDeeplink}
            />
          </DefaultErrorBoundary>
        )}
        {components.includes("HotelCta") && <StyledSearchResultsHotelPromo />}
        {components.includes("RentalCarCta") && (
          <DefaultErrorBoundary>
            <StyledSearchResultsCarHirePromo />
          </DefaultErrorBoundary>
        )}
        {components.includes("AirportTransferCta") && (
          <DefaultErrorBoundary>
            <StyledSearchResultsAirportTransferPromo />
          </DefaultErrorBoundary>
        )}
      </Content>
    </Wrapper>
  );
}

const StyledSearchResultsAirportTransferPromo = styled(
  SearchResultsAirportTransferPromo
)`
  border-bottom: 1px solid ${color.grey2};
`;

const StyledSearchResultsCarHirePromo = styled(SearchResultsCarHirePromo)`
  border-bottom: 1px solid ${color.grey2};
`;

const StyledSearchResultsHotelPromo = styled(SearchResultsHotelPromo)`
  border-bottom: 1px solid ${color.grey2};
`;

function RouteList(props: {
  context: "transport" | "tripPlanner";
  isLoading: boolean;
  handleTripSave: (routeIndex?: string) => void;
  isHotelsScreen?: boolean;
}) {
  const { origin, destination, searchResponse, isLoading, error } = useSearch();
  const searchResponsePending = props.isLoading || isLoading;
  const hasResults = !!searchResponse?.routes?.length;

  if (error) {
    return <ErrorContent errorType="error" />;
  } else if (searchResponse && !hasResults) {
    return <ErrorContent errorType="no-results" />;
  } else if (!origin) {
    return <ErrorContent errorType="no-origin" />;
  } else if (!destination) {
    return <ErrorContent errorType="no-destination" />;
  } else if (searchResponsePending) {
    return <SearchResultsLoading />;
  } else if (searchResponse) {
    return (
      <SearchResultsContent
        context={props.context}
        searchResponse={searchResponse}
        handleTripSave={props.handleTripSave}
      />
    );
  } else {
    return null;
  }
}

function ErrorContent(props: { errorType: Errors }) {
  return (
    <ErrorComponent>
      <SearchResultError errorType={props.errorType} />
    </ErrorComponent>
  );
}

const Wrapper = styled.div`
  z-index: 6;
  position: relative;
  display: inline;
`;

const ErrorComponent = styled.div`
  padding: ${spacing.xl};
`;

const Content = styled.div`
  width: 100%;
  margin-top: "initial";

  ${mobileLayout} {
    // Margin adjustment for mobile top of page ad slot
    margin-top: "25px";
  }
`;

export const SearchBarWrapper = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  z-index: 50;
`;
