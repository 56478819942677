import { defineMessages } from "react-intl";

const messages = defineMessages({
  multiSearchDefault: {
    id: "tripPlanner.multipleSearch.default",
    defaultMessage: "Enter countries, cities or places to visit on your trip",
    description:
      "When theres no destinations selected show description to add places",
  },
  multiSearchAndThen: {
    id: "tripPlanner.multipleSearch.andThen",
    defaultMessage: "And then to?",
    description:
      "After user selects one destination, text to ask them where to next",
  },
  multiSearchCancel: {
    id: "tripPlanner.multipleSearch.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button to close search modal",
  },
  multiSearch: {
    id: "tripPlanner.multipleSearch.search",
    defaultMessage: "Search",
    description: "Button for user to create their trip of multiple searches",
  },
  multiSearchWhereTo: {
    id: "tripPlanner.multipleSearch.whereTo",
    defaultMessage: "Where to?",
    description: "Button for user to open multi search to create new trip",
  },
  multiSearchWhereToGo: {
    id: "tripPlanner.multipleSearch.whereToGo",
    defaultMessage: "Where do you want to go?",
    description: "Heading for multi search input",
  },
  multiSearchSaveWarning: {
    id: "tripPlanner.multipleSearch.saveWarning",
    defaultMessage:
      "This search will replace your current trip. Sign in to save.",
    description:
      "Warning label that your trip will be overwritten if you dont sign in",
  },
  swap: {
    id: "tripPlanner.swap",
    defaultMessage: "Swap destinations",
    description:
      "Label on a button that swaps the first destination with the second destination",
  },
  clear: {
    id: "tripPlanner.multipleSearch.clear",
    defaultMessage: "Clear",
    description:
      "Label on button that clears all the destinations from the search input",
  },
});

export default messages;
