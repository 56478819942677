// The original source for useForkRef came from Material UI and was converted to
// TypeScript. See: https://github.com/mui-org/material-ui

import { MutableRefObject, RefCallback, useMemo } from "react";
import setRef from "./setRef";

type Ref<T> = MutableRefObject<T | null> | RefCallback<T> | null | undefined;

export function useForkRef<T>(refA: Ref<T>, refB: Ref<T>) {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return useMemo(() => {
    if (!refA && !refB) {
      return null;
    }
    return (refValue: T | null) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}
