import {
  Alternative,
  Route,
  SearchResponse,
  TicketLink,
} from "../api/SearchResponse";

const isJayrideLink = (link: TicketLink | undefined) =>
  link?.providers?.includes("Jayride");

export function getJayrideTicketLink(
  searchResponse: SearchResponse | undefined
) {
  if (!searchResponse) return undefined;

  let jayrideRouteLink: TicketLink | undefined;
  let jayrideSegmentLink: TicketLink | undefined;

  jayrideRouteLink = searchResponse.routes
    .flatMap((route: Route) => route.links)
    .find(isJayrideLink);

  if (!jayrideRouteLink) {
    searchResponse.routes.find((route: Route) => {
      jayrideSegmentLink = route.segments
        .slice()
        .reverse()
        .flatMap(
          (segmentIndex: number) => searchResponse.segments[segmentIndex].links
        )
        .find(isJayrideLink);

      if (!jayrideSegmentLink) {
        const routeAlternatives = route.alternatives
          ? [...route.alternatives]
          : undefined;
        jayrideSegmentLink = routeAlternatives
          ?.sort((a, b) => b.firstSegment - a.firstSegment)
          .flatMap((alternative: Alternative) =>
            alternative.segments.flatMap(
              (segmentIndex: number) =>
                searchResponse.segments[segmentIndex].links
            )
          )
          .find(isJayrideLink);
      }

      return jayrideSegmentLink;
    });
  }

  return jayrideRouteLink || jayrideSegmentLink;
}
