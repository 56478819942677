import Cookies from "js-cookie";
import { useRef, useState } from "react";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import useUser from "src/utils/hooks/useUser";

const HAS_BEEN_CLOSE_COOKIE_NAME = "hasBeenClosedTripPlannerUserPrompt";

export function useUserPrompt() {
  const [hasBeenClosed, setHasBeenClosed] = useState(() => {
    return Cookies.get(HAS_BEEN_CLOSE_COOKIE_NAME) === "true";
  });
  const userPromptRef = useRef<HTMLDivElement>(null);
  const { isSmScreen } = useScreenMinWidth();

  const { user } = useUser();
  const showUserPrompt = !user && !isSmScreen;

  function handleCloseUserPrompt() {
    Cookies.set(HAS_BEEN_CLOSE_COOKIE_NAME, "true", {
      expires: 365, // Set cookie to expire in a year.
    });
    setHasBeenClosed(true);
  }

  function handleSaveTripUserPrompt() {
    setHasBeenClosed(true);
  }

  return {
    hasBeenClosed,
    handleCloseUserPrompt,
    showUserPrompt,
    handleSaveTripUserPrompt,
    userPromptRef,
  };
}
