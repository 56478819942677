import Cookies from "js-cookie";
import { timeZoneDictionary, TimeZone } from "./timezoneDictionary";

export const getCountryCode = () => {
  return (
    getCountryCodeFromCookie() ||
    getCountryCodeFromLocaleTimezone() ||
    getCountryCodeFromUID()
  );
};

const getCountryCodeFromCookie = () => {
  const countryCodeCookie = Cookies.get("country_code");
  // The cookie might be set to the string "undefined"
  if (!countryCodeCookie || countryCodeCookie === "undefined") {
    return undefined;
  }
  return countryCodeCookie.toUpperCase();
};

const getCountryCodeFromLocaleTimezone = () => {
  const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (!timeZone || !timeZoneDictionary.hasOwnProperty(timeZone)) {
    return undefined;
  }
  // Return the first available country code for that timezone in our dictionary
  return timeZoneDictionary[timeZone as TimeZone][0];
};

const getCountryCodeFromUID = () => {
  const uidCountryCode = Cookies.get("uid")?.slice(0, 2)?.toUpperCase();
  // Anonymous users will have a country code of XX in the UID, we don't want to use this
  return uidCountryCode === "XX" ? undefined : uidCountryCode;
};
