import { defineMessages } from "react-intl";

export const messages = defineMessages({
  schedules: {
    id: "tripPlanner.tripCard.schedules",
    description: "Label for the CTA under the schedule trip card",
    defaultMessage: "Schedules",
  },
  fromPrice: {
    id: "tripPlanner.tripCard.fromPrice",
    description: "Subtitle for the CTA under the Search trip card",
    defaultMessage: "from {price}",
  },
});
