import {
  TripStopRoute,
  getRoutesBetween,
} from "src/domain/HotelsScreen/TravelOptions/utils";
import { useGetRoutesFromCoords } from "./useGetRoutesFromCoords";

type Props = {
  from: google.maps.LatLngLiteral;
  to: google.maps.LatLngLiteral;
  count?: number;
};

export function useRoutesBetween({
  from,
  to,
  count = 3,
}: Props): TripStopRoute[] | undefined {
  const secondarySearchResponse = useGetRoutesFromCoords(
    from.lat,
    from.lng,
    to.lat,
    to.lng,
    true
  ).data;

  const routes = getRoutesBetween({
    searchResponse: secondarySearchResponse,
    count,
  });

  return routes;
}
