import { PartialSearchResponse } from "src/domain/TripPlanner/util/getPartialSearchResponse";
import { SearchResponse } from "../../api/SearchResponse";
import { getRouteCanonicalFromHash } from "./routeCanonicalFromHash";

export function getRouteIndexFromHash(
  hash?: string,
  searchResponse?: SearchResponse | PartialSearchResponse
) {
  if (hash === undefined) return undefined;
  const routeCanonical = getRouteCanonicalFromHash(hash);
  const index = searchResponse?.routes.findIndex(
    (route) => route.canonicalName === routeCanonical
  );
  return index === -1 ? undefined : index;
}
