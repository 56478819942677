import { defineMessages } from "react-intl";

const messages = defineMessages({
  myTrips: {
    id: "myTripsModal.myTrips",
    description: "Button to show all the trips youve saved",
    defaultMessage: "My trips",
  },
});

export default messages;
