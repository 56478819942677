import { defineMessages } from "react-intl";

export const messages = defineMessages({
  enterCity: {
    id: "tripPlanner.addDestination.enterCity",
    description: "Description inside input to add city or place",
    defaultMessage: "Enter city or place",
  },
  addDestination: {
    id: "tripPlanner.addDestination.add",
    description: "Default description inside input to add destination",
    defaultMessage: "Add destination",
  },
});
