import { defineMessages } from "react-intl";

const messages = defineMessages({
  close: {
    id: "autocomplete.close",
    defaultMessage: "close",
    description: "The label for closing the autocomplete screen",
  },
});

export default messages;
