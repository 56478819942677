import { useEffect } from "react";
import useSearch from "../utils/hooks/useSearch";
import { SearchResponse } from "../api/SearchResponse";
import { ExperimentName, ExperimentVariant } from "./ExperimentDefinition";
import { createExperimentConfig } from "./createExperimentConfig";

let searchResponseCache: SearchResponse | undefined;

export function useExperimentConfig(): ExperimentConfig | undefined {
  const { searchResponse } = useSearch();

  // Cache the search response so that the missing search response when the
  // search pair changes doesn't arbitrarily change the feature config.
  useEffect(() => {
    if (searchResponse) {
      searchResponseCache = searchResponse;
    }
  }, [searchResponse]);

  return createExperimentConfig(searchResponse ?? searchResponseCache);
}

export type ExperimentConfig = {
  [experimentName in ExperimentName]: ExperimentVariant<experimentName>;
};
