import { defineMessages } from "react-intl";

const messages = defineMessages({
  heading: {
    id: "distanceScreen.heading",
    defaultMessage: "Change units",
    description: "Heading of page where user can change distance units",
  },
  newHeading: {
    id: "distanceScreen.newHeading",
    defaultMessage: "Distance",
    description: "Heading of page where user can change distance units",
  },
  description: {
    id: "distanceScreen.description",
    defaultMessage: "Choose how to show distance",
    description: "Description of page where user can change distance units",
  },
});

export default messages;
