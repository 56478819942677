import { color } from "src/theme";
import { SvgProps } from "./common";

export const LetterLogo = (props: SvgProps & { className?: string }) => {
  return (
    <svg viewBox="0 0 16 24" fill="none" className={props.className}>
      <title>{props.title}</title>
      <path
        fill={color.pink}
        fillRule="evenodd"
        d="M2.212 19.184a7.548 7.548 0 0 1-1.525-8.486 7.55 7.55 0 1 1 13.73 0 7.56 7.56 0 0 1-1.526 8.486L8.436 23.64a1.258 1.258 0 0 1-1.769-.006l-4.455-4.45ZM11.354 8.38a1.894 1.894 0 0 1-2.15.712 5.035 5.035 0 0 0-5.211 1.194 5.028 5.028 0 0 0 0 7.117l3.561 3.562 3.562-3.562a5.023 5.023 0 0 0 1.438-4.168l-4.112 4.112a1.258 1.258 0 0 1-1.769-.006l-3.18-3.18a.434.434 0 0 1 0-.613l.137-.138a1.886 1.886 0 0 1 2.668 0l1.263 1.263 3.562-3.562a5.027 5.027 0 0 0 0-7.118 5.027 5.027 0 0 0-7.118 0A5.041 5.041 0 0 0 2.568 8.18a7.554 7.554 0 0 1 8.66-.937c.082.05.263.143.332.206a.445.445 0 0 1 .063.568l-.27.363Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
