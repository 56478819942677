export type Kind = (typeof transitKinds)[number];

export const transitKinds = [
  "animal",
  "bus",
  "busferry",
  "cablecar",
  "car",
  "carferry",
  "cartrain",
  "cycle",
  "ferry",
  "helicopter",
  "hotelcity",
  "nightbus",
  "nighttrain",
  "none",
  "plane",
  "rideshare",
  "shuttle",
  "subway",
  "taxi",
  "towncar",
  "train",
  "tram",
  "transfer",
  "unknown",
  "walk",
] as const;
