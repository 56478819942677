import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { color, spacing } from "../../theme";
import { VisuallyHidden } from "../VisuallyHidden/VisuallyHidden";
import messages from "./Toggle.messages";

const DEFAULT_CIRCLE_DIAMETER = 20;
const ANIMATION_DURATION = 140;

type ToggleProps = {
  selected: boolean;
  setSelected: Dispatch<SetStateAction<boolean>>;
  label: string;
  disabled?: boolean;
  backgroundColor?: keyof typeof color;
  circleDiameter?: number;
  visualLabel?: boolean;
  role?: "checkbox" | "radio";
  className?: string;
};

export function Toggle({
  selected,
  setSelected,
  label,
  disabled = false,
  backgroundColor = "pink",
  circleDiameter = DEFAULT_CIRCLE_DIAMETER,
  children,
  visualLabel = false,
  role = "checkbox",
  className,
}: PropsWithChildren<ToggleProps>) {
  const STYLES = {
    backgroundColor: disabled ? `${color.fadedPink}` : `${color.grey3}`,
  };
  const intl = useIntl();

  const handleToggle = () => {
    const toggleState = !selected;
    if (disabled) return;
    setSelected(toggleState);
  };
  return (
    <Wrapper className={className}>
      <NativeCheck
        type={role}
        aria-checked={selected}
        id={`${label}-toggle`}
        value={label}
        checked={selected}
        onChange={handleToggle}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === "Enter") {
            handleToggle();
          }
        }}
        readOnly
      />

      <PresentationCheck
        id={`${label}-presentation`}
        data-testid={`${label}-presentation`}
        $toggleActive={selected && !disabled}
        onClick={handleToggle}
        style={STYLES}
        $backgroundColor={backgroundColor}
        $circleDiameter={circleDiameter}
      >
        <Circle $selected={selected} $circleDiameter={circleDiameter}>
          {children}
        </Circle>
      </PresentationCheck>

      {visualLabel ? (
        <Label htmlFor={`${label}-toggle`}>
          {/* TODO: isubmit intl for translation */}
          {label}
        </Label>
      ) : null}

      {!visualLabel ? (
        <VisuallyHidden>
          <Label htmlFor={`${label}-toggle`}>
            {intl.formatMessage(messages.toggleLabel, {
              label: `${label}`,
            })}
          </Label>
        </VisuallyHidden>
      ) : null}
    </Wrapper>
  );
}

// Keep for a11y, but hide and focus onto a presentation component with custom styles.
const NativeCheck = styled.input`
  appearance: none;
  margin: 0;
`;

const PresentationCheck = styled.span<{
  $toggleActive: boolean;
  $circleDiameter: number;
  $backgroundColor: keyof typeof color;
}>`
  width: calc(2 * ${({ $circleDiameter }) => $circleDiameter}px);
  height: ${({ $circleDiameter }) =>
    $circleDiameter ? `${$circleDiameter + 4}px` : "24px"};
  border-radius: ${({ $circleDiameter }) => $circleDiameter}px;
  display: flex;
  align-items: center;
  padding-inline: ${spacing.sm};
  position: relative;
  cursor: pointer;

  // For perf reasons we transition the opacity of a pseudo rather than bg-color directly.
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: ${({ $backgroundColor }) => color[$backgroundColor]};
    border-radius: ${({ $circleDiameter }) => $circleDiameter}px;
    opacity: ${(props) => (props.$toggleActive ? 1 : 0)};
    transition: opacity ${ANIMATION_DURATION}ms ease-in;
  }

  // + is adjacent sibling combinator. Mirror the actual focus that is hidden, onto this presentation component.
  ${NativeCheck}:focus-visible + & {
    outline: 3px solid ${color.pink};
    z-index: 1;
  }
  // Hide annoying outline for mouse users but preserve for keyboard users. Ignored by browsers that don’t support :focus-visible.
  ${NativeCheck}:focus:not(:focus-visible) + & {
    outline: 0;
  }
`;

const Circle = styled.div<{ $selected: boolean; $circleDiameter: number }>`
  width: ${({ $circleDiameter }) => $circleDiameter}px;
  height: ${({ $circleDiameter }) => $circleDiameter}px;
  background-color: ${color.white};
  border-radius: 50%;

  transform: ${({ $selected, $circleDiameter }) =>
    $selected ? `translateX(${$circleDiameter - 6}px)` : "translateX(-2px)"};
  transition: transform ${ANIMATION_DURATION}ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  margin-left: ${spacing.lg};
  display: block;
  position: relative;
  padding-right: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
