import { SearchResponse } from "src/api/SearchResponse";
import { Mode } from "src/utils/types/mode";
import { airportsFromFlightRoute } from "src/utils/airportsFromFlightRoute";
import {
  destinationPlaceFromSearch,
  originPlaceFromSearch,
} from "src/utils/adapters/place";
import { priceRangeFromRoute } from "src/utils/adapters/priceRange";
import { Fare, SchedulesResponse } from "src/api/SchedulesResponse";
import { AdaraEvent } from "./sendAdaraEvent";

export function getAdaraTransportData<T extends AdaraEvent>({
  searchResponse,
  routeIndex,
  schedulesResponse,
  transitMode,
  numTripLegs,
  returnDate,
}: {
  searchResponse?: SearchResponse;
  routeIndex?: number;
  schedulesResponse?: SchedulesResponse;
  transitMode?: Mode;
  numTripLegs?: number;
  returnDate?: string;
}): Partial<T> {
  const hasSearchAndRoute = searchResponse && routeIndex !== undefined;
  const departurePlace = hasSearchAndRoute
    ? originPlaceFromSearch(searchResponse)
    : undefined;
  const arrivalPlace = hasSearchAndRoute
    ? destinationPlaceFromSearch(searchResponse)
    : undefined;

  const airports =
    transitMode === "plane" && hasSearchAndRoute
      ? airportsFromFlightRoute(searchResponse, routeIndex)
      : [];

  const route = hasSearchAndRoute
    ? searchResponse.routes[routeIndex]
    : undefined;
  const priceRange = route && priceRangeFromRoute(route);

  const tripType = numTripLegs
    ? getTripType(numTripLegs, returnDate !== undefined, schedulesResponse)
    : undefined;

  const ticketClass = schedulesResponse?.fares
    ? getTicketClass(schedulesResponse.fares)
    : undefined;

  return {
    departureDate: schedulesResponse?.request.outboundDateTime,
    departureCountry: departurePlace?.countryCode,
    departureAirportCode: airports[0]?.code,
    arrivalDate: schedulesResponse?.request.inboundDateTime,
    arrivalCountry: arrivalPlace?.countryCode,
    arrivalAirportCode: airports[airports.length - 1]?.code,
    price: priceRange && `${priceRange.low} - ${priceRange?.high}`,
    currencyType: priceRange?.currencyCode,
    tripType,
    ticketClass,
  } as T;
}

function getTripType(
  numTripLegs: number,
  isRoundTrip?: boolean,
  schedulesResponse?: SchedulesResponse
) {
  // Adara have requested the below trip types however our segments
  // can be part of a multi-city route or trip which means they do
  // not perfectly align to the provided values.
  // Therefore multi-city takes precendence if a user has multiple
  // trips in the trip planner, regardless of whether the segment
  // being viewed is return or one-way.
  if (numTripLegs > 1) {
    return "multi-city";
  }
  if (isRoundTrip) {
    return "roundtrip";
  }
  if (schedulesResponse) {
    return "one-way";
  }
}

function getTicketClass(fares: Fare[]) {
  const ticketClass = [];

  for (const { services } of fares) {
    if (services) {
      ticketClass.push(...services);
    }
  }

  return [...new Set(ticketClass)].join();
}
