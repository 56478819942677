import { SupportedCurrencyCode } from "./currency";
import { DistanceSystem } from "./distanceSystem";

// Country codes and their respective currency code are from CurencyHelper.cs in rome2rio-core
// https://github.com/rome2rio/rome2rio-core/blob/develop/rome2rio/Code/Store/Currency/CurrencyHelper.cs
export const countryCodeToCurrencyCode = (
  countryCode?: string
): SupportedCurrencyCode => {
  switch (countryCode) {
    case "AE":
      return "AED";
    case "AR":
      return "ARS";
    case "AU":
      return "AUD";
    case "BR":
      return "BRL";
    case "CA":
      return "CAD";
    case "CH":
      return "CHF";
    case "CN":
      return "CNY";
    case "DK":
      return "DKK";
    case "GB":
      return "GBP";
    case "HK":
      return "HKD";
    case "ID":
      return "IDR";
    case "IN":
      return "INR";
    case "JP":
      return "JPY";
    case "MY":
      return "MYR";
    case "MX":
      return "MXN";
    case "NO":
      return "NOK";
    case "NZ":
      return "NZD";
    case "RU":
      return "RUB";
    case "SA":
      return "SAR";
    case "SE":
      return "SEK";
    case "SG":
      return "SGD";
    case "TH":
      return "THB";
    case "UK":
      return "GBP";
    case "US":
      return "USD";

    case "HR":
      return "EUR";
    case "CZ":
      return "CZK";
    case "HU":
      return "HUF";
    case "IL":
      return "ILS";
    case "MA":
      return "MAD";
    case "PH":
      return "PHP";
    case "PL":
      return "PLN";
    case "UA":
      return "UAH";
    case "TR":
      return "TRY";
    case "LK":
      return "LKR";
    case "KR":
      return "KRW";
    case "ZA":
      return "ZAR";
    case "RS":
      return "RSD";

    case "AT":
      return "EUR";
    case "BE":
      return "EUR";
    case "CY":
      return "EUR";
    case "EE":
      return "EUR";
    case "FI":
      return "EUR";
    case "FR":
      return "EUR";
    case "DE":
      return "EUR";
    case "GR":
      return "EUR";
    case "IE":
      return "EUR";
    case "IT":
      return "EUR";
    case "LU":
      return "EUR";
    case "MT":
      return "EUR";
    case "NL":
      return "EUR";
    case "PT":
      return "EUR";
    case "SK":
      return "EUR";
    case "SI":
      return "EUR";
    case "ES":
      return "EUR";
    case "MC":
      return "EUR";
    case "SM":
      return "EUR";
    case "VA":
      return "EUR";
    case "AD":
      return "EUR";
    case "ME":
      return "EUR";
    default:
      return "USD";
  }
};

export function countryCodeToPrefers12Hour(countryCode?: string) {
  if (!countryCode) {
    return undefined;
  }

  return ["US", "AU", "PH"].includes(countryCode.toUpperCase());
}

export const countryCodeToDistanceSystem = (
  countryCode?: string
): DistanceSystem | undefined => {
  if (!countryCode) {
    return undefined;
  }
  return ["US", "GB"].includes(countryCode) ? "imperial" : "metric";
};
