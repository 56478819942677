import { useState } from "react";
import { TextButton } from "src/components/TextButton/TextButton";
import { RotatingChevron } from "src/svg/RotatingChevron";
import { color, fontSize, fontWeight, spacing } from "src/theme";
import { useDefaultDateTime } from "src/utils/useDefaultDateTime";
import styled from "styled-components";
import { formatDate } from "src/components/FormattedIsoDate/FormattedIsoDate";
import { useIntl } from "react-intl";
import { formatLocationDates, getEndDateOfTrip } from "../util/getTripDates";
import { NightsModal } from "./NightsModal";

type LocationNightsProps = {
  checkInDate?: Date;
};

export function LocationNights({ checkInDate }: LocationNightsProps) {
  const [locationNights, setLocationNights] = useState(1);
  // Note: Rob / Jingle - To be updated to handle checkInDate or undefined if no trip start date
  const safeCheckInDate = useDefaultDateTime(0);
  const [checkOutDate, setCheckOutDate] = useState(
    getEndDateOfTrip(safeCheckInDate, locationNights)
  );
  const [nightsModalIsOpen, setNightsModalIsOpen] = useState(false);
  const intl = useIntl();
  const formatedDate = formatDate(
    checkInDate ? checkInDate.toDateString() : new Date().toDateString(),
    intl,
    {
      day: "numeric",
      month: "short",
      year: "numeric",
    }
  );

  function LocationNightsInfo() {
    return (
      <LocationNightsButton
        onClick={() => setNightsModalIsOpen(!nightsModalIsOpen)}
        textColor="primaryOnLight"
        underline={false}
        rightIcon={
          <RotatingChevron
            direction={nightsModalIsOpen ? "up" : "down"}
            tint="primaryOnLight"
          />
        }
      >
        <NumNights>
          {formatLocationDates(safeCheckInDate, checkOutDate)}
        </NumNights>
        <Dot />
        <NumNights>
          {locationNights === 1 ? `1 night` : `${locationNights} nights`}
        </NumNights>
      </LocationNightsButton>
    );
  }

  return (
    <LocationNightsWrapper>
      <LocationDates>{formatedDate}</LocationDates>
      <LocationNightsInfo />
      {nightsModalIsOpen && (
        <NightsModal
          {...{
            locationNights,
            setLocationNights,
            setNightsModalIsOpen,
            safeCheckInDate,
            setCheckOutDate,
          }}
        />
      )}
    </LocationNightsWrapper>
  );
}

const LocationNightsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-start;
  gap: ${spacing.md};
  margin: -8px 0 8px 12px;
  padding-left: ${spacing.xxxl};
  padding-bottom: ${spacing.sm};
  font-weight: ${fontWeight.medium};
  color: ${color.black};
`;

export const Dot = styled.span<{ $light?: boolean }>`
  display: block;
  width: ${spacing.sm};
  height: ${spacing.sm};
  border-radius: 50%;
  background-color: ${(props) => (props.$light ? color.n40 : color.n100)};
`;

const NumNights = styled.span`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.md};
`;

const LocationDates = styled.span`
  font-size: ${fontSize.md};
`;

const LocationNightsButton = styled(TextButton)`
  &:hover {
    text-decoration: none;
  }
`;
