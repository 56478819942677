export type Mode = (typeof transitModes)[number];

export const transitModes = [
  "animal",
  "bike",
  "bus",
  "cablecar",
  "car",
  "ferry",
  "helicopter",
  "plane",
  "rideshare",
  "shuttle",
  "taxi",
  "train",
  "tram",
  "towncar",
  "unknown",
  "walk",
  "busferry",
  "carferry",
  "multi",
] as const;
