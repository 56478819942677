import Cookies from "js-cookie";
import {
  getBackendFeatureOverrideString,
  useBackendCookieFeatures,
  useBackendParamFeatures,
} from "./useBackendFeatures";

export function useSetFeatureCookie() {
  // checks if `?backendFeatures` exists and adds any features to the feature cookie.
  // De-duplicate feature cookie before adding.
  const backendFeatureParam = useBackendParamFeatures();
  const featCookie = useBackendCookieFeatures();

  // Early out if nothing to add
  if (
    backendFeatureParam.length === 0 ||
    backendFeatureParam.every((f) => featCookie.includes(f))
  )
    return;

  // Order here matters, features will be written left-to-right, overriding any previous values.
  const newFeatureCookie = getBackendFeatureOverrideString([
    ...featCookie,
    ...backendFeatureParam,
  ]);

  Cookies.set("feature", newFeatureCookie);
}
