import { updateSessionInfo } from "./useAnalyticsCoreExperience/session";
import { sendAnalyticsEvent } from "./sendAnalyticsEvent";
import { AnalyticsEventCategory } from "./analyticsEventTypes";

export function sendAnalyticsInteractionEvent(
  category: AnalyticsEventCategory,
  action: string,
  label?: string,
  value?: number
) {
  updateSessionInfo();
  sendAnalyticsEvent(category, action, label, value, false);
}
