import { useIntl } from "react-intl";
import { NavigateFunction, useNavigate } from "react-router";
import { useTripRouteCanonical } from "src/domain/TripPlanner/hooks/useTripRoute";
import { useTripRouteSegmentIndex } from "src/domain/TripPlanner/hooks/useTripSegment";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { localeToLanguageCode } from "../conversions/languageCode";
import { SupportedLanguageCode } from "../language";
import { navigateToNewStateHash } from "../location/navigateToNewStateHash";
import { getPath } from "../url";
import { useRouteCanonical, useRouteIndex } from "./useRoute";
import { useRouteSegmentIndex } from "./useSegment";
import { TypedLocation, useTypedLocation } from "./useTypedLocation";

export type NavigationCanonicals = {
  originCanonical?: string;
  destinationCanonical?: string;
};

// The deeplink URL will vary depending on from where the user enters the hotels experience.
// /map/Paris/Berlin#h - no route specified, ie from search results page (shows hotels in trip destination).
// /map/Paris/Berlin#r/routeCanonical/h - no segment specified (shows hotels in trip destination).
// /map/Paris/Berlin#r/routeCanonical/s/4/h - a mid-trip segment specified (shows hotels in location of specified segment destination).
// If the user is viewing an individual hotels page, we can suffix the above urls with a /hotelId

const HOTELS_PAGE_FRAGMENT = "h";

export function useNavigateToHotelsPage() {
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const location = useTypedLocation();
  const navigate = useNavigate();
  const routeCanonical = useRouteCanonical();
  const routeIndex = useRouteIndex();
  const segmentIndex = useRouteSegmentIndex();

  const routePart = routeIndex !== undefined ? `r/${routeCanonical}/` : "";
  // SegmentIndex specifies hotels at a mid-point of a trip. ie to rest after a long flight, but before arrival at the final trip destination.
  const segmentPart =
    routeIndex !== undefined && segmentIndex !== undefined
      ? `s/${segmentIndex}/`
      : "";
  const hotelsPart = `${HOTELS_PAGE_FRAGMENT}`;
  const hotelsHash = `#${routePart}${segmentPart}${hotelsPart}`;

  return {
    hotelsHash,
    navigateToHotels: (
      canonicalOverride?: NavigationCanonicals,
      newTab: boolean = false
    ) => {
      // Sometimes we'll need to update the canonicals before the hotel page is requested
      // the trip planner will do this regularly
      if (canonicalOverride) {
        navigateWithCanonicals(
          navigate,
          location,
          canonicalOverride,
          languageCode,
          newTab
        );
      } else {
        // The highlightedTab router state determines which nav tab is highlighted on mobile,
        // (and any non-deeplink components that are rendered such as 'preferences)
        navigateToNewStateHash(
          navigate,
          {
            highlightedTab: "hotels",
          },
          hotelsHash,
          location
        );
      }
      return hotelsHash;
    },
  };
}

/**
 * Navigates to the hotel page with a new path
 * Useful for if we're getting a hotel click for an item
 * that isn't within the O/D pair in the URL
 */
function navigateWithCanonicals(
  navigate: NavigateFunction,
  hookLocation: TypedLocation,
  canonicalOverride: NavigationCanonicals,
  languageCode: SupportedLanguageCode,
  newTab: boolean
) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { originCanonical: originCanonicalString, destinationCanonical } =
    canonicalOverride || {};

  // Fall back to the timezone country as the origin
  // This is just for the SearchRequest, the hotels list
  // should only actually need the destination
  const originCanonical = originCanonicalString ?? timeZone.split("/")[0];

  const location: TypedLocation = {
    search: hookLocation.search,
    pathname: getPath(originCanonical, destinationCanonical, languageCode),
    hash: "#h",
    state: { fromHash: hookLocation.hash, ...hookLocation.state },
  };

  if (newTab) {
    window.open(
      `${location.pathname}${location.search}${location.hash}`,
      "_blank"
    );
  } else {
    navigate(
      {
        ...location,
      },
      {
        state: {
          ...location.state,
          userSearch: true,
          highlightedTab: "hotels",
        },
      }
    );
  }
}

export function useNavigateToTripsHotels() {
  const location = useTypedLocation();

  const routeCanonical = useTripRouteCanonical();
  const segmentIndex = useTripRouteSegmentIndex();

  const navigate = useNavigate();

  return (originCanonical: string, destinationCanonical?: string) => {
    let hotelsHash = `#trips/hotels/${originCanonical}`;

    if (destinationCanonical) {
      hotelsHash += `/${destinationCanonical}`;
    }
    if (routeCanonical) {
      hotelsHash += `/r/${routeCanonical}`;
    }

    if (segmentIndex !== undefined) {
      hotelsHash += `/s/${segmentIndex}`;
    }

    navigateToNewStateHash(
      navigate,
      { highlightedTab: "hotels" },
      hotelsHash,
      location
    );
  };
}

export function useIsHotelsUrlDeeplink() {
  const location = useTypedLocation();
  const parts = location.hash.slice(1).split("/");
  return parts.includes("h");
}
export const useIsTripHotelsUrlDeeplink = () => {
  const location = useTypedLocation();
  const tripPlannerScreenKey = getScreenKey(location.hash);
  return tripPlannerScreenKey === "hotels";
};
