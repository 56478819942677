import { IntlShape, useIntl } from "react-intl";
import useUser from "../../utils/hooks/useUser";
import { twentyFourToTwelveHour } from "../../utils/conversions/time";
import { MergeElementProps } from "../../utils/MergeElementProps";
import messages from "./FormattedIsoTime.messages";

type Props = MergeElementProps<
  "time",
  {
    time24Hr: string; // in format HH:MM (24 hour time)
  }
>;

// Not named 'FormattedTime' because that's the name of a react-intl component
export function FormattedIsoTime({ time24Hr, ...other }: Props) {
  const { prefers12Hour } = useUser();
  const intl = useIntl();

  return (
    <time dateTime={prefers12Hour ? "hh:mm" : "HH:mm"} {...other}>
      {format24HourTime(time24Hr, prefers12Hour, intl)}
    </time>
  );
}

export function format24HourTime(
  time24Hr: string,
  userPrefers12Hour: boolean,
  intl: IntlShape
): string {
  if (userPrefers12Hour) {
    const { time, isAm } = twentyFourToTwelveHour(time24Hr);
    if (isAm) {
      return intl.formatMessage(messages.am, { time });
    } else {
      return intl.formatMessage(messages.pm, { time });
    }
  } else {
    return time24Hr;
  }
}
