import { Suspense, useCallback, useEffect } from "react";
import useDestinationInfo from "src/utils/hooks/useDestinationInfo";
import {
  DestinationInfoResponse,
  SuccessfulDestinationInfoResponse,
} from "src/api/DestinationInfoResponse";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorBoundary } from "@sentry/react";
import { useIntl } from "react-intl";
import { Button } from "src/design-system/components/Button/Button";
import { Typography } from "src/design-system/components/Typography/Typography";
import styled from "styled-components";
import { Icon } from "src/design-system/components/Icon/Icon";
import { spacing } from "src/design-system/tokens/spacing";
import color from "src/design-system/tokens/color";
import { A11yOutline, A11yTargetSize } from "src/utils/accessibility";
import { ArrowBackward } from "src/svg/ArrowBackward";
import { useAnalyticsPageView } from "src/utils/hooks/useAnalyticsPageView";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { Link } from "react-router-dom";
import { hashChange } from "src/utils/location/hashChange";
import { useScroll } from "src/ScrollContext";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useTripSearchResponse } from "../TripPlanner/hooks/useTripSearchResponse";
import createDestinationViewModel from "./createDestinationViewModel";
import messages from "./DestinationScreen.messages";
import AttractionList, {
  AttractionListSkeleton,
} from "./AttractionList/AttractionList";
import TransportList, {
  TransportListSkeleton,
} from "./TransportList/TransportList";
import TopOverview, { TopOverviewSkeleton } from "./TopOverview/TopOverview";
import HotelPromo, { HotelPromoSkeleton } from "./HotelPromo/HotelPromo";

type Props = {
  origin?: string;
  destination?: string;
};

export default function DestinationScreen({ origin, destination }: Props) {
  const intl = useIntl();
  const location = useTypedLocation();
  const { setScrollTop } = useScroll();
  const { tripSearchResponse } = useTripSearchResponse();

  useAnalyticsPageView(
    {
      pagePath: location.pathname + location.search + location.hash,
      pageLocation: window.location.href,
    },
    tripSearchResponse,
    "Destination"
  );

  useEffect(() => {
    // The user may have scrolled down the page before navigating to this screen.
    // If they did, we want to reset the scroll position to the top of the page.
    setScrollTop(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenContainer>
      <CloseButton
        onClick={() =>
          sendAnalyticsInteractionEvent(
            "Destination",
            "Click:BackButton",
            destination
          )
        }
        to={hashChange("#trips", location)}
        title={intl.formatMessage(messages.close)}
      >
        <Icon size="small">
          <ArrowBackward tint="black" />
        </Icon>
      </CloseButton>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallback={({ resetError }) => (
              <FailureContainer>
                <Typography variant="body-lg" as="h4">
                  {intl.formatMessage(messages.requestFailed)}
                </Typography>
                <Button onPress={resetError}>
                  {intl.formatMessage(messages.retry)}
                </Button>
              </FailureContainer>
            )}
          >
            <Suspense fallback={<DestinationScreenContentSkeleton />}>
              {destination ? (
                <DestinationScreenContent
                  requestOrigin={origin}
                  requestDestination={destination}
                />
              ) : null}
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </ScreenContainer>
  );
}

type DestinationScreenContentProps = {
  requestOrigin?: string;
  requestDestination: string;
};

function DestinationScreenContent({
  requestOrigin,
  requestDestination,
}: DestinationScreenContentProps) {
  const intl = useIntl();
  const destinationInfo = useDestinationInfo(requestOrigin, requestDestination);

  const readViewModel = useCallback(() => {
    if (!destinationInfo) {
      return null;
    }
    return tryGetViewModel(requestDestination, destinationInfo);
  }, [requestDestination, destinationInfo]);

  const viewModel = readViewModel();

  if (!viewModel) {
    return (
      <FailureContainer>
        <Typography variant="body-lg" as="p">
          {intl.formatMessage(messages.requestNoResults)}
        </Typography>
      </FailureContainer>
    );
  }

  return (
    <>
      <TopOverview
        bannerImgSrc={viewModel.imageUrl}
        title={viewModel.title}
        description={viewModel.description}
      />
      {viewModel.attractions.length > 0 && (
        <>
          <Divider />
          <AttractionList
            attractions={viewModel.attractions}
            destination={requestDestination}
          />
        </>
      )}

      <Divider />
      <HotelPromo
        title={viewModel.title}
        originCanonical={requestOrigin}
        destinationCanonical={requestDestination}
      />

      {requestOrigin && (
        <>
          <Divider />
          <TransportList
            origin={requestOrigin}
            destination={requestDestination}
          />
        </>
      )}
    </>
  );
}

function DestinationScreenContentSkeleton() {
  return (
    <>
      <TopOverviewSkeleton />
      <Divider />
      <AttractionListSkeleton />
      <Divider />
      <HotelPromoSkeleton />
      <Divider />
      <TransportListSkeleton />
    </>
  );
}

function tryGetViewModel(
  destinationCanonical: string,
  destinationInfoResponse: DestinationInfoResponse
) {
  if (destinationInfoResponse.responseType === "Success") {
    return createDestinationViewModel(
      destinationCanonical,
      destinationInfoResponse as SuccessfulDestinationInfoResponse
    );
  }
  return null;
}

const ScreenContainer = styled.div`
  position: relative;
`;

const FailureContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: ${spacing.lg};
  margin-top: 10%;
`;

const CloseButton = styled(Link)`
  ${A11yTargetSize};
  ${A11yOutline};

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  top: ${spacing.lg};
  left: ${spacing.xl};
  background-color: ${color.bg.fill.fill};
  box-shadow: var(--shadow-lg);

  width: 32px;
  height: 32px;
  border-radius: 50%;

  &:hover,
  &:focus {
    background-color: ${color.bg.fill.hover};
  }
  // Reset
  text-decoration: none;
`;

const Divider = styled.hr`
  border: none;
  border-top: 8px solid ${color.bg.fill.active};
`;
