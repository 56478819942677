import { SvgProps, useColor } from "./common";

export const Search = (props: SvgProps) => {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 32 32" fill="none">
      <title>{props.title}</title>
      <path
        d="M23.59 13.311c0 5.697-4.612 10.311-10.295 10.311C7.61 23.622 3 19.008 3 13.312 3 7.614 7.611 3 13.295 3c5.683 0 10.294 4.615 10.294 10.311z"
        stroke={tintColor}
        strokeWidth="3"
      />
      <path
        d="M20.834 20.82L30.5 30.5"
        stroke={tintColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
