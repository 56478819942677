import { useRef, useState } from "react";
import { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import styled, { css } from "styled-components";
import { desktopLayout } from "src/utils/hooks/useLayout";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { spacing } from "src/design-system/tokens/spacing";
import useDeepCompareEffect from "use-deep-compare-effect";
import { rectIntersection } from "@dnd-kit/core";
import { DraggableTripPlannerCard } from "../../TripPlannerCard/TripPlannerCard";
import { TripPlannerPlace } from "../../TripPlannerCard/TripPlannerPlace";
import { useTripPlannerContext } from "../../hooks/useTripPlannerContext";
import getOrderedPlaceId from "../../util/getOrderedPlaceId";
import DragContextWrapper, {
  useDragContextHandlers,
} from "../../TripSearchBar/DragListWrapper";

type Props = {
  onRemove: () => void;
};

export function OnscreenReorder({ onRemove }: Props) {
  const {
    tripPlanningState,
    setHoveredPlaceIndex,
    dispatch,
    tripPlannerDetails,
    isMultiTrip,
  } = useTripPlannerContext();
  const { places } = tripPlannerDetails;
  const [sortablePlaces, setSortablePlaces] = useState<
    SortableObject<GeocodedPlace>[]
  >(getSortablePlaces(places));

  const scrollRef = useRef<HTMLDivElement>(null);

  const { onDragEnd, onDragStart, activeDragItem } = useDragContextHandlers({
    sortableItems: sortablePlaces,
    setSortableItems: setSortablePlaces,
    onDragEnd(event) {
      const { over, active } = event;
      if (over && active.id !== over.id) {
        const oldIndex = sortablePlaces.findIndex(
          (item) => item.id === active.id
        );
        const newIndex = sortablePlaces.findIndex(
          (item) => item.id === over.id
        );
        const newOrder = arrayMove(sortablePlaces, oldIndex, newIndex);
        sendAnalyticsInteractionEvent("TripPlanner", "Reorder:Inline");
        dispatch({ type: "REORDER_TRIP", newOrder });
      }
    },
  });

  useDeepCompareEffect(() => {
    setSortablePlaces(getSortablePlaces(places));
  }, [places]);

  return (
    <DragContextWrapper
      sortableItems={sortablePlaces}
      setSortableItems={setSortablePlaces}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      collisionDetection={rectIntersection}
    >
      <SelectionDropZone
        $isMultiTrip={isMultiTrip}
        $isDragging={!!activeDragItem}
      >
        <SortableContext
          items={sortablePlaces}
          strategy={verticalListSortingStrategy}
        >
          {tripPlannerDetails.places.map((place, index) => {
            const sortable = sortablePlaces[index];
            const isLast = index === tripPlannerDetails.places.length - 1;

            if (isLast && !isMultiTrip) return null;

            return (
              <TripPlannerPlace key={sortable?.id} index={index}>
                <DraggableTripPlannerCard
                  draggableId={sortable?.id}
                  scrollRef={
                    tripPlanningState.updatedPlace.value === index + 1
                      ? scrollRef
                      : undefined
                  }
                  places={tripPlannerDetails.places}
                  startDate={tripPlannerDetails.startDate}
                  index={index}
                  place={place}
                  callbacks={{
                    hoverCallback: () => !isLast && setHoveredPlaceIndex(index),
                  }}
                  onRemove={onRemove}
                />
              </TripPlannerPlace>
            );
          })}
        </SortableContext>
      </SelectionDropZone>
    </DragContextWrapper>
  );
}

export function getSortablePlaces(places: { canonicalName: string }[]) {
  return places.map((place, index) => {
    return {
      ...place,
      id: getOrderedPlaceId(place, index),
    } as SortableObject<GeocodedPlace>;
  });
}

const SelectionDropZone = styled.ul<{
  $isMultiTrip: boolean;
  $isDragging: boolean;
}>`
  list-style: none;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  padding-left: ${spacing.sm};

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      // Why here? :active won't work for DragOverlay elements- they're technically not :active.
      cursor: grabbing;
    `}

  ${desktopLayout} {
    ${({ $isMultiTrip }) =>
      $isMultiTrip &&
      css`
        padding-left: 64px;
      `}
  }
`;
