import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useIntl } from "react-intl";
import { getFormattedDurationText } from "src/components/FormattedDuration/FormattedDuration";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { font_size, font_weight } from "src/design-system/tokens/typography";
import { durationInMinutesFromRoute } from "src/utils/adapters/duration";
import { useGetRoutesFromCoords } from "src/utils/hooks/useGetRoutesFromCoords";
import styled, { css } from "styled-components";
import { useDiscoverContext } from "../DiscoverProvider";
import { City } from "../data/places";
import { Card } from "../Card/Card";

type DiscoverPinProps = {
  origin: {
    canonicalName: string;
    lat: number;
    lng: number;
  };
  destination: City;
};

export function Pin({ origin, destination }: DiscoverPinProps) {
  const { setHoveredCanonical, selectedCanonical, setSelectedCanonical } =
    useDiscoverContext();
  const intl = useIntl();
  const { data: searchResponse } = useGetRoutesFromCoords(
    origin.lat,
    origin.lng,
    destination.lat,
    destination.lng
  );
  const duration = searchResponse
    ? durationInMinutesFromRoute(searchResponse, searchResponse.routes[0])
    : undefined;

  if (duration === undefined) {
    return null;
  }

  const isSelected = selectedCanonical === destination.canonicalName;

  return (
    <AdvancedMarker
      position={{ lat: destination.lat, lng: destination.lng }}
      key={destination.canonicalName}
      onClick={() => setSelectedCanonical(destination.canonicalName)}
      onMouseEnter={() => setHoveredCanonical(destination.canonicalName)}
      onMouseLeave={() => setHoveredCanonical(undefined)}
      zIndex={isSelected ? 1 : 0}
    >
      <MapItem>
        <PinDiv $isSelected={isSelected}>
          {getFormattedDurationText(duration, intl)}
        </PinDiv>
        {isSelected ? (
          <PinInfo>
            <Card destination={destination} origin={origin} />
          </PinInfo>
        ) : null}
      </MapItem>
    </AdvancedMarker>
  );
}

const MapItem = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const PinDiv = styled.div<{ $isSelected: boolean }>`
  padding: ${spacing.md};
  background-color: ${color.bg.fill.fill};
  border-radius: ${spacing.xxxl};
  width: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 5px 0px rgba(31, 37, 52, 0.08),
    0px 1px 3px 0px rgba(31, 37, 52, 0.1);
  font-size: ${font_size.text_sm};
  font-weight: ${font_weight.bold};
  white-space: nowrap;
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${color.text.onBgFill};
      background-color: ${color.text.primary.active};
    `}
`;

const PinInfo = styled.div`
  position: absolute;
  width: 300px;
  min-width: 270px;
  border-radius: ${border_radius.rounded_lg};
  box-shadow: 0px 1px 4px 0px rgba(31, 37, 52, 0.05),
    0px 2px 8px 0px rgba(31, 37, 52, 0.05),
    0px 6px 12px 0px rgba(31, 37, 52, 0.08);
  bottom: 36px;
`;
