import { useCallback } from "react";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsInteractionEvent";
import { useQueryClient } from "@tanstack/react-query";
import { TripPlanWithID } from "../util/api";
import { useTripPlannerContext } from "./useTripPlannerContext";
import { getTripQueryKey } from "./useTripPlanQueries";

export default function useSetTrip() {
  const { dispatch } = useTripPlannerContext();
  const queryClient = useQueryClient();

  const setTrip = useCallback(
    (id: string) => {
      sendAnalyticsInteractionEvent(
        "TripPlanner",
        "Click:SelectTrip:MultipleTrips"
      );
      const data = queryClient.getQueryData<TripPlanWithID>(
        getTripQueryKey(id)
      );
      if (data) {
        dispatch({ type: "SET_TRIP", trip: data });
      }
    },
    [dispatch, queryClient]
  );

  return setTrip;
}
