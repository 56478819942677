import { createGlobalStyle } from "styled-components";
import { color, lineHeight } from "../../theme";
import { ThemeMap } from "../Theme/Theme";
import "../../design-system/index.css";

export const GlobalStyle = createGlobalStyle<{ theme: ThemeMap }>`
  * {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${(props) => props.theme.body.text};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /** We needed to add this as pull to refresh extends the background colour
        and we need one to match the searchbar, routeHeader and segmentHeader. */
    background-color: ${(props) => props.theme.body.background};
  }

  /* mostly taken from Normalize */
  button {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    border: none;
    background-color: transparent;
    color: unset;

    &:focus {
      outline: none;
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    line-height: ${lineHeight.normal};
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 3px solid ${color.n20};
    background-color: ${color.n40};
  }

  ::-webkit-scrollbar-track {
    background-color: ${color.white};
    border: 1px solid ${color.n20};
  }
`;
