export const duration = {
  xs: 50,
  sm: 100,
  md: 200,
  lg: 400,
  xl: 800,
  xxl: 1000,
} as const;

/**
 * transition: opacity ${duration.md}ms ease-out;
 */
