export const border_radius = {
  /** (0px) */ none: "0",
  /** (4px) */ rounded_sm: "0.25rem",
  /** (8px) */ rounded_md: "0.5rem",
  /** (12px) */ rounded_lg: "0.75rem",
  /** (16px) */ rounded_xl: "1rem",
  /** (24px) */ rounded_xxl: "1.5rem",
  /** (9999px) */ rounded_full: "9999px",
} as const;
/**
 * border-radius: ${border_radius.rounded_sm};
 */
