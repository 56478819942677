import Cookies from "js-cookie";
import { ApiConfig } from "src/api/ApiConfig";
import { sendAnalyticsNonInteractionEvent } from "../../analytics/sendAnalyticsNonInteractionEvent";
import { initializeAuthEndpoint, sessionEndpoint } from "../../api/endpoints";
import { buildUrl } from "../../utils/url";
import { logout_google } from "../sdks/GoogleAuthSdk";
import { UserProfile, UserProfileUpdateValues } from "./user";
import { UserServiceConfig } from "./userServiceConfig";
import { logFetchError } from "./log";

export type AuthType = "google" | "apple" | "OneTimePassword";

function createInitializeAuthRequest(config: ApiConfig, emailAddress: string) {
  const url = initializeAuthEndpoint(config);

  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      emailAddress,
    }),
  });
}

function createSessionRequest(
  config: UserServiceConfig,
  token: string,
  authMethod: AuthType,
  defaultUserProfile?: UserProfileUpdateValues
) {
  const url = sessionEndpoint(config.apiConfig);

  return fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token,
      authMethod,
      defaultUserProfile,
    }),
  });
}

const SESSION_COOKIE_NAME = "r2r_session";
const SESSION_COOKIE_DOMAIN = ".rome2rio.com";

export function isLoggedIn() {
  return Cookies.get(SESSION_COOKIE_NAME) !== undefined;
}

function createSessionCookie(sessionToken: string) {
  Cookies.set(SESSION_COOKIE_NAME, sessionToken, {
    expires: 364,
    secure: true,
    sameSite: "none",
    domain: SESSION_COOKIE_DOMAIN,
  });
}

function removeSessionCookie() {
  Cookies.remove(SESSION_COOKIE_NAME, {
    domain: SESSION_COOKIE_DOMAIN,
  });
}

function getSessionRequest(config: UserServiceConfig) {
  const url = buildUrl(`${config.apiConfig.endpoint16Base}/Auth/Session`, {
    key: config.apiConfig.key,
  });

  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
}

function deleteSessionRequest(config: UserServiceConfig) {
  const url = sessionEndpoint(config.apiConfig);

  return fetch(url, {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
}

type UserSession = {
  sessionId: string;
  user: UserProfile;
};

type CreateSessionResponse = UserSession & {
  action: "SignUp" | "Login" | "NewLoginMethod";
};

type InitializeAuthResponse = {
  browserId: string;
};

export async function createSession(
  token: string,
  authType: AuthType,
  config: UserServiceConfig,
  defaultUserProfile?: UserProfileUpdateValues
) {
  try {
    const data = await createSessionRequest(
      config,
      token,
      authType,
      defaultUserProfile
    );
    const result: CreateSessionResponse = await data.json();

    if (result.sessionId && result.user) {
      createSessionCookie(result.sessionId);
      sendAnalyticsNonInteractionEvent("UserAccount", result.action, authType);
      return result.user;
    }
  } catch (err) {
    logFetchError(err);
  }
}

export async function initializeAuth(
  emailAddress: string,
  config: ApiConfig
): Promise<InitializeAuthResponse | undefined> {
  try {
    const data = await createInitializeAuthRequest(config, emailAddress);
    const result: InitializeAuthResponse = await data.json();
    return result;
  } catch (err) {
    logFetchError(err);
  }
  return undefined;
}

export async function getSession(
  config: UserServiceConfig,
  setLoadingSession: (loading: boolean) => void
): Promise<UserProfile | undefined> {
  try {
    const data = await getSessionRequest(config);
    if (data.status === 401) {
      // Our session is invalid, remove our session cookie so we don't try again
      removeSessionCookie();
      return undefined;
    }

    const result: UserSession = await data.json();
    if (result.user) {
      return result.user;
    }
  } catch (error) {
    logFetchError(error);
    return undefined;
  } finally {
    setLoadingSession(false);
  }
}

export function logout(config: UserServiceConfig, resetUser: () => void) {
  deleteSessionRequest(config);
  removeSessionCookie();
  logout_google();
  resetUser();
}
