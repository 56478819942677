import { TripPlannerDetails } from "../TripPlannerProvider";
import { TRIP_DATA_VERSION } from "./migrateTripPlanSchema";

type RemoteTripPlan = {
  transport: TripPlannerDetails["transport"];
  places: TripPlannerDetails["places"];
  name: TripPlannerDetails["name"];
  versionNumber: number;
};

export default function getTripRequestContents(
  body?: TripPlannerDetails,
  hasAuth: boolean = false
) {
  const bodyWithDefaults: RemoteTripPlan | undefined = body
    ? {
        versionNumber: TRIP_DATA_VERSION,
        name: body.name,
        places: body.places,
        transport: body.transport,
      }
    : undefined;

  let request: Request | {} = bodyWithDefaults
    ? { body: JSON.stringify(bodyWithDefaults) }
    : {};

  if (hasAuth) {
    request = {
      ...request,
      credentials: "include",
    };
  }

  return {
    headers: {
      "content-type": "application/json",
    },
    ...request,
  };
}
