import { defineMessages } from "react-intl";

export const timeFormatMessages = defineMessages({
  timeFormatTitle: {
    id: "topNav.timeFormat.timeFormatTitle",
    defaultMessage: "Time format",
    description: "Title above the section of time format options",
  },
  timeFormat12H: {
    id: "topNav.timeFormat.timeFormat12H",
    defaultMessage: "12 hour",
    description:
      "Option to select the 12 hour time system to be set in the app. e.g. 8.30 am, 1.45 pm",
  },
  timeFormat24H: {
    id: "topNav.timeFormat.timeFormat24H",
    defaultMessage: "24 hour",
    description:
      "Option to select the 24 hour time system to be set in the app. e.g. 08:30, 21:15",
  },
  description: {
    id: "topNav.timeFormat.description",
    defaultMessage: "Choose a time format",
    description: "Description of page where user can change their time format",
  },
});
