import { defineMessages } from "react-intl";

const messages = defineMessages({
  departure: {
    id: "dynamicDatePicker.departure",
    defaultMessage: "Departure",
    description: "Label displayed above the users selected departure date.",
  },
  return: {
    id: "dynamicDatePicker.return",
    defaultMessage: "Return",
    description: "Label displayed above the users selected return date.",
  },
  addDate: {
    id: "dynamicDatePicker.addDate",
    defaultMessage: "Add date",
    description:
      "Text displayed in the selection display prompting user to choose a date.",
  },
  selectBelow: {
    id: "dynamicDatePicker.selectBelow",
    defaultMessage: "Select below",
    description:
      "Text displayed in the selection display prompting user to select a date below.",
  },
  datePicker: {
    id: "dynamicDatePicker.datePicker",
    defaultMessage: "Date Picker",
    description: "The heading for the date picker modal.",
  },
  search: {
    id: "dynamicDatePicker.search",
    defaultMessage: "Search",
    description: "Text displayed on the search for dates button.",
  },
  oneWaySearch: {
    id: "dynamicDatePicker.oneWaySearch",
    defaultMessage: "Search one-way",
    description: "Text displayed on the one-way search for dates button.",
  },
  roundTripSearch: {
    id: "dynamicDatePicker.roundTripSearch",
    defaultMessage: "Search round trip",
    description: "Text displayed on the round trip search for dates button.",
  },
  next: {
    id: "dynamicDatePicker.next",
    defaultMessage: "Next",
    description: "Text displayed to navigate to the next tab.",
  },
  close: {
    id: "dynamicDatePicker.close",
    defaultMessage: "Close",
    description: "Text displayed on the close button.",
  },
  skipReturnsMessage: {
    id: "dynamicDatePicker.skipReturns",
    defaultMessage: "Skip for now",
    description:
      "Skip button for the user to close the date picker modal without choosing a date.",
  },
  skipMessage: {
    id: "dynamicDatePicker.skip",
    defaultMessage: "Skip",
    description:
      "Skip button for the user to close the date picker modal without choosing a date.",
  },
});

export default messages;
