import { useEffect } from "react";
import { useIntl } from "react-intl";
import { localeToLanguageCode } from "../conversions/languageCode";

export function useLanguageAttribute(): void {
  const intl = useIntl();

  useEffect(() => {
    const languageCode = localeToLanguageCode(intl.locale);
    const htmlElement = document.documentElement;
    if (htmlElement) {
      htmlElement.setAttribute("lang", languageCode);
    }
  }, [intl]);
}
