import { TripPlannerTransportKey } from "src/domain/TripPlanner/TripPlannerProvider";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { getPlaceIndexFromTransportKey } from "src/domain/TripPlanner/util/getPlaceIndexFromTransportKey";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { getOriginDestinationFromHash } from "src/domain/TripPlanner/util/getOriginDestinationFromHash";
import { useTypedLocation } from "./useTypedLocation";

export function useTripTransportIndex() {
  const location = useTypedLocation();
  const { tripPlannerDetails } = useTripPlannerContext();

  const transportKey = getTransportKeyFromHash(location.hash);

  if (!transportKey || !getScreenKey(location.hash)) {
    return undefined;
  }

  const placeIndex = getPlaceIndexFromTransportKey(
    tripPlannerDetails.places,
    transportKey
  );

  return placeIndex;
}

export function getTransportKeyFromHash(
  hash: ReturnType<typeof useTypedLocation>["hash"]
): TripPlannerTransportKey | undefined {
  const { origin, destination } = getOriginDestinationFromHash(hash) ?? {};

  if (!origin || !destination) {
    return undefined;
  }

  return `${origin}_${destination}`;
}
