// inspired by https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export const textColorBasedOnBackgroundColor = (backgroundhex: string) => {
  const bh = backgroundhex.replace("#", "");

  const r = parseInt(bh.substr(0, 2), 16);
  const g = parseInt(bh.substr(2, 2), 16);
  const b = parseInt(bh.substr(4, 2), 16);

  const rLum = relativeLuminance(r);
  const gLum = relativeLuminance(g);
  const bLum = relativeLuminance(b);

  const lum = 0.2126 * rLum + 0.7152 * gLum + 0.0722 * bLum;

  return lum > 0.179 ? "dark" : "light";
};

// relative luminance equation from https://www.w3.org/TR/WCAG20/#relativeluminancedef
const relativeLuminance = (color: number) => {
  const colorDividedBy255 = color / 255;

  if (colorDividedBy255 <= 0.03928) {
    return colorDividedBy255 / 12.92;
  } else {
    return Math.pow((colorDividedBy255 + 0.055) / 1.055, 2.4);
  }
};
