import { fontSize, fontWeight, lineHeight, color, spacing } from "src/theme";
import styled from "styled-components";

export const Heading = styled.h3`
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.semibold};
  line-height: ${lineHeight.snug};
  text-align: center;
  color: ${color.cod};
  flex: auto;
  margin-bottom: ${spacing.sm};
`;

export const Text = styled.p`
  font-size: ${fontSize.body};
  font-weight: ${fontWeight.normal};
  line-height: ${lineHeight.tight};
  text-align: center;
  color: ${color.cod};
  margin-bottom: ${spacing.xxxxl};
`;

export const PageContent = styled.div`
  margin: ${spacing.xl} 0;
`;
