import { PropsWithChildren } from "react";
import { RadioGroup } from "src/components/RadioGroup/RadioGroup";

type ResultsWrapperProps = {
  isRadioHidden: boolean;
  handleTripSave: (args: any) => void;
} & PropsWithChildren;

export function ResultsWrapper({
  isRadioHidden,
  handleTripSave,
  children,
}: ResultsWrapperProps) {
  return isRadioHidden ? (
    <> {children} </>
  ) : (
    <RadioGroup value="" name="search-results" onChange={handleTripSave}>
      {children}
    </RadioGroup>
  );
}
