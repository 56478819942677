import { defineMessages } from "react-intl";

const TripHotelMapPinMessages = defineMessages({
  hotelsIn: {
    id: "tripHotelMapPin.hotelsIn",
    description:
      "Label for pin on a map for hotels in that area e.g. Hotels in Sydney",
    defaultMessage: "Hotels in {location}",
  },
  from: {
    id: "tripHotelMapPin.from",
    description:
      "Label for the median price of a hotel in an area e.g. from US$399",
    defaultMessage: "from {price}",
  },
  compare: {
    id: "tripHotelMapPin.compare",
    description: "Label for a button to compare hotels at the destination",
    defaultMessage: "Compare stays",
  },
  findGreatDeals: {
    id: "tripHotelPromo.findGreatDeals",
    description: "Label for a button to explore hotels at a destination",
    defaultMessage: "Find great deals",
  },
  viewHotels: {
    defaultMessage: "View hotels",
    id: "MapPinHotelEntryPoint.header",
    description: "A label for a map pin that directs user to the Hotels page",
  },
});

export default TripHotelMapPinMessages;
