import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { useIsRouteSkipped } from "src/utils/hooks/useIsRouteSkipped";
import { getSegmentIndexFromHash } from "src/utils/location/segmentIndexFromHash";
import { useTripRouteIndex } from "./useTripRoute";

export function useTripRouteSegmentIndex(): number | undefined {
  const location = useTypedLocation();
  const segmentIndex = getSegmentIndexFromHash(location.hash);

  const routeIndex = useTripRouteIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);

  return isRouteSkipped ? undefined : segmentIndex;
}
