import { defineMessages } from "react-intl";

const messages = defineMessages({
  toggleLabel: {
    id: "Toggle.screenReaderLabel",
    description:
      "a visually hidden label to describe the purpose of a toggle for users with screen readers",
    defaultMessage: "A switch to toggle {label}",
  },
});

export default messages;
