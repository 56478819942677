import { useIntl } from "react-intl";
import styled from "styled-components";
import { ArrowForward } from "../../../svg/ArrowForward";
import { formatDate } from "../../FormattedIsoDate/FormattedIsoDate";
import { Button } from "../../Button/Button";
import { fontSize, fontWeight, spacing, textColor } from "../../../theme";
import messages from "./CalendarConfirmation.messages";

type CalendarConfirmationProps = {
  departureDate: string;
  returnDate?: string;
  onDepartureDateClicked: () => void;
  onReturnDateClicked: () => void;
  onFindTicketsClicked?: () => void;
  ticketsExitUrl: string;
  isFlightSchedule?: boolean;
  transitKind?: string;
};

export function CalendarConfirmation(props: CalendarConfirmationProps) {
  const intl = useIntl();

  return (
    <div>
      <Title>{intl.formatMessage(messages.title)}</Title>
      <ButtonWrapper>
        <DateButton
          backgroundColor="white"
          textColor="primaryOnLight"
          size="large"
          isFilled={true}
          onClick={props.onDepartureDateClicked}
        >
          {intl.formatMessage(messages.departureDate, {
            date: formatDate(props.departureDate, intl, {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),
          })}
        </DateButton>
        <DateButton
          backgroundColor="white"
          textColor="primaryOnLight"
          size="large"
          isFilled={!!props.returnDate}
          onClick={props.onReturnDateClicked}
        >
          {props.returnDate
            ? intl.formatMessage(messages.returnDate, {
                date: formatDate(props.returnDate, intl, {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                }),
              })
            : intl.formatMessage(messages.addReturnDate)}
        </DateButton>
        <StyledButton
          backgroundColor="pink"
          textColor="primaryOnDark"
          size="large"
          rightIcon={<ArrowForward tint="white" title="forward" />}
          onClick={props.onFindTicketsClicked}
          href={props.ticketsExitUrl}
          target="_blank"
          rel="noopener"
        >
          {intl.formatMessage(messages.next)}
        </StyledButton>
      </ButtonWrapper>
    </div>
  );
}

const largeModalWidth = 550;

const Title = styled.div`
  line-height: 28px;
  padding: ${spacing.lg};
  font-size: ${fontSize.h3};
  font-weight: ${fontWeight.medium};
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
  margin-left: ${spacing.xxl};
  margin-right: ${spacing.xxl};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

type DateButtonProps = {
  isFilled: boolean;
};

const DateButton = styled(Button)<DateButtonProps>`
  width: 100%;
  margin-bottom: 8px;
  flex: 1 0 auto;

  /** On larger devices, sit buttons inline with gutter between */
  @media only screen and (min-width: ${largeModalWidth}px) {
    max-width: calc(50% - 8px);
  }

  ${(props) => {
    if (props.isFilled) {
      return `
        border: solid 1px ${textColor.primaryOnLight};
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
        color: ${textColor.primaryOnLight};
      `;
    } else {
      return `
        border: dashed 1px ${textColor.disabledOnLight};
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0);
        color: ${textColor.disabledOnLight};
      `;
    }
  }}
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.xxl};
`;
