import { useEffect, useState } from "react";
import { SearchResponse } from "../../api/SearchResponse";
import { useRouteCanonical } from "./useRoute";

export function useCachedResponse(searchResponse: SearchResponse | undefined) {
  const [cachedResponse, setCachedResponse] = useState(searchResponse);

  useEffect(() => {
    if (searchResponse) {
      setCachedResponse(searchResponse);
    }
  }, [searchResponse]);

  return cachedResponse;
}

/**
 * @deprecated use getRouteIndexFromHash instead
 */
export function useCachedRouteIndex(
  searchResponse?: SearchResponse
): number | undefined {
  // The routeIndex can become undefined if a new search request is made. This hook
  // will return the cached responses route index while a new response is fetched.
  const routeCanonical = useRouteCanonical();
  const index = searchResponse?.routes.findIndex(
    (route) => route.canonicalName === routeCanonical
  );
  return index !== undefined && index >= 0 ? index : undefined;
}
