import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { APIProvider as VisGlApiProvider } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useSearchParams } from "react-router-dom";
import { L10nProvider } from "src/L10nProvider";
import UserProvider from "src/UserProvider";
import { ApiConfigProvider } from "src/api/ApiConfigProvider";
import { AuthProvider } from "src/auth/contexts/AuthContext";
import { DefaultErrorBoundary } from "src/components/DefaultErrorBoundary/DefaultErrorBoundary";
import { GlobalStyle } from "src/components/GlobalStyle/GlobalStyle";
import { GOOGLE_MAPS_KEY } from "src/components/Map/constants";
import { Theme } from "src/components/Theme/Theme";
import { FeatureProvider } from "src/feature/FeatureProvider";
import { getCountryCode } from "src/utils/countryCode";
import {
  getCurrencyCodeFromCookie,
  type SupportedCurrencyCode,
} from "src/utils/currency";
import {
  getDistanceSystemFromCookie,
  type DistanceSystem,
} from "src/utils/distanceSystem";
import useUser from "src/utils/hooks/useUser";
import logError from "src/utils/logError";
import { getInitialTimeFormatCookieValue } from "src/utils/timeFormat";
import {
  countryCodeToCurrencyCode,
  countryCodeToDistanceSystem,
  countryCodeToPrefers12Hour,
} from "src/utils/userPreferences";

export function ProviderLayout({ queryClient }: { queryClient: QueryClient }) {
  const [searchParams] = useSearchParams();

  // Load preferences from query params, for easy testing of different preferences.
  let prefers12Hour;
  let currencyCode;
  let distanceSystem;
  if (import.meta.env.VITE_ENVIRONMENT !== "Production") {
    prefers12Hour = searchParams.has("12hr")
      ? searchParams.get("12hr") === "true"
      : undefined;
    currencyCode = searchParams.get("currency") as SupportedCurrencyCode;
    distanceSystem = (
      searchParams.get("distance") === "km" ? "metric" : "imperial"
    ) as DistanceSystem;
  }

  return (
    <L10nProvider>
      <ApiConfigProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <UserProvider
              initialPrefers12Hour={
                getInitialTimeFormatCookieValue() ??
                prefers12Hour ??
                countryCodeToPrefers12Hour(getCountryCode())
              }
              initialCurrencyCode={
                getCurrencyCodeFromCookie() ??
                currencyCode ??
                countryCodeToCurrencyCode(getCountryCode())
              }
              initialDistanceSystem={
                getDistanceSystemFromCookie() ??
                distanceSystem ??
                countryCodeToDistanceSystem(getCountryCode())
              }
            >
              <MapsApiProvider>
                <FeatureProvider>
                  <Theme>
                    <DefaultErrorBoundary defaultMessage>
                      <GlobalStyle />
                      <Outlet />
                    </DefaultErrorBoundary>
                  </Theme>
                </FeatureProvider>
              </MapsApiProvider>
            </UserProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ApiConfigProvider>
    </L10nProvider>
  );
}

function MapsApiProvider({ children }: { children: React.ReactNode }) {
  const languageCode = useUser().language;
  const [scriptFailed, setScriptFailed] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (scriptFailed && retryCount < 3) {
      const backoffDelay = 2000 * Math.pow(2, retryCount);
      console.log(`Map API not loaded: Retrying in ${backoffDelay}ms...`);

      const timer = setTimeout(() => {
        setRetryCount((state) => state + 1);
        setScriptFailed(false);
      }, backoffDelay);
      return () => clearTimeout(timer);
    }

    if (retryCount === 3) {
      logError(new Error("The Google Maps JavaScript API could not load."), [
        "MapsApiProvider",
      ]);
    }
  }, [retryCount, scriptFailed]);

  return (
    <VisGlApiProvider
      key={retryCount}
      apiKey={GOOGLE_MAPS_KEY}
      language={languageCode}
      libraries={["geometry"]}
      onError={() => {
        setScriptFailed(true);
      }}
    >
      {children}
    </VisGlApiProvider>
  );
}
