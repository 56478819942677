import Cookies from "js-cookie";

export function getUidCookie() {
  return Cookies.get("uid");
}

export function getAqidCookie() {
  return Cookies.get("aqid");
}

export function getGclidCookie() {
  return Cookies.get("gclid");
}

export function extractUidChannel(uid?: string): string | null {
  if (!uid) {
    return null;
  }

  const match = /\w{5}\d{15}....(\w)./.exec(uid);
  if (match) {
    return match[1];
  }
  return null;
}

export function extractUidProduct(uid?: string): string | null {
  if (!uid) {
    return null;
  }

  const match = /\w{5}\d{15}...(\w)../.exec(uid);
  if (match) {
    return match[1];
  }
  return null;
}

export function isAdWordsAcquisition(aqid?: string): boolean {
  return extractUidChannel(aqid) === "a";
}
