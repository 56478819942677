export function uuid() {
  return getUuid();
}

// Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
// where each x is replaced with a random hexadecimal digit from 0 to f, and y
// is replaced with a random hexadecimal digit from 8 to b.
//
// This code comes from https://gist.github.com/jed/982883.
function getUuid(a: string = ""): string {
  return a
    ? ((Number(a) ^ (Math.random() * 16)) >> (Number(a) / 4)).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, getUuid);
}
