import { SvgProps, useColor } from "../../../svg/common";

export function OriginIcon(props: SvgProps) {
  const tintColor = useColor(props.tint);

  return (
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <title>{props.title}</title>
      <path
        fillRule="evenodd"
        d="M9 3a6 6 0 1 0 0 12A6 6 0 1 0 9 3zM4.92 9a4.08 4.08 0 1 1 8.16 0 4.08 4.08 0 1 1-8.16 0z"
        fill={tintColor}
      />
    </svg>
  );
}
