import { useQuery } from "@tanstack/react-query";
import { useIntl } from "react-intl";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsNonInteractionEvent";
import { ApiConfig } from "src/api/ApiConfig";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { SearchResponse } from "src/api/SearchResponse";
import { searchEndpoint } from "src/api/endpoints";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { SupportedCurrencyCode } from "src/utils/currency";
import useUser from "src/utils/hooks/useUser";
import { SupportedLanguageCode } from "src/utils/language";
import { ONE_DAY_IN_MILLISECONDS } from "../conversions/time";
import { formatCoordsToPos } from "./formatCoordsToPos";

// Based on the search API docs, you can provide coordinates instead of a canonical name or GooglePlaceId
// https://www.rome2rio.com/api/1.5/docs?key=oaSfxu2D#tag/search/operation/search
// This is similar to search.ts where it uses the search API. The main difference being that instead of using Geocoded results,
// this accepts coordinates instead, excluded travel audiences and has different logging.
export function useGetRoutesFromCoords(
  oLat: number | undefined,
  oLng: number | undefined,
  dLat: number | undefined,
  dLng: number | undefined,
  lightweightRequest = false
) {
  const intl = useIntl();
  const { currencyCode } = useUser();
  const languageCode = localeToLanguageCode(intl.locale);
  const config = useApiConfig();

  const searchKey = ["routesFromCoords", oLat, oLng, dLat, dLng];

  const searchResultsInfo = useQuery({
    queryKey: searchKey,
    queryFn: async () => {
      const oPos = formatCoordsToPos({ lat: oLat!, lng: oLng! });
      const dPos = formatCoordsToPos({ lat: dLat!, lng: dLng! });
      const result = await hotelRoutesSearch(
        config,
        languageCode,
        currencyCode,
        oPos,
        dPos,
        lightweightRequest
      );
      return result;
    },
    gcTime: ONE_DAY_IN_MILLISECONDS,
    staleTime: ONE_DAY_IN_MILLISECONDS,
    enabled: !!(
      oLat !== undefined &&
      oLng !== undefined &&
      dLat !== undefined &&
      dLng !== undefined
    ),
    retryOnMount: false,
    retry: 3,
  });

  return searchResultsInfo;
}

export function logApiInfo(
  status: "Sent" | "Received" | "Success" | "Error",
  oPos: string,
  dPos: string,
  durationMilliseconds?: number
) {
  sendAnalyticsNonInteractionEvent(
    "GenericExplore",
    `HotelsSearchRequest:${status}`,
    `${oPos}:${dPos}`,
    durationMilliseconds
  );
}

async function hotelRoutesSearch(
  config: ApiConfig,
  languageCode: SupportedLanguageCode,
  currencyCode: SupportedCurrencyCode,
  oPos: string,
  dPos: string,
  lightweightRequest = false
) {
  const url = searchEndpoint(
    config,
    undefined,
    undefined,
    undefined,
    undefined,
    languageCode,
    currencyCode,
    oPos,
    dPos,
    lightweightRequest
  );

  try {
    logApiInfo("Sent", oPos, dPos);

    const requestTimestamp = Date.now();
    const response = await fetch(url, {
      referrerPolicy: "no-referrer-when-downgrade",
    });

    if (!response.ok) {
      throw Error(`GetRoutes Error: ${response.status}`);
    }

    const json: SearchResponse = await response.json();

    logApiInfo("Received", oPos, dPos, Date.now() - requestTimestamp);

    return json;
  } catch (error) {
    logApiInfo("Error", oPos, dPos);
    throw error;
  }
}
