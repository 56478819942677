import { Suspense, lazy } from "react";
import { GeocodedPlace } from "src/PrefetchData";
import { SortableObject } from "src/components/DragAndDropList/DraggableItem";
import { PlaceName } from "src/utils/useCreateTripFromSearch";
import { Skeleton } from "src/components/Skeleton/Skeleton";
import styled from "styled-components";
import { borderRadius, color, spacing } from "src/theme";

export type MultiSearchInputProps = {
  showMultiSearchModal: boolean;
  destinations: SortableObject<GeocodedPlace>[];
  setDestinations: React.Dispatch<
    React.SetStateAction<SortableObject<GeocodedPlace>[]>
  >;
  overridePlaces: (places: PlaceName[]) => void;
  removePlace: (places: SortableObject<GeocodedPlace>[], key: string) => void;
  addOrChangePlaceInPosition: (place: PlaceName, position: number) => void;
};

const MultiSearchInput = lazy(
  () =>
    import(
      /* webpackChunkName: "MultiSearchInput" */
      "./MultiSearchInput"
    )
);

export const LazyMultiSearchInput = (props: MultiSearchInputProps) => {
  return (
    <Suspense fallback={<MultiSearchInputSkeleton />}>
      <MultiSearchInput {...props} />
    </Suspense>
  );
};

export function MultiSearchInputSkeleton() {
  return (
    <SkeletonWrapperDiv>
      <Skeleton height={56} />
    </SkeletonWrapperDiv>
  );
}

const SkeletonWrapperDiv = styled.div`
  margin: ${spacing.xxl} 0 ${spacing.xxxl};
  border: 1px solid ${color.cod};
  border-radius: ${borderRadius.lg};
  overflow: hidden;
`;
