import { useFeature } from "src/feature/useFeature";
import { useIsTripScreen } from "./useIsTripScreen";
import { useScreenMinWidth } from "./useScreenMinWidth";

export const useIsTripsAsCoreFullExperience = () => {
  const isTripsV2OnMobile = useFeature("TripsV2OnMobile");
  const { isSmScreen } = useScreenMinWidth();
  const isTripScreen = useIsTripScreen();

  const showTripsV2OnDesktop = isTripScreen && isSmScreen;
  const showTripsV2Mobile = isTripScreen && isTripsV2OnMobile;
  return showTripsV2OnDesktop || showTripsV2Mobile;
};
