import styled, { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router";
import { darken } from "polished";
import { PaneNavSkeleton } from "src/domain/PaneNav/PaneNavSkeleton";
import { Navbar } from "../../../components/Navbar/Navbar";
import {
  useRouteIndex,
  useRouteCanonical,
} from "../../../utils/hooks/useRoute";
import { SectionHeader } from "../SectionHeader/SectionHeader";
import useSearch from "../../../utils/hooks/useSearch";
import { Skeleton } from "../../../components/Skeleton/Skeleton";
import { color, spacing } from "../../../theme";
import { hashChange } from "../../../utils/location/hashChange";
import { desktopLayout, useLayout } from "../../../utils/hooks/useLayout";
import { useTheme } from "../../../utils/hooks/useTheme";
import { ScheduleLoadingRow } from "../DynamicSchedules/ScheduleLoadingRow/ScheduleLoadingRow";
import { FontLoader } from "../../../components/FontLoader/FontLoader";
import { useTypedLocation } from "../../../utils/hooks/useTypedLocation";

export function SegmentScreenLoading() {
  const layout = useLayout();
  const navigate = useNavigate();
  const location = useTypedLocation();
  const routeCanonical = useRouteCanonical();
  const { searchResponse } = useSearch();
  const routeIndex = useRouteIndex();
  const isMobile = layout === "mobile";

  // Update the breadcrumb theme back to the SegmentHeader colors. We needed to
  // overwrite the breadrumbs in the route header, so we're switching it back here.
  const theme = useTheme();
  theme.breadcrumbs = theme.segmentHeaderLoading;

  const handleBackClick = () => {
    if (searchResponse === undefined || routeIndex === undefined) {
      // Back to search response screen
      navigate(hashChange(undefined, location));
      return;
    }

    const numSegmentsInRoute =
      searchResponse.routes[routeIndex].segments.length;

    // If only one segment in the route, don't show the route screen
    if (numSegmentsInRoute === 1) {
      navigate(hashChange(undefined, location));
    } else {
      navigate(hashChange(`#r/${routeCanonical}`, location));
    }
  };

  return (
    <LoadingWrapper>
      {isMobile ? (
        <>
          <Navbar headingText="" onBackClick={handleBackClick} />
          <HeadingWrapper isMobile={isMobile}>
            <Skeleton width="80%" height={20} padding="5px 0 0" lightOnDark />
          </HeadingWrapper>
          <DateWrapper>
            <Skeleton width="24%" height={14} margin="0 0 4px" lightOnDark />
            <Skeleton width="40%" height={14} lightOnDark />
          </DateWrapper>
        </>
      ) : (
        <>
          <HeadingWrapper isMobile={isMobile}>
            <ThemeProvider theme={theme}>
              <PaneNavSkeleton />
            </ThemeProvider>
            <Skeleton
              width="80%"
              height={24}
              margin="18px 0 14px"
              lightOnDark={false}
            />
          </HeadingWrapper>
        </>
      )}
      <SectionWrapper>
        <SectionHeader hideBorderBottom>
          <Skeleton width="30%" variant="h4" />
        </SectionHeader>
        <ScheduleLoadingRow transitMode="train" />
        <ScheduleLoadingRow transitMode="train" />
        <ScheduleLoadingRow transitMode="train" />
        <ScheduleLoadingRow transitMode="train" />
        <ScheduleLoadingRow transitMode="train" />
        {layout !== "mobile" && (
          <>
            <ScheduleLoadingRow transitMode="train" />
            <ScheduleLoadingRow transitMode="train" />
            <ScheduleLoadingRow transitMode="train" />
            <ScheduleLoadingRow transitMode="train" />
            <ScheduleLoadingRow transitMode="train" />
          </>
        )}
      </SectionWrapper>
      <Skeleton height={50} />
      <Skeleton height={18} width="30%" margin="18px 12px" />
      <FontLoader />
    </LoadingWrapper>
  );
}

const LoadingWrapper = styled.div`
  // Give enough space that footer ads don't creep into the view
  min-height: 200vh;
`;

const HeadingWrapper = styled.div<{ isMobile: boolean }>`
  background-color: ${(props) => {
    if (props.isMobile) {
      return `${color.n50}`;
    }
    return `${color.n20}`;
  }};
  min-height: 55px;
  padding: ${spacing.xl};
`;

const DateWrapper = styled.div`
  background-color: ${darken(0.1, color.n50)};
  padding: ${spacing.xl};
`;

const SectionWrapper = styled.div`
  ${desktopLayout} {
    padding: 0 12px;
  }
`;
