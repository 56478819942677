import { useState } from "react";
import styled from "styled-components";
import { EmailLogin } from "./Login/Login";
import { PreSignIn } from "./PreSignIn/PreSignIn";

export type FormState = "presignin" | "emailLogin";

type UserAccessType = {
  title?: string;
};

export function UserAccess({ title }: UserAccessType) {
  const [state, setState] = useState<FormState>("presignin");

  return (
    <UserAccessPageContainer>
      <UserAccessPage
        state={state}
        onStateChange={(newState) => setState(newState)}
        title={title}
      />
    </UserAccessPageContainer>
  );
}

type PageProps = {
  state: FormState;
  onStateChange: (newState: FormState) => void;
  title?: string;
};

function UserAccessPage({ state, ...rest }: PageProps) {
  switch (state) {
    case "presignin":
      return <PreSignIn {...rest} />;
    case "emailLogin":
      return <EmailLogin {...rest} />;
    default:
      throw new Error("Invalid page state in User Access");
  }
}

const UserAccessPageContainer = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;
