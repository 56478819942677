import { useIntl } from "react-intl";
import useCarHireRedirect from "src/utils/hooks/useCarHireRedirect";
import { SearchResultsPromo } from "../SearchResultsPromo/SearchResultsPromo";
import { Key } from "../../../svg/Key";
import messages from "./SearchResultsCarHirePromo.messages";

type Props = {
  className?: string;
};

export function SearchResultsCarHirePromo(props: Props) {
  const intl = useIntl();
  const handleCarHirePromoClicked = useCarHireRedirect();

  return (
    <SearchResultsPromo
      header={intl.formatMessage(messages.header)}
      tagline={intl.formatMessage(messages.tagline)}
      iconBackgroundColor="green"
      icon={<Key tint="white" />}
      onClick={handleCarHirePromoClicked}
      className={props.className}
    />
  );
}
