import { createContext, useContext } from "react";

export type FocusedElement = {
  id: string;
  index: number;
};

export type FocusContextState = {
  focusedElement: FocusedElement;
  onFocusChanged: (newFocusedElement: FocusedElement) => void;
};

export const defaultFocusedElement: FocusedElement = {
  id: "none",
  index: 0,
};

export const FocusContext = createContext<FocusContextState>({
  focusedElement: defaultFocusedElement,
  onFocusChanged: (newFocusedElement) => {},
});

export function useFocusState() {
  return useContext(FocusContext);
}
