import { ButtonBase } from "src/components/Button/ButtonBase";
import styled from "styled-components";
import Close from "src/svg/Close.svg?react";
import Marker from "src/svg/BrandDestination.svg?react";

import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { fontSize } from "src/theme";
import { Plus } from "src/svg/tripplanner/Plus";
import { font_weight } from "src/design-system/tokens/typography";
import { useIntl } from "react-intl";
import messages from "./PlaceInputList.messages";

type PlaceInputListProps = {
  places: string[];
  handleEdit: (index: number) => void;
  handleRemove: (index: number) => void;
};
export function PlaceInputList({
  places,
  handleEdit,
  handleRemove,
}: PlaceInputListProps) {
  const intl = useIntl();
  return (
    <WrapperDiv>
      <List>
        {places.length <= 2 ? (
          <>
            <Item>
              <StarterDiv $isEmpty={!places[0]} />
              <InputButton $isEmpty={!places[0]} onClick={() => handleEdit(0)}>
                <InputSpan>
                  {places[0] ?? intl.formatMessage(messages.startingFrom)}
                </InputSpan>
              </InputButton>
              <RemoveButton onClick={() => handleRemove(0)}>
                <Close width={12} height={12} color={color.icon.secondary} />
              </RemoveButton>
            </Item>
            {places[1] ? <LinkDiv /> : <DottedLine />}
            <Item>
              <MarkerSvg $isEmpty={!places[1]} />
              <InputButton $isEmpty={!places[1]} onClick={() => handleEdit(1)}>
                <InputSpan>
                  {places[1] ?? intl.formatMessage(messages.goingTo)}
                </InputSpan>
              </InputButton>
              <RemoveButton onClick={() => handleRemove(1)}>
                <Close width={12} height={12} color={color.icon.secondary} />
              </RemoveButton>
            </Item>
          </>
        ) : (
          places.map((place, index) => {
            return (
              <>
                <Item>
                  <DotDiv />
                  <InputButton onClick={() => handleEdit(index)}>
                    <InputSpan>{place}</InputSpan>
                  </InputButton>
                  <RemoveButton onClick={() => handleRemove(index)}>
                    <Close
                      width={12}
                      height={12}
                      color={color.icon.secondary}
                    />
                  </RemoveButton>
                </Item>
                {places.length - 1 !== index ? <LinkDiv /> : null}
              </>
            );
          })
        )}
      </List>
      <DottedLine />
      <AddButton>
        <AddSvg tint="pink" />
        {intl.formatMessage(messages.addDestination)}
      </AddButton>
    </WrapperDiv>
  );
}

function DottedLine() {
  return (
    <DottedLineDiv>
      <DottedLineSvg width="2" height="40">
        <line
          x1="1"
          y1="0"
          x2="1"
          y2="44"
          stroke={color.icon.secondary}
          strokeDashoffset="1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="0, 6"
        />
      </DottedLineSvg>
    </DottedLineDiv>
  );
}

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;

const List = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xs};
`;
const Item = styled.ul`
  display: flex;
  align-items: center;
  background-color: ${color.bg.surface.active};
  :first-child {
    border-radius: ${spacing.md} ${spacing.md} 0 0;
  }
  :last-child {
    border-radius: 0 0 ${spacing.md} ${spacing.md};
  }
`;

const DotDiv = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${color.icon.icon};
  margin-left: ${spacing.xl};
`;

const InputButton = styled(ButtonBase)<{ $isEmpty?: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: ${spacing.xl};
  padding-right: 0;
  flex: 1;
  font-size: ${fontSize.h5};
  overflow: hidden;
  white-space: nowrap;
  color: ${({ $isEmpty }) =>
    $isEmpty ? color.icon.icon : color.text.primary.active};
`;

const InputSpan = styled.span`
  text-align: left;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(ButtonBase)`
  display: flex;
  padding: ${spacing.xl};
`;

const StarterDiv = styled.div<{ $isEmpty: boolean }>`
  box-sizing: content-box;
  width: 5px;
  height: 5px;
  border: 6px solid
    ${({ $isEmpty }) => ($isEmpty ? color.bg.brand.fill : color.icon.icon)};
  border-radius: 50%;
  margin-left: 12.5px;
  z-index: 1;
`;

const MarkerSvg = styled(Marker)<{ $isEmpty: boolean }>`
  margin-left: 11px;
  z-index: 1;
  path {
    fill: ${({ $isEmpty }) =>
      $isEmpty ? color.bg.brand.fill : color.icon.icon};
  }
`;

const AddSvg = styled(Plus)`
  margin-left: 14px;
  margin-right: 14px;
  width: 14px;
  height: 14px;
  z-index: 1;
`;

const AddButton = styled(ButtonBase)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  color: ${color.bg.brand.fill};
  font-weight: ${font_weight.medium};
  padding: ${spacing.xl};
  padding-left: 0;
`;

const LinkDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
  ::before {
    position: absolute;
    content: "";
    height: 44px;
    border-left: 1px solid ${color.icon.secondary};
  }
`;

const DottedLineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 10px;
  margin: 0 ${spacing.xl};
`;
const DottedLineSvg = styled.svg`
  position: absolute;
`;
