import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type AuthProviderProps = {
  googleLoading: boolean;
  setGoogleLoading: Dispatch<SetStateAction<boolean>>;
  appleLoading: boolean;
  setAppleLoading: Dispatch<SetStateAction<boolean>>;
  setAuthProvidersLoading: (loading: boolean) => void;
};

const AuthContext = createContext<AuthProviderProps>({
  googleLoading: true,
  setGoogleLoading: () => {},
  appleLoading: true,
  setAppleLoading: () => {},
  setAuthProvidersLoading: () => {},
});

export function AuthProvider(props: PropsWithChildren<{}>) {
  const [googleLoading, setGoogleLoading] = useState(true);
  const [appleLoading, setAppleLoading] = useState(true);

  const setAuthProvidersLoading = useCallback(
    (loading: boolean) => {
      setGoogleLoading(loading);
      setAppleLoading(loading);
    },
    [setGoogleLoading, setAppleLoading]
  );

  const value = useMemo(
    () => ({
      googleLoading,
      setGoogleLoading,
      appleLoading,
      setAppleLoading,
      setAuthProvidersLoading,
    }),
    [
      googleLoading,
      setGoogleLoading,
      appleLoading,
      setAppleLoading,
      setAuthProvidersLoading,
    ]
  );

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export function useAuthProviders() {
  return useContext(AuthContext);
}
