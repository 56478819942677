import Cookies from "js-cookie";
import { useReducer } from "react";

export function useCookieState<T extends U, U = T | undefined>(
  name: string,
  defaultValue?: U,
  expires?: number | Date | undefined
): [U, (value: T) => void] {
  const initialCookieValue = Cookies.get(name);
  const initialValue = initialCookieValue
    ? JSON.parse(initialCookieValue)
    : defaultValue;

  // Wrapping the operations in useReducer causes React to refresh the component when the cookie changes
  return useReducer((_: U, value: T) => {
    Cookies.set(name, JSON.stringify(value), { expires });
    return value;
  }, initialValue);
}
