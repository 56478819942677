import { PropsWithChildren, useLayoutEffect, useState } from "react";
import { IntlConfig, IntlProvider } from "react-intl";
import { useRouteParams } from "./utils/useRouteParams";
import { loadLocaleData } from "./polyfill";

const DEFAULT_LANG = "en";
const DEFAULT_LOCALE = "en-gb";
const DEFAULT_MESSAGES = {};

export function L10nProvider(
  props: PropsWithChildren<{
    onError?: IntlConfig["onError"];
  }>
) {
  const { languageCode = DEFAULT_LANG } = useRouteParams();

  // Note: The locale is basically just the language for now, we might want to consider allowing culture-specific locales, e.g. en-us
  const locale = languageCode === DEFAULT_LANG ? DEFAULT_LOCALE : languageCode;

  // Load the locale & messages data and trigger this component and all our children to
  // re-render once they are loaded.
  const [messages, setMessages] = useState<IntlConfig["messages"]>();
  const [loadedLocale, setLoadedLocale] = useState<string>();

  useLayoutEffect(() => {
    // Import the messages
    if (languageCode in languageImportMap) {
      let cancel = false;
      Promise.all([
        languageImportMap[languageCode](),
        loadLocaleData(languageCode),
      ])
        .then(([messages]) => {
          if (cancel) {
            return;
          }
          setMessages(messages);
          setLoadedLocale(locale);
        })
        .catch(() => {
          setMessages(DEFAULT_MESSAGES);
          setLoadedLocale(DEFAULT_LOCALE);
        });
      return () => {
        cancel = true;
      };
    } else {
      // Fallback to english if we can't get the users language
      setMessages(DEFAULT_MESSAGES);
      setLoadedLocale(DEFAULT_LOCALE);
    }
  }, [languageCode, locale]);

  // Only render the IntlProvider once we have the locale and messages
  if (loadedLocale === undefined || messages === undefined) {
    return null;
  }

  return (
    <IntlProvider
      locale={loadedLocale}
      messages={messages}
      defaultLocale="en-gb"
      onError={props.onError}
    >
      {props.children}
    </IntlProvider>
  );
}

type LanguageMap = Record<string, () => Promise<IntlConfig["messages"]>>;
const languageImportMap: LanguageMap = {
  en: () => Promise.resolve({}),
  de: async () => (await import("./translations/translations-de.json")).default,
  es: async () => (await import("./translations/translations-es.json")).default,
  fr: async () => (await import("./translations/translations-fr.json")).default,
  it: async () => (await import("./translations/translations-it.json")).default,
  pt: async () => (await import("./translations/translations-pt.json")).default,
  ru: async () => (await import("./translations/translations-ru.json")).default,
  pl: async () => (await import("./translations/translations-pl.json")).default,
  nl: async () => (await import("./translations/translations-nl.json")).default,
  sv: async () => (await import("./translations/translations-sv.json")).default,
  no: async () => (await import("./translations/translations-no.json")).default,
  da: async () => (await import("./translations/translations-da.json")).default,
  ja: async () => (await import("./translations/translations-ja.json")).default,
  ko: async () => (await import("./translations/translations-ko.json")).default,
  th: async () => (await import("./translations/translations-th.json")).default,
  zh: async () => (await import("./translations/translations-zh.json")).default,
  ro: async () => (await import("./translations/translations-ro.json")).default,
  ms: async () => (await import("./translations/translations-ms.json")).default,
  cs: async () => (await import("./translations/translations-cs.json")).default,
  hu: async () => (await import("./translations/translations-hu.json")).default,
  el: async () => (await import("./translations/translations-el.json")).default,
  fi: async () => (await import("./translations/translations-fi.json")).default,
  sk: async () => (await import("./translations/translations-sk.json")).default,
  hr: async () => (await import("./translations/translations-hr.json")).default,
};
