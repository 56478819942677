import { useNavigate } from "react-router";
import { searchParamsChange } from "../location/searchParamsChange";
import { useLayout } from "./useLayout";
import { useSearchParams } from "./useSearchParams";
import { useTypedLocation } from "./useTypedLocation";

const queryBackendFlagsName = "backendFlags";
export const defaultFlags = [
  "AllPaths",
  "NoPrune",
  "NoPaths",
  "NoPolitical",
  "SimilarPaths",
  "NoCarDirections",
  "NoCityStationMapping",
  "NoRegionMapping",
];

type UseBackendFlagHook = {
  backendFlags: string;
  backendFlagsOptions: string[];
  addBackendFlag: (flagName: string) => void;
  removeBackendFlag: (flagName: string) => void;
  getBackendFlagValue: (flagName: string) => boolean;
};

export function useBackendFlags(): UseBackendFlagHook {
  const searchParams = useSearchParams();
  const layout = useLayout();
  const location = useTypedLocation();
  const navigate = useNavigate();

  const backendFlags = searchParams.get(queryBackendFlagsName) ?? "";
  const activeBackendFlags =
    searchParams
      .get(queryBackendFlagsName)
      ?.split(",")
      .map((flag) => checkFlagName(flag, defaultFlags)) ?? [];
  const backendFlagsOptions = Array.from(
    new Set([...defaultFlags, ...activeBackendFlags])
  );

  function navigateSearchParamsChange() {
    /** Without this, on select of a feature, the screen will update to search.
     *  We also don't want to force this on desktop, as it updates the pane on
     *  the left.
     *  */
    if (layout === "mobile") {
      navigate(searchParamsChange(searchParams, location), {
        replace: true,
        state: {
          highlightedTab: "preferences",
          preferencesScreen: "debug",
        },
      });
    } else {
      navigate(searchParamsChange(searchParams, location), {
        replace: true,
      });
    }
  }

  function addBackendFlag(flagName: string) {
    const flags = activeBackendFlags
      ? [...activeBackendFlags, flagName]
      : [flagName];

    searchParams.set(queryBackendFlagsName, flags.join(","));

    navigateSearchParamsChange();
  }

  function removeBackendFlag(flagName: string) {
    const flags: string[] = activeBackendFlags
      ? activeBackendFlags.filter((flag) => flag !== flagName)
      : [];
    if (flags.length > 0)
      searchParams.set(queryBackendFlagsName, flags.join(","));
    else searchParams.delete(queryBackendFlagsName);

    navigateSearchParamsChange();
  }

  function getBackendFlagValue(flagName: string) {
    return activeBackendFlags.includes(flagName);
  }

  return {
    backendFlags,
    backendFlagsOptions,
    addBackendFlag,
    removeBackendFlag,
    getBackendFlagValue,
  };
}

function checkFlagName(maybeFlagName: string, defaultFlags: string[]): string {
  // Want to check for a matching flag in a non-case-sensitive way
  const lowercaseSearchFlag = maybeFlagName.toLowerCase();
  for (const flagName of defaultFlags) {
    if (flagName.toLowerCase() === lowercaseSearchFlag) {
      return flagName;
    }
  }

  return maybeFlagName;
}
