import { SearchResponse } from "../../../../api/SearchResponse";
import {
  frequencyFromSegment,
  frequencyFromOption,
} from "../../../../utils/adapters/frequency";
import { durationInMinutesFromSegment } from "../../../../utils/adapters/duration";
import {
  lineNameFromNamesAndColors,
  LineName,
} from "../../../../utils/adapters/lineNames";
import { vehicleFromOption } from "../../../../utils/adapters/vehicle";
import {
  priceRangeFromSegment,
  PriceRange,
} from "../../../../utils/adapters/priceRange";
import { transitModeFromVehicleKind } from "../../../../utils/adapters/transitMode";
import { Mode } from "../../../../utils/types/mode";
import { Kind } from "../../../../api/Kind";

export function detailCellFromSegment(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number
): NoVehicleDetailCell {
  // Create the DetailCellProps for the first option
  const result = detailCellFromOption(response, routeIndex, segmentIndex, 0);
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[segmentIndex];
  const segment = response.segments[segmentLookupIndex];
  result.frequencyPerWeek = frequencyFromSegment(response, segment);
  return result;
}

function detailCellFromOption(
  response: SearchResponse,
  routeIndex: number,
  segmentIndex: number,
  optionIndex: number
): NoVehicleDetailCell {
  const route = response.routes[routeIndex];
  const segmentLookupIndex = route.segments[segmentIndex];
  const segment = response.segments[segmentLookupIndex];

  const option = response.options[segment.options[optionIndex]];
  const lineNames = [];
  const durationInMinutes = durationInMinutesFromSegment(response, segment);
  let distanceInMeters: number | undefined;
  const vehicle = vehicleFromOption(response, option);
  let carrierName: string | undefined;
  const stops = [];

  for (let i = 0; i < option.hops.length; i++) {
    const currentHopIndex = option.hops[i];
    const currentHop = response.hops[currentHopIndex];
    const line = response.lines[currentHop.line];

    stops.push(
      ...line.places
        .slice(1, line.places.length - 1)
        .map((placeIndex) => response.places[placeIndex].shortName)
    );

    // Collect the line names
    if (line.names) {
      const transitMode = transitModeFromVehicleKind(vehicle.kind);
      lineNames.push(
        ...lineNameFromNamesAndColors(line.names, line.colors, transitMode)
      );
    }

    // Collect and sum the distance
    if (line.distance) {
      if (!distanceInMeters) {
        distanceInMeters = 0;
      }
      distanceInMeters += line.distance;
    }

    if (!carrierName && currentHop.marketingCarrier !== undefined) {
      const carrier = response.carriers[currentHop.marketingCarrier];
      carrierName = carrier.name;
    }
  }

  const frequencyPerWeek = frequencyFromOption(response, option);
  const priceRange = priceRangeFromSegment(segment);

  return {
    type: "detail",
    transitMode: transitModeFromVehicleKind(vehicle.kind),
    vehicleKind: vehicle.kind,
    durationInMinutes,
    lineNames,
    segmentIndex: segmentIndex,
    distanceInMeters: distanceInMeters,
    id: `detail-${routeIndex}-${segmentIndex}-${optionIndex}`,
    frequencyPerWeek,
    priceRange: priceRange,
    carrierName,
    stops,
  };
}

export type DetailCell = NoVehicleDetailCell | VehicleDetailCell;

export type NoVehicleDetailCell = {
  type: "detail";
  id: string;
  durationInMinutes: number;
  transitMode: Mode;
  vehicleKind: Kind;
  lineNames: LineName[];
  frequencyPerWeek?: number;
  priceRange?: PriceRange;
  distanceInMeters?: number;
  segmentIndex: number;
  carrierName: string | undefined;
  stops: string[];
  carrierVehicleDetails?: string[];
};

export type VehicleDetailCell = NoVehicleDetailCell & {
  carrierVehicleDetails: string[];
};
